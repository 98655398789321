<template>
  <router-link :to="to" class="categoryCard__wrapper no-underline" :class="{ 'category-card-translucent': translucent }" @click="onClick">
    <div>
      <div class="title text-black pc:text-[48px] mobile:text-[32px] tracking-tight mb-2">
        {{ title }}
      </div>
      <div class="pc:text-caption-large mobile:text-caption-medium text-gray-50">{{ description }}</div>
    </div>

    <div>
      <icon-base><right-arrow /></icon-base>
    </div>
  </router-link>
</template>

<script>
import RightArrow from '@/icons/RightArrow';

export default {
  components: {
    RightArrow,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: 'Abril Fatface' !important;
  font-weight: 400;
  line-height: 100%;
}
.categoryCard {
  &__wrapper {
    @apply border-black border-solid border-b-2 border-x-0 border-t-0 flex items-center justify-between py-3;
  }
}

.category-card {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;

  background: rgba(0, 112, 188, 0.5);

  border: 1px solid $blue-50;
  backdrop-filter: blur(20px);
  border-radius: 8px;

  @include lg-and-up {
    height: 140px;
    flex-direction: column;
  }

  @include md {
    height: 140px;
  }

  &__content {
    @include md-and-up {
      align-self: flex-start;
    }
    h4 {
      @include spreadTypoMap($title-xsmall);

      @include md-and-up {
        @include spreadTypoMap($title-medium);
      }
    }

    h6 {
      margin-top: 8px;
      @include spreadTypoMap($body-xsmall);
      color: white;
      word-break: keep-all;
    }
  }

  svg {
    display: block;
    width: 24px;
    height: 24px;

    @include md-and-up {
      align-self: flex-end;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
