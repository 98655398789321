<template>
  <page title="미용회보">
    <template #aside-left></template>
    <template #main-section>
      <PdfViewer :source="source" />
    </template>
    <template #aside-right></template>
  </page>
</template>

<script>
import Page from '@/components/Page';
import PdfViewer from '@/views/PdfViewer';
import { useRoute } from 'vue-router';
import { regularReportDataList } from '@/constants/regularReport';

export default {
  name: 'RegularReportDetail',
  components: {
    PdfViewer,
    Page,
  },
  setup() {
    const route = useRoute();
    const reportId = route.params.reportId;

    const source = regularReportDataList[reportId];

    return { reportId, source };
  },
};
</script>

<style scoped lang="scss">
// board pc & mobile
.board-pc {
  display: none;

  @include md-and-up {
    display: block;
  }
}

.board-mobile {
  display: block;
  margin-top: 32px;
  padding: 0 20px 48px;

  @include md-and-up {
    display: none;
  }
}
</style>
