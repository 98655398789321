<template>
  <svg :width="Number(width) + 4" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="4" :width="width" height="1.6" rx="0.8" :fill="fill" />
    <rect x="2" y="11.2" :width="width" height="1.6" rx="0.8" :fill="fill" />
    <rect x="2" y="18.3999" :width="width" height="1.6" rx="0.8" :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#272F3E',
    },
    width: {
      type: String,
      default: '20',
    },
  },
};
</script>
