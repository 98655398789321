<template>
  <v-row justify="center">
    <v-col xl="6" lg="8" md="8" sm="10">
      <page title="회원 가입" center-title>
        <template #main-section>
          <process-tab :active-id="process" :items="steps"></process-tab>
          <v-window v-model="process" class="main-wrapper">
            <v-window-item v-for="step in steps" :key="step.id" :value="step.id">
              <component :is="step.components" :key="step.id" :go-back-step="goBackStep" :go-next-step="goNextStep" :signup-handler="signupHandler" />
            </v-window-item>
          </v-window>
          <div class="product-mall-wrapper">
            <product-mall-redirect-card />
          </div>
        </template>
      </page>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { signup } from '@/apis/user';

import SignupType from '@/containers/SignUp/SignupType.vue';
import TermsAccept from '@/containers/SignUp/TermsAccept.vue';
import InformationForm from '@/containers/SignUp/InformationForm.vue';
import Page from '@/components/Page.vue';
import Tabs from '@/components/Tab2.vue';
import ProcessTab from '@/containers/SignUp/ProcessTab.vue';
import ProductMallRedirectCard from '@/components/ProductMallRedirectCard.vue';

export default {
  components: { Page, Tabs, ProcessTab, ProductMallRedirectCard },
  setup() {
    const process = ref(1);
    const router = useRouter();
    const isAllowAdRef = ref(false);
    const isAllowPersonalInfoRef = ref(false);

    const steps = [
      { id: 1, label: '가입 유형', components: SignupType },
      { id: 2, label: '약관 동의', components: TermsAccept },
      { id: 3, label: '정보 입력', components: InformationForm },
    ];

    const goBackStep = () => {
      if (process.value === 1) {
        router.go(-1);
      } else {
        process.value -= 1;
      }
    };

    const goNextStep = (props = { isAllowAd: false, isAllowPersonalInfo: false }) => {
      isAllowAdRef.value = props.isAllowAd;
      isAllowPersonalInfoRef.value = props.isAllowPersonalInfo;

      process.value += 1;
    };

    const signupHandler = async (req) => {
      return await signup({ isAllowAd: isAllowAdRef.value, isAllowPersonalInfo: isAllowPersonalInfoRef.value, ...req });
    };

    return { steps, process, goBackStep, goNextStep, signupHandler };
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.product-mall-wrapper {
  margin-bottom: 3rem;
}
</style>
