<template>
  <div class="grettings">
    <v-img alt="president" src="@/assets/president.png" class="profile-img" max-width="25rem"></v-img>
    <div>
      <title-large-paragraph class-name="paragraph" :paragraphs="grettings"></title-large-paragraph>
    </div>
  </div>
</template>

<script>
import TitleLargeParagraph from '@/components/TitleLargeParagraph.vue';

export default {
  components: { TitleLargeParagraph },
  setup() {
    const grettings = [
      '사)대한미용사회중앙회는 항상 회원여러분들의 권익향상을 먼저 생각하겠습니다.',
      '사)대한미용사회중앙회는 전국 회원 상호간의 친목을 도모하고 공중위생과 국민보건의 향상에 기여하고 회원들의 권익향상을 위해 노력하고 있습니다.',
      '해방 이후부터 오랜 시간동안 미용인들의 구심점이 되어 미용인들의 위상제고와 대정부 미용정책 수립에 한 목소리를 내고 있습니다.',
      '지난 30여년 이상 매월 미용회보를 만들어 회원들에게 배포함으로써 미용계 소식과 새로운 트렌드와 테크닉을 제공함은 물론 시대에 발맞추어서 인터넷 위생교육, 인터넷 미용회보, 홈페이지 등으로 회원 상호간의 소통을 돕고 있습니다.',
      '미용산업의 발전과 미용인의 위상제고에 노력함은 물론 항상 미용인들에게 열려있는 대한미용사회가 되어 회원여러분들과 함께 하겠습니다.',
      '미용산업의 발전과 협회의 개혁에 좋은 아이디어가 있으시면 언제든지 이 곳 홈페이지에 제시해주시는 등 많은 관심이 필요합니다. 회원 여러분들의 성원에 보답하는 중앙회가 되도록 최선을 다 하겠습니다.',
      '감사합니다.',
      '사단법인 사)대한미용사회중앙회 회장 이선심',
    ];

    return { grettings };
  },
};
</script>

<style lang="scss" scoped>
.grettings {
  display: flex;

  @include md-and-down {
    align-items: center;
    flex-direction: column;
  }
}

.profile-img {
  width: 40%;

  @include md-and-down {
    width: 60%;
  }
}

.paragraph {
  padding: 1rem;
  align-items: start !important;

  :deep(.paragraph) {
    text-align: left;
  }
}
</style>
