import axiosInstance, { removeAuthHeader, setAuthHeader } from '@/lib/axiosController';
// import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useLoadingStore } from '@/stores/index';

const STORE_ID = 'user';
const REFRESH_LOGIN = 'REFRESH_LOGIN';
export const USER_ACTION_NAME = {
  [REFRESH_LOGIN]: `${STORE_ID}/refreshLogin`,
};

export default defineStore({
  id: STORE_ID,
  state: () => ({
    userView: {},
  }),
  getters: {
    getUserView: (state) => state.userView,
  },
  actions: {
    async login(payload) {
      const newLoginView = await axiosInstance.post('/user/login', payload);
      this.userView = newLoginView;
      localStorage.setItem('authToken', newLoginView.token);
      setAuthHeader(this.userView.token);
      window.location.href = '/';
    },
    async refreshLogin(token) {
      const loadingStore = useLoadingStore();

      try {
        loadingStore.setLoadingState(USER_ACTION_NAME.REFRESH_LOGIN, true);

        const newLoginView = await axiosInstance.post('/user/login-check', { token: token });
        setAuthHeader(newLoginView.token);
        localStorage.setItem('authToken', newLoginView.token);
        this.userView = newLoginView;
      } catch (e) {
        setTimeout(() => {
          this.logout();
        }, 500);
      } finally {
        loadingStore.setLoadingState(USER_ACTION_NAME.REFRESH_LOGIN, false);
      }
    },
    async logout() {
      removeAuthHeader();
      localStorage.removeItem('authToken');
      this.userView = {};
      window.location.href = '/';
    },
    checkLoginStatus() {
      return !!this.userView?.id;
    },
  },
});
