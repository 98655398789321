<template>
  <page :title="title" :doc-title="title" title-size="large" center-title>
    <template #main-section="{ isMobileUi }">
      <div class="content-wrapper">
        <div class="content-body">
          <tab2 v-model="selectedTab" :tabs="tabs" is-full-width />
          <find-id v-if="selectedTab === 'id'" :is-mobile-ui="isMobileUi" />
          <find-password v-else :is-mobile-ui="isMobileUi" />
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import Page from '@/components/Page';
import Tab2 from '@/components/Tab2';
import { FindId, FindPassword } from '@/containers/Find/components';

export default {
  name: 'FindPage',
  components: { Page, Tab2, FindId, FindPassword },
  props: {},
  setup() {
    const route = useRoute();
    const selectedTab = computed(() => route.params.type);
    const tabs = [
      { id: 'id', name: '아이디 찾기', path: '/find/id' },
      { id: 'password', name: '비밀번호 재설정', path: '/find/password' },
    ];

    const title = computed(() => (selectedTab.value === 'id' ? '아이디 찾기' : '비밀번호 재설정'));

    return { selectedTab, tabs, title };
  },
};
</script>

<style scoped lang="scss">
.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content-body {
  width: 100%;
  max-width: 600px;
}
</style>
