<template>
  <div class="table-wrapper">
    <div class="commitee-table-community-selector">
      <v-btn flat class="button-medium-line" type="button" @click="moveToCommiteeCommunityPage(100002)">위원회 게시판으로 이동하기</v-btn>
    </div>
    <table class="table">
      <tbody>
        <tr>
          <td class="table-tit" style="width: 30%; text-align: center">직위</td>
          <td class="table-tit" style="width: 30%; text-align: center">성명</td>
          <td class="table-tit" style="width: 40%; text-align: center">주소</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원장</td>
          <td class="table-2ed" style="text-align: center">이광종</td>
          <td class="table-3rd" style="text-align: center">서울 금천구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">자문위원</td>
          <td class="table-2ed" style="text-align: center">황애자</td>
          <td class="table-3rd" style="text-align: center">서울 중랑구</td>
        </tr>
        <tr>
          <td class="table-1st" height="88" rowspan="4" style="text-align: center">
            부위원장<br />
            (4명)
          </td>
          <td class="table-2ed" style="text-align: center">남양순</td>
          <td class="table-3rd" style="text-align: center">강원 원주시</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">정연희</td>
          <td class="table-3rd" style="text-align: center">경기도 수원시 권선구</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">채순단</td>
          <td class="table-3rd" style="text-align: center">서울 은평구</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">노대영</td>
          <td class="table-3rd" style="text-align: center">전남 화순군</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">권옥순</td>
          <td class="table-3rd" style="text-align: center">경기도 가평군</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">이순녀</td>
          <td class="table-3rd" style="text-align: center">경남 진주시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">이문희</td>
          <td class="table-3rd" style="text-align: center">경기 고양시 덕양구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">이정실</td>
          <td class="table-3rd" style="text-align: center">전남 나주시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">김미숙</td>
          <td class="table-3rd" style="text-align: center">인천 남동구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">임준경</td>
          <td class="table-3rd" style="text-align: center">경기 부천시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">박경옥</td>
          <td class="table-3rd" style="text-align: center">대구시 수성구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">이정옥</td>
          <td class="table-3rd" style="text-align: center">충남 보령시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">배희자</td>
          <td class="table-3rd" style="text-align: center">경기 화성시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">김희숙</td>
          <td class="table-3rd" style="text-align: center">전남 고흥군</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">이애숙</td>
          <td class="table-3rd" style="text-align: center">전남 보성군</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">박정해</td>
          <td class="table-3rd" style="text-align: center">부산 중구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">안귀례</td>
          <td class="table-3rd" style="text-align: center">서울시 은평구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">손월순</td>
          <td class="table-3rd" style="text-align: center">충남 당진시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">심근하</td>
          <td class="table-3rd" style="text-align: center">경남 창원시 마산합포구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">강복순</td>
          <td class="table-3rd" style="text-align: center">충남 당진시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">이호준</td>
          <td class="table-3rd" style="text-align: center">경기 하남시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">김경숙</td>
          <td class="table-3rd" style="text-align: center">충남 보령시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">김미숙</td>
          <td class="table-3rd" style="text-align: center">경남 김해시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">박선자</td>
          <td class="table-3rd" style="text-align: center">경남 창원시 진해구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">이정훈</td>
          <td class="table-3rd" style="text-align: center">경남 함양군</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">윤 희</td>
          <td class="table-3rd" style="text-align: center">경기 화성시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">이나경</td>
          <td class="table-3rd" style="text-align: center">경기 고양시 일산서구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">최연숙</td>
          <td class="table-3rd" style="text-align: center">경남 창원시 의창구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">정은주</td>
          <td class="table-3rd" style="text-align: center">경기도 파주시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">최은경</td>
          <td class="table-3rd" style="text-align: center">서울시 광진구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">김영배</td>
          <td class="table-3rd" style="text-align: center">경기 남양주시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">왕상석</td>
          <td class="table-3rd" style="text-align: center">전북 전주시 완산구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">강소연</td>
          <td class="table-3rd" style="text-align: center">서울시 도봉구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">김 겸</td>
          <td class="table-3rd" style="text-align: center">전남 여수시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">한중석</td>
          <td class="table-3rd" style="text-align: center">충북 충주시</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">박정윤</td>
          <td class="table-3rd" style="text-align: center">서울 강북구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">유영례</td>
          <td class="table-3rd" style="text-align: center">인천 계양구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">성이진</td>
          <td class="table-3rd" style="text-align: center">서울 강서구</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위원</td>
          <td class="table-2ed" style="text-align: center">오영진</td>
          <td class="table-3rd" style="text-align: center">서울 종로구</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { moveToCommiteeCommunityPage } from '@/components/committee/commonUtil';

export default {
  name: 'MarketingTable',
  components: {},
  props: {},
  setup() {
    return { moveToCommiteeCommunityPage };
  },
};
</script>

<style scoped lang="scss">
.commitee-table-community-selector:deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 15px;

  .branch-selects {
    .branch-selector {
      max-width: unset;
      width: auto;
    }
  }
}
</style>
