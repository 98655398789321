<template>
  <!-- 디자이너 한마디 -->
  <div class="introWrapper">
    <div class="mr-16">
      <div class="desc__intro">강사소개</div>
      <div class="desc__name">Jawed Habib</div>
      <div class="desc__text mb-8">현) 루아블랑 원장, 헤어디자이너</div>
      <div class="careerWrapper__profile">
        <v-img class="careerWrapper__profile" alt="" src="@/assets/education-mocking-detail-designer-example.png" width="240px" height="240px" />
      </div>
    </div>

    <div class="desc__body">
      <div class="desc__section">
        <div class="desc__body-title mb-6">안녕하세요, 헤어 디자이너 Jawed Habib 입니다.<br />저만의 헤어 디자인 철학이 만들어졌습니다.</div>
        <p>
          시술을 막 마친 머리는 대체로 만족스럽지만 집에 오면 고객님 스스로 그 스타일을 다시 만들기가 어렵죠.<br />
          여기서부터 저만의 헤어 디자인 철학이 만들어졌습니다.
        </p>

        <p>
          매일 스타일링하는 것도 어렵고 제품을 바르는 것도 귀찮은<br />
          아시안 모발의 표준을 세우는 것을 목표로 커리어를 쌓아왔습니다. 그리고 그것을 이론과 테크닉으로 정립해서 <br />
          혼자 스타일링을 해도 만족스러운 머리, 시술 다음 날 머리를 감고 이번 클래스를 수강하신다면 연출할 수 있습니다.
        </p>
      </div>

      <div class="desc__section mb-0">
        <div class="desc__body-title mb-6">이력 및 경력</div>
        <div class="careerWrapper__intro__body">
          <strong>[이력]</strong><br />
          - U.S.A SASSOON BARBERING TEACHER'S course<br />
          - NL SCHOREM THE OLD SCHOOL CUTTING & SHAVING course complete<br />
          - U.K MENSPIRE EDUCATION CUTTING course complete<br />
          - Portugal FIGAROS BARBERING course complete<br />
          - U.K LONDON SCHOOL OF BARBER ACADEMY 5DAYS FADE course complete<br />
          - U.K SASSOON MEN'S CUT Technician<br />
          - U.K SASSOON ABC CUT Technician
        </div>

        <br />

        <div class="careerWrapper__intro__body">
          <strong>[교육 이력 사항]</strong><br />
          - SASSOON abc CUT/BARBERING INSTRUCTOR in JUNO ACADEMY<br />
          - THIS IS BARBERING 오프라인 베이직 클래스<br />
          - THIS IS BARBERING 오프라인 어드반스 클래스<br />
          - 한국 기술대학교 주관 뷰티산업연구소 주최 기술강사 보수교육 강의<br />
          - 건국대 대전과학기술대 남성 커트 강의<br />
          - 서울예술종합학교, 경복대, 명지대 외 다수의 대학과 고등학교에서 특강 & 세미나
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style lang="scss" scoped>
.introWrapper {
  display: flex;
  background-color: $gray-80;
  padding: 5rem;
  border-radius: 20px;
}

.careerWrapper {
  display: flex;
  justify-content: center;
  padding: 5rem;

  &__profile {
    border-radius: 50%;
    background-color: rgb(4, 4, 4);
    width: 240px;
    height: 240px;
  }

  &__intro {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__name {
    }

    &__body {
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      color: #a0a6b1;
    }
  }
}

.desc {
  &__intro {
    color: $koba-red;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 1rem;
  }

  &__section {
    margin-bottom: 80px;
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
  }

  &__name {
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    margin-bottom: 4px;
    color: white;
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: white;
  }

  &__body {
    color: white;

    &-title {
      font-weight: 600;
      font-size: 28px;
      line-height: 150%;
    }

    &-text {
      font-weight: 500;
      font-size: 18px;
      color: #f4f5f7;
    }
  }
}
</style>
