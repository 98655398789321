<template>
  <div class="table-wrapper">
    <div class="commitee-table-community-selector">
      <v-btn flat class="button-medium-line" type="button" @click="moveToCommiteeCommunityPage(100006)">위원회 게시판으로 이동하기</v-btn>
    </div>
    <table-with-photo number="1">
      <tr>
        <td>1</td>
        <td>1기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2313.jpg&amp;filename=김진숙.jpg" alt="" style="width: 80px" />
        </td>
        <td>김진숙</td>
        <td>한울이미용실</td>
      </tr>
      <tr>
        <td>2</td>
        <td>1기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2314_1.jpg&amp;filename=정매자.jpg" alt="" style="width: 80px" />
        </td>
        <td>정매자</td>
        <td>정정원헤어룩</td>
      </tr>
      <tr>
        <td>3</td>
        <td>1기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2315.jpg&amp;filename=최태연.jpg" alt="" style="width: 80px" />
        </td>
        <td>최태연</td>
        <td>최태연헤어연구소</td>
      </tr></table-with-photo
    >
    <table-with-photo number="2">
      <tr>
        <td>1</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2316.jpg&amp;filename=김민정.jpg" alt="" style="width: 80px" />
        </td>
        <td>김민정</td>
        <td>아담헤어삽</td>
      </tr>
      <tr>
        <td>2</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2317.jpg&amp;filename=박은수.jpg" alt="" style="width: 80px" />
        </td>
        <td>박은수</td>
        <td>마르꾸파리헤어샵</td>
      </tr>
      <tr>
        <td>3</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2318.jpg&amp;filename=송순복.jpg" alt="" style="width: 80px" />
        </td>
        <td>송순복</td>
        <td>송순복헤어월드</td>
      </tr>
      <tr>
        <td>4</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2319.jpg&amp;filename=심봉섭.jpg" alt="" style="width: 80px" />
        </td>
        <td>심봉섭</td>
        <td>하늘미용실</td>
      </tr>
      <tr>
        <td>5</td>
        <td>2기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2320.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>심선자</td>
        <td>상희미용실</td>
      </tr>
      <tr>
        <td>6</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2321.jpg&amp;filename=양애순.jpg" alt="" style="width: 80px" />
        </td>
        <td>양애순</td>
        <td>중동리안헤어</td>
      </tr>
      <tr>
        <td>7</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2322.jpg&amp;filename=유시자.jpg" alt="" style="width: 80px" />
        </td>
        <td>유시자</td>
        <td>류시자뷰티오헤어</td>
      </tr>
      <tr>
        <td>8</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2323.jpg&amp;filename=이경애.jpg" alt="" style="width: 80px" />
        </td>
        <td>이경애</td>
        <td>참빗미용실</td>
      </tr>
      <tr>
        <td>9</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2324.jpg&amp;filename=이복자.jpg" alt="" style="width: 80px" />
        </td>
        <td>이복자</td>
        <td>이경은헤어팜</td>
      </tr>
      <tr>
        <td>10</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2325.jpg&amp;filename=이영숙.jpg" alt="" style="width: 80px" />
        </td>
        <td>이영숙</td>
        <td>사틴헤어살롱</td>
      </tr>
      <tr>
        <td>11</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2326.jpg&amp;filename=이해분.jpg" alt="" style="width: 80px" />
        </td>
        <td>이해분</td>
        <td>사틴헤어살롱(본점)</td>
      </tr>
      <tr>
        <td>12</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2327.jpg&amp;filename=이화자.jpg" alt="" style="width: 80px" />
        </td>
        <td>이화자</td>
        <td>리가토탈헤어클럽</td>
      </tr>
      <tr>
        <td>13</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2328.jpg&amp;filename=이효지.jpg" alt="" style="width: 80px" />
        </td>
        <td>이효지</td>
        <td>효지헤어뷰</td>
      </tr>
      <tr>
        <td>14</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2329.jpg&amp;filename=임선희.jpg" alt="" style="width: 80px" />
        </td>
        <td>임선희</td>
        <td>에이큐미용타운</td>
      </tr>
      <tr>
        <td>15</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2330.jpg&amp;filename=임순화.jpg" alt="" style="width: 80px" />
        </td>
        <td>임순화</td>
        <td>세븐미용실</td>
      </tr>
      <tr>
        <td>16</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2331.jpg&amp;filename=임영례.jpg" alt="" style="width: 80px" />
        </td>
        <td>임영례</td>
        <td>임영례헤어.컴</td>
      </tr>
      <tr>
        <td>17</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2332.jpg&amp;filename=정기분.jpg" alt="" style="width: 80px" />
        </td>
        <td>정기분</td>
        <td>오컷헤어살롱</td>
      </tr>
      <tr>
        <td>18</td>
        <td>2기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2333.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>정영옥</td>
        <td>스마트머리방</td>
      </tr>
      <tr>
        <td>19</td>
        <td>2기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2334.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>최미숙</td>
        <td>샤론코베라</td>
      </tr>
      <tr>
        <td>20</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2335.jpg&amp;filename=하옥현.jpg" alt="" style="width: 80px" />
        </td>
        <td>하옥현</td>
        <td>옥수헤어모드</td>
      </tr>
      <tr>
        <td>21</td>
        <td>2기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2336.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>허숙희</td>
        <td>수정미용실</td>
      </tr></table-with-photo
    >

    <table-with-photo number="3">
      <tr>
        <td>1</td>
        <td>3기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2337.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>권미숙</td>
        <td>신데렐라미용실</td>
      </tr>
      <tr>
        <td>2</td>
        <td>3기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2338.jpg&amp;filename=김경란.jpg" alt="" style="width: 80px" />
        </td>
        <td>김경란</td>
        <td>김경란미용예술박사랩</td>
      </tr>
      <tr>
        <td>3</td>
        <td>3기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2339.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김순덕</td>
        <td>김순덕뷰티살롱</td>
      </tr>
      <tr>
        <td>4</td>
        <td>3기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2340.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김영숙</td>
        <td>김영숙헤어스쿨</td>
      </tr>
      <tr>
        <td>5</td>
        <td>3기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2341.jpg&amp;filename=박주연.jpg" alt="" style="width: 80px" />
        </td>
        <td>박주연</td>
        <td>스크린헤어살롱(가양역점)</td>
      </tr>
      <tr>
        <td>6</td>
        <td>3기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2342.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>서정옥</td>
        <td>백설미용실</td>
      </tr>
      <tr>
        <td>7</td>
        <td>3기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2343.jpg&amp;filename=안창순.jpg" alt="" style="width: 80px" />
        </td>
        <td>안창순</td>
        <td>수지헤어샾</td>
      </tr>
      <tr>
        <td>8</td>
        <td>3기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2344.jpg&amp;filename=이순.jpg" alt="" style="width: 80px" />
        </td>
        <td>이 순</td>
        <td>한국미용박물관</td>
      </tr>
      <tr>
        <td>9</td>
        <td>3기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2345.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이춘란</td>
        <td>란헤어샾</td>
      </tr>
      <tr>
        <td>10</td>
        <td>3기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2346.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>임경숙</td>
        <td></td>
      </tr>
      <tr>
        <td>11</td>
        <td>3기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2347.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>조성순</td>
        <td>가위소리미용실</td>
      </tr>
      <tr>
        <td>12</td>
        <td>3기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2348.jpg&amp;filename=조순남.jpg" alt="" style="width: 80px" />
        </td>
        <td>조순남</td>
        <td>조희미용실</td>
      </tr>
      <tr>
        <td>13</td>
        <td>3기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2349.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>채문희</td>
        <td>문희리안(화서점)</td>
      </tr>
    </table-with-photo>

    <table-with-photo number="4">
      <tr>
        <td>1</td>
        <td>4기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2350.jpg&amp;filename=강순자.jpg" alt="" style="width: 80px" />
        </td>
        <td>강순자</td>
        <td>이가자 헤어비스</td>
      </tr>
      <tr>
        <td>2</td>
        <td>4기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2351.jpg&amp;filename=고민화.jpg" alt="" style="width: 80px" />
        </td>
        <td>고민화</td>
        <td>고미나헤어모드</td>
      </tr>
      <tr>
        <td>3</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2352.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>고정화</td>
        <td>잉꼬헤어샵</td>
      </tr>
      <tr>
        <td>4</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2353.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김경희</td>
        <td>김경희헤어샵</td>
      </tr>
      <tr>
        <td>5</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2354.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김미숙</td>
        <td>로즈헤어샵</td>
      </tr>
      <tr>
        <td>6</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2355.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김선옥</td>
        <td>김도연헤어</td>
      </tr>
      <tr>
        <td>7</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2356.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김원현</td>
        <td>샤올헤어펌</td>
      </tr>
      <tr>
        <td>8</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2357.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>남금희</td>
        <td>버르장머리헤어팡팡</td>
      </tr>
      <tr>
        <td>9</td>
        <td>4기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2358.jpg&amp;filename=박미홍.jpg" alt="" style="width: 80px" />
        </td>
        <td>박미홍</td>
        <td>쎄시박헤어</td>
      </tr>
      <tr>
        <td>10</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2359.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박용자</td>
        <td>스타일헤어샵</td>
      </tr>
      <tr>
        <td>11</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2360.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박은경</td>
        <td>동그라미</td>
      </tr>
      <tr>
        <td>12</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2361.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>석인자</td>
        <td>석인자헤어아트</td>
      </tr>
      <tr>
        <td>13</td>
        <td>4기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2362.jpg&amp;filename=신송자.jpg" alt="" style="width: 80px" />
        </td>
        <td>신송자</td>
        <td>신송자여우만들기</td>
      </tr>
      <tr>
        <td>14</td>
        <td>4기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2363.jpg&amp;filename=오술생.jpg" alt="" style="width: 80px" />
        </td>
        <td>오술생</td>
        <td>한빛머리방</td>
      </tr>
      <tr>
        <td>15</td>
        <td>4기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2365.jpg&amp;filename=오창현.jpg" alt="" style="width: 80px" />
        </td>
        <td>오창현</td>
        <td>오선영헤어갤러리</td>
      </tr>
      <tr>
        <td>16</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2366.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>윤계순</td>
        <td>윤 헤어라인</td>
      </tr>
      <tr>
        <td>17</td>
        <td>4기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2364.jpg&amp;filename=이숙임.jpg" alt="" style="width: 80px" />
        </td>
        <td>이숙임</td>
        <td>명성미용실</td>
      </tr>
      <tr>
        <td>18</td>
        <td>4기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2367_1.jpg&amp;filename=이점숙.jpg" alt="" style="width: 80px" />
        </td>
        <td>이점숙</td>
        <td>이노뷰티</td>
      </tr>
      <tr>
        <td>19</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2376.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이혜숙</td>
        <td>헤나컷트클럽</td>
      </tr>
      <tr>
        <td>20</td>
        <td>4기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2368.jpg&amp;filename=장은순.jpg" alt="" style="width: 80px" />
        </td>
        <td>장은순</td>
        <td>버블헤어샵</td>
      </tr>
      <tr>
        <td>21</td>
        <td>4기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2369.jpg&amp;filename=전지인.jpg" alt="" style="width: 80px" />
        </td>
        <td>전지인</td>
        <td>영 헤어라인</td>
      </tr>
      <tr>
        <td>22</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2370.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>조광임</td>
        <td>진 미용실</td>
      </tr>
      <tr>
        <td>23</td>
        <td>4기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2371.jpg&amp;filename=조현지.jpg" alt="" style="width: 80px" />
        </td>
        <td>조현지</td>
        <td>C&amp;C 헤어</td>
      </tr>
      <tr>
        <td>24</td>
        <td>4기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2372.jpg&amp;filename=최복자.jpg" alt="" style="width: 80px" />
        </td>
        <td>최복자</td>
        <td>노블헤어팀</td>
      </tr>
      <tr>
        <td>25</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2373.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>최성열</td>
        <td>찬미헤어</td>
      </tr>
      <tr>
        <td>26</td>
        <td>4기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2374.jpg&amp;filename=한미림.jpg" alt="" style="width: 80px" />
        </td>
        <td>한미림</td>
        <td>헤어큐비즘</td>
      </tr>
      <tr>
        <td>27</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2375.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>황미옥</td>
        <td>비비추헤어살롱</td>
      </tr></table-with-photo
    >

    <table-with-photo number="5">
      <tr>
        <td>1</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2377.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김미숙</td>
        <td>개성시대헤어컬렉션</td>
      </tr>
      <tr>
        <td>2</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2378.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김정아</td>
        <td>김정아헤어뷰</td>
      </tr>
      <tr>
        <td>3</td>
        <td>5기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2379.jpg&amp;filename=방소림.jpg" alt="" style="width: 80px" />
        </td>
        <td>방소림</td>
        <td>라브릿지헤어</td>
      </tr>
      <tr>
        <td>4</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2380.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>손옥자</td>
        <td>새정헤어</td>
      </tr>
      <tr>
        <td>5</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2381.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>장명희</td>
        <td>라브릿지</td>
      </tr>
      <tr>
        <td>6</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2382.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>장순정</td>
        <td>디에쎄살롱드</td>
      </tr>
      <tr>
        <td>7</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2383.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>정미향</td>
        <td>정미향헤어토크</td>
      </tr>
      <tr>
        <td>8</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2384.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>조영자</td>
        <td>투쓰리미용실</td>
      </tr>
      <tr>
        <td>9</td>
        <td>5기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2385.jpg&amp;filename=주외숙.jpg" alt="" style="width: 80px" />
        </td>
        <td>주외숙</td>
        <td>잰틀맨헤어</td>
      </tr>
      <tr>
        <td>10</td>
        <td>5기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2386.jpg&amp;filename=최정민.jpg" alt="" style="width: 80px" />
        </td>
        <td>최정민</td>
        <td>리안헤어</td>
      </tr>
      <tr>
        <td>11</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2387.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>최진순</td>
        <td>최진순헤어&amp;메이크업</td>
      </tr>
      <tr>
        <td>12</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2388.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>황경옥</td>
        <td>헤어마마</td>
      </tr></table-with-photo
    >

    <table-with-photo number="6">
      <tr>
        <td>1</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2389.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>강선희</td>
        <td>헤어두비취</td>
      </tr>
      <tr>
        <td>2</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2390.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>강우정</td>
        <td>리안헤어</td>
      </tr>
      <tr>
        <td>3</td>
        <td>6기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2391.jpg&amp;filename=권정란.jpg" alt="" style="width: 80px" />
        </td>
        <td>권정란</td>
        <td>사쿠토헤어</td>
      </tr>
      <tr>
        <td>4</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2392.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김선희</td>
        <td>앙포레모리노헤어</td>
      </tr>
      <tr>
        <td>5</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2393.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박춘묵</td>
        <td>외출준비오다헤어</td>
      </tr>
      <tr>
        <td>6</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2394.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박현순</td>
        <td>박현순헤어</td>
      </tr>
      <tr>
        <td>7</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2395.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>양승지</td>
        <td>춤추는 가위</td>
      </tr>
      <tr>
        <td>8</td>
        <td>6기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2396.jpg&amp;filename=오정희.jpg" alt="" style="width: 80px" />
        </td>
        <td>오정희</td>
        <td>오정희미용실</td>
      </tr>
      <tr>
        <td>9</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2397.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이명순</td>
        <td>미소헤어룩</td>
      </tr>
      <tr>
        <td>10</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2398.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이명희</td>
        <td>솔잎미용실</td>
      </tr>
      <tr>
        <td>11</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2399.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이숙이</td>
        <td>진희미용실</td>
      </tr>
      <tr>
        <td>12</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2400.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이영자</td>
        <td>이영자헤어살롱</td>
      </tr>
      <tr>
        <td>13</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2401.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이옥주</td>
        <td>예솔헤어</td>
      </tr>
      <tr>
        <td>14</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2402.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이은경</td>
        <td>이은경헤어살랑</td>
      </tr>
      <tr>
        <td>15</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2403.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>장화선</td>
        <td>장화선헤어</td>
      </tr>
      <tr>
        <td>16</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2404.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>정선자</td>
        <td>정선자헤어라인</td>
      </tr>
      <tr>
        <td>17</td>
        <td>6기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2405.jpg&amp;filename=조연숙.jpg" alt="" style="width: 80px" />
        </td>
        <td>조연숙</td>
        <td>조연숙헤어라인</td>
      </tr>
      <tr>
        <td>18</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2406.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>차정미</td>
        <td>차정미헤어살롱</td>
      </tr>
      <tr>
        <td>19</td>
        <td>6기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2407.jpg&amp;filename=최은경.jpg" alt="" style="width: 80px" />
        </td>
        <td>최은경</td>
        <td>하이클라스</td>
      </tr></table-with-photo
    >

    <table-with-photo number="7">
      <tr>
        <td>1</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2408.jpg&amp;filename=김명선.jpg" alt="" style="width: 80px" />
        </td>
        <td>김명선</td>
        <td>김명선헤어카페</td>
      </tr>
      <tr>
        <td>2</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2409.jpg&amp;filename=김순금.jpg" alt="" style="width: 80px" />
        </td>
        <td>김순금</td>
        <td>루키헤어</td>
      </tr>
      <tr>
        <td>3</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2410.jpg&amp;filename=김윤경.jpg" alt="" style="width: 80px" />
        </td>
        <td>김윤경</td>
        <td>컬앤깍새</td>
      </tr>
      <tr>
        <td>4</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2411.jpg&amp;filename=김윤순.jpg" alt="" style="width: 80px" />
        </td>
        <td>김윤순</td>
        <td>스타미용실</td>
      </tr>
      <tr>
        <td>5</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2412.jpg&amp;filename=김정숙.jpg" alt="" style="width: 80px" />
        </td>
        <td>김정숙</td>
        <td>김정숙뷰티살롱</td>
      </tr>
      <tr>
        <td>6</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2413.jpg&amp;filename=김정순.jpg" alt="" style="width: 80px" />
        </td>
        <td>김정순</td>
        <td>선화미용실</td>
      </tr>
      <tr>
        <td>7</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2414.jpg&amp;filename=김진란.jpg" alt="" style="width: 80px" />
        </td>
        <td>김진란</td>
        <td>비엔나헤어비스</td>
      </tr>
      <tr>
        <td>8</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2415.jpg&amp;filename=김창교.jpg" alt="" style="width: 80px" />
        </td>
        <td>김창교</td>
        <td>화미주아리클헤어</td>
      </tr>
      <tr>
        <td>9</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2416.jpg&amp;filename=김현자.jpg" alt="" style="width: 80px" />
        </td>
        <td>김현자</td>
        <td>이은헤어센스</td>
      </tr>
      <tr>
        <td>10</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2417.jpg&amp;filename=박선희.jpg" alt="" style="width: 80px" />
        </td>
        <td>박선희</td>
        <td>써니</td>
      </tr>
      <tr>
        <td>11</td>
        <td>7기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2418.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박재순</td>
        <td>장원미용실</td>
      </tr>
      <tr>
        <td>12</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2419.jpg&amp;filename=서민서.jpg" alt="" style="width: 80px" />
        </td>
        <td>서민서</td>
        <td>댕기헤어크리닉</td>
      </tr>
      <tr>
        <td>13</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2420.jpg&amp;filename=서선민.jpg" alt="" style="width: 80px" />
        </td>
        <td>서선민</td>
        <td>서서민헤어</td>
      </tr>
      <tr>
        <td>14</td>
        <td>7기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2421.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>심영숙</td>
        <td>덕일헤어코디</td>
      </tr>
      <tr>
        <td>15</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2422.jpg&amp;filename=오인자.jpg" alt="" style="width: 80px" />
        </td>
        <td>오인자</td>
        <td>오&amp;뷰티헤어</td>
      </tr>
      <tr>
        <td>16</td>
        <td>7기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2423.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이미정</td>
        <td>엘미강헤어(유천점)</td>
      </tr>
      <tr>
        <td>17</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2424.jpg&amp;filename=이상례.jpg" alt="" style="width: 80px" />
        </td>
        <td>이상례</td>
        <td>모아헤어샵</td>
      </tr>
      <tr>
        <td>18</td>
        <td>7기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2425.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이윤심</td>
        <td>윤헤어샾</td>
      </tr>
      <tr>
        <td>19</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2426.jpg&amp;filename=이재순.jpg" alt="" style="width: 80px" />
        </td>
        <td>이재순</td>
        <td>이재순미용실</td>
      </tr>
      <tr>
        <td>20</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2427.jpg&amp;filename=이정연.jpg" alt="" style="width: 80px" />
        </td>
        <td>이정연</td>
        <td>헤어펌</td>
      </tr>
      <tr>
        <td>21</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2428.jpg&amp;filename=장광분.jpg" alt="" style="width: 80px" />
        </td>
        <td>장광분</td>
        <td>이사라</td>
      </tr>
      <tr>
        <td>22</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2429.jpg&amp;filename=장지의.jpg" alt="" style="width: 80px" />
        </td>
        <td>장지의</td>
        <td>장지의헤어</td>
      </tr>
      <tr>
        <td>23</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2430.jpg&amp;filename=정찬이.jpg" alt="" style="width: 80px" />
        </td>
        <td>정찬이</td>
        <td>라인뷰티샵</td>
      </tr>
      <tr>
        <td>24</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2431.jpg&amp;filename=조은숙.jpg" alt="" style="width: 80px" />
        </td>
        <td>조은숙</td>
        <td>천연염색제이헤어샵</td>
      </tr>
      <tr>
        <td>25</td>
        <td>7기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2432.jpg&amp;filename=홍서여.jpg" alt="" style="width: 80px" />
        </td>
        <td>홍서여</td>
        <td>뽀끄레</td>
      </tr></table-with-photo
    >

    <table-with-photo number="8">
      <tr>
        <td>1</td>
        <td>8기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2758_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김지영</td>
        <td>하나미용실</td>
      </tr>
      <tr>
        <td>2</td>
        <td>8기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2759_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>남희정</td>
        <td>라브리지</td>
      </tr>
      <tr>
        <td>3</td>
        <td>8기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2760_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박선</td>
        <td>까미헤어</td>
      </tr>
      <tr>
        <td>4</td>
        <td>8기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2761_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박선자</td>
        <td>박선자헤어샵</td>
      </tr>
      <tr>
        <td>5</td>
        <td>8기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2762_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박옥희</td>
        <td>여왕프로헤어</td>
      </tr>
      <tr>
        <td>6</td>
        <td>8기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2763_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박정희</td>
        <td>박정희헤어</td>
      </tr>
      <tr>
        <td>7</td>
        <td>8기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2764_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이애원</td>
        <td>이소윤프로헤어</td>
      </tr>
      <tr>
        <td>8</td>
        <td>8기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2765_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>조현경</td>
        <td>민헤어</td>
      </tr>
      <tr>
        <td>9</td>
        <td>8기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2766_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>진태숙</td>
        <td>미사랑헤어샵</td>
      </tr>
      <tr>
        <td>10</td>
        <td>8기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_13&amp;savefilename=bbs_13_2767_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>하예담</td>
        <td>에니미용실</td>
      </tr></table-with-photo
    >
  </div>
</template>

<script>
import TableWithPhoto from '@/components/committee/TableWithPhoto';
import { moveToCommiteeCommunityPage } from '@/components/committee/commonUtil';

export default {
  components: { TableWithPhoto },
  setup() {
    return { moveToCommiteeCommunityPage };
  },
};
</script>
<style scoped lang="scss">
.commitee-table-community-selector:deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -10px;

  .branch-selects {
    .branch-selector {
      max-width: unset;
      width: auto;
    }
  }
}
</style>
