<template>
  <div v-if="articles.length === 0" class="empty_wrapper"><empty-data-card /></div>
  <v-row v-else class="wrapper">
    <template v-for="(article, idx) in articles" :key="article.id">
      <subtitle-text-row-vue :rank="idx + 1" :src="article.thumbnail" :title="article.title" :desc="article.bodySummary" :news-id="article.id" />
    </template>
  </v-row>
</template>

<script>
import EmptyDataCard from '@/components/EmptyDataCard.vue';
import SubtitleTextRowVue from '@/components/SubtitleTextRow.vue';

export default {
  components: { EmptyDataCard, SubtitleTextRowVue },
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.empty_wrapper {
  @apply pc:pt-[32px] pc:pb-[72px] mobile:pt-[24px] mobile:pb-[48px] pc:p-0 mobile:pr-[20px];
}

.wrapper {
  @apply pc:pt-[32px] pc:pb-[72px] mobile:pt-[24px] mobile:pb-[48px] mobile:pr-[20px] m-0 grid pc:grid-cols-2 mobile:grid-cols-1 gap-y-[24px] gap-x-[16px];
}
</style>
