<template>
  <v-row class="signin-wrapper" justify="center" align="center">
    <v-col xl="6" lg="6" md="8" sm="10" cols="10">
      <page title="로그인" center-title>
        <template #main-section="{ isMobileUi }">
          <basic-input
            id="id"
            :text-value="loginId"
            variant="underlined"
            label="아이디(휴대전화 번호)"
            placeholder="아이디(휴대전화 번호)를 입력해주세요"
            @update:textValue="loginId = $event"
            @keyup.enter="loginHandler"
          ></basic-input>

          <basic-input
            id="password"
            :text-value="password"
            :rules="passwordRules"
            :prepend-icon="mdiLock"
            variant="underlined"
            type="password"
            label="비밀번호"
            placeholder="4글자 이상"
            @update:textValue="password = $event"
            @keyup.enter="loginHandler"
          ></basic-input>
          <v-btn
            type="button"
            :class="{ 'button-large-primary': !isMobileUi, 'button-medium-primary': isMobileUi }"
            size="x-large"
            flat
            block
            :loading="isLoading"
            @click="loginHandler"
            ><span>로그인</span></v-btn
          >
          <v-btn class="mt-3" :class="{ 'button-large-line': !isMobileUi, 'button-medium-line': isMobileUi }" flat block size="x-large" to="/signup"
            ><span>회원가입</span></v-btn
          >
          <div class="sub-link__wrapper mt-3">
            <router-link :class="{ 'button-medium-text': !isMobileUi, 'button-small-text': isMobileUi }" to="/find/id" draggable="false"
              ><span>아이디 찾기</span></router-link
            >
            <span>|</span>
            <router-link :class="{ 'button-medium-text': !isMobileUi, 'button-small-text': isMobileUi }" to="/find/password" draggable="false"
              ><span>비밀번호 재설정</span></router-link
            >
          </div>
        </template>
        <template #bottom-section>
          <div class="product-mall-wrapper">
            <product-mall-redirect-card />
          </div>
        </template>
      </page>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from 'vue';
import { mdiChevronRight, mdiLock } from '@mdi/js';
import Page from '@/components/Page.vue';
import BasicInput from '@/components/BasicInput.vue';
import ProductMallRedirectCard from '@/components/ProductMallRedirectCard.vue';
import { useUserStore } from '@/stores';

export default {
  components: { Page, BasicInput, ProductMallRedirectCard },
  setup() {
    // TODO (HYHP) 220827 추후에 loginId, password에 정규식 적용 필요
    const loginId = ref('');
    const password = ref('');
    const passwordRules = [(v) => !!v || '비밀번호를 입력해주세요.'];
    const isLoading = ref(false);
    const { login } = useUserStore();
    const loginHandler = async () => {
      isLoading.value = true;

      try {
        await login({ loginId: loginId.value, password: password.value });
        isLoading.value = true;
      } catch (e) {
        isLoading.value = false;
      }
    };
    return {
      mdiChevronRight,
      mdiLock,
      loginId,
      password,
      passwordRules,
      loginHandler,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.signin-wrapper {
  padding: 3rem 0;
  height: 100%;
}

.login-button {
  color: #f3f3f3;
  background-color: $blue-50;
}

.sub-link {
  &__wrapper {
    display: flex;
    justify-content: flex-end;

    a {
      text-decoration: none;
    }

    span {
      margin: 0 0.25rem;
      color: $blue-50;
    }
  }
}

.product-mall-wrapper {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
</style>
