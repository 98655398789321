<template>
  <div class="wrapper">
    <v-row v-resize="onResize" class="m-0" style="display: none" />
    <v-col :md="asideSize.md" :lg="asideSize.lg" class="aside" tag="aside"><slot name="aside-left" /></v-col>
    <v-col :sm="mainSize.sm" :md="mainSize.md" :lg="mainSize.lg" class="p-0" tag="section">
      <h1 v-if="title" class="page-title mb-0" :class="{ 'center-title': centerTitle, [`text__title-${titleSize}`]: !isMobileUi }">{{ title }}</h1>
      <slot name="main-section" :isMobileUi="isMobileUi" />
    </v-col>
    <v-col :md="asideSize.md" :lg="asideSize.lg" class="aside" tag="aside"><slot name="aside-right" /></v-col>
  </div>
  <slot v-if="$slots['bottom-section']" name="bottom-section" />
</template>

<script>
import { computed, onMounted, reactive } from 'vue';

const MAXIMUM_SIZE = 12;

export default {
  name: 'Page',
  props: {
    title: {
      type: String,
      default: '',
    },
    docTitle: {
      type: String,
      default: '',
    },
    centerTitle: {
      type: Boolean,
      default: false,
    },
    titleSize: {
      type: String,
      default: 'xxLarge',
    },
    mainWidth: {
      type: String,
      default: '8',
      validator(value) {
        /** 12, 8, 6, 4, 2 사용 지향 */
        return Number(value) >= 2 && Number(value) <= 12;
      },
    },
  },
  setup(props) {
    const windowSize = reactive({ x: 0, y: 0 });
    const isMobileUi = computed(() => windowSize.x < 960);

    const onResize = () => {
      windowSize.x = window.innerWidth;
      windowSize.y = window.innerHeight;
    };

    onMounted(() => {
      document.title = props.docTitle || props.title || '사)대한미용사회중앙회';
      onResize();
    });

    const asideSize = {
      md: (MAXIMUM_SIZE - props.mainWidth) / 2,
      lg: (MAXIMUM_SIZE - props.mainWidth) / 2,
    };
    const mainSize = {
      sm: MAXIMUM_SIZE,
      md: props.mainWidth,
      lg: props.mainWidth,
    };

    return { onResize, isMobileUi, asideSize, mainSize };
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.aside {
  @apply pc:block mobile:hidden p-0;
}

.page-title {
  display: none;

  @include md-and-up {
    display: block;
    padding: 48px 0;
  }
}

.center-title {
  text-align: center;
}
</style>
