<template>
  <form class="form-wrapper" :class="{ 'mobile-wrapper-padding': isMobileUi }" novalidate @submit="onSubmit">
    <!--        선수 정보-->
    <player-info-form
      :is-login="!isStudentForm"
      :is-student-form="isStudentForm"
      :is-independent-business-form="isIndependentBusinessForm"
      :errors="errors.playerInfo"
      :player-info="playerInfo"
      :title="title"
      :branch-infos="branchInfos"
      :region-selects="regionSelects"
      :is-update-mode="true"
      @setInputError="setInputError"
      @setInput="setInput"
      @resetInputError="resetInputError"
      @deleteContestItemIds="onDeleteContestItems"
    />

    <!--        추천인 정보-->
    <recommender-form
      :is-student-form="isStudentForm"
      :errors="errors.recommender"
      :recommender="recommender"
      :branch-infos="branchInfos"
      :region-selects="regionSelects"
      :initial-branch-change="initialBranchChange"
      @setInputError="setInputError"
      @setInput="setInput"
      @resetInputError="resetInputError"
    />

    <div class="button-card-wrapper mb-5" :class="{ 'button-card-wrapper__mobile': isMobileUi }">
      <circular-loader v-if="isLoading"></circular-loader>
      <k-button v-else :size="isMobileUi ? 'large' : 'medium'" variant="primary" :text="buttonText" style="width: 100%" :disabled="isDisabledForm" />
      <contest-rules-card :is-mobile-ui="isMobileUi" />
    </div>
  </form>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-text>
        {{ dialogMessage ?? '임시 메세지' }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions style="display: flex; justify-content: flex-end">
        <v-btn color="primary" text @click="dialog = false">닫기</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, onMounted, reactive, ref, toRef } from 'vue';

import { isEmpty } from 'lodash-es';
import CircularLoader from '@/components/CircularLoader.vue';
import PlayerInfoForm from '@/containers/ContestEditForm/PlayerInfoForm';
import RecommenderForm from '@/containers/ContestEditForm/RecommenderForm';

import { useBranchStore, useContestStore } from '@/stores';
import { branchMappedKo } from '@/constants/category';
import { warningToast } from '@/utils/toast';

export default {
  name: 'ContestEditForm',
  components: {
    PlayerInfoForm,
    RecommenderForm,
    CircularLoader,
  },
  props: {
    isMobileUi: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isStudentForm: {
      type: Boolean,
      default: false,
    },
    isIndependentBusinessForm: {
      type: Boolean,
      default: false,
    },
    defaultPlayerInfo: {
      type: Object,
      default: () => ({}),
    },
    defaultRecommenderInfo: {
      type: Object,
      default: () => ({}),
    },
    submitHandler: {
      type: Function,
      default: () => {},
    },
    completeHandler: {
      type: Function,
      default: () => {},
    },
    buttonText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    submitPlayerInfo: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const branchStore = useBranchStore();

    const dialog = ref(false);
    const dialogMessage = ref('');

    const isLoading = ref(false);

    const errors = reactive({
      playerInfo: {},
      recommender: {},
    });

    const playerInfo = toRef(props, 'defaultPlayerInfo');
    const recommender = toRef(props, 'defaultRecommenderInfo');

    /** 지회,지부 데이터 {SEOUL: [{id: '', label: ''}, ...], ...} */
    const branchInfos = ref({});
    const regionSelects = Object.values(branchMappedKo);

    const isDisabledForm = computed(() => props.isDisabled || !isEmpty(errors.playerInfo) || !isEmpty(errors.recommender));

    onMounted(() => {
      if (!props.isStudentForm) {
        fetchBranches();
      }
    });

    const fetchBranches = async () => {
      await branchStore.fetchBranchInfos();
      const branchMap = branchStore.branchInfos;

      branchInfos.value = Object.entries(branchMap).reduce((obj, [curKey, curValue]) => {
        obj[curKey] = curValue.map(({ id, region, branchName }) => ({ id, regionName: region, label: branchName }));

        return obj;
      }, {});

      if (playerInfo.value.branchOfficeId) {
        /** 유저 정보에 지회, 지부 정보가 존재한다면 세팅해준다. */
        const { regionName, label: associateName } = Object.values(branchInfos.value)
          .flat()
          .find(({ id }) => +id === +playerInfo.value.branchOfficeId);
        playerInfo.value.regionName = regionName;
        playerInfo.value.associationName = associateName;
      }

      if (recommender.value.branchOfficeId) {
        /** 추천인 정보에 지회, 지부 정보가 존재한다면 세팅해준다. */
        const { regionName, label: associateName } = Object.values(branchInfos.value)
          .flat()
          .find(({ id }) => +id === +recommender.value.branchOfficeId);
        recommender.value.regionName = regionName;
        recommender.value.associationName = associateName;
      }
    };

    const setInputError = ({ infoType, name, message }) => {
      errors[infoType][name] = message;
    };

    const setInput = ({ infoType, name, value }) => {
      if (infoType === 'recommender') {
        recommender.value[name] = value;
      } else if (name.includes('birthday')) {
        playerInfo.value.birthday[name.split('.')[1]] = value;
      } else if (name === 'contestItemIds') {
        const numId = parseInt(value);
        if (playerInfo.value.contestItemIds.length >= 5) {
          warningToast('종목은 최대 5개까지 선택 가능합니다.');
        }
        // fixme 대학(공모전) - 아트 / 공예는 중복 등록이 가능해야함. refactor hardcode
        else if (numId === 609 || numId === 610) {
          playerInfo.value.contestItemIds.push(numId);
        } else if (!playerInfo.value.contestItemIds.includes(numId)) {
          playerInfo.value.contestItemIds.push(numId);
        }
      } else {
        playerInfo.value[name] = value;
      }
    };

    const resetInputError = ({ infoType, name }) => {
      if (name.includes('birthday')) {
        if ('birthday' in errors[infoType]) {
          delete errors[infoType].birthday;
          return;
        }
      }

      if (name in errors[infoType]) {
        delete errors[infoType][name];
      }
    };

    const onDeleteContestItems = (contestItemId) => {
      playerInfo.value.contestItemIds = playerInfo.value.contestItemIds.filter((id) => id !== contestItemId);
    };

    const onComplete = () => {
      isLoading.value = false;

      props.completeHandler();
    };

    const onSubmit = async (e) => {
      e.preventDefault();
      isLoading.value = true;

      try {
        const { infoType, isValid, name, message } = await props.submitHandler(playerInfo.value, recommender.value, !props.isStudentForm);

        /** !isValid 면 인풋을 입력하라는 모달 표시 */
        if (!isValid) {
          setInputError({ infoType, name, message });

          dialog.value = true;
          dialogMessage.value = message;
          isLoading.value = false;
          return;
        }

        onComplete();
      } catch (e) {
        isLoading.value = false;
      }
    };

    const registerPlayerInfo = async (e) => {
      e.preventDefault();
      isLoading.value = true;

      try {
        await props.submitPlayerInfo(playerInfo.value, recommender.value);

        onComplete();
      } catch (e) {
        isLoading.value = false;
      }
    };

    const contestStore = useContestStore();
    const contestItemNames = computed(() => {
      const contestItems = contestStore.getContestItems;
      const names = playerInfo.value.contestItemIds?.map((itemId) => {
        return contestItems.find((v) => v.id === itemId)?.name;
      });

      return names;
    });

    return {
      isLoading,
      playerInfo,
      recommender,
      errors,
      isDisabledForm,
      branchInfos,
      regionSelects,
      dialog,
      dialogMessage,
      contestItemNames,
      onSubmit,
      setInputError,
      setInput,
      resetInputError,
      onDeleteContestItems,
      onComplete,
      registerPlayerInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper:deep {
  .form-player + .form-recommender {
    margin-top: 32px;

    @include md-and-up {
      margin-top: 48px;
    }
  }
}

.mobile-wrapper-padding {
  padding: 20px;
}

// 하단
.button-card-wrapper {
  display: flex;
  flex-direction: column;
  margin: 48px 0 0;

  & > button:last-of-type {
    margin: 48px 0 0;
  }

  &__mobile {
    flex-direction: column-reverse;
    margin: 32px 0 0;

    & > button:last-of-type {
      margin: 0 0 24px;
    }
  }
}

.noti-modal-body {
  background: $gray-10;
  border-radius: 4px;
  border: 1px solid $gray-30;
  padding: 0.5rem;
}
</style>
