<template>
  <button v-bind="attrs" :class="{ [`button-${size}-${variant}`]: true }" :disabled="disabled" @click="onClick">
    <span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'xxLarge',
      validator: (value) => ['xxLarge', 'xLarge', 'large', 'medium', 'small', 'xSmall', 'xxSmall'].includes(value),
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary', 'line', 'text'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, { attrs }) {
    return { attrs };
  },
};
</script>

<style lang="scss" scoped></style>
