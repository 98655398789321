<template>
  <div>
    <required-caption :label="label" :required="required"></required-caption>
    <v-btn-toggle class="w-100" :model-value="activeValue" mandatory @update:modelValue="(value) => $emit('update:activeValue', value)">
      <v-btn
        v-for="item in items"
        :key="item.id"
        :width="`${parseInt(100 / items.length)}%`"
        :max-width="btnMaxWith"
        class="item"
        selected-class="item-active"
        :value="item.id"
      >
        {{ item.label }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import RequiredCaption from '@/components/RequiredCaption.vue';

export default {
  components: { RequiredCaption },
  props: {
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    activeValue: {
      type: [String, Boolean],
      default: '',
    },
    btnMaxWith: {
      type: String,
      default: '15rem',
    },
  },
};
</script>

<style lang="scss" scoped>
.name {
  @include spreadTypoMap($caption-xsmall);
  color: $gray-60;
  letter-spacing: 0.005em;
  margin-bottom: 0.5rem;
}

.required {
  color: $koba-red;
}

.item {
  @include spreadTypoMap($button-medium);
  border: 1px solid $gray-30;

  &-active {
    background: $blue-50;
    color: white;
  }
}
</style>
