import axios from '@/lib/axiosController';

/**
 * 메인페이지 중요뉴스 fetch API
 * @param {number} newsId
 * @returns
 */
export const getImportantArticleAPI = async (newsId) => {
  return await axios.get('/main/important', {
    params: {
      newsId,
    },
  });
};

/**
 * 메인페이지 최신뉴스 fetch API
 * @param {string} type
 * @param {number} rankSize
 * @returns
 */
export const getLatestArticleAPI = async (type, rankSize) => {
  return await axios.get('/main/article/latest', {
    params: {
      type,
      rankSize,
    },
  });
};

export const getArticlesOrderByViewsAPI = async (formData, body) => {
  return await axios.post(`/post/upload`, formData || {}, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * 메인페이지 인기기사 fetch API
 * @param {string} type
 * @param {number} rankSize
 * @returns
 */
export const getPopularNewsAPI = async (type, rankSize) => {
  const data = axios.get('/main/article/popularity', {
    params: {
      type,
      rankSize,
    },
  });

  return data;
};

/**
 * Main 홈페이지 데이터 전체 가져오는 API
 * @returns
 */
export const getMainHomeDataApi = async () => {
  const data = await axios.get('/main/cache');

  return (
    data ?? {
      schedules: [],
      mainArticles: [],
      importantArticles: [],
      mainContestSectionView: {
        video: {},
        contestArticles: [],
      },
      popularArticles: [],
      lectureView: {},
      latestArticles: [],
      photoArticles: [],
    }
  );
};
