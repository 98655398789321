<template>
  <v-card flat class="w-100">
    <v-checkbox
      class="terms__checkbox_label"
      :model-value="isAccept"
      :label="title"
      color="#0070BC"
      hide-details
      @click="$emit('toggleAccept', !isAccept)"
    ></v-checkbox>
    <div class="terms__body">
      <ol>
        <li class="terms__title">
          <div class="mb-3">정보를 제공받는 기관</div>
          <ul>
            <li>㈜하나은행, 하나증권㈜, 하나캐피탈㈜, 하나생명보험㈜, 하나손해보험㈜, ㈜하나저축은행</li>
          </ul>
        </li>

        <li class="terms__title">
          <div class="title mb-3">정보수집목적</div>
          <ul>
            <li>홍보 및 마케팅</li>
          </ul>
        </li>

        <li class="terms__title">
          <div class="title mb-3">제공받는 항목</div>
          <ul>
            <li>이름, 연락처, 소속지회지부, 매칭영업점코드, 하나은행계좌번호, 금융주치의 상담 희망여부</li>
          </ul>
        </li>

        <li class="terms__title">
          <div class="title mb-3">보유기간</div>
          <ul>
            <li>2년</li>
          </ul>
        </li>
      </ol>

      <div>위의 개인정보 제공에 대한 동의를 거부할 권리가 있습니다.</div>
      <div>그러나 동의를 거부할 경우 일부 서비스 이용에 제한을 받을 수 있습니다.</div>
    </div>
  </v-card>
</template>

<script>
import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    isAccept: {
      type: Boolean,
      default: false,
    },
    toggleAccept: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    return { mdiCheckboxMarkedCircleOutline };
  },
};
</script>

<style lang="scss" scoped>
.terms {
  &__checkbox_label {
    :deep(.v-label) {
      @include spreadTypoMap($body-xLarge);
      opacity: unset;
      white-space: normal;
    }
  }

  &__body {
    background-color: $gray-10;
    height: 18.75rem;
    padding: 2rem 1.5rem;
    overflow: auto;
  }

  &__title {
    @include spreadTypoMap($title-xsmall);
    margin-bottom: 2rem;
  }

  &__desc {
    @include spreadTypoMap($body-large);
    margin-bottom: 0.5rem;
  }
}

li {
  @include spreadTypoMap($body-medium);
  margin: 0;
  line-height: 1.6rem;
  color: $gray-60;
}
</style>
