<template>
  <section class="section-wrapper">
    <h3 class="text__title-large text-center section-title" :style="`display: ${isMobileUi ? 'none' : 'block'}`">접수 내역 확인 및 수정</h3>

    <v-row justify="center">
      <v-col :sm="mainSize.sm" :md="mainSize.md" :lg="mainSize.lg" tag="section">
        <basic-input
          id="playerName"
          label="이름"
          placeholder="선수 이름을 입력해주세요"
          required
          :text-value="playerName"
          @keyup.enter="onClickSearch"
          @update:textValue="playerName = $event"
        />

        <basic-input
          id="phoneNumber"
          label="휴대전화"
          placeholder="접수시 입력한 휴대전화 번호를 입력해주세요"
          required
          :text-value="phoneNumber"
          @keyup.enter="onClickSearch"
          @update:textValue="phoneNumber = $event"
        />

        <basic-input
          v-if="!isLogin"
          id="password"
          label="비밀번호"
          placeholder="접수시 입력한 비밀번호를 입력해주세요."
          required
          type="password"
          :text-value="password"
          @keyup.enter="onClickSearch"
          @update:textValue="password = $event"
        />

        <CircularLoader v-if="isLoding" />
        <v-btn v-else class="search-btn" size="x-large" flat block @click="onClickSearch">내역 조회</v-btn>

        <contest-document-buttons :is-mobile-ui="isMobileUi" style="margin-top: 20px" />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useUserStore, useContestStore } from '@/stores';

import BasicInput from '@/components/BasicInput.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import ContestDocumentButtons from '@/containers/Contest/ContestDocumentButtons';

const mainSize = {
  sm: 12,
  md: 10,
  lg: 6,
};

export default {
  name: 'ContestPlayerUpdate',
  components: { BasicInput, CircularLoader, ContestDocumentButtons },
  props: {
    isMobileUi: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isLoading = ref(false);
    const isLogin = ref(false);

    const playerName = ref('');
    const phoneNumber = ref('');
    const password = ref('');
    const contestId = 1; // TODO: ContestForm.vue 처럼 contestId 변경 가능하게 동작하도록 수정

    const router = useRouter();
    const useStore = useUserStore();
    const contestStore = useContestStore();

    onMounted(() => {
      // TODO: Login loading status 가져와서 수정하는거로 변경 필요. (setTimeout Fadeout)
      setTimeout(() => {
        isLogin.value = useStore.checkLoginStatus();
      }, 500);
    });

    const onClickSearch = async () => {
      isLoading.value = true;

      const parsedPhone = phoneNumber.value.replace(/-/g, '');
      const isSuccess = await contestStore.fetchUpdatePlayerInfo(contestId, playerName.value, parsedPhone, password.value);

      if (isSuccess) {
        router.push('/contest-form/update');
      }

      isLoading.value = false;
    };

    return { isLoading, isLogin, playerName, phoneNumber, password, mainSize, onClickSearch };
  },
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  margin-top: 24px;
  padding: 0 20px;

  @include md-and-up {
    margin-top: 48px;
    padding: 0 20px;
  }

  & > * {
    margin-bottom: 3rem;
  }
}

.search-btn {
  color: #f3f3f3;
  background-color: $blue-50;
}
</style>
