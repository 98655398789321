export default [
  {
    year: '2020',
    history: [
      {
        date: '09월 01일',
        text: '제 29차 전국 대의원 총회에서 제 12대 이선심 여사(제24대) 선출됨',
      },
    ],
  },
  {
    year: '2018',
    history: [
      {
        date: '08월 29일',
        text: '전국 소상공인 “최저임금 제도 개선 촉구” 대규모 총궐기 국민대회 개최 (광화문 광장)',
      },
      {
        date: '05월 24일',
        text: '“미용업의 진흥 및 관리에 관한 법률(안)” 입법을 위한 공청회 개최 (국회의원회관 2층 제1소회의실)',
      },
      {
        date: '03월 26일',
        text: '“미용업의 진흥 및 관리에 관한 법률(안)” 국회발의(정태옥 의원 대표발의)',
      },
    ],
  },
  {
    year: '2017',
    history: [
      {
        date: '06월 13일',
        text: '제28차 전국 대의원총회에서 제11대 회장으로 최영희여사(제23대) 중임됨',
      },
      {
        date: '03월 20일 - 03월 21일',
        text: '2014 국제한국미용페스티벌(IKBF 2017) 개최',
      },
    ],
  },
  {
    year: '2016',
    history: [
      {
        date: '11월 06일',
        text: '미용분야 산학일체형 도제학교 선정',
      },
      {
        date: '03월 27일',
        text: '2016년 OMC 헤어월드 코리아 및 무궁화컵대회 개최(킨텍스)',
      },
      {
        date: '01월 31일',
        text: '신용카드 수수료율 0.8%(연매출 2억원 이하), 1.3%(연매출 2~3억원), 1.9%(연매출 3~10억원) 인하달성',
      },
    ],
  },
  {
    year: '2015',
    history: [
      {
        date: '03월 24일',
        text: '2016년 헤어월드 조직위원회 출범식 개최',
      },
    ],
  },
  {
    year: '2014',
    history: [
      {
        date: '10월 30일 - 11월 01일',
        text: '2014 국제한국미용페스티벌(IKBF 2014) 개최',
      },
      {
        date: '09월 17일',
        text: '대한미용사회 정관 변경(보건복지부 허가 제91-22호)',
      },
      {
        date: '06월 10일',
        text: '제27차 전국 대의원총회에서 제10대 회장으로 최영희여사(제22대) 중임됨',
      },
    ],
  },
  {
    year: '2013',
    history: [
      {
        date: '12월 18일',
        text: '대한미용사회 정관 변경(보건복지부 허가 제91-21호)',
      },
      {
        date: '09월 12일 - 09월 15일',
        text: '2013 한국미용페스티벌(KBF 2013) 개최',
      },
      {
        date: '05월 19일 - 05월 22일',
        text: '2013 오송 화장품, 뷰티세계박람회 개최',
      },
    ],
  },
  {
    year: '2012',
    history: [
      {
        date: '12월',
        text: '신용카드 수수료율 1.5%(연매출 2억원 이하) 인하달성',
      },
      {
        date: '11월 05일 - 11월 06일',
        text: '2012 한국미용페스티벌(KBF 2012) 개최',
      },
    ],
  },
  {
    year: '2011',
    history: [
      {
        date: '11월 28일 - 11월 29일',
        text: '2011 한국미용페스티벌(KBF 2011) 개최',
      },
      {
        date: '11월 09일',
        text: '“미용,이용 등 뷰티산업의 진흥 및 관리에 관한 법률(안)” 보건복지법안 소위원회 의결(신상진 의원 대표발의)',
      },
      {
        date: '03월 04일',
        text: '제26차 전국 대의원총회에서 제9대 회장으로 최영희여사(제21대) 중임됨',
      },
      {
        date: '02월 22일',
        text: '서울중앙지방법원이 ‘선거권행사허용등가처분’ 판결',
      },
    ],
  },
  {
    year: '2010',
    history: [
      {
        date: '11월 12일',
        text: '법정관리 회장 김영혜 변호사에서 김삼화 변호사로 변경',
      },
      {
        date: '04월 02일',
        text: '법정관리 이사 14명 선임',
      },
      {
        date: '02월 18일',
        text: '서울중앙지방법원이 ‘중앙회장 당선결정과 임원선임권 위임결의무효’ 판결',
      },
    ],
  },
  {
    year: '2009',
    history: [
      {
        date: '12월 09일',
        text: '서울중앙지방법원이 중앙회 직무집행정지 및 대행자선임가처분 판결 (최영희 회장 및 이사진 직무정지, 법정관리 회장 김영혜 변호사 선임)',
      },
      {
        date: '11월 03일 - 11월 04일',
        text: '2009 한국미용페스티벌(KBF 2009) 개최',
      },
      {
        date: '06월 30일',
        text: '제25차 전국 대의원총회에서 제9대 회장으로 최영희여사(제21대) 중임됨',
      },
      {
        date: '05월 07일',
        text: '‘미용업법’ 국회 발의(손범규 의원 대표발의)',
      },
      {
        date: '01월 13일',
        text: '‘위생교육관리규정’, ‘임원및회원의징계규정’ 제정',
      },
    ],
  },
  {
    year: '2008',
    history: [
      {
        date: '11월 04일 - 11월 05일',
        text: '2008 한국미용페스티벌(KBF 2008) 개최',
      },
      {
        date: '03월 28일',
        text: '공중위생관리법 개정(2008.7.1시행)으로 위생교육을 보건복지가족부장관이 허가한 단체 또는 제16조에 따른 단체가 실시할 수 있도록 변경',
      },
    ],
  },
  {
    year: '2007',
    history: [
      {
        date: '12월 11일',
        text: '‘중앙회 회계규정’, ‘지회 및 지부 회계규정’ 제정',
      },
      {
        date: '11월 06일',
        text: '‘이사회운영규정’ 제정',
      },
      {
        date: '11월',
        text: '신용카드가맹점 수수료율 인하 달성',
      },
      {
        date: '10월 17일 - 10월 19일',
        text: '2007 한국미용페스티벌(KBF 2007) 개최',
      },
      {
        date: '09월 12일',
        text: '대한미용사회 정관 변경(보건복지부 허가 제91-20호)',
      },
      {
        date: '08월 23일',
        text: '금융감독원 주최 신용카드가맹점 수수료율체계 개선을 위한 공청회 참가',
      },
      {
        date: '07월 13일',
        text: '‘미용업법’ 국회발의 (문희 의원 대표발의)',
      },
      {
        date: '06월 26일',
        text: '제24차 전국 대의원총회에서 정관 전면 개정하여 보건복지부장관 허가 (보허91-20호. 2007.9.12)',
      },
      {
        date: '06월 12일',
        text: '신용카드가맹점 수수료율 인하 법제화 촉구 2차 자영업자대회(여의도)',
      },
      {
        date: '04월 05일',
        text: '공중위생관리법 시행규칙 일부개정령(업무범위) 공포 (보건복지부령 제392호)',
      },
      {
        date: '03월 30일',
        text: '독립 ‘미용업법’ 입법을 위한 공청회 개최(국회의원회관 대회의실)',
      },
      {
        date: '02월 27일',
        text: '신용카드가맹점 수수료율 인하 입법청원 및 결의대회 (여의도)',
      },
      {
        date: '02월 13일',
        text: '‘임원선거규정’, ‘대의원선출규정’ 제정',
      },
    ],
  },
  {
    year: '2006',
    history: [
      {
        date: '10월 31일 - 11월 02일',
        text: '2006 한국미용페스티벌(KBF 2006) 개최',
      },
      {
        date: '07월 25일',
        text: '정부의 미용사 자격분리 반대를 위한 전국적인 대규모 집회',
      },
      {
        date: '03월 08일',
        text: '제23차 전국 대의원총회에서 제8대 회장으로 최영희여사(제20대) 선출',
      },
      {
        date: '01월 26일',
        text: '서울고등법원이 2004년 총회결의등무효확인 및 중앙회 직무집행정지 확정판결',
      },
    ],
  },
  {
    year: '2005',
    history: [
      {
        date: '11월 21일',
        text: '법정관리 이사 9명 추가 선임',
      },
      {
        date: '08월 25일',
        text: '미용회관 소유권 보존등기 (건물전체면적 1,827.64㎡)',
      },
      {
        date: '07월 01일',
        text: '사)대한미용사회중앙회 미용회관건립(재건축)준공 (연면적 2,039.41㎡, 대지면적 483.4㎡)',
      },
      {
        date: '06월 16일',
        text: '서울중앙지방법원이 “2004년 총회결의등무효확인” 및 “중앙회 직무집행정지가처분“ 판결(강경남 회장 및 이사진 직무정지) (법정관리 회장 박기동변호사 선임, 법정관리 이사 15명 선임)',
      },
    ],
  },
  {
    year: '2004',
    history: [
      {
        date: '06월 22일',
        text: '제23차 전국 대의원총회에서 제8대 회장으로 강경남여사(제20대) 중임됨.(2006.1.26일 고등법원 판결로 선출무효)',
      },
      {
        date: '06월 07일',
        text: '사)대한미용사회중앙회 미용회관건립(재건축) 기공식',
      },
      {
        date: '01월 29일',
        text: '공중위생관리법 개정(2004.7.29 시행)으로 위생교육대상자를 모든 영업자로 변경',
      },
    ],
  },
  {
    year: '2003',
    history: [
      {
        date: '02월 27일',
        text: '미용업 개설통보제에서 개설신고제로 변경',
      },
    ],
  },
  {
    year: '2002',
    history: [
      {
        date: '07월 04일',
        text: '정부의 미용사 면허제도 분리정책 백지화를 위한 70만 미용인 규탄궐기대회(정부→미용사 면허제도분리정책 철회함)',
      },
    ],
  },
  {
    year: '2001',
    history: [
      {
        date: '05월',
        text: '국가기능장려법에 의거 미용명장제도 탄생',
      },
    ],
  },
  {
    year: '1999',
    history: [
      {
        date: '02월 28일',
        text: '미용업 개설신고제에서 개설통보제로 변경됨',
      },
      {
        date: '02월 08일',
        text: '공중위생법이 폐지되고 공중위생관리법(보허 제5839호)이 제정됨. (9가지 미용업무= 파마,머리카락자르기,머리카락모양내기,머리피부손질, 머리카락염색,머리감기,손톱손질및화장,피부미용(의료기구와 의약품 사용금지),얼굴손질 및 화장',
      },
    ],
  },
  {
    year: '1998',
    history: [
      {
        date: '12월 29일',
        text: '제19차 전국대의원총회에서 제6대 회장에 하종순여사(18대) 중임됨',
      },
      {
        date: '09월 05일 - 09월 08일',
        text: "제27회 세계이․미용대회(Hair World '98 Seoul)가 올림픽공원(제1,2,3체육관, 한얼광장)에서 세계 36개국이 참가한 가운데 성황리에 개최됨",
      },
      {
        date: '04월 22일',
        text: '각종 규정 제정',
      },
    ],
  },
  {
    year: '1996',
    history: [
      {
        date: '12월 29일',
        text: '미용업 개설허가제에서 개설신고제로 변경됨',
      },
      {
        date: '10월',
        text: '법정관리 종식하고 하종순회장 업무 복기',
      },
      {
        date: '03월',
        text: '국제기구 CIC, OAI를 OMC로 명칭 변경',
      },
    ],
  },
  {
    year: '1995',
    history: [
      {
        date: '11월 28일',
        text: '법정관리 회장 대리로 이향아 변호사 선임',
      },
      {
        date: '06월 07일',
        text: '제16차 전국대의원총회에서 제5대 회장에 하종순여사(17대) 중임됨',
      },
      {
        date: '03월',
        text: '국가기술자격법에 의거 미용기능장제도 탄생',
      },
    ],
  },
  {
    year: '1993',
    history: [
      {
        date: '07월 08일',
        text: '미용회관으로 뒷 건물 50평 매입',
      },
      {
        date: '06월 14일',
        text: '미용사 면허 제도를 자격증 제도로 일원화 하려는 정부안 저지',
      },
    ],
  },
  {
    year: '1992',
    history: [
      {
        date: '06월 19일',
        text: '제13차 전국 대의원총회에서 제4대 회장에 하종순여사(16대) 피선됨',
      },
    ],
  },
  {
    year: '1991',
    history: [
      {
        date: '05월',
        text: '대한미용사회 상표등록 및 홈페이지 개설',
      },
      {
        date: '03월',
        text: '행정쇄신위원회의 미용사면허 소지자에 대한 1개 업소개설 제도 폐지에 대한 저지',
      },
    ],
  },
  {
    year: '1990',
    history: [
      {
        date: '09월 04일 - 09월 05일',
        text: '제1회 미용의날 행사를 올림픽공원 펜싱경기장에서 2일간 개최(미용경기대회, 작품전시회, 미용관련제품 전시회)',
      },
      {
        date: '01월 13일',
        text: '미용업 개설신고제가 개설허가제로 변경됨. 미용사면허를 받은 자만이 미용업을 개설하도록 법 개정됨',
      },
    ],
  },
  {
    year: '1989',
    history: [
      {
        date: '06월 30일',
        text: '제10차 전국대의원총회에서 제3대 회장에 오정순여사(15대) 중임됨',
      },
      {
        date: '03월 14일',
        text: '제8차 전국임시대의원총회에서 민법에 의한 사단법인을 공중위생법 제32조에 의한 사단법인으로 정관을 개정하여 보건사회부장관으로부터 보허 91~12호로 승인을 득함',
      },
    ],
  },
  {
    year: '1988',
    history: [
      {
        date: '12월',
        text: '본회의 건의로 전국 전문대학 및 대학에 미용학과 신설(영동전문대학 등 2개 대학에서 1989년 신입생 모집)',
      },
      {
        date: '06월 10일',
        text: '본회가 한국 최초로 미용전문대학 설립 추진위원회 발족(6개월 후 자진 취소)',
      },
    ],
  },
  {
    year: '1987',
    history: [
      {
        date: '09월 12일',
        text: 'CIC,OAI 아시아미용연맹 제2회 미용대회 3일간 서울 88체육관에서 8개국 참가 개최',
      },
      {
        date: '06월',
        text: '미용실 12세 미만의 남자가 출입할 수 있도록 하는 정부의 법개정 저지 미용사 면허자는 1개 업소만 개설할 수 있도록 법 개정 미용업소 자율지도, 위생교육실시, 부가가치세 신고 대행, 정부로부터 위탁받아 시행',
      },
    ],
  },
  {
    year: '1986',
    history: [
      {
        date: '12월 30일',
        text: '미용사의 업무범위 중 커트와 피부미용이 삽입되어 9개업무로 확정됨',
      },
      {
        date: '06월 19일',
        text: '제5차 전국대의원총회에서 제2대 회장에 오정순여사(14대) 피선됨',
      },
      {
        date: '05월 10일',
        text: '이용사 및 미용사법이 폐지되고 공중위생법이 제정됨.〈미용실 남자출입 제한 철패〉(미용의정의=손님의 얼굴, 머리, 피부 등에 손질하여 외모를 아름답게 꾸미는 영업)',
      },
    ],
  },
  {
    year: '1985',
    history: [
      {
        date: '10월 20일',
        text: '국제민간기구 ICD,CACF,CIDESCO 한국지부를 본회 산하 기구로 편입',
      },
      {
        date: '03월 27일',
        text: '한국, 일본, 대만, 홍콩, 필리핀, 태국, 싱가폴, 인도네시아 8개국이 서울 신라호텔에서 모여 CIC아세아연맹을 구성키로 합의.〈회장 일본 야스무리, 부회장 한국 김봉란〉',
      },
    ],
  },
  {
    year: '1984',
    history: [
      {
        date: '12월 20일',
        text: '서울특별시 강남구 방배동 925-5호에 미용회관을 마련',
      },
      {
        date: '12월 20일',
        text: '미용기능사 1급 및 2급 국가기술자격 종목이 미용사로 변경됨. 2급 제도로 개편된 것을 미용사로 다시 환원됨',
      },
      {
        date: '03월',
        text: '1982년부터 산업인력공단에서 실시해온 이용사 시험에 파마 과목 삭제',
      },
    ],
  },
  {
    year: '1983',
    history: [
      {
        date: '07월 20일',
        text: '이.미용회보를 미용회보로 제호를 변경하여 문화부 바-1072호로 허가',
      },
      {
        date: '06월 19일',
        text: '보건복지부장관배 미용경기대회를 여원과 공동주최로 워커힐에서 개최(1회)',
      },
    ],
  },
  {
    year: '1982',
    history: [
      {
        date: '12월 13일',
        text: '보건사회부장관으로부터 보허841호로 민법 제32조에 의한 사단법인 설립 허가를 득함',
      },
      {
        date: '10월 28일',
        text: '대한미용사회 창립총회 개최하고 초대회장에 김봉란여사(13대) 피선됨',
      },
      {
        date: '10월 28일',
        text: '전국대의원총회에서 사단법인 대한이․미용사회 자진 해산 결의',
      },
      {
        date: '06월 15일',
        text: '이.미용 의료보험조합 보건복지부 설립인가(이사장 미용대표 김봉란, 부이사장 이용대표 이의상)',
      },
      {
        date: '04월 29일',
        text: '미용사자격시험 실시기관이 시․도지사에서 노동부 산하 한국직업훈련관리공단으로 변경됨.〈미용기능사 1급, 미용기능사 2급〉',
      },
    ],
  },
  {
    year: '1981',
    history: [
      {
        date: '09월 10일',
        text: '불란서 파리에서 개최하는 국제기구 CIC, OAI 대표자 회의에서 한국이 32번째 회원국으로 가입.〈CIC한국대표 김봉란, OAI한국대표 이남길〉',
      },
      {
        date: '09월 03일',
        text: '제2차 전국대의원총회에서 제2대 회장에 김봉란여사(12대) 피선됨',
      },
      {
        date: '05월 01일',
        text: '이.미용회보를 문화부 바949호로 허가받아 매월 발행됨',
      },
      {
        date: '05월',
        text: '본회에 미용기술회를 구성하고 오정순,이행숙,강형숙씨를 최초 강사로 위촉',
      },
    ],
  },
  {
    year: '1980',
    history: [
      {
        date: '10월 30일',
        text: '제1차 전국 이미용사회 대의원총회에서 사단법인 대한이미용사회로 개칭하고 초대회장에 김옥진여사(11대) 피선됨',
      },
      {
        date: '10월 27일',
        text: '제14차 전국 임시총회에서 대한미용사회와 한국이용사회 통합정관개정 승인',
      },
      {
        date: '03월',
        text: '한.일 뷰티페스티발을 신라호텔 컨벤션 센타에서 개최됨',
      },
    ],
  },
  {
    year: '1978',
    history: [
      {
        date: '11월 16일',
        text: '제13차 전국 대의원총회에서 제10대 회장에 김옥진여사 피선됨',
      },
    ],
  },
  {
    year: '1975',
    history: [
      {
        date: '09월 26일',
        text: '제10차 전국 대의원총회에서 제9대 회장에 김경애여사 중임됨',
      },
    ],
  },
  {
    year: '1973',
    history: [
      {
        date: '09월 19일',
        text: '제8차 전국 대의원총회에서 제8대 회장에 김경애여사 중임됨',
      },
    ],
  },
  {
    year: '1971',
    history: [
      {
        date: '09월 12일',
        text: '제6차 대의원총회에서 제7대 회장에 김경애여사 피선됨',
      },
    ],
  },
  {
    year: '1969',
    history: [
      {
        date: '07월 27일',
        text: '제5차 대의원총회에서 제6대 회장에 유숙자여사 중임됨',
      },
    ],
  },
  {
    year: '1968',
    history: [
      {
        date: '06월 27일',
        text: '제4차 대의원총회에서 제5대 회장에 유숙자여사 피선됨',
      },
      {
        date: '06월 10일',
        text: '회장 사임으로 회장직무대리로 유숙자 부회장이 전담함',
      },
    ],
  },
  {
    year: '1967',
    history: [
      {
        date: '08월 31일',
        text: '회장 사임으로 인하여 전국 이사회에서 후임으로 제4대 회장에 한영정여사 피선됨',
      },
      {
        date: '05월 12일',
        text: '제3차 대의원총회에서 제3대 회장에 김춘자여사 피선됨',
      },
      {
        date: '01월 17일',
        text: '제2차 대의원총회에서 제2대 회장에 박계국여사 중임됨',
      },
    ],
  },
  {
    year: '1966',
    history: [
      {
        date: '03월 29일',
        text: '사단법인 설립이 허가됨. (보허제744호)',
      },
    ],
  },
  {
    year: '1965',
    history: [
      {
        date: '10월',
        text: '대한미용사회를 창립, 초대회장에 박계국여사 피선됨',
      },
    ],
  },
  {
    year: '1961',
    history: [
      {
        date: '12월 05일',
        text: '이.미용사법 최초 제정 공포(법률 제798호) (미용업무=파마넨트웨이브, 결발, 세발, 염발, 두피처리, 메니큐어, 미안술 및 화장)〈업=신고제〉',
      },
      {
        date: '07월',
        text: '상기 협회 미용분과위원장에 이정녀여사 피선됨',
      },
      {
        date: '05월 16일',
        text: '단체 해체와 동시에 대한환경위생협회의 일원이 됨',
      },
      {
        date: '05월 10일',
        text: '상기 연합회를 법인체로 승격시킴. 회장에 박계국여사 취임함',
      },
    ],
  },
  {
    year: '1957',
    history: [
      {
        date: '04월 20일',
        text: '상기 협회 회장에 박계국여사 취임함',
      },
      {
        date: '02월 27일',
        text: '상기 협회를 총망라하여 대한미용협회를 발족시킴',
      },
    ],
  },
  {
    year: '1945',
    history: [
      {
        date: '08월 15일',
        text: '해방 후 각 도시 단위로 자율적인 미용협회를 형성하다',
      },
    ],
  },
];
