<template>
  <section class="section-wrapper">
    <v-select
      v-model="targetContestId"
      :items="contestList"
      label="대회 선택"
      dense
      outlined
      item-title="title"
      item-value="id"
      :loading="isContestsLoading"
      class="select-style"
    />
    <template v-if="!isContestsLoading">
      <h2 style="margin-top: 0" class="section-title__wrapper">
        <strong v-for="(text, idx) in targetContestInfo.title?.split('\n')" :key="idx" class="section-title" style="display: block">{{ text }}</strong>
      </h2>
      <template v-for="(paragraph, idx) in targetContestInfo.description?.split('\n')" :key="idx">
        <p class="section-description">{{ paragraph }}</p>
      </template>
      <div class="section-info1">{{ targetContestInfo.sectionInfo1 }}</div>
      <v-btn class="button-large-line section-button button-qr" @click="openModal">
        <span
          >선수접수 QR 코드<icon-base><chevron-right-arrow /></icon-base
        ></span>
      </v-btn>
      <BasicModal
        :is-open="showModal"
        :body="'QR 코드 이미지'"
        :image-src="require('@/assets/2024_contest_qr.png')"
        :close-button="{ text: '닫기', onClick: closeModal }"
      />
      <p class="section-info2">
        <template v-for="(paragraph, idx) in targetContestInfo.sectionInfo2?.split('\n')" :key="idx">
          <br v-if="idx !== 0" />
          {{ paragraph }}
        </template>
      </p>
    </template>
  </section>
</template>

<script>
import ChevronRightArrow from '@/icons/ChevronRightArrow';
import { onUpdated, ref, watch } from 'vue';
import { isEmpty } from 'lodash-es';
import { useContestStore } from '@/stores';
import { contestInfoMap } from '@/constants/contest';
import BasicModal from '@/components/modal/BasicModal';

export default {
  name: 'ContestMainInfo',
  components: { ChevronRightArrow, BasicModal },
  props: {
    isContestsLoading: {
      type: Boolean,
      required: true,
    },
    contestList: {
      type: Array,
      default: () => [],
    },
    isMobileUi: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const contestStore = useContestStore();

    const targetContestId = ref('');
    const targetContestInfo = ref({});

    watch(
      () => props.contestList,
      (curContestList, prevContestList) => {
        if (!isEmpty(prevContestList) || isEmpty(curContestList)) return;

        const curContestId = curContestList[0].id;

        targetContestId.value = curContestId;
        contestStore.setTargetContestId(curContestId);
      },
    );

    onUpdated(() => {
      if (!targetContestId.value) return;

      contestStore.setTargetContestId(targetContestId.value);

      targetContestInfo.value = contestInfoMap[targetContestId.value] || {
        title: '',
        description: '',
        sectionInfo1: '',
        sectionInfo2: '',
        download: '',
      };
      console.log(targetContestInfo);
    });

    return { targetContestId, targetContestInfo };
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  width: 100%;
  background: url(@/assets/contestBg.png);
  background-size: cover;
  padding: 56px 20px;

  @include md-and-up {
    height: 800px;
    overflow: auto;
    padding: 2% 15%;
  }
}

.select-style {
  width: 100%;

  @include md-and-up {
    width: 40%;
  }
}

.section-title__wrapper {
  margin-bottom: 0;

  @include md-and-up {
    margin-top: 3%;
  }
}

.section-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.5;
  color: $gray-80;

  @include md-and-up {
    @include spreadTypoMap($title-xxLarge);
    color: $gray-80;
  }
}

.section-description {
  margin: 16px 0 0;
  max-width: 390px;
  @include spreadTypoMap($body-xsmall);
  color: $gray-80;

  @include md-and-up {
    @include spreadTypoMap($body-large);
    color: $gray-80;
    margin-top: 24px;
    width: 48%;
  }
}

.section-info1 {
  word-break: keep-all;
  @include spreadTypoMap($body-medium);
  color: $gray-80;
  margin-top: 15px;

  @include md-and-up {
    @include spreadTypoMap($body-xxLarge);
    color: $gray-80;
  }
}

.section-button {
  display: block;
  width: fit-content;
  text-decoration: none;
  margin-top: 16px;
  padding: 12px 10px 14px 18px;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
  @include sm-and-down {
    span {
      @include spreadTypoMap($button-small);
    }
  }

  svg {
    margin-left: 4px;
  }

  @include md-and-up {
    margin-top: 1.9%;
    padding: 16px 16px 16px 24px;

    svg {
      margin-left: 8px;
    }
  }

  &.button-qr {
    height: auto;
  }
}

.section-info2 {
  @include spreadTypoMap($body-medium);
  color: $gray-40;
  margin-top: 16px;

  @include md-and-up {
    @include spreadTypoMap($body-large);
    color: $gray-40;

    margin-top: 1.5%;
    margin-bottom: 0;
  }
}
</style>
