<template>
  <v-card flat class="w-100">
    <v-checkbox
      class="terms__checkbox_label"
      :model-value="isAccept"
      :label="title"
      color="#0070BC"
      hide-details
      @click="$emit('toggleAccept', !isAccept)"
    ></v-checkbox>
    <div class="terms__body">
      <ol>
        <li v-for="(item, idx) in items" :key="idx" class="terms__title">
          <div class="mb-3">{{ item.title }}</div>
          <div v-if="item.desc" class="terms__desc">{{ item.desc }}</div>
          <ol v-if="item.terms" class="pl-4">
            <li v-for="(term, idx2) in item.terms" :key="idx2" class="mb-3">{{ term }}</li>
          </ol>
        </li>
      </ol>
    </div>
  </v-card>
</template>

<script>
import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    isAccept: {
      type: Boolean,
      default: false,
    },
    toggleAccept: {
      type: Function,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return { mdiCheckboxMarkedCircleOutline };
  },
};
</script>

<style lang="scss" scoped>
.terms {
  &__checkbox_label {
    :deep(.v-label) {
      @include spreadTypoMap($body-xLarge);
      opacity: unset;
      white-space: normal;
    }
  }

  &__body {
    background-color: $gray-10;
    height: 18.75rem;
    padding: 2rem 1.5rem;
    overflow: auto;
  }

  &__title {
    @include spreadTypoMap($title-xsmall);
    margin-bottom: 2rem;
  }

  &__desc {
    @include spreadTypoMap($body-large);
    margin-bottom: 0.5rem;
  }
}

li {
  @include spreadTypoMap($body-medium);
  margin: 0;
  line-height: 1.6rem;
  color: $gray-60;
}
</style>
