<template>
  <section-layout background="#181e2d">
    <template #main-section>
      <category-tabs
        v-model="selectedTab"
        style="padding-top: 32px"
        is-customized-tab-action
        :tabs="data"
        :tab-action="tabAction"
        is-full-width
        slider-color="transparent"
      />
    </template>
  </section-layout>
</template>

<script>
import { onMounted, ref } from 'vue';
import CategoryTabs from './CategoryTabs';
import SectionLayout from '@/education-mocking/educationHome/SectionLayout';

export default {
  name: 'EducationCategory',
  components: { SectionLayout, CategoryTabs },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    onMounted(async () => {
      // const categoryResult = await getEducationCategoryList();
      // const instructorResult = await getInstructorList();
      // tabs.value = categoryResult;
      // console.log(categoryResult);
    });

    const items = ['강의 전체보기', '여성펌&헤어', '남성펌&헤어', '경영', '피부관리', '마케팅'];
    // const tabs = [
    //   { id: '1', name: '강의 전체보기', src: require('@/icons/categorySolid.svg') },
    //   { id: '2', name: '여성펌&헤어', src: require('./assets/hair.jpeg') },
    //   { id: '3', name: '남성펌&헤어', src: require('./assets/man.jpg') },
    //   { id: '4', name: '경영', src: require('./assets/management.jpeg') },
    //   { id: '5', name: '피부관리', src: require('./assets/aesthetic.jpeg') },
    //   { id: '6', name: '마케팅', src: require('./assets/marketing.jpeg') },
    // ];
    const selectedTab = ref('1');

    const tabAction = () => {
      console.log('helo');
    };
    return { items, selectedTab, tabAction };
  },
};
</script>

<style scoped lang="scss"></style>
