<template>
  <v-tabs
    v-if="tabs"
    :grow="isFullWidth"
    :slider-color="sliderColor"
    :v-model="modelValue"
    class="custom-tab-group"
    background-color="#ffffff"
    style="height: auto"
    center-active
    @click="routerClick"
  >
    <v-tab
      v-for="tab in tabs"
      :key="tab.id"
      class="tab custom-tab"
      :value="tab.key || tab.id"
      @click="isCustomizedTabAction ? tabAction(tab.id) : router.push(tab.path)"
    >
      {{ tab.name }}</v-tab
    >
  </v-tabs>
</template>

<script>
import { isEmpty } from 'lodash-es';
import { useRouter } from 'vue-router';

export default {
  name: 'Tab2',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    sliderColor: {
      type: String,
      default: '#0070bc',
    },
    tabAction: {
      type: Function,
      default: () => {},
    },
    isCustomizedTabAction: {
      type: Boolean,
      default: false,
    },
    routerClick: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const router = useRouter();
    return {
      showTabs: !isEmpty(props.tabs),
      router,
    };
  },
};
</script>

<style lang="scss" scoped>
.tab {
  padding: 16px;
  height: auto !important;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: $gray-30;

  &:first-of-type {
    margin-inline-start: 0 !important;
  }

  @include md-and-up {
    @include spreadTypoMap($caption-large);
    color: $gray-50;
    padding: 24px 16px;

    &:first-of-type {
      margin-inline-start: 0 !important;
    }
  }
}

.custom-tab-group button[aria-selected='true'] {
  font-weight: 700;
  color: $blue-50;
}

.custom-tab-group {
  .v-tab--selected {
    font-weight: 700;
    color: $blue-50;
  }
}
</style>
