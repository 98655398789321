<template>
  <div v-if="articles.length === 0" class="empty_wrapper"><empty-data-card /></div>
  <v-row v-else class="wrapper">
    <v-col v-for="article in articles" :key="article.id" class="p-0">
      <image-summary-card-vue :img-src="article.thumbnail" :title="article.title" :desc="article.bodySummary" :news-id="article.id" />
    </v-col>
  </v-row>
</template>

<script>
import EmptyDataCard from '@/components/EmptyDataCard.vue';
import ImageSummaryCardVue from '@/components/ImageSummaryCard.vue';

export default {
  components: { ImageSummaryCardVue, EmptyDataCard },
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.empty_wrapper {
  @apply pc:pt-[32px] pc:pb-[72px] mobile:pt-[24px] mobile:pb-[48px] pc:p-0 mobile:pr-[20px];
}

.wrapper {
  @apply pc:pt-[32px] pc:pb-[72px] mobile:pt-[24px] mobile:pb-[48px] m-0 flex-nowrap overflow-x-scroll;

  &::-webkit-scrollbar {
    width: 0;
  }
}
</style>
