<template>
  <section class="article_wrapper"><slot /></section>
</template>

<script>
export default {
  components: {},
  props: {},
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.article_wrapper {
  @apply w-full pc:p-0 lg:px-[20px] mobile:pl-[20px];
}
</style>
