<template>
  <article-wrapper>
    <article-title>Articles</article-title>
    <article-tabs
      :selected-tab="selectedTab"
      :is-customized-tab-action="true"
      :tabs="mockTabsData"
      :on-click="onClickTab"
      :is-full-width="true"
      class="mobile:pr-[20px]"
    />
    <article-horizontal-rank-group :articles="postInfos" />
  </article-wrapper>
</template>

<script>
import { ref } from 'vue';
import { getPopularNewsAPI } from '@/apis/__main__.js';
import { ARTICLE_TYPE } from '@/constants/article';
import { ArticleWrapper, ArticleTitle, ArticleTabs, ArticleHorizontalRankGroup } from '@/containers/Main/components';
import { errorToast } from '@/utils/toast';
import { UNEXPECTED_ERROR } from '@/constants/commons/errors';

export default {
  components: {
    ArticleWrapper,
    ArticleTitle,
    ArticleTabs,
    ArticleHorizontalRankGroup,
  },
  props: {
    initNews: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const selectedTab = ref(null);
    const postInfos = ref(props.initNews);

    const onClickTab = async (id) => {
      try {
        const news = await getPopularNewsAPI(id, 7);
        postInfos.value = news;
      } catch (e) {
        errorToast(e.message || UNEXPECTED_ERROR);
      }
    };

    const mockTabsData = [
      { id: ARTICLE_TYPE.NEWS.ID, name: ARTICLE_TYPE.NEWS.NAME },
      { id: ARTICLE_TYPE.BEAUTY.ID, name: ARTICLE_TYPE.BEAUTY.NAME },
      { id: ARTICLE_TYPE.COLUMN.ID, name: ARTICLE_TYPE.COLUMN.NAME },
    ];

    return { mockTabsData, selectedTab, onClickTab, postInfos };
  },
};
</script>

<style lang="scss" scoped>
.popular-article {
  &__wrapper {
    @apply w-full pc:p-0 lg:px-[20px] mobile:pl-[20px];
  }
}
</style>
