<template>
  <div class="button-group" :class="{ ['button-group__mobile']: isMobileUi }" v-bind="attrs">
    <a :href="contestInfoMap[targetContestId]?.rule" :download="contestInfoMap[targetContestId]?.ruleFilename" class="button-large-line section-button">
      <span
        >경기 규정 다운로드 <icon-base><chevron-right-arrow /></icon-base
      ></span>
    </a>
    <a :href="contestInfoMap[targetContestId]?.timeline" :download="contestInfoMap[targetContestId]?.timelineFilename" class="button-large-line section-button">
      <span
        >경기일정 참가 안내 및 일정 다운받기<icon-base><chevron-right-arrow /></icon-base
      ></span>
    </a>
    <v-btn class="button-large-line section-button button-qr" @click="openModal">
      <span
        >QR 코드 보기<icon-base><chevron-right-arrow /></icon-base
      ></span>
    </v-btn>
    <BasicModal
      :is-open="showModal"
      :body="'QR 코드 이미지'"
      :image-src="require('@/assets/2024_contest_qr.png')"
      :close-button="{ text: '닫기', onClick: closeModal }"
    />
  </div>
</template>

<script>
import ChevronRightArrow from '@/icons/ChevronRightArrow';
import BasicModal from '@/components/modal/BasicModal';
import { contestInfoMap } from '@/constants/contest';
import { computed } from 'vue';
import { useContestStore } from '@/stores';

export default {
  name: 'ContestDocumentButtons',
  components: { ChevronRightArrow, BasicModal },
  props: {
    isMobileUi: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { attrs }) {
    const contestStore = useContestStore();
    const targetContestId = computed(() => contestStore.targetContestId);

    return { attrs, targetContestId };
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    contestInfoMap() {
      return contestInfoMap;
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &__mobile {
    justify-content: center;
    flex-direction: column;
  }

  a {
    text-decoration: none;
    margin-bottom: 20px;
  }

  .button-qr {
    padding: 19px 24px;
    height: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
}
</style>
