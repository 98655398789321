<template>
  <div class="wrapper-desc">
    <div class="class-card">
      <div class="class-card__title">클래스 상세 정보</div>
      <div class="content-box">
        <div class="d-flex">
          <div class="mr-4">카테고리</div>
          <span>커트교육, 여성커트</span>
        </div>
        <div class="d-flex">
          <div class="mr-4">수강기간</div>
          <span>01.31(화)까지</span>
        </div>
        <div class="d-flex">
          <div class="mr-4">난이도</div>
          <span>중급</span>
        </div>
        <div class="d-flex">
          <div class="mr-4">강의분량</div>
          <span>총 10회 강의</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiCalendarCheck, mdiPlay } from '@mdi/js';

export default {
  setup() {
    return { mdiCalendarCheck, mdiPlay };
  },
};
</script>

<style lang="scss" scoped>
.wrapper-desc {
  background-color: $gray-80;
  padding: 5rem;
  border-radius: 20px;
  color: white;
}
.class-card {
  display: flex;

  &__title {
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    margin-right: 15rem;
  }
}

.content-box {
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > div {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }

  & > div > span {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
  }
}
</style>
