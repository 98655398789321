import axios from '@/lib/axiosController';

const BASE = '/user/forget';

/**
 * 비밀번호 찾기 > 인증번호 요청 API
 * @param {string} name
 * @param {string} phone
 * @param {string} loginId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postForgetPasswordAPI = ({ name, phone, loginId }) => axios.post(`${BASE}/password`, { name, phone, loginId });

/**
 * 비밀번호 찾기 > 인증번호 확인 API
 * - 인증번호 확인
 * @param {string} name
 * @param {string} phone
 * @param {string} loginId
 * @param {string} authcode
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postForgetPasswordVerificationAPI = ({ name, phone, loginId, authcode }) =>
  axios.post(`${BASE}/password/verification`, { name, phone, loginId, authcode });

/**
 * 비밀번호 재설정 API
 * @param {string} loginId
 * @param {string} password
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postPasswordResetAPI = ({ loginId, password }) => axios.post(`${BASE}/password/reset`, { loginId, password });

/**
 * 아이디 찾기 > 인증번호 요청 API
 * @param {string} name
 * @param {string} phone
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postForgetIdAPI = ({ name, phone }) => axios.post(`${BASE}/id`, { name, phone });

/**
 * 아이디 찾기 > 인증번호 확인 API
 * - 아이디를 알려준다.
 * @param {string} name
 * @param {string} phone
 * @param {string} authcode
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postForgetIdVerificationAPI = ({ name, phone, authcode }) => axios.post(`${BASE}/id/verification`, { name, phone, authcode });
