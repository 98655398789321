<template>
  <section class="section-wrapper">
    <h3 class="text__title-large section-title" :style="`display: ${isMobileUi ? 'none' : 'block'}`">부스 배치도</h3>
    <div>
      <img src="/boothLayout2022.png" alt="부스 배치도" class="booth-image" />
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section-wrapper {
  margin-top: 24px;
  padding: 0 20px;
  text-align: center;

  @include md-and-up {
    margin-top: 48px;
    padding: 0 20px;
  }

  & > * {
    margin-bottom: 3rem;
  }
}

.booth-image {
  width: 100%;
}
</style>
