<template>
  <div class="box">
    <!-- Profile Image -->
    <div class="title__profile">
      <v-img src="@/assets/education-mocking-detail-title-example.png" width="360" />
    </div>

    <div class="d-flex justify-content-between flex-column">
      <div class="title_wrapper">
        <div class="title__edu_name">[1월0원] 여성 커트 교육의 기준 (11강, 14강)</div>
        <div class="title__designer_name">헤어디자이너 최재영</div>

        <div class="d-flex">
          <div class="icon__box mr-4">
            <v-icon :icon="mdiCalendarCheck" color="white" size="small"></v-icon>
            <div class="icon__desc">기간제</div>
          </div>
          <div class="icon__box">
            <v-icon :icon="mdiPlay" color="white" size="small"></v-icon>
            <div class="icon__desc">수강가능</div>
          </div>
        </div>

        <div class="price__desc mb-12">가격 <span class="price__value">0원</span></div>
      </div>

      <button class="price__button">수강 신청하기</button>
    </div>
  </div>
</template>

<script>
import { mdiCalendarCheck, mdiPlay } from '@mdi/js';

export default {
  setup() {
    return { mdiCalendarCheck, mdiPlay };
  },
};
</script>

<style lang="scss" scoped>
.box {
  color: white;
  display: flex;
  padding: 5rem;
  background: $gray-80;
  border-radius: 20px;
}

.title_wrapper {
  & > :not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.title {
  &__profile {
    margin-right: 2rem;
  }

  &__edu_name {
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    color: white;
  }

  &__designer_name {
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: #6c727f;
  }
}

.icon {
  &__box {
    display: flex;
    background-color: #394150;
    border-radius: 8px;
    padding: 14px 12px;

    & > :not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  &__desc {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }
}

.price {
  &__desc {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }

  &__value {
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
  }

  &__button {
    @include spreadTypoMap($title-medium);
    background-color: $blue-50;
    font-size: 24px;
    font-weight: 700;
    color: white;
    border: 1px solid $blue-50;
    border-radius: 10px;
    padding: 1.5rem;
    width: 300px;
  }
}
</style>
