<template>
  <div class="d-flex mb-2 branch-selects">
    <v-select
      v-model="selectedMain"
      class="branch-selector mr-4"
      :append-inner-icon="mdiChevronDown"
      :items="mains"
      no-data-text="가입된 지회/지부가 없습니다."
      variant="underlined"
      item-title="label"
      item-value="id"
      hide-details
      persistent-placeholder
      @update:modelValue="onChangeMainBranch"
    ></v-select>

    <v-select
      :model-value="selectedSub"
      class="branch-selector"
      :append-inner-icon="mdiChevronDown"
      :items="subs"
      no-data-text="가입된 지회/지부가 없습니다."
      variant="underlined"
      item-title="label"
      item-value="id"
      hide-details
      persistent-placeholder
      @update:model-value="emitBranchOfficeId"
    ></v-select>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { mdiChevronDown } from '@mdi/js';
import { useBranchStore } from '@/stores';

export default {
  name: 'BranchOfficeSelects',
  props: {
    defaultBranchOfficeId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const branchStore = useBranchStore();

    const defaultBranchOfficeId = computed(() => props.defaultBranchOfficeId);

    const mains = ref([]);
    const subs = ref([]);

    const selectedMain = ref('');
    const selectedSub = ref('');

    const emitBranchOfficeId = (value) => {
      selectedSub.value = value;
      emit('update:branchOfficeId', value);
    };

    onMounted(() => {
      const { main, sub } = branchStore.defaultBranchSelectorInfos;

      mains.value = main;

      const defaultBranchOfficeIdNum = parseInt(defaultBranchOfficeId.value, 10);

      if (Number.isNaN(defaultBranchOfficeIdNum)) {
        subs.value = sub;
        selectedMain.value = main[0].id;

        emitBranchOfficeId(sub[0].id);
      } else {
        const { sub: subForDefault, selectedMainBranch } = branchStore.getByBranchOfficeId(props.defaultBranchOfficeId);
        subs.value = subForDefault;
        selectedMain.value = selectedMainBranch;

        emitBranchOfficeId(defaultBranchOfficeIdNum);
      }
    });

    /** 메인 브랜치 선택시 제일 첫번째 subBranch 로 자동선택 */
    const onChangeMainBranch = (value) => {
      subs.value = branchStore.getSubBranch(selectedMain.value);
      emitBranchOfficeId(subs.value[0]?.id);
      emit('update:mainBranch', value);
    };

    return {
      selectedMain,
      selectedSub,
      mains,
      subs,
      mdiChevronDown,
      emitBranchOfficeId,
      onChangeMainBranch,
    };
  },
};
</script>

<style lang="scss" scoped>
.branch-selector {
  max-width: 150px;
  width: 100%;
}
</style>
