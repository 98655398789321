import { inputRules } from '@/lib';

const { required, checkPhone } = inputRules;

const ruleTemplate = {
  name: [required],
  loginId: [required],
  phone: [required, checkPhone],
  authCode: [required],
  password: [required],
  isVerified: [(value) => Boolean(value) || '전화번호 인증 완료 후 진행해주세요.'],
};

export default ruleTemplate;
