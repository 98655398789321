const CERTIFICATE_STATUS = {
  none: {
    id: 'none',
  },
  pending: {
    id: 'pending',
  },
  complete: {
    id: 'complete',
  },
  reject: {
    id: 'reject',
  },
};

const MONTH_KO_EN = [
  {
    ko: '1월',
    en: 'jan',
  },
  {
    ko: '2월',
    en: 'feb',
  },
  {
    ko: '3월',
    en: 'mar',
  },
  {
    ko: '4월',
    en: 'apr',
  },
  {
    ko: '5월',
    en: 'may',
  },
  {
    ko: '6월',
    en: 'jun',
  },
  {
    ko: '7월',
    en: 'jul',
  },
  {
    ko: '8월',
    en: 'agu',
  },
  {
    ko: '9월',
    en: 'sep',
  },
  {
    ko: '10월',
    en: 'oct',
  },
  {
    ko: '11월',
    en: 'nov',
  },
  {
    ko: '12월',
    en: 'dec',
  },
];

const phoneAuthLimitSecond = 60 * 3;

export { CERTIFICATE_STATUS, MONTH_KO_EN, phoneAuthLimitSecond };
