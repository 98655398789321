<template>
  <div>
    <required-caption :disabled="disabled" :label="label" :required="required" />
    <v-text-field
      :id="id"
      :name="id"
      :model-value="textValue"
      :placeholder="placeholder"
      :rules="rules"
      :type="showPasswordIcon ? 'text' : type"
      :required="required"
      :append-inner-icon="passwordIcon"
      :hint="hint"
      :persistent-hint="persistentHint"
      :hide-details="hideDetails"
      :readonly="readonly"
      :disabled="disabled"
      :error="error"
      :error-messages="errorMessages"
      class="basic-input"
      autocomplete="on"
      variant="underlined"
      @click:append-inner="showPasswordIcon = !showPasswordIcon"
      @update:modelValue="(value) => $emit('update:textValue', value)"
    >
      <template v-if="$slots.append" #append>
        <slot name="append" />
      </template>

      <template v-if="$slots.details" #details>
        <slot name="details" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import RequiredCaption from '@/components/RequiredCaption.vue';

export default {
  components: { RequiredCaption },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    textValue: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const showPasswordIcon = ref(false);
    const passwordIcon = computed(() => {
      if (props.type !== 'password') {
        return null;
      }

      return showPasswordIcon.value ? mdiEye : mdiEyeOff;
    });

    return { showPasswordIcon, passwordIcon };
  },
};
</script>

<style lang="scss" scoped>
.name {
  @include spreadTypoMap($caption-xsmall);
  color: $gray-60;
  letter-spacing: 0.005em;
}

.required {
  color: $koba-red;
}

.basic-input {
  :deep(.v-input__append) {
    padding: 0.5rem;
  }

  :deep(.v-field__input) > input {
    @include sm-and-down {
      font-size: 14px;
      text-overflow: ellipsis;
    }
  }
}
</style>
