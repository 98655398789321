<template>
  <div v-if="loginId" class="form-body notice-id__wrapper" :style="`padding: 0 ${isMobileUi ? 20 : 0}px 48px;`">
    <div class="id-box text__body-large">
      아이디 : <strong>{{ loginId }}</strong>
    </div>
    <button :class="{ 'button-large-primary': !isMobileUi, 'button-medium-primary': isMobileUi }" type="button" style="width: 100%" @click="resetData">
      <span>다시 찾기</span>
    </button>
  </div>
  <form v-else :style="`padding: 0 ${isMobileUi ? 20 : 0}px 48px;`" class="form-body" @submit="verifyAuthCode">
    <v-text-field v-model.trim="form.name" name="name" label="이름" placeholder="2글자 이상 입력해주세요." :rules="rules.name" variant="underlined" clearable />
    <v-text-field
      v-model.trim="form.phone"
      name="phone"
      label="전화번호"
      type="tel"
      placeholder="숫자만 입력해주세요."
      :rules="rules.phone"
      :disabled="disabledPhoneField"
      variant="underlined"
      class="text-field-deep"
      clearable
      ><template #append
        ><v-btn
          type="button"
          class="button-xSmall-line append-button"
          :loading="request.isLoading"
          :disabled="request.isLoading && isRequestBtnActive"
          @click="requestAuthCode"
        >
          <span>인증번호 받기</span>
        </v-btn></template
      ></v-text-field
    >
    <v-text-field
      v-model.trim="form.authcode"
      name="authcode"
      type="number"
      label="인증코드"
      placeholder="인증번호를 입력해주세요"
      hint="핸드폰 번호를 다시 입력하려면 인증하기 버튼을 눌러주세요."
      :rules="rules.authcode"
      variant="underlined"
      class="text-field-deep"
      clearable
    >
      <template v-if="request.success && (verify.success || !isFinish)" #prepend-inner>
        <div class="text__caption-small" style="padding-right: 0.5rem; padding-top: 5px" :style="`color: ${verify.success ? 'green' : 'tomato'}`">
          {{ verify.success ? '인증완료' : mmSS }}
        </div>
      </template>
    </v-text-field>

    <button
      :class="{ 'button-large-primary': !isMobileUi, 'button-medium-primary': isMobileUi }"
      type="submit"
      :loading="verify.isLoading"
      :disabled="verify.isLoading"
      style="width: 100%"
    >
      <span>{{ verify.success || verify.error ? '재인증하기' : '인증하기' }}</span>
    </button>
  </form>
  <basic-modal :is-open="isDialogOpen" :body="dialogBody" :close-button="dialogCloseButton" />
</template>

<script>
import { computed, ref, watch } from 'vue';

import BasicModal from '@/components/modal/BasicModal';
import { ruleTemplate, usePhone, validate } from '@/containers/Find/service';
import useTimer from '@/containers/Find/service/useTimer';
import { toMMSS } from '@/utils/date';
import { postForgetIdAPI, postForgetIdVerificationAPI } from '@/apis/forget';
import { pick } from 'lodash-es';
import { infoToast } from '@/utils/toast';

export default {
  name: 'FindId',
  components: { BasicModal },
  props: {
    isMobileUi: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    /** formData */
    const form = ref({
      name: '',
      phone: '',
      authcode: '',
    });
    const isVerified = ref(false);

    /** result Data */
    const loginId = ref('');

    /** btn */
    const isRequestBtnActive = ref(true);
    const isSubmitButtonLoading = ref(false);

    /** dialog */
    const isDialogOpen = ref(false);
    const dialogBody = ref('');
    const dialogCloseButton = ref({
      isLoading: false,
      text: '닫기',
      disabled: false,
      onClick: () => {
        isDialogOpen.value = false;
        dialogBody.value = '';
      },
    });

    const openDialog = (message) => {
      isDialogOpen.value = true;
      dialogBody.value = message;
    };

    const disabledPhoneField = ref(false);
    const { request, verify, phoneVerifyRequest, phoneVerify, resetPhoneVerifyResult, resetVerifyValue } = usePhone();
    const { isFinish, time, timerAction } = useTimer({ initialTime: 180 });

    const mmSS = computed(() => toMMSS(time.value.minute, time.value.second));

    const activateRequestAuthCode = () => {
      isRequestBtnActive.value = true;
      disabledPhoneField.value = false;
      form.value.authcode = '';
      timerAction.stop();
    };

    const requestAuthCode = async () => {
      const newRules = pick(rules, 'name', 'phone');
      const { isValid, message } = validate(newRules, { ...form.value, isVerified: isVerified.value });

      if (!isValid) {
        openDialog(message);
        return;
      }

      disabledPhoneField.value = true;

      await phoneVerifyRequest(() => postForgetIdAPI({ name: form.value.name, phone: form.value.phone }));
      timerAction.start();
      isRequestBtnActive.value = false;
    };

    /** 인증 요청이 실패했을 경우 */
    watch(
      () => request.value.error,
      (cur, prev) => {
        if (!prev && cur) {
          activateRequestAuthCode();
          resetPhoneVerifyResult();
        }
      },
    );

    const verifyAuthCode = async (e) => {
      e.preventDefault();
      /** 재인증하기 클릭 후 인증번호 받기를 누르지 않고 인증하기를 클릭하는 경우 */
      if (isFinish.value && !verify.success && !verify.value.error) {
        infoToast('인증번호 요청 후 인증 번호 받기 버튼을 눌러주세요.');
      }

      /** 재인증하기 -> 인증번호 찾기 버튼 활성화 */
      if (verify.value.success || verify.error || isFinish.value) {
        activateRequestAuthCode();
        resetVerifyValue();
        return;
      }

      const newRules = pick(rules, 'name', 'phone', 'authcode');
      const { isValid, message } = validate(newRules, { ...form.value, isVerified: isVerified.value });

      if (!isValid) {
        openDialog(message);
        return;
      }

      const res = await phoneVerify(() => postForgetIdVerificationAPI({ name: form.value.name, phone: form.value.phone, authcode: form.value.authcode }));
      loginId.value = res;

      isVerified.value = verify.value.success;
      timerAction.stop();
    };

    const resetData = () => {
      form.value = { name: '', phone: '', authcode: '' };
      isVerified.value = false;
      loginId.value = '';
      disabledPhoneField.value = false;
      isRequestBtnActive.value = true;
      isSubmitButtonLoading.value = false;

      resetPhoneVerifyResult();
    };

    return {
      loginId,
      form,
      rules,
      request,
      verify,
      mmSS,
      isFinish,
      disabledPhoneField,
      isRequestBtnActive,
      isDialogOpen,
      dialogBody,
      dialogCloseButton,
      isSubmitButtonLoading,
      requestAuthCode,
      verifyAuthCode,
      resetData,
    };
  },
};

const rules = {
  name: ruleTemplate.name,
  phone: ruleTemplate.phone,
  authcode: ruleTemplate.authCode,
  isVerified: ruleTemplate.isVerified,
};
</script>

<style scoped lang="scss">
.form-body {
  margin-top: 32px;
}

.notice-id__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.id-box {
  background: $gray-10;
  border-radius: 6px;
  padding: 1rem;
}

.text-field-deep:deep {
  .v-input__append {
    padding-top: 0;
    margin-inline-start: 8px;
  }
}

.append-button {
  box-shadow: none;
  width: 102px;
  height: 100%;
  align-self: flex-end;

  &:hover {
    box-shadow: none;
  }
}
</style>
