const LEADERS_DATA_IDS = {
  // 위원 전용 게시판은 운영하지 않는다.
  // /** 회장 */
  // president: 'president',
  // /** 부회장, 이사 */
  // vicePresident: 'vicePresident',
  // /** 대의원 */
  // grandAssemblyman: 'grandAssemblyman',
  // /** 감사 */
  // audit: 'audit',
  // /** 이사회 */
  // boardOfDirectors: 'boardOfDirectors',
  // /** 사무총장 */
  // secretaryGeneral: 'secretaryGeneral',
};

const DEPARTMENTS_DATA_IDS = {
  /** 총무국 */
  administrationMain: 'administrationMain',
  /** 총무부 */
  administrationDepartment: 'administrationDepartment',
  /** 경리부 */
  administrationAccounting: 'administrationAccounting',

  /** 홍보국 */
  publicityMain: 'publicityMain',
  /** 광고부 */
  publicityAdvertising: 'publicityAdvertising',
  /** 편집부 */
  publicityEdit: 'publicityEdit',

  /** 교육원 */
  educationMain: 'educationMain',
  /** 교육부 */
  educationDepartment: 'educationDepartment',

  /** 위원회 */
  committeeMain: 'committeeMain',
  /** 위원회 관리부 */
  committeeManagement: 'committeeManagement',
};

export const ORGANIZATION_COMMUNITY_TABLE_IDS = {
  /** 총무국 */
  administrationMain: 100011,
  /** 총무부 */
  administrationDepartment: 100012,
  /** 경리부 */
  administrationAccounting: 100013,

  /** 홍보국 */
  publicityMain: 100014,
  /** 광고부 */
  publicityAdvertising: 100015,
  /** 편집부 */
  publicityEdit: 100016,

  /** 교육원 */
  educationMain: 100017,
  /** 교육부 */
  educationDepartment: 100018,

  /** 위원회 */
  committeeMain: 100019,
  /** 위원회 관리부 */
  committeeManagement: 100020,
};

export const ETC_DATA_IDS = {
  ...LEADERS_DATA_IDS,
  ...DEPARTMENTS_DATA_IDS,
};
