<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16.46 10.0404C16.7139 10.2942 16.7139 10.7058 16.46 10.9596L13.1671 14.2525C12.5228 14.8969 11.478 14.8969 10.8337 14.2525L7.54077 10.9596C7.28693 10.7058 7.28693 10.2942 7.54077 10.0404C7.79461 9.78655 8.20617 9.78655 8.46001 10.0404L11.7529 13.3333C11.8896 13.47 12.1112 13.47 12.2479 13.3333L15.5408 10.0404C15.7946 9.78655 16.2062 9.78655 16.46 10.0404Z"
  />
</template>

<script>
export default {};
</script>
