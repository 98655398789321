<template>
  <div class="table-wrapper">
    <table class="committee-table">
      <tr class="table-header-row">
        <th v-for="tableHeader in tableHeaders" :key="tableHeader.id" class="table-header text-center" :data-th-id="tableHeader.id">
          {{ tableHeader.label }}
        </th>
      </tr>
      <tr v-for="tableContent in committee" :key="tableContent.id" class="table-row text-center" :class="{ 'row-highlight': tableContent.highlight }">
        <template v-for="tableHeader in tableHeaders" :key="tableHeader.id">
          <td v-if="tableHeader.id !== 'position' || tableContent[tableHeader.id]" :rowspan="getRowSpan(tableHeader, tableContent)">
            {{ tableContent[tableHeader.id] }}
          </td>
        </template>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'CommitteeTable',
  props: {
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    committee: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    return {
      getRowSpan,
    };
  },
};

const getRowSpan = (tableHeader, tableContent) => {
  if (tableHeader.id === 'position' && tableContent.rowSpan) return tableContent.rowSpan;
  return 1;
};
</script>

<style scoped lang="scss">
.table-wrapper {
  margin: 24px 0;
  width: 100%;
  padding: 0 20px;

  @include md-and-up {
    padding: 0;
    margin-bottom: 48px;
  }
}

.committee-table {
  width: 100%;
}

.table-header {
  background: $blue-50;
  padding: 13px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: white;
  width: 25%;

  @include md-and-up {
    padding: 17px 46px;
    font-size: 16px;
  }
}

.table-row {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $gray-80;
  border-bottom: 1px solid $gray-30;

  td {
    padding: 13px 8px;
    word-break: keep-all;
  }
}

.row-highlight {
  background: $blue-00;
}
</style>
