export const regularReportDataList = {
  10: {
    id: 10,
    number: 10,
    title: '2022년 10월 미용회보',
    author: '대한미용사회',
    date: '2022-10-14',
    pdfUrl: 'https://storage.googleapis.com/koba-dev-static/beautyMPdf/beautyM_202210-02.pdf',
    isSecure: false,
  },
  9: {
    id: 9,
    number: 9,
    title: '2022년 9월 미용회보',
    author: '대한미용사회',
    date: '2022-10-14',
    pdfUrl: 'https://storage.googleapis.com/koba-dev-static/beautyMPdf/beautyM_202209-02.pdf',
    isSecure: false,
  },
  8: {
    id: 8,
    number: 8,
    title: '2022년 8월 미용회보',
    author: '대한미용사회',
    date: '2022-10-14',
    pdfUrl: 'https://storage.googleapis.com/koba-dev-static/beautyMPdf/beautyM_202208.pdf',
    isSecure: false,
  },
  7: {
    id: 7,
    number: 7,
    title: '2022년 7월 미용회보',
    author: '대한미용사회',
    date: '2022-10-14',
    pdfUrl: 'https://storage.googleapis.com/koba-dev-static/beautyMPdf/beautyM_202207.pdf',
    isSecure: false,
  },
  6: {
    id: 6,
    number: 6,
    title: '2022년 6월 미용회보',
    author: '대한미용사회',
    date: '2022-10-14',
    pdfUrl: 'https://storage.googleapis.com/koba-dev-static/beautyMPdf/beautyM_202206.pdf',
    isSecure: false,
  },
  5: {
    id: 5,
    number: 5,
    title: '2022년 5월 미용회보',
    author: '대한미용사회',
    date: '2022-10-14',
    pdfUrl: 'https://storage.googleapis.com/koba-dev-static/beautyMPdf/beautyM_202205.pdf',
    isSecure: false,
  },
  4: {
    id: 4,
    number: 4,
    title: '2022년 4월 미용회보',
    author: '대한미용사회',
    date: '2022-10-14',
    pdfUrl: 'https://storage.googleapis.com/koba-dev-static/beautyMPdf/beautyM_202204.pdf',
    isSecure: false,
  },
  3: {
    id: 3,
    number: 3,
    title: '2022년 3월 미용회보',
    author: '대한미용사회',
    date: '2022-10-14',
    pdfUrl: 'https://storage.googleapis.com/koba-dev-static/beautyMPdf/beautyM_202203.pdf',
    isSecure: false,
  },
  2: {
    id: 2,
    number: 2,
    title: '2022년 2월 미용회보',
    author: '대한미용사회',
    date: '2022-10-14',
    pdfUrl: 'https://storage.googleapis.com/koba-dev-static/beautyMPdf/beautyM_202202.pdf',
    isSecure: false,
  },
  1: {
    id: 1,
    number: 1,
    title: '2022년 1월 미용회보',
    author: '대한미용사회',
    date: '2022-10-14',
    pdfUrl: 'https://storage.googleapis.com/koba-dev-static/beautyMPdf/beautyM_202201.pdf',
    isSecure: false,
  },
};
