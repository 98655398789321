<template>
  <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 24px">
    <div style="display: flex">
      <select-box :options="selectedOptions" :default-option="selectedOptions[0]?.label" @click="onChangeSearchCategory" />
      <div class="input-wrapper">
        <form @submit="onSearchPost">
          <input v-model="searchInputModel" type="search" placeholder="검색어를 입력하세요" class="text-input" />
          <v-btn style="margin-left: 4px" size="small" type="submit" :loading="isLoading" flat>
            <icon-base icon-name="search" icon-color="none" icon-stroke="#0070bc" width="16" height="16"><search /></icon-base>
          </v-btn>
        </form>
      </div>
    </div>
    <div style="display: flex">
      <!--      <v-btn class="view-more-btn" variant="outlined" :size="btnSize" flat>-->
      <!--        전체 글 보기<icon-base style="margin-left: 4px"><chevron-down-arrow /></icon-base>-->
      <!--      </v-btn>-->
      <v-btn v-if="hideWriteable" class="add-btn" :size="btnSize" :to="`/write?communityId=${communityId}`" flat>
        <icon-base icon-name="plus" icon-color="white" style="margin-right: 4px"><plus /></icon-base>
        <span class="add-btn__text">글 작성하기</span>
      </v-btn>
    </div>
  </div>
  <table style="margin-top: 24px; width: 100%">
    <tr class="table-header-row">
      <th v-for="tableHeader in tableHeaders" :key="tableHeader.id" class="table-header" :data-th-id="tableHeader.id">{{ tableHeader.label }}</th>
    </tr>
    <tr v-if="posts.length === 0">
      <td colspan="5">
        <empty-data-card />
      </td>
    </tr>
    <tr v-for="post in posts" :key="post.id" class="table-row" :data-post-id="post.id" @click="(e) => goDetailPost(e, post)">
      <td>{{ post.id }}</td>
      <td class="post-title">
        <v-icon v-if="post.parentId !== post.id" :icon="mdiArrowRightBottom" size="x-small" start></v-icon>
        <reply-badge v-if="post.parentId !== post.id" />
        <v-icon v-if="post.hasPassword" :icon="mdiLock" size="x-small" start></v-icon>
        {{ post.title }}
      </td>
      <td>{{ post.userName }}</td>
      <td>{{ toYYYYMMDD(post.createdDate) }}</td>
      <td>{{ post.views }}</td>
    </tr>
  </table>
  <div class="pagination-wrapper">
    <v-pagination v-model="currentPage" :length="pages || 1" />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useDisplay } from 'vuetify';
import { useRoute, useRouter } from 'vue-router';
import { mdiLock, mdiArrowRightBottom } from '@mdi/js';
import Plus from '@/icons/Plus';
// import ChevronDownArrow from '@/icons/ChevronDownArrow';
import EmptyDataCard from '@/components/EmptyDataCard.vue';
import ReplyBadge from '@/components/ReplyBadge.vue';
import Search from '@/icons/Search';
import SelectBox from '@/components/SelectBox';
import { useMenuStore, useUserStore } from '@/stores';
import { toYYYYMMDD } from '@/utils/date';

export default {
  name: 'ProblemBoardPc',
  components: { Plus, EmptyDataCard, Search, SelectBox, ReplyBadge },
  props: {
    selectedOptions: {
      type: Array,
      required: true,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    posts: {
      type: Array,
      default: () => [],
    },
    hideWriteButton: {
      type: Boolean,
      default: () => false,
    },
    pages: {
      type: Number,
      default: () => 0,
    },
    searchPost: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const currentPage = ref(1);
    const userStore = useUserStore();
    const menuStore = useMenuStore();
    const searchInputModel = ref('');
    const isLoading = ref(false);

    let selectedCategory = props.selectedOptions?.[0].id;
    let searchInputValue = '';

    const isOpenDialog = ref(false);

    const closeDialog = () => {
      isOpenDialog.value = false;
    };

    const { name } = useDisplay();

    const btnSize = computed(() => (name.value === 'md' ? 'large' : 'x-large'));
    const communityId = computed(() => route.params.communityId); // read parameter id (it is reactive)

    const onChangeSearchCategory = ({ target = {} }) => {
      if (target?.value) {
        selectedCategory = target.value;
      }
    };

    const goDetailPost = (e, post) => {
      const { postId } = e.currentTarget.dataset;
      if (post.hasPassword) {
        router.push(`/post/${postId}?type=secure`);
        return;
      }

      router.push(`/post/${postId}`);
    };

    const initPage = () => {
      currentPage.value = 1;
    };

    const fetchPost = async (page = 1) => {
      isLoading.value = true;
      searchInputValue = searchInputModel.value;
      try {
        if (selectedCategory === 'author') {
          await props.searchPost({ page, keyword: null, userName: searchInputValue });
        } else if (selectedCategory === 'all') {
          await props.searchPost({ page, keyword: searchInputValue, userName: searchInputValue });
        } else {
          await props.searchPost({ page, keyword: searchInputValue, userName: null });
        }
      } finally {
        isLoading.value = false;
      }
    };

    watch(currentPage, (current, prev) => {
      fetchPost(current);
    });

    const onSearchPost = (e) => {
      e.preventDefault();
      if (!isLoading.value) {
        fetchPost(1);
      }
    };

    const hideWriteable = computed(() => {
      const communityMenuMap = menuStore.getWriteAuthorityMap;
      const communityMenu = communityMenuMap[`/community/${communityId.value}`];
      const writeAuthority = communityMenu?.writeAuthority;
      if (!communityMenuMap || !communityMenu) {
        return true;
      }
      return !!userStore.getUserView?.id && writeAuthority;
    });

    return {
      currentPage,
      btnSize,
      goDetailPost,
      mdiLock,
      mdiArrowRightBottom,
      initPage,
      userStore,
      toYYYYMMDD,
      isOpenDialog,
      closeDialog,
      communityId,
      hideWriteable,
      onSearchPost,
      onChangeSearchCategory,
      searchInputModel,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/custom/customVPagination';

.input-wrapper {
  margin-left: 8px;
  min-width: 352px;
  background: white;
  border-bottom: 1px solid $gray-30;
  padding: 10px 0;
  display: flex;
}

.text-input {
  min-width: 300px;
  &:focus {
    outline: none;
  }
}

.view-more-btn {
  @include spreadTypoMap($button-medium);

  border: 1px solid $gray-30;
  border-radius: 4px;
  margin-right: 8px;
}

.add-btn {
  @include spreadTypoMap($button-medium);
  color: white;

  background: $blue-50;
  border-radius: 4px;

  @include md-and-down {
    padding: 0.5rem;
  }

  &__text {
    @include md-and-down {
      display: none;
    }
  }
}

// 테이블
.table-header-row {
  background: $blue-50;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: white;
}

.table-header {
  padding: 17px 18px;
  text-align: center;

  &[data-th-id='title'] {
    text-align: left;
  }
}

.table-row {
  background: white;
  border-bottom: 1px solid $gray-20;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  & > td {
    padding: 17px 18px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $gray-80;
    text-align: center;
  }

  .post-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $black;
    text-align: left;
  }
}

.pagination-wrapper:deep {
  margin-top: 24px;
  margin-bottom: 48px;

  @include custom-v-pagination;
}
</style>
