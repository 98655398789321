<template>
  <product-mall-info />
  <search-bank-branch />
  <loan-benefits />
</template>
<script>
import ProductMallInfo from '@/containers/ProductMall/ProductMallInfo';
import SearchBankBranch from '@/containers/ProductMall/SearchBankBranch';
import LoanBenefits from '@/containers/ProductMall/LoanBenefits';

export default {
  name: 'ProductMall',
  components: {
    ProductMallInfo,
    SearchBankBranch,
    LoanBenefits,
  },
};
</script>
