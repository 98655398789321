<template>
  <v-container class="term-accept" :class="className">
    <v-row>
      <v-col>
        <v-checkbox
          class="term-accept__all"
          :model-value="acceptObj.service && acceptObj.privacy"
          label="전체 동의"
          color="#0070BC"
          hide-details
          @click="toggleAllDesc"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <terms-vue :is-accept="acceptObj.service" :items="serviceTerms" title="이용약관에 동의 (필수)" @toggle-accept="(v) => toggle(v, 'service')"></terms-vue>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <privacy-terms
          :is-accept="acceptObj.privacy"
          title="개인정보 수집 및 이용에 대한 안내 (필수)"
          @toggle-accept="(v) => toggle(v, 'privacy')"
        ></privacy-terms>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <third-party-terms
          :is-accept="acceptObj.thirdParty"
          title="개인정보 제 3자 제공 내역 (선택)"
          @toggle-accept="(v) => toggle(v, 'thirdParty')"
        ></third-party-terms>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-checkbox
          v-model="acceptObj.marketing"
          class="term-accept__normal"
          label="이벤트 등 프로모션 알림 메일 수신 (선택)"
          color="#0070BC"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row>
      <v-btn
        class="term-accept__accept_btn"
        :disabled="!(acceptObj.service && acceptObj.privacy)"
        block
        flat
        size="x-large"
        @click="() => goNextStep({ isAllowAd: acceptObj.marketing, isAllowPersonalInfo: acceptObj.thirdParty })"
      >
        동의
      </v-btn>
      <v-btn class="term-accept__not_accept_btn" size="x-large" flat block @click="goBackStep">비동의</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';
import { ref } from 'vue';

import { serviceTerms } from '@/constants/terms';
import TermsVue from '@/components/Terms.vue';
import PrivacyTerms from '@/containers/SignUp/terms/PrivacyTerms.vue';
import ThirdPartyTerms from '@/containers/SignUp/terms/ThirdPartyTerms.vue';

export default {
  components: { TermsVue, PrivacyTerms, ThirdPartyTerms },
  props: {
    className: {
      type: String,
      default: '',
    },
    goBackStep: {
      type: Function,
      default: () => {},
    },
    goNextStep: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const acceptObj = ref({
      service: false,
      privacy: false,
      thirdParty: false,
      marketing: false,
    });

    const toggleAllDesc = () => {
      const accepted = acceptObj.value.service && acceptObj.value.privacy;

      acceptObj.value.service = !accepted;
      acceptObj.value.privacy = !accepted;
      acceptObj.value.thirdParty = !accepted;
      acceptObj.value.marketing = !accepted;
    };

    const toggle = (v, target) => {
      acceptObj.value[target] = v;
    };

    return { acceptObj, toggle, toggleAllDesc, mdiCheckboxMarkedCircleOutline, serviceTerms };
  },
};
</script>

<style lang="scss" scoped>
.term-accept {
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }

  &__all {
    :deep(.v-label) {
      @include spreadTypoMap($body-xxLarge);
      opacity: unset;
    }

    :deep(.v-icon) {
      width: 2rem;
      height: 2rem;
    }
  }

  &__normal {
    :deep(.v-label) {
      @include spreadTypoMap($body-xLarge);
      opacity: unset;
      white-space: normal;
    }
  }

  &__accept_btn {
    background: $blue-50;
    color: white;
  }

  &__not_accept_btn {
    color: $blue-50;
  }
}
</style>
