<template>
  <span class="reply-badge text__body-xxsmall">RE</span>
</template>

<style lang="scss" scoped>
.reply-badge {
  padding: 4px;
  background: $blue-50;
  color: white !important;
  border-radius: 4px;
  width: 22px;
  height: 24px;
  margin-right: 4px;
}
</style>
