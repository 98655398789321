<template>
  <v-dialog v-model="showsModal" :fullscreen="true" width="90%" height="90%" min-height="90%" min-width="90%" @click:outside="closeDialog">
    <v-card tag="ul" class="popover__wrapper">
      <button class="close-button" type="button" @click="closeDialog"><v-icon :icon="mdiClose" /></button>
      <li v-for="(attr, idx) in attributes" :key="attr.key" class="box" :style="idx === 0 ? 'border: none' : ''">
        <p class="popover__date">
          <span class="popover__indicator" :style="`background:${attr.highlight?.base?.color}`" />( {{ getMMDD(attr.targetDate.start) }} ~
          {{ getMMDD(attr.targetDate.end) }} )
        </p>
        <p class="popover__title">{{ attr.customData.title }}</p>
        <p v-if="attr.customData.description" class="description">{{ attr.customData.description }}</p>
      </li>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from 'vue';
import { getKrDate } from '@/utils/date';
import { mdiClose } from '@mdi/js';

export default {
  name: 'MainCalendarPopover',
  props: {
    day: {
      type: Object,
      required: true,
    },
    attributes: {
      type: Array,
      required: true,
    },
    closeDialog: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const showsModal = ref(true);
    return { getMMDD: (date) => getKrDate(date).format('MM/DD'), showsModal, mdiClose };
  },
};
</script>

<style scoped lang="scss">
.popover__wrapper {
  position: relative;
  background: $gray-80;
  color: white;
  border-radius: 8px !important;
  padding: 0.5rem;
  overflow-y: scroll;
}

.popover__indicator {
  display: inline-block;
  width: 20px;
  height: 10px;
  border-radius: 8px;
  padding: 2px;
  margin-right: 0.5rem;
  filter: brightness(1.2);
}

.box {
  width: 100%;
  align-self: center;
  list-style: none;
  margin-bottom: 1rem;
  padding: 1rem 4px 4px;

  border-top: 1px solid #999;

  p {
    @include spreadTypoMap($body-medium);
    color: white;
    word-break: break-word;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
  }
}

.popover__date {
  width: calc(100% - 24px);
  @include spreadTypoMap($body-small);
  color: $gray-40 !important;
  padding-bottom: 0.1rem !important;
}

.popover__title {
  width: calc(100% - 24px);
}

.description {
  width: 100%;
  background: #4b576b;
  padding: 0.5rem;
  border-radius: 2px;
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>
