<template>
  <section class="news-list__wrapper">
    <router-link v-for="newsItem in newsList" :key="newsItem.id" :to="`/news-detail/${newsItem.id ?? ''}`" class="news-list__card">
      <div v-if="!!newsItem.thumbnail" class="image-wrapper">
        <img :src="newsItem.thumbnail" />
      </div>
      <div class="contents-wrapper" :class="{ 'no-thumbnail': !newsItem.src }">
        <h2 class="news-list__title">{{ newsItem.title }}</h2>
        <p class="news-list__body">{{ newsItem.bodySummary }}</p>
      </div>
    </router-link>
  </section>
</template>

<script>
export default {
  name: 'NewsList',
  props: {
    newsList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
// 로컬 변수
$visible-lines-mobile: 3;
$visible-lines-pc-small: 3;
$visible-lines-pc: 5;

.news-list__wrapper {
  padding: 24px 20px 16px;

  @include md-and-up {
    padding: 0 0 24px;
  }
}

.news-list__card {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  text-decoration: none;

  & + & {
    margin-top: 16px;
  }

  @include md-and-up {
    flex-direction: row;
    padding-bottom: 0;

    & + & {
      margin-top: 32px;
    }
  }
}

.image-wrapper {
  width: 100%;

  > img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 16 / 9;
    margin-bottom: 16px;

    @include md-and-up {
      aspect-ratio: 4 / 3;
      margin-bottom: 0;
    }
  }

  @include md-and-up {
    width: 25%;
    margin-right: 24px;
    align-self: center;
  }
}

.no-thumbnail {
  @include md-and-up {
    width: 100% !important;
    align-self: center;
    min-height: 120px;
  }
}

.contents-wrapper {
  @include md-and-up {
    width: 75%;
    align-self: center;
  }
}

.news-list__title {
  margin-bottom: 8px;
  @include spreadTypoMap($title-xsmall);

  @include md-and-up {
    @include spreadTypoMap($title-small);
  }

  @include lg-and-up {
    @include spreadTypoMap($title-medium);
  }
}

.news-list__body {
  margin-bottom: 0;
  @include spreadTypoMap($body-xsmall);
  color: $gray-50;
  max-height: calc(#{map-get($body-xsmall, 'font-size')} * #{map-get($body-xsmall, 'line-height')} * #{$visible-lines-mobile});
  -webkit-line-clamp: $visible-lines-mobile;

  @include md-and-up {
    @include spreadTypoMap($body-small);
    color: $gray-50;
    max-height: calc(#{map-get($body-small, 'font-size')} * #{map-get($body-medium, 'line-height')} * #{$visible-lines-pc-small});
    -webkit-line-clamp: $visible-lines-pc-small;
  }

  @include lg-and-up {
    @include spreadTypoMap($body-medium);
    color: $gray-50;
    max-height: calc(#{map-get($body-medium, 'font-size')} * #{map-get($body-medium, 'line-height')} * #{$visible-lines-pc});
    -webkit-line-clamp: $visible-lines-pc;
  }

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
