<template>
  <div class="box" @click="onClickCard">
    <div>
      <div class="box__title">사)대한미용사회중앙회 X <hana-logo height="32" /></div>
      <div class="box__body">각종 혜택을 만나보세요</div>
    </div>
    <div class="box__btn">
      금융주치의 바로가기 <v-icon>{{ mdiChevronRight }}</v-icon>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { mdiChevronRight } from '@mdi/js';
import HanaLogo from '@/icons/HanaLogo.vue';

export default {
  components: { HanaLogo },
  setup() {
    const router = useRouter();

    const onClickCard = () => {
      router.push('/product-mall');
    };
    return { mdiChevronRight, onClickCard };
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 24px;
  background: $hana-green-80;

  @include sm-and-down {
    justify-content: center;
    flex-direction: column;
    text-align: center;

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &__title {
    @include spreadTypoMap($title-small);
    color: white;
    margin-bottom: 0.5rem;
  }

  &__body {
    @include spreadTypoMap($body-medium);
    color: $gray-20;
  }

  &__btn {
    @include spreadTypoMap($body-small);

    display: flex;
    align-items: center;
    color: white;
  }
}
</style>
