<template>
  <v-card flat class="w-100">
    <div class="terms__body" style="height: 800px">
      <ol>
        <li class="terms__title">
          <div class="mb-3">수집하는 개인정보 항목 및 수집방법</div>
          <div class="terms__desc">
            회사는 회원 가입, 서비스제공, 기타상담 등을 위해 개인정보를 수집하고 있으며, 또한 설문조사나 이벤트 시에 집단적인 통계분석을 위해서나 경품발송을
            위한 목적으로도 개인정보 기재를 요청할 수 있습니다. 그러나, 회사는 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및
            신조, 출신지 및 본적지, 정치적 성향 및 범죄기록 등)는 가급적 수집하지 않으며 부득이하게 수집해야 할 경우 이용자들의 사전동의를 반드시 구합니다.
            이때에도 기입하신 정보는 해당서비스 제공이나 회원님께 사전에 밝힌 목적 이외의 다른 어떠한 목적으로도 사용되지 않음을 알려드립니다.
          </div>

          <div class="terms__desc">개인정보의 수집 내용은 아래와 같습니다.</div>

          <ol class="pl-4">
            <li class="mb-3">필수항목</li>
            <ul class="pl-4">
              <li class="mb-3">성명, 생년월일, 연락처, 이메일, 사업장명, 사업장주소, 소속지회/지부</li>
            </ul>
          </ol>
        </li>

        <li class="terms__title">
          <div class="mb-3">개인정보의 보유 및 이용 기간</div>
          <div class="terms__desc">
            서비스 이용자가 당사의 회원으로서 서비스를 계속 이용하는 동안 당사는 이용자의 개인정보를 계속 보유하며 서비스 제공 등을 위해 이용합니다.
          </div>

          <div class="terms__desc">
            서비스 이용자의 개인정보는 그 수집 및 이용 목적(설문조사, 이벤트 등 일시적인 목적을 포함)이 달성되거나 이용자가 직접 삭제 또는 회원 탈퇴한 경우에
            재생할 수 없는 방법으로 파기합니다.
          </div>

          <div class="terms__desc">회사 내부 방침에 의한 정보보유 사유 회원탈퇴 시 개인정보 보존기간은 아래와 같습니다.</div>

          <ol class="pl-4">
            <li class="mb-3">보존근거: 불량 이용자의 재가입 방지, 명예훼손 등 권리침해 분쟁 및 수사협조</li>
            <li class="mb-3">홈페이지 회원 탈퇴시까지</li>
          </ol>

          <div class="terms__desc">
            관련법령에 의한 정보보유 사유 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는
            관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
          </div>

          <ol class="pl-4">
            <li class="mb-3">계약 또는 청약철회 등에 관한 기록</li>
            <ul class="pl-4">
              <li class="mb-3">보존 이유 및 기간 : 전자상거래 등에서의 소비자보호에 관한 법률 (5년)</li>
            </ul>

            <li class="mb-3">대금결제 및 재화 등의 공급에 관한 기록</li>
            <ul class="pl-4">
              <li class="mb-3">보존 이유 및 기간 : 전자상거래 등에서의 소비자보호에 관한 법률 (5년)</li>
            </ul>

            <li class="mb-3">소비자의 불만 또는 분쟁처리에 관한 기록</li>
            <ul class="pl-4">
              <li class="mb-3">보존 이유 및 기간 : 전자상거래 등에서의 소비자보호에 관한 법률 (3년)</li>
            </ul>

            <li class="mb-3">본인확인에 관한 기록</li>
            <ul class="pl-4">
              <li class="mb-3">보존 이유 및 기간 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률 (6개월)</li>
            </ul>

            <li class="mb-3">방문에 관한 기록</li>
            <ul class="pl-4">
              <li class="mb-3">보존 이유 및 기간 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률 (3개월)</li>
            </ul>
          </ol>
        </li>

        <li class="terms__title">
          <div class="mb-3">개인정보의 파기절차 및 방법</div>
          <div class="terms__desc">
            회원님은 언제든지 등록되어 있는 회원님의 개인 정보를 열람하거나 정정하실 수 있으며, 회원 탈퇴를 요청하실 수 있습니다. 회원님의 개인 정보 열람 및
            수정은 사이트 내의 회원정보 변경을 통해 로그인(Log-in) 후 가능하며, 아이디, 이름을 제외한 사항은 수정 가능합니다. 회원 탈퇴를 원하실 경우 사이트
            내에서 탈퇴가 가능하며, 등록된 모든 정보를 파기함으로 탈퇴 여부를 신중히 고려하셔야 합니다. 또한 탈퇴 후 재가입에 제약이 따를 수도 있습니다.
          </div>

          <div class="terms__desc">회사의 개인정보 파기절차 및 방법은 다음과 같습니다.</div>

          <ol class="pl-4">
            <li class="mb-3">파기절차</li>
            <ul class="pl-4">
              <li class="mb-3">
                이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
                정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.
              </li>
              <li class="mb-3">동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.</li>
            </ul>

            <li class="mb-3">파기방법</li>
            <ul class="pl-4">
              <li class="mb-3">종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</li>
              <li class="mb-3">전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</li>
            </ul>
          </ol>
        </li>

        <li v-for="(item, idx) in privacyTerms1" :key="idx" class="terms__title">
          <div class="mb-3">{{ item.title }}</div>
          <div v-if="item.desc" class="terms__desc">{{ item.desc }}</div>
          <ol v-if="item.terms" class="pl-4">
            <li v-for="(term, idx2) in item.terms" :key="idx2" class="mb-3">{{ term }}</li>
          </ol>
        </li>
      </ol>
      개인정보취급방침 시행일자 : 2015년 06월 01일
    </div>
  </v-card>
</template>

<script>
import { privacyTerms1 } from '@/constants/terms';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    isAccept: {
      type: Boolean,
      default: false,
    },
    toggleAccept: {
      type: Function,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return { privacyTerms1 };
  },
};
</script>

<style lang="scss" scoped>
.terms {
  &__checkbox_label {
    :deep(.v-label) {
      @include spreadTypoMap($body-xLarge);
      opacity: unset;
      white-space: normal;
    }
  }

  &__body {
    background-color: $gray-10;
    height: 18.75rem;
    padding: 2rem 1.5rem;
    overflow: auto;
  }

  &__title {
    @include spreadTypoMap($title-xsmall);
    margin-bottom: 2rem;
  }

  &__desc {
    @include spreadTypoMap($body-large);
    margin-bottom: 0.5rem;
  }
}

li {
  @include spreadTypoMap($body-medium);
  margin: 0;
  line-height: 1.6rem;
  color: $gray-60;
}
</style>
