<template>
  <div class="kCard">
    <div class="kCard__title">{{ title }}</div>
    <div class="kCard__desc">{{ desc }}</div>
  </div>
</template>

<script>
export default {
  name: 'TitleDescCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.kCard {
  display: flex;
  flex-direction: column;

  padding: 1.5rem;
  border: 1px solid $gray-20;
  border-radius: 0.5rem;

  &__title {
    @include spreadTypoMap($title-small);

    color: $gray-60;
    margin-bottom: 0.5rem;
  }

  &__desc {
    @include spreadTypoMap($body-medium);

    color: $gray-50;
  }
}
</style>
