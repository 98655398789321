<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.9004 4.8C12.9004 4.35817 12.5422 4 12.1004 4C11.6586 4 11.3004 4.35817 11.3004 4.8L11.3004 11.5H4.60078C4.15895 11.5 3.80078 11.8582 3.80078 12.3C3.80078 12.7418 4.15895 13.1 4.60078 13.1H11.3004L11.3004 19.8C11.3004 20.2418 11.6586 20.6 12.1004 20.6C12.5422 20.6 12.9004 20.2418 12.9004 19.8V13.1H19.6008C20.0426 13.1 20.4008 12.7418 20.4008 12.3C20.4008 11.8582 20.0426 11.5 19.6008 11.5H12.9004V4.8Z"
  />
</template>

<script>
export default {};
</script>
