<template>
  <iframe
    class="map"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.9739186930988!2d126.99279771596203!3d37.48494183655313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca1a85b5f7193%3A0xf5ed1b2d1bddb358!2z64yA7ZWc66-47Jqp7IKs7ZqM7KSR7JWZ7ZqM!5e0!3m2!1sko!2skr!4v1661089449033!5m2!1sko!2skr"
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"
    allowfullscreen
  ></iframe>
  <div class="address">
    서울 서초구 방배로 123 미용회관 5층<br />
    내방역 3번 출구에서 258m
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 600px;

  @include sm-and-down {
    height: 300px;
  }
}

.address {
  @include spreadTypoMap($body-xxLarge);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;

  @include sm-and-down {
    @include spreadTypoMap($body-medium);
  }
}
</style>
