<template>
  <page title="마이페이지" doc-title="마이페이지" title-size="large" center-title>
    <template #main-section="{ isMobileUi }">
      <div class="content-wrapper">
        <section class="form-body" :style="`padding: 0 ${isMobileUi ? 20 : 0}px 48px;`">
          <v-text-field v-model.trim="form.loginId" name="loginId" label="아이디" disabled variant="underlined" />
          <v-text-field v-model.trim="form.phone" name="phone" label="전화번호" disabled variant="underlined" />
          <v-text-field v-model.trim="form.branchOfficeName" name="branchOfficeName" label="지회/지부" disabled variant="underlined" />
          <v-text-field
            v-model.trim="form.password"
            name="password"
            label="비밀번호"
            type="password"
            :rules="rules.password"
            variant="underlined"
            class="text-field-deep"
            clearable
            ><template #append
              ><v-btn type="button" class="button-xSmall-line append-button" @click="editPassword">
                <span>변경하기</span>
              </v-btn></template
            ></v-text-field
          >
          <v-btn class="button-medium-text" style="box-shadow: none" @click="confirmDialog">회원 탈퇴하기</v-btn>
        </section>
      </div>
      <basic-modal :is-open="isDialogOpen" :body="dialogBody" :close-button="dialogCloseButton" :additional-button="dialogConfirmButton" />
    </template>
  </page>
</template>

<script>
import { onMounted, ref } from 'vue';

import BasicModal from '@/components/modal/BasicModal';
import Page from '@/components/Page';
import { getProfileDetailAPI, putPasswordAPI, withdrawUser } from '@/apis/profile';
import { inputRules } from '@/lib';
import { errorToast, successToast } from '@/utils/toast';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores';

export default {
  name: 'Mypage',
  components: { Page, BasicModal },
  props: {},
  setup() {
    const router = useRouter();
    const userStore = useUserStore();

    const form = ref({ loginId: '', name: '', phone: '', branchOfficeName: '', password: '' });
    const prevName = ref('');

    /** dialog */
    const isDialogOpen = ref(false);
    const dialogBody = ref('');

    const dialogConfirmButton = ref({
      isLoading: false,
      text: '확인',
      disabled: false,
      show: false,
    });

    const openDialog = (message) => {
      isDialogOpen.value = true;
      dialogBody.value = message;
    };

    const closeDialog = () => {
      isDialogOpen.value = false;
      dialogBody.value = '';
      dialogConfirmButton.value.onClick = () => {};
      dialogConfirmButton.value.show = false;
    };

    const dialogCloseButton = ref({
      isLoading: false,
      text: '닫기',
      disabled: false,
      onClick: closeDialog,
    });

    const init = async () => {
      const res = await getProfileDetailAPI();
      form.value.loginId = res.loginId;
      form.value.name = res.name;
      prevName.value = res.name;
      form.value.phone = res.phone;
      form.value.branchOfficeName = res.branchOfficeName;
    };

    const editPassword = () => {
      if (!form.value.password) {
        openDialog('변경할 비밀번호를 입력해주세요.');
        return;
      }

      openDialog('비밀번호를 정말로 변경하시겠습니까?');

      dialogConfirmButton.value.show = true;
      dialogConfirmButton.value.onClick = async () => {
        dialogConfirmButton.value.isLoading = true;
        try {
          await putPasswordAPI(form.value.password);
          successToast('비밀번호 변경이 완료되었습니다.');
        } catch (e) {
          //
        } finally {
          dialogConfirmButton.value.isLoading = false;
          closeDialog();
        }
      };
    };

    const handleLogout = () => {
      userStore.logout();
      router.push('/');
    };

    const confirmDialog = () => {
      openDialog('정말로 탈퇴하시겠습니까?');

      dialogConfirmButton.value.show = true;
      dialogConfirmButton.value.onClick = async () => {
        try {
          await withdrawUser();
          successToast('회원 탈퇴가 완료되었습니다.');
          setTimeout(() => {
            handleLogout();
          }, 1000);
        } catch (e) {
          errorToast('회원 탈퇴에 실패하였습니다.');
        } finally {
          //
          dialogConfirmButton.value.isLoading = false;
          closeDialog();
        }
      };
    };

    onMounted(() => {
      init();
    });
    return { form, isDialogOpen, dialogBody, dialogCloseButton, dialogConfirmButton, rules, editPassword, confirmDialog };
  },
};

const rules = {
  name: [inputRules.required],
  password: [inputRules.required],
};
</script>

<style scoped lang="scss">
.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form-body {
  width: 100%;
  margin-top: 32px;
  max-width: 600px;
}

.text-field-deep:deep {
  .v-input__append {
    padding-top: 0;
    margin-inline-start: 8px;
  }
}

.append-button {
  box-shadow: none;
  width: 82px;
  height: 100%;
  align-self: flex-end;

  &:hover {
    box-shadow: none;
  }
}
</style>
