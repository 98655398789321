<template>
  <page>
    <template #aside-left>
      <!-- <div ref="leftAdvertiseRef" class="pc ad-banner__left">
        <a href="https://www.hairzzang.com/contents/html/RecentMarketing/" target="_blank">
          <v-img class="mb-2" src="@/assets/advertisement/ad-banner-left-1.png" width="130px" height="260px" />
        </a>

        <v-img src="@/assets/advertisement/ad-banner-left-2.jpg" width="130px" height="260px" />
      </div> -->
    </template>

    <template #aside-right>
      <!-- <div ref="rightAdvertiseRef" class="pc ad-banner__right">
        <a href="http://crm.ideyeevent.co.kr/landing/ideye/ideyecenter/" target="_blank">
          <v-img class="mb-2" src="@/assets/advertisement/ad-banner-right-1.gif" width="130px" height="260px" />
        </a>
        <a href="https://www.youtube.com/channel/UCGLU6Zrt3kKQqxAjgQMO9ow" target="_blank">
          <v-img src="@/assets/advertisement/ad-banner-right-2.jpg" width="130px" height="260px" />
        </a>
      </div> -->
    </template>

    <template #main-section>
      <main-feature :main-articles="mainData.mainArticles" />
      <major-news-vue :important-articles="mainData.importantArticles" />
      <beauty-article />
      <categories />
      <main-contest-section :main-contest-section-view="mainData.mainContestSectionView" />
      <popular-article-vue :init-news="mainData.popularArticles" />
      <education-preview :lecture-view="mainData.lectureView" />
      <latest-article-vue :init-articles="mainData.latestArticles" />
      <main-calendar />

      <!-- <v-row class="pc m-0" justify="center" tag="section">
        <div class="d-flex ad-banner">
          <div class="ad-banner__card mr-4">
            <v-img src="@/assets/advertisement/ad-banner-top-1.jpg" height="180px" />
          </div>
          <div class="ad-banner__card">
            <a href="/community/21" target="_blank">
              <v-img src="@/assets/advertisement/ad-banner-top-2.jpg" height="180px" />
            </a>
          </div>
        </div>
      </v-row>

      <v-row class="mobile" justify="center" tag="section">
        <div class="ad-banner mt-4">
          <div class="ad-banner__card">
            <a href="http://crm.ideyeevent.co.kr/landing/ideye/ideyecenter/" target="_blank">
              <v-img class="m-auto" src="@/assets/advertisement/ad-mobile-1.gif" width="90%" />
            </a>
          </div>
        </div>
      </v-row> -->

      <!--      <search-input-vue />-->
      <!--
      <v-row class="article__wrapper" offset-lg="1">
        <div class="mobile">
          <div class="ad-banner d-flex flex-column">
            <div class="ad-banner__card mb-2">
              <v-img class="m-auto" src="@/assets/advertisement/ad-mobile-2.jpg" width="90%" />
            </div>
            <div class="ad-banner__card">
              <a href="https://www.hairzzang.com/contents/html/RecentMarketing/" target="_blank">
                <v-img class="m-auto" src="@/assets/advertisement/ad-mobile-3.jpg" width="90%" />
              </a>
            </div>
          </div>
        </div>
      </v-row> -->

      <!--      <div class="carousel-root">-->
      <!--        <banner-carousels-vue />-->
      <!--      </div>-->

      <!-- <v-row class="pc" justify="center" tag="section">
        <div class="d-flex ad-banner ad-banner__bottom">
          <div class="ad-banner__card mr-4">
            <a href="http://sinzo.co.kr/" target="_blank">
              <v-img src="@/assets/advertisement/ad-banner-bottom-1.png" height="180px" />
            </a>
          </div>
          <div class="ad-banner__card">
            <a href="http://www.bii.seoul.kr/" target="_blank">
              <v-img src="@/assets/advertisement/ad-banner-bottom-2.png" height="180px" />
            </a>
          </div>
        </div>
      </v-row> -->

      <photo-news :photo-articles="mainData.photoArticles" />
    </template>

    <!--      리플렛 모달-->
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <header class="modal-header">
          <h2 class="modal-title text__title-small">경기 안내</h2>
          <button type="button" @click="dialog = false"><v-icon :icon="mdiClose" /></button>
        </header>
        <section style="height: 100%; display: flex; justify-content: center; align-items: center">
          <v-carousel show-arrows="hover" hide-delimiters>
            <template v-for="({ src }, idx) in leafletImages" :key="idx">
              <v-carousel-item :src="src" />
            </template>
          </v-carousel>
        </section>
      </v-card>
    </v-dialog>
  </page>
</template>

<script>
import Page from '@/components/Page';
// import SearchInputVue from '@/components/SearchInput.vue';
// import BannerCarouselsVue from '@/components/BannerCarousels.vue';
import { ref, onMounted, onBeforeUnmount } from 'vue';

import MainFeature from '@/containers/Main/MainFeature';
import MajorNewsVue from '@/containers/Main/MajorNews.vue';
import Categories from '@/containers/Main/Categories';
import PopularArticleVue from '@/containers/Main/PopularArticle.vue';
import LatestArticleVue from '@/containers/Main/LatestArticle.vue';
import EducationPreview from '@/containers/Main/EducationPreview.vue';
import MainCalendar from '@/containers/Main/MainCalendar';
import MainContestSection from '@/containers/Main/MainContestSection.vue';
import PhotoNews from '@/containers/Main/PhotoNews';

import { mdiClose } from '@mdi/js';
import { throttle } from 'lodash-es';
import { getMainHomeDataApi } from '@/apis/__main__';
import BeautyArticle from '@/containers/Main/BeautyArticle';
// import { isMobileTablet } from '@/utils/device';

// import { infoToast } from '@/utils/toast';

export default {
  name: 'HomeView',
  components: {
    PhotoNews,
    Page,
    // SearchInputVue,
    // BannerCarouselsVue,
    Categories,
    MajorNewsVue,
    PopularArticleVue,
    LatestArticleVue,
    EducationPreview,
    MainCalendar,
    MainFeature,
    MainContestSection,
    BeautyArticle,
  },
  async setup() {
    const dialog = ref(false);
    const model = ref(null);

    const leftAdvertiseRef = ref(null);
    const rightAdvertiseRef = ref(null);

    const mainData = await getMainHomeDataApi();

    const footerHeight = 400;
    const headerHeight = 120;
    const adBannerHeight = 530;

    const scrollAdvertisement = throttle(() => {
      const htmlClassNames = document.documentElement.classList.value.split(' ') ?? [];
      if (!htmlClassNames.includes('v-overlay-scroll-blocked')) {
        const minValue = Math.max(window.top.scrollY - 30, 64);
        const scrollMaxOffset = document.body.offsetHeight - footerHeight - headerHeight - adBannerHeight;
        const value = Math.min(minValue, scrollMaxOffset);

        leftAdvertiseRef.value.style.top = `${value}px`;
        rightAdvertiseRef.value.style.top = `${value}px`;
      }
    }, 500);

    onMounted(() => {
      document.title = '사)대한미용사회중앙회';

      window.addEventListener('scroll', scrollAdvertisement);

      // if (isMobileTablet(window.navigator)) {
      //   mockCategoryCards.value = [
      //     { title: '뉴스', desc: '협회 정보를 확인하실 수 있습니다.', to: '/news/1' },
      //     { title: '미용회보', desc: '미용회보를 확인하실 수 있습니다.', to: '/regular-report' },
      //     { title: '칼럼&문화', desc: '칼럼&문화를 확인하실 수 있습니다.', to: '/culture/11' },
      //     { title: '금융몰', desc: '하나은행과 함께하는 사)대한미용사회중앙회.', to: '/product-mall' },
      //   ];
      // } else {
      //   mockCategoryCards.value = [
      //     { title: '뉴스', desc: '협회 정보를 확인하실 수 있습니다.', to: '/news/1' },
      //     { title: '미용회보', desc: '미용회보를 확인하실 수 있습니다.', to: '/regular-report' },
      //     { title: '강의서비스', desc: '대한 미용사회에서 제공하는 강의 서비스입니다.', to: '/education/home' },
      //     { title: '금융몰', desc: '하나은행과 함께하는 사)대한미용사회중앙회.', to: '/product-mall' },
      //   ];
      // }
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', scrollAdvertisement);
    });

    // const onClickPreparePage = () => {
    //   infoToast('오픈 예정인 페이지입니다.');
    // };
    const leafletImages = [{ src: require('@/assets/leaflet-1.jpg') }, { src: require('@/assets/leaflet-2.jpg') }];

    return {
      dialog,
      model,
      mdiClose,
      leafletImages,
      leftAdvertiseRef,
      rightAdvertiseRef,
      mainData,
    };
  },
};
</script>

<style scoped lang="scss">
.pc {
  @include sm-and-down {
    display: none;
  }
}

.mobile {
  @include md-and-up {
    display: none;
  }
}

.ad-banner {
  width: 100%;

  &__bottom {
    margin-bottom: 5rem;
  }

  &__left {
    transition: top 0.5s ease-in-out;
    position: absolute;
    top: 64px;

    @include sm-and-down {
      display: none;
    }

    @include md-and-up {
      left: 8px;
    }

    @include lg-and-up {
      left: calc(4%);
    }
  }

  &__right {
    transition: top 0.5s ease-in-out;
    position: absolute;
    top: 64px;

    @include sm-and-down {
      display: none;
    }

    @include md-and-up {
      right: 8px;
    }

    @include lg-and-up {
      right: calc(4%);
    }
  }

  &__card {
    width: 100%;
  }
}

.article {
  &__wrapper {
    margin-top: 24px;
    margin-bottom: 116px;
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(1, 100%);

    @include lg-and-up {
      margin-top: 96px;
      display: grid;
      grid-template-columns: repeat(2, minmax(400px, calc(50% - 8px)));
      grid-gap: 16px;
    }
  }

  &__item {
    padding: 24px 30px;

    @include lg-and-up {
      padding: 0;
    }
  }
}

.carousel-root {
  padding-top: 24px;
  padding-bottom: 24px;

  @include md-and-up {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}
</style>
