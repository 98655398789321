<template>
  <div class="p-4 svg-wrapper" @click="onClick">
    <organization-svg />
  </div>
  <branch-office-dialog :is-dialog-open="showsBranchOfficeDialog" :close-dialog="closeBranchOfficeDialog" :is-mobile-ui="isMobileUi" />
  <committee-dialog :committee-type="committeeType" :close-dialog="closeCommitteeDialog" :is-dialog-open="showsCommitteeDialog" :is-mobile-ui="isMobileUi" />
  <basic-modal :is-open="showsEtcDialog" :body="dialogBody" :close-button="closeEtcModalButton" :additional-button="additionalEtcModalButton" />
</template>

<script>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import BasicModal from '@/components/modal/BasicModal';

import OrganizationSvg from '@/containers/About/OrganizationSvg';
import BranchOfficeDialog from '@/containers/About/BranchOfficeDialog';
import CommitteeDialog from '@/containers/About/CommitteeDialog';

import { useMenuStore } from '@/stores';
import { COMMITTEE_TYPES } from '@/constants/committee';
import { ORGANIZATION_COMMUNITY_TABLE_IDS, ORGANIZATION_LABELS } from '@/constants/organization';

export default {
  components: { OrganizationSvg, BranchOfficeDialog, CommitteeDialog, BasicModal },
  props: {
    isMobileUi: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const menuStore = useMenuStore();

    const committeePath = computed(() => menuStore.getMenu.find((v) => v.id === '5')?.path || '/committee/0');

    const showsBranchOfficeDialog = ref(false);
    const showsCommitteeDialog = ref(false);
    const committeeType = ref('');

    const showsEtcDialog = ref(false);
    const selectedEtcDialog = ref('');
    const dialogBody = computed(() => `${ORGANIZATION_LABELS[selectedEtcDialog.value] || ''} 게시판으로 이동하시겠습니까?`);

    const closeBranchOfficeDialog = () => {
      showsBranchOfficeDialog.value = false;
    };

    const closeCommitteeDialog = () => {
      showsCommitteeDialog.value = false;
      committeeType.value = '';
    };

    const openEtcDialog = (dialogName) => {
      showsEtcDialog.value = true;
      selectedEtcDialog.value = dialogName;
    };

    const closeEtcDialog = () => {
      showsEtcDialog.value = false;
      selectedEtcDialog.value = '';
    };

    const closeEtcModalButton = {
      isLoading: false,
      text: '닫기',
      disabled: false,
      onClick: closeEtcDialog,
    };

    const additionalEtcModalButton = {
      show: true,
      isLoading: false,
      text: '이동하기',
      disabled: false,
      onClick: () => {
        if (selectedEtcDialog.value) {
          window.open(`/community/19?branchOfficeId=${ORGANIZATION_COMMUNITY_TABLE_IDS[selectedEtcDialog.value]}`);
          closeEtcDialog();
        } else {
          closeEtcDialog();
        }
      },
    };

    const onClick = (e) => {
      const {
        dataset: { id },
      } = e.target;

      console.log(id);

      if (id === undefined) {
        return;
      }

      if (id === 'branch-office') {
        showsBranchOfficeDialog.value = true;
        return;
      }

      if (id === 'committee') {
        router.push(committeePath.value);
        return;
      }

      if (Object.values(COMMITTEE_TYPES).includes(id)) {
        committeeType.value = id;
        showsCommitteeDialog.value = true;
        return;
      }

      openEtcDialog(id);
    };

    return {
      showsBranchOfficeDialog,
      showsCommitteeDialog,
      showsEtcDialog,
      committeeType,
      selectedEtcDialog,
      dialogBody,
      onClick,
      closeBranchOfficeDialog,
      closeCommitteeDialog,
      closeEtcModalButton,
      additionalEtcModalButton,
    };
  },
};

// const getUrl = (selectedDialog) => {
//   console.log(selectedDialog);
//   return '';
// };
</script>

<style lang="scss" scoped>
.svg-wrapper:deep {
  svg {
    width: 100%;
    height: auto;

    [data-element='button'] {
      cursor: pointer;
    }
  }
}
</style>
