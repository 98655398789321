const MAIN_NAV_MAP = [
  { id: 'about', name: '미용사회소개', href: '/about' },
  { id: 'committee', name: '산하위원회', href: '/committee' },
  { id: 'community', name: '자료&커뮤니티', href: '/community/11' },
  { id: 'news', name: '뉴스', href: '/news/1' },
  { id: 'beauty', name: '뷰티', href: '/beauty/1' },
  { id: 'culture', name: '칼럼&문화', href: '/culture/1' },
  { id: 'event', name: '독자투고&선물', href: '/event' },
];

const MAIN_SUB_NAV_MAP = {
  about: [
    { id: 'test1', name: '회장인사', href: '/about' },
    { id: 'test2', name: '협회소개', href: '/about' },
    { id: 'test3', name: '미용회보', href: '/regular-report' },
  ],
  committee: [
    { id: 'test1', name: '홍보 위원회', href: '/committee' },
    { id: 'test2', name: '미용기술위원회', href: '/committee' },
    { id: 'test2', name: '헤어스케치위원회', href: '/committee' },
    { id: 'test2', name: '국제미용위원회', href: '/committee' },
    { id: 'test2', name: '고전머리특별위원회', href: '/committee' },
    { id: 'test2', name: '고충처리위원회', href: '/committee' },
    { id: 'test2', name: '두피모발위원회', href: '/committee' },
    { id: 'test2', name: '반영구메이크업위원회', href: '/committee' },
    { id: 'test2', name: '헤어아트위원회', href: '/committee' },
    { id: 'test2', name: '증모가발특별위원회', href: '/committee' },
  ],
  community: [
    { id: 11, name: '규정', href: '/community/11' },
    { id: 12, name: '협회양식', href: '/community/12' },
    { id: 13, name: '공지사항', href: '/community/13' },
    { id: 14, name: 'Q&A', href: '/community/14' },
    { id: 16, name: '세미나 일정', href: '/community/16' },
    { id: 17, name: '구인 매매', href: '/community/17' },
    { id: 18, name: '관련단체', href: '/community/18' },
    { id: 19, name: '지회/지부 전용게시판', href: '/community/19' },
  ],
  news: [
    { id: 'test1', name: '중앙회', href: '/news/1' },
    { id: 'test2', name: '위원회', href: '/news/2' },
    { id: 'test2', name: '지회,지부', href: '/news/3' },
    { id: 'test2', name: '업체', href: '/news/4' },
    { id: 'test2', name: '이슈', href: '/news/5' },
    { id: 'test2', name: '이벤트', href: '/news/6' },
  ],
  beauty: [
    { id: 'test1', name: '트렌드', href: '/beauty/1' },
    { id: 'test2', name: '드라마 스타일', href: '/beauty/2' },
    { id: 'test2', name: '신제품', href: '/beauty/3' },
    { id: 'test2', name: '테크닉', href: '/beauty/4' },
  ],
  culture: [
    { id: 'test1', name: '회장칼럼', href: '/culture/1' },
    { id: 'test2', name: '기자칼럼', href: '/culture/2' },
    { id: 'test2', name: '문화', href: '/culture/3' },
    { id: 'test2', name: '기자칼럼', href: '/culture/4' },
  ],
  event: [
    { id: 'test1', name: '독자선물', href: '/event' },
    { id: 'test2', name: '당첨자 발표', href: '/event' },
    { id: 'test2', name: '독자투고', href: '/event' },
  ],
};

// TODO: href 수정 필요
const FOOTER_NAV_MAP = [
  { id: '기사제보', name: '기사제보', href: '/' },
  { id: '광고문의', name: '광고문의', href: '/' },
  { id: '개인정보취급방침', name: '개인정보취급방침', href: '/' },
  { id: '청소년보호정책', name: '청소년보호정책', href: '/' },
  { id: '이메일무단수집거부', name: '이메일무단수집거부', href: '/' },
  { id: 'RSS', name: 'RSS', href: '/' },
];

export { MAIN_NAV_MAP, MAIN_SUB_NAV_MAP, FOOTER_NAV_MAP };
