import { defineStore } from 'pinia';

export default defineStore({
  id: 'scrollPosition',
  state: () => ({
    position: {
      top: 0,
      left: 0,
    },
  }),
  getters: {
    getScrollPosition: (state) => state.position,
  },
  actions: {
    updateScrollPosition({ top, left }) {
      this.position.top = top;
      this.position.left = left;
    },
  },
});
