import { cloneDeep, isEmpty } from 'lodash-es';
import { CONTEST_REGISTER_TYPE, PLAYER_INPUT_KR, RECOMMENDER_INPUT_KR, FILE_CATEGORY, FILE_CATEGORY_ID_MAPPING } from '@/constants/contest';
import { getKrDate } from '@/utils/date';

const recommenderValidator = (recommender) => {
  const validInfo = {
    infoType: 'recommender',
    name: '',
    message: '',
    isValid: false,
  };

  /** 추천인 정보 */
  const isErrorForm = Object.entries(recommender).some(([key, val]) => {
    const inputNameKr = RECOMMENDER_INPUT_KR[key];

    if (!inputNameKr || val) return false;
    validInfo.name = key;
    validInfo.message = `추천인 정보 > 필수 입력값 "${inputNameKr}" 을/를 입력해주세요.`;
    return true;
  });

  validInfo.isValid = !isErrorForm;
  return validInfo;
};

const playerValidator = (playerInfo, isLogin, isStudentForm, isIndependentBusinessForm) => {
  const validInfo = {
    infoType: 'playerInfo',
    name: '',
    message: '',
    isValid: false,
  };
  const isErrorForm = Object.entries(playerInfo).some(([key, val]) => {
    const inputNameKr = PLAYER_INPUT_KR[key];
    validInfo.name = key;

    if (!inputNameKr && key !== 'attachmentList') return false;

    if (key === 'birthday') {
      if (!val.year) {
        validInfo.message = '선수 정보 > "연도"를 입력해주세요.';
        return true;
      }
      if (!val.month) {
        validInfo.message = '선수 정보 > "월"을 입력해주세요.';
        return true;
      }
      if (!val.day) {
        validInfo.message = '선수 정보 > "날짜"를 입력해주세요.';
        return true;
      }
    }
    if (key === 'contestItemIds' && isEmpty(val)) {
      validInfo.message = `선수 정보 > 최소 하나의 "종목" 을 선택해주세요.`;
      return true;
    }

    /** 비회원 필수 값 입력 */
    validInfo.message = `선수 정보 > 필수 입력값 "${inputNameKr}" 을/를 입력해주세요.`;

    if (key === 'password' && !isLogin) {
      if (!val) return true;
      return false;
    } else if (key === 'attachmentList') {
      const requiredCategories = [FILE_CATEGORY.ID_PHOTO];

      if (isStudentForm) {
        // requiredCategories.push(FILE_CATEGORY.ENROLLMENT_VERIFICATION_LETTER);
      }

      if (!isStudentForm && !isIndependentBusinessForm) {
        requiredCategories.push(FILE_CATEGORY.CERTIFICATE);
      }

      if (isIndependentBusinessForm) {
        requiredCategories.push(FILE_CATEGORY.BUSINESS_REGISTRATION);
        requiredCategories.push(FILE_CATEGORY.SMALL_BUSINESS_CONFIRMATION);
      }

      const requiredKey = requiredCategories.filter((category) => !playerInfo.playerFiles.some((v) => v.category === category))?.[0];
      if (requiredKey) {
        validInfo.name = FILE_CATEGORY_ID_MAPPING[requiredKey];
        validInfo.message = `선수정보 > 필수 첨부파일 "${PLAYER_INPUT_KR[requiredKey]}" 을/를 첨부해주세요.`;
        return true;
      }

      return false;
    } else if (!val) {
      return true;
    }

    return false;
  });

  validInfo.isValid = !isErrorForm;
  return validInfo;
};

export const validateFormData = (playerInfo, recommender, isLogin, contestRegisterType) => {
  const isStudentForm = contestRegisterType === CONTEST_REGISTER_TYPE.STUDENT;
  const isIndependentBusinessForm = contestRegisterType === CONTEST_REGISTER_TYPE.INDEPENDENT_BUSINESS;

  const checker = (info) => {
    const { infoType, isValid, name, message } = info;
    return { infoType, isValid, name, message };
  };

  /** 참가자 Validation */
  const playerValidInfo = playerValidator(playerInfo, isLogin, isStudentForm, isIndependentBusinessForm);

  if (!playerValidInfo.isValid) {
    return checker(playerValidInfo);
  }

  /** 추천인 Validation */
  const recommenderInfo = recommenderValidator(recommender);

  if (!recommenderInfo.isValid) {
    return checker(recommenderInfo);
  }

  return { isValid: true };
};

export const convertDataForApi = (playerInfo, recommender) => {
  const clonedPlayerInfo = cloneDeep(playerInfo);
  const clonedRecommender = cloneDeep(recommender);
  // const needsUploadFileList = [];

  /** 불필요한 데이터 제거 */
  delete clonedPlayerInfo.regionName;
  delete clonedRecommender.regionName;

  // /** 미리보기Object가 아닌 경우 업로드 */
  // if (typeof clonedPlayerInfo.idPhoto === 'string') needsUploadFileList.push('idPhoto');
  // if (typeof clonedPlayerInfo.enrollmentVerificationLetter === 'string') needsUploadFileList.push('enrollmentVerificationLetter');
  // if (typeof clonedPlayerInfo.certificate === 'string') needsUploadFileList.push('certificate');
  // if (typeof clonedPlayerInfo.businessRegistration === 'string') needsUploadFileList.push('businessRegistration');
  // if (typeof clonedPlayerInfo.smallBusinessConfirmation === 'string') needsUploadFileList.push('smallBusinessConfirmation');

  /** 파일명 or 미리보기 Object 삭제 */
  if ('idPhoto' in clonedPlayerInfo) delete clonedPlayerInfo.idPhoto;
  if ('enrollmentVerificationLetter' in clonedPlayerInfo) delete clonedPlayerInfo.enrollmentVerificationLetter;
  if ('certificate' in clonedPlayerInfo) delete clonedPlayerInfo.certificate;
  if ('businessRegistration' in clonedPlayerInfo) delete clonedPlayerInfo.businessRegistration;
  if ('smallBusinessConfirmation' in clonedPlayerInfo) delete clonedPlayerInfo.smallBusinessConfirmation;

  /** birthday 하나로 합치기 */
  const birthday = `${clonedPlayerInfo.birthday.year}-${clonedPlayerInfo.birthday.month}-${clonedPlayerInfo.birthday.day}`;
  clonedPlayerInfo.birthday = getKrDate(birthday).format('YYYY-MM-DD');
  clonedPlayerInfo.birthDay = getKrDate(birthday).format('YYYY-MM-DD');

  // /** 업로드가 필요한 파일 리스트 필터링 */
  // clonedPlayerInfo.playerFiles = clonedPlayerInfo.playerFiles.filter(({ category }) => needsUploadFileList.includes(category));

  return {
    convertedPlayerInfo: clonedPlayerInfo,
    convertedRecommender: clonedRecommender,
  };
};
