import Qs from 'qs';

/**
 *
 * @param {object} params
 * @param {object} options
 * @return {string}
 */
const paramsSerializer = (params, options = { arrayFormat: 'comma' }) => Qs.stringify(params, options);
export default paramsSerializer;
