<template>
  <v-container class="term-accept" :class="className">
    <v-row>
      <v-col>
        <privacy-terms-static :is-accept="acceptObj.privacy" title=" "></privacy-terms-static>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';
import { ref } from 'vue';

import { serviceTerms } from '@/constants/terms';
import PrivacyTermsStatic from '@/containers/PrivacyStatic/PrivacyTermsStatic';

export default {
  components: { PrivacyTermsStatic },
  props: {
    className: {
      type: String,
      default: '',
    },
    goBackStep: {
      type: Function,
      default: () => {},
    },
    goNextStep: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const acceptObj = ref({
      service: false,
      privacy: false,
      thirdParty: false,
      marketing: false,
    });

    const toggleAllDesc = () => {
      const accepted = acceptObj.value.service && acceptObj.value.privacy;

      acceptObj.value.service = !accepted;
      acceptObj.value.privacy = !accepted;
      acceptObj.value.thirdParty = !accepted;
      acceptObj.value.marketing = !accepted;
    };

    const toggle = (v, target) => {
      acceptObj.value[target] = v;
    };

    return { acceptObj, toggle, toggleAllDesc, mdiCheckboxMarkedCircleOutline, serviceTerms };
  },
};
</script>

<style lang="scss" scoped>
.term-accept {
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }

  &__all {
    :deep(.v-label) {
      @include spreadTypoMap($body-xxLarge);
      opacity: unset;
    }

    :deep(.v-icon) {
      width: 2rem;
      height: 2rem;
    }
  }

  &__normal {
    :deep(.v-label) {
      @include spreadTypoMap($body-xLarge);
      opacity: unset;
      white-space: normal;
    }
  }

  &__accept_btn {
    background: $blue-50;
    color: white;
  }

  &__not_accept_btn {
    color: $blue-50;
  }
}
</style>
