<template>
  <v-footer id="koba-footer" class="footer" padless app>
    <v-col sm="12" md="8" lg="8" class="p-0">
      <!--    푸터 링크-->
      <v-row class="w-100 footer-nav">
        <v-btn v-for="navItem in navItems" :key="navItem.id" :to="navItem.href" class="footer-nav-link">
          {{ navItem.text }}
        </v-btn>
      </v-row>

      <!--    미용회보 정보-->
      <v-row class="ma-0">
        <v-col class="p-0" cols="12" md="5" lg="5">
          <div class="name">사)대한미용사회중앙회</div>
          <p class="sub-name">미용회보</p>
        </v-col>
        <v-col cols="12" md="7" lg="7" class="p-0 info-detail">
          <address class="address">
            <div>서울시 서초구 방배로 123 미용회관 5층</div>
            <div>대표전화 : 02-585-3351~3</div>
            <div>팩스 : 02-588-5012, 525-1637</div>
            <div>명칭 : 사)대한미용사회중앙회 제호 : BeautyM (미용회보) 사)대한미용사회중앙회</div>
          </address>
          <div>모든 콘텐츠(영상,기사, 사진)는 저작권법의 보호를 받은바, 무단 전재와 복사, 배포 등을 금합니다.</div>
          <div>Copyright © 2022 사)대한미용사회중앙회. All rights reserved</div>
        </v-col>
      </v-row>
    </v-col>
  </v-footer>
</template>

<script>
import { FOOTER_NAV_MAP } from '@/constants/navigation';

export default {
  name: 'FNB',
  setup() {
    return {
      navItems: FOOTER_NAV_MAP,
    };
  },
};
</script>

<style scoped lang="scss">
// 스타일 로컬 변수
$footer-bg: #272f3e;
$footer-color: #ffffff;

// 푸터 스타일
.footer {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px 16px 124px;
  background: $footer-bg;
  z-index: $footer-z-index;

  @include md-and-up {
    padding: 16px 0 72px;
  }
}

// 푸터 링크
.footer-nav {
  margin: 0 0 16px 0;

  @include md-and-up {
    margin: 0 0 48px 0;
  }
}

.footer-nav-link {
  @include spreadTypoMap($caption-medium);

  padding: 16px 8px;

  &:first-of-type {
    margin-left: -8px;
  }

  @include md-and-up {
    @include spreadTypoMap($caption-large);
    color: $footer-color;

    padding: 24px 20px;

    &:first-of-type {
      margin-left: -20px;
    }
  }

  // v-btn css reset
  background: none;
  box-shadow: none;
  min-width: auto;
  height: 100% !important;

  color: $footer-color;

  &:hover,
  &:focus {
    box-shadow: none;
  }
}

// 미용회보 정보
.name {
  @include spreadTypoMap($title-xsmall);

  @include md-and-up {
    @include spreadTypoMap($title-medium);
    color: $footer-color;
  }

  color: $footer-color;
  margin-bottom: 16px;
}

.sub-name {
  @include spreadTypoMap($body-xsmall);

  @include md-and-up {
    @include spreadTypoMap($body-medium);
    color: $footer-color;
  }

  color: $footer-color;
  margin-bottom: 16px;
}

.info-detail {
  @include spreadTypoMap($body-xsmall);

  @include md-and-up {
    @include spreadTypoMap($body-medium);
    color: $footer-color;
  }

  color: $footer-color;
}

.address {
  flex: 1 1;
  flex-wrap: wrap;
  margin: 0;
}
</style>
