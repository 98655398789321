<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.93353 20.5657C8.24595 20.8781 8.75248 20.8781 9.0649 20.5657L16.3578 13.2728C17.0607 12.5698 17.0607 11.4302 16.3578 10.7272L9.0649 3.43432C8.75248 3.1219 8.24595 3.1219 7.93353 3.43432C7.62111 3.74674 7.62111 4.25327 7.93353 4.56569L15.2264 11.8586C15.3045 11.9367 15.3045 12.0633 15.2264 12.1414L7.93353 19.4343C7.62111 19.7467 7.62111 20.2533 7.93353 20.5657Z"
  />
</template>

<script>
export default {};
</script>
