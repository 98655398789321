<template>
  <div ref="wrap" class="wrap"></div>
</template>

<script>
import { ref, onMounted } from 'vue';

const daumAddressScript = document.createElement('script');
daumAddressScript.setAttribute('src', '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js');
document.head.appendChild(daumAddressScript);

export default {
  props: {
    onComplete: {
      type: Function,
      default: () => {},
    },
  },
  setup({ onComplete }) {
    const wrap = ref(null);

    onMounted(() => {
      window.daum.postcode.load(() => {
        new window.daum.Postcode({
          oncomplete: function (data) {
            /**
             * 사용자가 도로명 주소를 선택했을 경우(R)
             * 사용자가 지번 주소를 선택했을 경우(J)
             * onComplete(address, zipcode)
             * */
            onComplete(data.userSelectedType === 'R' ? data.roadAddress : data.jibunAddress, data.zonecode);
          },
          width: '100%',
          height: '100%',
        }).embed(wrap.value);
      });
    });

    return {
      wrap,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: calc(100vw - 24px * 2);
  max-width: 500px;
  height: 500px;
}
</style>
