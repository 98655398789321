import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import { paramsSerializer } from '@/utils';
import { UNEXPECTED_ERROR } from '@/constants/commons/errors';
import { errorToast } from '@/utils/toast';

class CustomError {
  constructor(e, message) {
    this.message = message;
    this.error = e?.error;
    this.path = e?.path;
    this.status = e?.status ?? 200;
    this.code = e?.code;
  }
}
// TODO (HYHP) 220827 : need to set baseURL
const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api-app' : '/api-app',
  headers: { 'Cache-Control': 'no-cache' },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' }),
  paramsSerializer,
});

export const removeAuthHeader = () => {
  delete axios.defaults.headers.common.Authorization;
  axios.interceptors.request.use(async (request) => {
    return request;
  });
};

export const setAuthHeader = (str) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${str}`;
  axios.interceptors.request.use(async (request) => {
    if (str) {
      request.headers.Authorization = `Bearer ${str}`;
    }
    return request;
  });
};

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    errorToast(err.message);
    return Promise.reject(err);
  },
);

axiosInstance.interceptors.response.use(
  ({ data: response }) => {
    if (response.code < 0) {
      if (response.code === -9999) {
        errorToast(response.data.message);
        throw new CustomError(response, response.data.message);
      } else {
        errorToast(response.message);
        throw new CustomError(response, response.message);
      }
    }

    return response?.data;
  },
  ({ response }) => {
    console.error(response);
    errorToast(UNEXPECTED_ERROR);
    return Promise.reject(new CustomError(response.data, UNEXPECTED_ERROR));
  },
);
export default axiosInstance;
