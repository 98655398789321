<template>
  <div class="form__input-root">
    <label class="text__caption-small form__input-label" :for="attrs.id" :class="{ 'form__input-label-required': required }">{{ label }}</label>
    <div
      class="form__input-wrapper form__input-deep-wrapper"
      :class="{
        'form__input-wrapper-focus': isFocus,
        'form__input-wrapper-error': errorText,
        'form__has-btn': hasButton,
        [`btn-${buttonOrder === 0 ? 'start' : 'end'}`]: hasButton,
      }"
    >
      <slot name="form-input" />
      <slot name="form-button" />
    </div>
    <div v-if="errorText || helperText" class="text__caption-small form__input-description" :class="{ 'form__input-description-error': errorText }">
      {{ errorText || helperText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputWrapper',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    helperText: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
    isFocus: {
      type: Boolean,
      default: false,
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
    buttonOrder: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { attrs }) {
    return { attrs };
  },
};
</script>

<style lang="scss" scoped>
.form__input-label {
  color: $gray-60;

  &-required::after {
    content: '*';
    color: $koba-red;
  }
}

.form__input-wrapper {
  padding: 12px 0 10px;
  border-bottom: 1px solid $gray-30;
  transition: border-bottom-color 0.3s ease-in-out;

  &-error {
    border-bottom-color: $koba-red;
  }

  &-focus {
    border-bottom-color: $gray-50;
  }
}

.form__input-deep-wrapper:deep {
  input:focus {
    outline: none;
  }

  input::placeholder {
    @include spreadTypoMap($body-medium);
    color: $gray-30;
  }
}

.form__input-description {
  margin-top: 8px;

  &-error {
    color: $koba-red;
  }
}

.form__has-btn {
  display: flex;
  align-items: center;
}

.btn-start:deep {
  input {
    order: 1;
  }
  [data-tag='input'] {
    order: 1;
  }

  button {
    order: 0;
  }

  [data-tag='button'] {
    order: 0;
  }
}

.btn-end:deep {
  input {
    order: 0;
  }
  [data-tag='input'] {
    order: 0;
  }

  button {
    order: 1;
  }

  [data-tag='button'] {
    order: 1;
  }
}
</style>
