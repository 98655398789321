<template>
  <div class="history">
    <v-timeline align="start" side="end" line-thickness="1" line-color="#BCE4FF">
      <v-timeline-item v-for="(h, i) in companyHistory" :key="i" dot-color="#0070bc" fill-dot size="12px">
        <template #opposite>
          <div class="history__year">{{ h.year }}</div>
        </template>
        <div v-for="e in h.history" :key="e.date" class="history__wrapper">
          <div class="history__date">{{ e.date }}</div>
          <div class="history__text">
            {{ e.text }}
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import companyHistory from '@/constants/companyHistory';

export default {
  setup() {
    return { companyHistory };
  },
};
</script>

<style lang="scss" scoped>
.history {
  padding: 2rem 1.25rem;
  word-break: keep-all;

  &__wrapper {
    margin-bottom: 1rem;
  }
  &__year {
    @include spreadTypoMap($title-medium);

    @include sm-and-down {
      @include spreadTypoMap($title-small);
    }
  }

  &__date {
    @include spreadTypoMap($title-xsmall);
    margin-bottom: 0.5rem;

    @include sm-and-down {
      @include spreadTypoMap($title-xxsmall);
    }
  }

  &__text {
    @include spreadTypoMap($body-small);

    @include sm-and-down {
      @include spreadTypoMap($body-xsmall);
    }
  }
}
</style>
