<template>
  <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 24px">
    <div style="display: flex">
      <!--      <select-box :options="selectedOptions" />-->
      <!--      <div class="input-wrapper">-->
      <!--        <input type="search" placeholder="텍스트를 입력하세요" class="text-input" />-->
      <!--        <v-btn style="margin-left: 4px" size="small" flat>-->
      <!--          <icon-base icon-name="search" icon-color="none" icon-stroke="#0070bc" width="16" height="16"><search /></icon-base>-->
      <!--        </v-btn>-->
      <!--      </div>-->
    </div>
    <div style="display: flex">
      <!--      <v-btn class="view-more-btn" variant="outlined" :size="btnSize" flat>-->
      <!--        전체 글 보기<icon-base style="margin-left: 4px"><chevron-down-arrow /></icon-base>-->
      <!--      </v-btn>-->
      <v-btn v-if="!hideWriteButton && !!userStore.getUserView?.id" class="add-btn" :size="btnSize" to="/used-market/write" flat>
        <icon-base icon-name="plus" icon-color="white" style="margin-right: 4px"><plus /></icon-base>
        <span class="add-btn__text">글 작성하기</span>
      </v-btn>
    </div>
  </div>
  <table style="margin-top: 24px; width: 100%">
    <tr class="table-header-row">
      <th v-for="tableHeader in tableHeaders" :key="tableHeader.id" class="table-header" :data-th-id="tableHeader.id">{{ tableHeader.label }}</th>
    </tr>
    <tr v-if="posts.length === 0">
      <td colspan="6">
        <empty-data-card />
      </td>
    </tr>
    <tr v-for="post in posts" :key="post.postId" class="table-row" :data-post-id="post.postId" @click="goDetailPost">
      <td>{{ post.postId }}</td>
      <td class="post-title">
        <v-icon v-if="post.isSecure" :icon="mdiLock" size="x-small" start></v-icon>
        {{ post.title }} <span v-if="post.replySize != 0" class="ml-1" style="color: red">[{{ post.replySize }}]</span>
      </td>
      <td>
        <v-chip class="table-row__chip" :color="MARKET_TYPE[post.type]?.COLOR || 'default'" label>
          {{ post.finish ? '거래완료' : MARKET_TYPE[post.type]?.NAME }}
        </v-chip>
      </td>
      <td>{{ post.userName }}</td>
      <td>{{ toYYYYMMDD(post.createdDate) }}</td>
      <td>{{ post.viewCount }}</td>
    </tr>
  </table>
  <div class="pagination-wrapper">
    <v-pagination v-model="currentPage" :length="pages || 1" />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useDisplay } from 'vuetify';
import { useRouter } from 'vue-router';
import { mdiLock } from '@mdi/js';
import Plus from '@/icons/Plus';
// import ChevronDownArrow from '@/icons/ChevronDownArrow';
import EmptyDataCard from '@/components/EmptyDataCard.vue';
// import Search from '@/icons/Search';
// import SelectBox from '@/components/SelectBox';
import { useUserStore } from '@/stores';
import { MARKET_TYPE } from '@/constants/usedMarket';
import { toYYYYMMDD } from '@/utils/date';

export default {
  name: 'BoardPc',
  components: { Plus, EmptyDataCard },
  props: {
    selectedOptions: {
      type: Array,
      required: true,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    posts: {
      type: Array,
      default: () => [],
    },
    hideWriteButton: {
      type: Boolean,
      default: () => false,
    },
    pages: {
      type: Number,
      default: () => 0,
    },
    onPageChange: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const router = useRouter();
    const currentPage = ref(1);
    const userStore = useUserStore();

    const { name } = useDisplay();

    const btnSize = computed(() => (name.value === 'md' ? 'large' : 'x-large'));

    const goDetailPost = (e) => {
      const { postId } = e.currentTarget.dataset;
      router.push(`/used-market/post/${postId}`);
    };

    const initPage = () => {
      currentPage.value = 1;
    };

    watch(currentPage, (current) => {
      props.onPageChange.call(this, current);
    });
    return { currentPage, btnSize, goDetailPost, mdiLock, initPage, userStore, MARKET_TYPE, toYYYYMMDD };
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/custom/customVPagination';

.input-wrapper {
  margin-left: 8px;
  min-width: 352px;
  background: white;
  border-bottom: 1px solid $gray-30;
  padding: 10px 0;
  display: flex;
}

.text-input {
  min-width: 300px;
  &:focus {
    outline: none;
  }
}

.view-more-btn {
  @include spreadTypoMap($button-medium);

  border: 1px solid $gray-30;
  border-radius: 4px;
  margin-right: 8px;
}

.add-btn {
  @include spreadTypoMap($button-medium);
  color: white;

  background: $blue-50;
  border-radius: 4px;

  @include md-and-down {
    padding: 0.5rem;
  }

  &__text {
    @include md-and-down {
      display: none;
    }
  }
}

// 테이블
.table-header-row {
  background: $blue-50;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: white;
}

.table-header {
  padding: 17px 18px;
  text-align: center;

  &[data-th-id='title'] {
    text-align: left;
  }
}

.table-row {
  background: white;
  border-bottom: 1px solid $gray-20;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  & > td {
    padding: 17px 18px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $gray-80;
    text-align: center;
  }

  .post-title {
    font-size: 16px;
    color: $black;
    text-align: left;

    display: flex;
    align-items: center;
  }

  &__chip {
    @include spreadTypoMap($body-xxsmall);

    padding: 0.2rem 0.5rem;
    margin-right: 0.5rem;
  }
}

.pagination-wrapper:deep {
  margin-top: 24px;
  margin-bottom: 48px;

  @include custom-v-pagination;
}
</style>
