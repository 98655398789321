<template>
  <v-dialog v-model="isOpen" :fullscreen="isMobileUi" close-on-back @click:outside="closeDialog">
    <v-card :style="!isMobileUi && 'min-width: 900px !important'">
      <div class="title-wrapper">
        <v-card-title class="text__title-small">{{ COMMITTEE_NAME[committeeType] }}</v-card-title>
        <v-btn flat variant="plain" :icon="mdiClose" type="button" @click="closeDialog" />
      </div>
      <v-divider class="ma-0"></v-divider>
      <v-card-item class="table-deep">
        <component :is="COMMITTEE_COMPONENT[committeeType]" />
      </v-card-item>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed } from 'vue';
import { mdiClose } from '@mdi/js';
import { COMMITTEE_NAME, COMMITTEE_COMPONENT } from '@/constants/committee';

export default {
  components: {},
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    closeDialog: {
      type: Function,
      required: true,
    },
    committeeType: {
      type: String,
      required: true,
    },
    isMobileUi: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const isOpen = computed(() => props.isDialogOpen);

    return { isOpen, COMMITTEE_NAME, mdiClose, COMMITTEE_COMPONENT };
  },
};
</script>

<style scoped lang="scss">
.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.table-deep:deep {
  .table-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0 2rem;
  }

  .table-tit,
  tr:first-of-type td {
    background: #0963a8;
    @include spreadTypoMap($body-medium);
    color: $gray-10;
  }

  .table {
    width: 100%;

    td:not(.table-tit) {
      @include spreadTypoMap($body-small);
      vertical-align: middle;
      overflow-wrap: anywhere;
    }
  }
}
</style>
