<template>
  <div class="signup-type">
    <div class="title w-100">가입 유형을 선택해주세요</div>
    <div class="d-flex justify-content-between w-100">
      <div class="signup-type__item" :class="{ 'signup-type__item-active': type === 'normal' }" data-type="normal" @click="onClickType">
        <span class="signup-type__text" :class="{ 'signup-type__text-active': type === 'normal' }">일반 회원</span>
        <icon-base><chevron-right-arrow /> </icon-base>
      </div>
      <!--
        <div class="signup-type__item" :class="{ 'signup-type__item-active': type === 'chilren' }" data-type="chilren" @click="onClickType">
        <span class="signup-type__text" :class="{ 'signup-type__text-active': type === 'chilren' }">어린이 회원</span>
        <icon-base><chevron-right-arrow /> </icon-base>
      </div>
      -->
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import ChevronRightArrow from '@/icons/ChevronRightArrow.vue';

export default {
  components: { ChevronRightArrow },
  props: {
    goNextStep: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const type = ref('normal');

    const onClickType = (e) => {
      // TODO: 어린이, 일반 회원이 무엇이 다른지 조사 필요
      const { type: newType } = e.currentTarget.dataset;
      type.value = newType;

      props.goNextStep();
    };

    return { type, onClickType };
  },
};
</script>

<style lang="scss" scoped>
.signup-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;

  &__item {
    width: 49%;
    padding: 2rem 1rem;
    border-radius: 3px;

    border: 1px solid $gray-20;
    color: $gray-50;

    &-active {
      border: 1px solid $blue-05;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__text {
    @include spreadTypoMap($title-xxsmall);
    color: $gray-50;

    &-active {
      color: $blue-50;
    }
  }
}

.title {
  @include spreadTypoMap($body-xxLarge);
  color: $black;
}
</style>
