<template>
  <div class="summer-wrapper">
    <v-card max-width="400px" flat>
      <v-img class="summer-wrapper__img" :src="thumbnail !== '' ? thumbnail : '/logo.svg'" alt="product" />
    </v-card>

    <div class="title-box">
      <h1 class="title-box__title">
        <v-chip class="title-box__chip" :color="MARKET_TYPE[sellType]?.COLOR || 'default'" label size="large">
          {{ isFinish ? '거래완료' : MARKET_TYPE[sellType]?.NAME }}
        </v-chip>
        <span>{{ title }}</span>
      </h1>

      <h1 class="summary-wrapper__price">{{ Intl.NumberFormat('ko-KR', price).format(price) }}원</h1>

      <v-divider />

      <ul class="meta-row">
        <li>
          <div class="d-flex">
            <div class="meta-row__title">작성자</div>
            <div class="meta-row__value">{{ userName }}</div>
          </div>
        </li>

        <li>
          <div class="d-flex">
            <div class="meta-row__title">작성일자</div>
            <div v-if="!!createdDate" class="meta-row__value">{{ parseAgoDate(createdDate) }}</div>
          </div>
        </li>

        <li>
          <div class="d-flex">
            <div class="meta-row__title">조회수</div>
            <div class="meta-row__value">{{ views }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { MARKET_TYPE } from '@/constants/usedMarket';
import { parseAgoDate } from '@/utils/date';

export default {
  props: {
    thumbnail: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
    sellType: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
    views: {
      type: Number,
      default: 0,
    },
    isFinish: {
      type: Boolean,
      default: false,
    },
    createdDate: {
      type: String,
      default: '',
    },
  },

  setup() {
    return { MARKET_TYPE, parseAgoDate };
  },
};
</script>

<style lang="scss" scoped>
.summer-wrapper {
  @include spreadTypoMap($body-medium);
  display: flex;

  &__img {
    width: 400px;
    height: 400px;
    object-fit: cover;
  }

  & > :not(:last-child) {
    margin-right: 5rem;
  }

  &__price {
    & > span {
      color: $blue-50;
    }
  }
}

.title-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;

  & > :not(:last-child) {
    margin-right: 0.5rem;
  }

  &__chip {
    @include spreadTypoMap($title-medium);
    justify-content: center;

    @include sm-and-down {
      @include spreadTypoMap($title-small);
    }

    padding: 0.3rem 0.5rem;
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    @include spreadTypoMap($title-large);

    @include sm-and-down {
      @include spreadTypoMap($title-medium);
    }

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__price {
    margin: 0;

    @include spreadTypoMap($title-large);

    @include sm-and-down {
      @include spreadTypoMap($title-medium);
    }
  }
}

.meta-row {
  @include spreadTypoMap($body-small);

  & > :not(:last-child) {
    margin-right: 0.5rem;
  }

  & > li {
    color: $gray-50;
  }

  &__title {
    @include spreadTypoMap($body-xsmall);

    width: 65px;
    color: $gray-60;
  }

  &__value {
    color: $gray-80;
  }
}
</style>
