import axios from '@/lib/axiosController';

/**
 * 하나 은행 계좌 소유 여부 확인 API
 *
 * @description authenticatedAccount
 * @description requestedLoan - 계좌 인증 여부
 * @returns {Promise<AxiosResponse<{
 *   authenticatedAccount: boolean, requestedLoan: boolean
 * }>>}
 */
export const checkHanaAccountAPI = () => axios.post('/loan/check/hana-account');

/**
 * 계좌 갱신 및 대출상담 신청 API
 * @param {object} params
 * @param {string} params.name
 * @param {string} params.accountNumber
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateHanaAccountAPI = ({ name, accountNumber }) => axios.put('/loan/update/hana-account', { name, accountNumber });

/**
 * 대출상담 신청
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateLoanRequestAPI = () => axios.put('/loan/update/request');
