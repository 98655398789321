import { useToast } from 'vue-toastification';

const toast = useToast();

export const successToast = (message) => {
  toast.success(message);
};

export const errorToast = (message) => {
  toast.error(message);
};

export const warningToast = (message) => {
  toast.warning(message);
};

export const infoToast = (message) => {
  toast.info(message);
};
