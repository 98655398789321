<template>
  <input-wrapper :required="required" :label="label" :helper-text="helperText" :error-text="errorText" :is-focus="isFocus" has-button :button-order="0">
    <template #form-input>
      <input type="file" v-bind="attrs" class="ally" @focus="onFocus" @blur="onBlur" @change="onChange" />
      <label v-if="!unChanged" :for="attrs.id" data-tag="input" class="label-filename" :class="{ 'label-placeholder': !fileName && !preview.label }">
        <span>{{ fileName || '선택된 파일 없음' }}</span>
      </label>
      <a v-if="unChanged" :href="preview.url" class="label-filename preview" target="_blank" data-tag="input"
        ><span>{{ preview.label }}</span
        ><icon-base v-if="unChanged" width="19" height="19" icon-color="#005289" style="position: absolute; right: 0; bottom: 0">
          <path :d="mdiTrayArrowDown" /> </icon-base
      ></a>
    </template>
    <template #form-button>
      <label :for="attrs.id" data-tag="button" class="button-xSmall-primary label-btn"><span>파일 선택</span></label>
    </template>
  </input-wrapper>
</template>

<script>
import { computed, ref } from 'vue';
import InputWrapper from '@/components/form/InputWrapper';
import { mdiTrayArrowDown } from '@mdi/js';
import IconBase from '@/components/IconBase';

export default {
  name: 'FileInput',
  components: {
    IconBase,
    InputWrapper,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    helperText: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
    preview: {
      type: Object,
      default: () => ({
        label: '',
        url: '',
      }),
    },
  },
  setup(props, { attrs }) {
    const fileName = ref('');
    const isFocus = ref(false);
    const unChanged = computed(() => !fileName.value && props.preview?.url);

    const onFocus = (e) => {
      isFocus.value = true;

      attrs?.onFocus?.(e);
    };

    const onBlur = (e) => {
      isFocus.value = false;

      attrs?.onBlur?.(e);
    };

    const onChange = (e) => {
      fileName.value = e.target.files[0].name;
      attrs?.onChange?.(e);
    };

    return { attrs, isFocus, fileName, unChanged, mdiTrayArrowDown, onFocus, onBlur, onChange };
  },
};
</script>

<style lang="scss" scoped>
.ally {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
}

.label-btn {
  border-radius: 3px;
  margin-right: 8px;
  width: 76px;
}

.label-filename {
  width: calc(100% - 76px - 8px);
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.preview {
  position: relative;
  color: $blue-60;
}

.label-placeholder {
  @include spreadTypoMap($body-xsmall);
  color: $gray-30;
}
</style>
