<template>
  <section class="mobile:px-6">
    <ArticleTitle>#2022 IKBF Contest</ArticleTitle>
    <div class="flex mobile:flex-col pc:gap-6 mobile:gap-8">
      <div class="pc:max-w-[740px]">
        <video id="contest-video-player" class="xl:w-[46.25rem] w-full mb-4" :poster="thumbnail" controls></video>
        <div class="pc:text-title-small mobile:text-title-xsmall text-black mb-4">{{ mainContestSectionView.video?.title ?? '' }}</div>
        <div class="pc:text-body-medium mobile:text-body-xxsmall text-gray-50">
          {{ dayjs(mainContestSectionView.video?.createdDate).format('YYYY.MM.DD (ddd) hh:mm') }}
        </div>
      </div>
      <div class="flex flex-col gap-6">
        <template v-for="data in mainContestSectionView.contestArticles ?? []" :key="data.articleId">
          <router-link class="no-underline" :to="`/news-detail/${data.articleId}`">
            <div class="flex gap-4">
              <img class="pc:w-[11.25rem] mobile:w-[10rem]" :src="data.thumbnail" alt="" />
              <div>
                <div class="pc:text-title-xsmall mobile:text-title-xxsmall text-black mb-4">{{ data.title }}</div>
                <div class="pc:text-caption-medium mobile:text-caption-xxsmall text-gray-40">{{ dayjs(data.createdDate).format('YYYY.MM.DD (ddd)') }}</div>
              </div>
            </div>
          </router-link>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { ArticleTitle } from '@/containers/Main/components';
import { dayjs } from '@/utils/date';
import { onMounted } from 'vue';
import Hls from 'hls.js';

export default {
  components: {
    ArticleTitle,
  },
  props: {
    mainContestSectionView: {
      type: Object,
      default: () => ({
        contestArticles: [],
        video: {},
      }),
    },
  },
  setup(props) {
    onMounted(() => {
      const videoSource = props.mainContestSectionView.video.videoUrl;
      const videoTag = document.getElementById('contest-video-player');

      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(videoSource);
        hls.attachMedia(videoTag);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          // videoTag.play();
        });
      } else if (videoTag.canPlayType('application/vnd.apple.mpegurl')) {
        videoTag.src = videoSource;
        videoTag.addEventListener('loadedmetadata', () => {
          // videoTag.play();
        });
      }
    });

    return { dayjs, thumbnail: props.mainContestSectionView.video?.thumbnail ?? require('@/assets/main_ibkf_video_thumnail.png') };
  },
};
</script>

<style lang="scss" scoped>
video {
  object-fit: fill;
}
</style>
