import axios from '@/lib/axiosController';

/**
 * 경기 유효기간 상태 조회 API
 *
 * @param {string} getAllContestItemByContestId
 *
 * @returns {{
 *  isEditableDay: boolean,
 *  isRunContest: boolean,
 *  isRunContestApplication: boolean,
 * }}
 */

export const getContestPeriodStatus = (contestId) => axios.get(`/contest/period-status/${contestId}`);

/** 진행중 대회 리스트 */
export const getAllContest = async () => await axios.get('/contest/all');

/**
 * 대회 상세보기
 *
 * @param {number} contestId
 * */
export const getContestDetail = async (contestId) => await axios.get(`/contest/${contestId}`);

/**
 * 대회 종목 전체 리스트
 *
 * @param {number} contestId
 * */
export const getAllContestItemByContestId = async (contestId, contestRegisterType) =>
  await axios.get(`/contest-item/all/${contestId}/contest-register-type/${contestRegisterType}`);

/**
 * 자식 종목 조회 API
 *
 * @param {number} parentId
 */
export const getContestItemByParentId = async (parentId) =>
  await axios.get(`/contest-item/child-list`, {
    params: {
      parentId,
    },
  });
