<template>
  <section class="section-wrapper">
    <div class="background-box"></div>
    <!--  <education-lnb />-->
    <education-feature />
    <education-category :data="tabs" />
    <education-list-groups :lectures="recommendLectures" :instructors="instructors" />
  </section>
</template>

<script>
import EducationCategory from '@/education-mocking/educationHome/EducationCategory';
import EducationFeature from '@/education-mocking/educationHome/EducationFeature';
import EducationListGroups from '@/education-mocking/educationHome/EducationListGroups';
import { onMounted, ref } from 'vue';
import { getEducationCategoryList, getInstructorList, getRecommendLectureList } from '@/apis/__education__';

export default {
  name: 'EducationHomePage',
  components: { EducationCategory, EducationFeature, EducationListGroups },
  props: {},
  setup() {
    const tabs = ref([]);
    const instructors = ref([]);
    const recommendLectures = ref([]);

    onMounted(() => {
      Promise.all([getEducationCategoryList(), getInstructorList(), getRecommendLectureList(1)]).then(
        ([educationList, instructorList, recommendLectureList]) => {
          tabs.value = educationList;
          instructors.value = instructorList;
          recommendLectures.value = recommendLectureList;
          console.log(instructorList, recommendLectureList);
        },
      );
    });

    return { tabs, instructors, recommendLectures };
  },
};
</script>

<style scoped lang="scss">
.background-box {
  background: #181e2d;
  width: 100%;
  height: 90px;
  position: absolute;
  top: -90px;
  left: 0;
}
</style>
