<template>
  <div ref="viewer"></div>
</template>

<script>
import { onMounted, ref } from 'vue';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';

export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const viewer = ref();
    const editorRef = ref();

    const updateBody = (body) => {
      const elements = document.getElementsByClassName('toastui-editor-contents');
      elements[0].innerHTML = body;
      editorRef.value.options.el = '';
    };

    onMounted(() => {
      const e = new Viewer({
        el: viewer.value,
        viewer: true,
        initialValue: props.content,
      });
      editorRef.value = e;
    });

    return { viewer, editorRef, updateBody };
  },
};
</script>

<style lang="scss" scoped>
div :deep(.toastui-editor-contents p) {
  font-size: 16px;
}
</style>
