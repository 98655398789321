<template>
  <v-dialog v-model="isOpen" :fullscreen="isMobileUi" close-on-back @click:outside="closeDialog">
    <v-card :style="!isMobileUi && 'min-width: 900px !important'">
      <div class="title-wrapper">
        <v-card-title class="text__title-xsmall">지회 지부 게시판으로 이동하시겠습니까?</v-card-title>
        <v-btn flat variant="plain" :icon="mdiClose" type="button" @click="closeDialog" />
      </div>

      <v-divider class="ma-0"></v-divider>
      <v-card-item class="pb-3">
        <p v-if="isAccessible" class="text__caption-small">이동할 지회/지부 게시판을 선택해주세요.</p>
        <p v-else-if="userView.id" class="text__caption-small">지회/지부 게시판 접근 권한이 없습니다.</p>
        <p v-else class="text__caption-small">회원가입 후 지회/지부 게시판으로 이동가능합니다.</p>

        <div class="branch-office-selector__wrapper">
          <branch-office-infos-fetcher>
            <template #contents>
              <branch-office-selects
                default-branch-office-id="1"
                @update:branchOfficeId="selectedBranchOfficeId = $event"
                @update:mainBranch="selectedMainBranch = $event"
              />
            </template>
          </branch-office-infos-fetcher>
          <v-btn v-if="isAccessible" flat class="button-medium-line" type="button" @click="redirectToBranchCommunity">이동하기</v-btn>
        </div>
      </v-card-item>

      <v-card-item class="pb-1 table-deep">
        <div class="text__title-xxsmall">지부</div>
        <branch-office-table :table-headers="MAIN_BRANCH_TABLE_HEADERS" :table-rows="mainTableRows" />
      </v-card-item>

      <v-card-item class="pb-3 table-deep">
        <div class="text__title-xxsmall">지회</div>
        <branch-office-table :table-headers="SUB_BRANCH_TABLE_HEADERS" :table-rows="subTableRows" />
      </v-card-item>
      <v-divider class="ma-0"></v-divider>
      <v-card-actions class="card-actions">
        <v-btn class="button-medium-line" type="button" @click="closeDialog">닫기</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { mdiClose } from '@mdi/js';

import { useBranchStore, useMenuStore, useUserStore } from '@/stores';
import { BranchOfficeInfosFetcher, BranchOfficeSelects, BranchOfficeTable } from '@/components/branchOffice';
import { MAIN_BRANCH_TABLE_HEADERS, SUB_BRANCH_TABLE_HEADERS, MAIN_TABLE_ROWS, SUB_TABLE_ROWS } from '@/constants/branchOffice';

export default {
  components: { BranchOfficeInfosFetcher, BranchOfficeSelects, BranchOfficeTable },
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    closeDialog: {
      type: Function,
      required: true,
    },
    isMobileUi: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const userStore = useUserStore();
    const menuStore = useMenuStore();
    const branchStore = useBranchStore();

    const { main } = branchStore.defaultBranchSelectorInfos;

    const isOpen = computed(() => props.isDialogOpen);
    const userView = computed(() => userStore.getUserView);
    const isAccessible = computed(() => userStore.getUserView.boardAuthorities?.length > 0);

    const selectedMainBranch = ref(main[0].id);
    const selectedBranchOfficeId = ref('1');

    const communityPath = computed(() => menuStore.getMenuMap[16]?.find((v) => v.id === 45)?.path || '/community/19');

    const initApi = async () => {
      await branchStore.fetchBranchInfos();
    };

    onMounted(() => {
      initApi();
    });

    const redirectToBranchCommunity = () => {
      props.closeDialog();
      router.push(`${communityPath.value}?branchOfficeId=${selectedBranchOfficeId.value}`);
    };

    const mainTableRows = computed(() => MAIN_TABLE_ROWS.filter((r) => r.region === selectedMainBranch.value));
    const subTableRows = computed(() => SUB_TABLE_ROWS.filter((r) => r.id === selectedBranchOfficeId.value));

    return {
      isOpen,
      userView,
      isAccessible,
      selectedMainBranch,
      selectedBranchOfficeId,
      mdiClose,
      redirectToBranchCommunity,
      MAIN_BRANCH_TABLE_HEADERS,
      SUB_BRANCH_TABLE_HEADERS,
      mainTableRows,
      subTableRows,
    };
  },
};
</script>

<style scoped lang="scss">
.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.branch-office-selector__wrapper:deep {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  .branch-selects {
    .branch-selector {
      max-width: unset;
      width: auto;
    }
  }
}

.table-deep:deep {
  .table-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0 2rem;
  }

  .table-tit,
  tr:first-of-type td {
    background: #0963a8;
    @include spreadTypoMap($body-medium);
    color: $gray-10;
  }

  .table {
    width: 100%;

    td:not(.table-tit) {
      @include spreadTypoMap($body-small);
      vertical-align: middle;
    }
  }
}

.card-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
