import axios from '@/lib/axiosController';

/**
 * 뉴스 기사 상세보기 API
 * @param {Number} articleId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getArticle = async (articleId) => {
  return await axios.get(`/article/${articleId}`);
};

/**
 * 뉴스 기사 검색 조회 API
 * @param {Number} size
 * @param {Number} page
 * @param {Number} newsId
 * @param {String} keyword
 * @param {String} reporterName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getArticles = async ({ size = 5, page = 0, newsId = 1, keyword = '', reporterName = '' }) => {
  const params = { size, page, newsId, ...(keyword && keyword), ...(reporterName && reporterName) };
  return await axios.get('/article/search', { params });
};
