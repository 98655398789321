<template>
  <div class="table-wrapper">
    <div class="commitee-table-community-selector">
      <v-btn flat class="button-medium-line" type="button" @click="moveToCommiteeCommunityPage(100010)">지회지부 게시판으로 이동하기</v-btn>
    </div>
    <table-with-photo number="1">
      <tr>
        <td>1</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2768_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>강명이</td>
        <td>예뻐지기미용실</td>
      </tr>
      <tr>
        <td>2</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2769_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>고경서</td>
        <td>김민주헤어뱅크</td>
      </tr>
      <tr>
        <td>3</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2770_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>고재경</td>
        <td>에코헤어</td>
      </tr>
      <tr>
        <td>4</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2771_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김미섭</td>
        <td>다기헤어샵</td>
      </tr>
      <tr>
        <td>5</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2772_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김미소</td>
        <td>가위든여자미용실</td>
      </tr>
      <tr>
        <td>6</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2773_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김영숙</td>
        <td>김영숙헤어컬렉션</td>
      </tr>
      <tr>
        <td>7</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2774_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김옥매</td>
        <td>옥돌매헤어샵</td>
      </tr>
      <tr>
        <td>8</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2775_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김옥순</td>
        <td>멋쟁이뷰티</td>
      </tr>
      <tr>
        <td>9</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2776_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김현옥</td>
        <td>헤어창조</td>
      </tr>
      <tr>
        <td>10</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2777_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김현자</td>
        <td>이은헤어센스</td>
      </tr>
      <tr>
        <td>11</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2778_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>남유진</td>
        <td>남유진헤어갤러리</td>
      </tr>
      <tr>
        <td>12</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2779_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>류영주</td>
        <td>Will헤어</td>
      </tr>
      <tr>
        <td>13</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2780_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>문성자</td>
        <td>헤어디자인엠</td>
      </tr>
      <tr>
        <td>14</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2781_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박명희</td>
        <td>박정민헤어스케치</td>
      </tr>
      <tr>
        <td>15</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2782_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박상숙</td>
        <td>박상숙헤어미인</td>
      </tr>
      <tr>
        <td>16</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2783_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>서유경</td>
        <td>뷰티스토리</td>
      </tr>
      <tr>
        <td>17</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2784_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>서정희</td>
        <td>송정오렌지헤어샵</td>
      </tr>
      <tr>
        <td>18</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2785_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>소정예</td>
        <td>소정례에코헤어</td>
      </tr>
      <tr>
        <td>19</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2786_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>송명애</td>
        <td>송헤어샵</td>
      </tr>
      <tr>
        <td>20</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2787_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>신미화</td>
        <td>미화헤어클럽</td>
      </tr>
      <tr>
        <td>21</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2788_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>심경남</td>
        <td>보미헤어</td>
      </tr>
      <tr>
        <td>22</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2789_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>심남연</td>
        <td>미담헤어</td>
      </tr>
      <tr>
        <td>23</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2790_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>오승현</td>
        <td>헤어인</td>
      </tr>
      <tr>
        <td>24</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2791_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>오영주</td>
        <td>오즈미용실</td>
      </tr>
      <tr>
        <td>25</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2792_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>오영진</td>
        <td>디그헤어</td>
      </tr>
      <tr>
        <td>26</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2793_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이상미</td>
        <td>이상미두피모발크리닉</td>
      </tr>
      <tr>
        <td>27</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2794_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이은미</td>
        <td>이은미헤어</td>
      </tr>
      <tr>
        <td>28</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2795_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이종숙</td>
        <td>꽃바구니헤어살롱</td>
      </tr>
      <tr>
        <td>29</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2796_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>임경희</td>
        <td>지우헤어</td>
      </tr>
      <tr>
        <td>30</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2797_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>임은앵</td>
        <td>임은앵헤어나라</td>
      </tr>
      <tr>
        <td>31</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2798_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>장지의</td>
        <td>장지의헤어</td>
      </tr>
      <tr>
        <td>32</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2799_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>전정길</td>
        <td>전정길헤어뉴스</td>
      </tr>
      <tr>
        <td>33</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2800_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>정선윤</td>
        <td>지은헤어샾</td>
      </tr>
      <tr>
        <td>34</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2801_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>최미자</td>
        <td>수정미용실</td>
      </tr>
      <tr>
        <td>35</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2802_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>최복자</td>
        <td>노블헤어팀</td>
      </tr>
      <tr>
        <td>36</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2803_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>황영은</td>
        <td>헤어스미용실</td>
      </tr>
      <tr>
        <td>37</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_19&amp;savefilename=bbs_19_2804_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>황희정</td>
        <td>희정헤어라인</td>
      </tr></table-with-photo
    >
  </div>
</template>

<script>
import TableWithPhoto from '@/components/committee/TableWithPhoto';
import { moveToCommiteeCommunityPage } from '@/components/committee/commonUtil';

export default {
  components: { TableWithPhoto },
  setup() {
    return { moveToCommiteeCommunityPage };
  },
};
</script>

<style scoped lang="scss">
.commitee-table-community-selector:deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -10px;

  .branch-selects {
    .branch-selector {
      max-width: unset;
      width: auto;
    }
  }
}
</style>
