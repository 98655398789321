<template>
  <v-sheet class="mx-auto pc:pb-[72px] lg:pb-[48px] mobile:pb-[48px]" elevation="0" max-width="100%">
    <v-slide-group v-model="model" class="slide-wrapper" show-arrows>
      <v-slide-group-item v-for="slide in slides" :key="slide.id">
        <image-cover-card-vue
          class-name="mr-4"
          :src="slide.src"
          :title="slide.title"
          :subtitle="slide.subtitle"
          :text="slide.description"
          :news-id="slide.id"
        ></image-cover-card-vue>
      </v-slide-group-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
import ImageCoverCardVue from '@/components/ImageCoverCard.vue';

export default {
  components: { ImageCoverCardVue },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-wrapper {
  padding: 0;
}

.slide-group {
  &__item {
    & > :not(:last-child) {
      margin-right: 1rem;
    }
  }
}
</style>
