<template>
  <header>
    <v-row v-resize="onResize" class="m-0" style="display: none" />
    <GNB-menu-mobile v-model="sidebar" @change="sidebar = $event"></GNB-menu-mobile>

    <v-hover v-slot="{ isHovering, props }">
      <v-app-bar
        id="koba-header"
        app
        :class="{ 'app-bar-animation': isHovering }"
        :color="isHovering ? '' : 'transparent'"
        class="gnb !bg-white px-0 mobile:h-[3.5rem] h-[12rem] mx-auto w-full"
        :height="isMobileUi ? 56 : 192"
        elevation="0"
        absolute
      >
        <div class="flex flex-col justify-around items-center">
          <GNB-row>
            <template #left
              ><a class="no-underline" href="/">
                <div class="pc:hidden">
                  <v-img width="56" alt="koba-logo" src="@/assets/logo.svg" />
                </div> </a
            ></template>

            <template #mobile-right
              ><div class="md:flex items-center ml-auto hidden">
                <!-- 모바일 푸시 알림 리스트 (login user only) -->
                <alarm-vue v-if="userStore.getUserView?.id" :fill="menuColor"></alarm-vue>
                <!-- 모바일 GNB 버튼-->
                <v-btn class="gnb__mobile gnb__mobile__btn" flat @click="sidebarToggle">
                  <menu-vue :fill="menuColor" width="24"></menu-vue>
                </v-btn></div
            ></template>

            <template #mobile-center>
              <div class="gnb__mobile-title" :style="isGnbTransparent && 'color: white;'">{{ pageTitle }}</div>
            </template>

            <!-- GNB 메뉴 -->
            <template #center>
              <div class="relative">
                <div class="absolute w-full left-0 right-0 top-[-64px] flex justify-content-between items-center">
                  <a class="pc:h-[80px] mobile:hidden no-underline flex items-center" href="/">
                    <div class="w-full flex justify-start items-center gap-2">
                      <v-img width="80" alt="koba-logo" src="@/assets/logo.svg" />
                      <div class="text-title-medium text-black">사)대한미용사회중앙회</div>
                    </div>
                  </a>

                  <div class="flex items-center">
                    <!-- 로그인 / 회원가입 버튼 -->
                    <template v-if="userStore.getUserView?.id">
                      <v-btn class="profile-btn profile-btn__normal" fab :icon="mdiAccount" to="/mypage" />
                      <div
                        class="gnb__item__text-clickable"
                        :class="{ text__active: isHovering, 'gnb__item__text-link': isGnbTransparent }"
                        @click="handleLogout"
                      >
                        로그아웃
                      </div>
                    </template>
                    <template v-else>
                      <a class="gnb__item__text-clickable mr-4" :class="{ text__active: isHovering, 'gnb__item__text-link': isGnbTransparent }" href="/signin">
                        로그인
                      </a>
                      <a class="gnb__item__text-clickable" :class="{ text__active: isHovering, 'gnb__item__text-link': isGnbTransparent }" href="/signup">
                        회원가입
                      </a>
                    </template>
                  </div>
                </div>
              </div>

              <ul class="gnb__menu" v-bind="props">
                <template v-for="item in menuStore.getMenu" :key="item.id">
                  <v-hover v-slot="{ isHovering: listHover, props: listProps }">
                    <li class="gnb__item text-black" :class="{ 'gnb__item-active': listHover }" v-bind="listProps">
                      <a :href="item.path" class="gnb__item__text" :class="{ text__active: isHovering, 'gnb__item__text-link': isGnbTransparent }">{{
                        item.name
                      }}</a>
                    </li>

                    <GNB-menu-pc :is-hover="isHovering"></GNB-menu-pc>
                  </v-hover>
                </template>
              </ul>
            </template>
          </GNB-row>
        </div>
      </v-app-bar>
    </v-hover>
  </header>
</template>

<script>
import { computed, reactive, onMounted, ref, toRef } from 'vue';
import GNBMenuPc from './GNBMenuPc.vue';
import GNBRow from '@/components/GNBRow.vue';
import GNBMenuMobile from '@/components/GNBMenuMobile.vue';

import MenuVue from '@/icons/Menu.vue';
import { useMenuStore, useUserStore } from '@/stores';
import { useRouter } from 'vue-router';
import AlarmVue from '@/components/Alarm.vue';
import { mdiAccount } from '@mdi/js';

export default {
  name: 'Navbar',
  components: {
    GNBMenuPc,
    GNBRow,
    GNBMenuMobile,
    MenuVue,
    AlarmVue,
  },
  props: {
    isGnbTransparent: {
      type: Boolean,
      default: false,
    },
    pageTitle: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const sidebar = ref(false);
    const isGnbTransparent = toRef(props, 'isGnbTransparent');
    const menuColor = computed(() => (isGnbTransparent.value ? '#181E2D' : ''));

    const windowSize = reactive({ x: 0, y: 0 });
    const isMobileUi = computed(() => windowSize.x < 768);

    const onResize = () => {
      windowSize.x = window.innerWidth;
      windowSize.y = window.innerHeight;
    };

    onMounted(() => {
      document.title = props.docTitle || props.title;
      onResize();
    });

    const sidebarToggle = () => {
      sidebar.value = !sidebar.value;
    };

    const userStore = useUserStore();
    const menuStore = useMenuStore();
    const router = useRouter();
    const handleLogout = () => {
      userStore.logout();
      router.push('/');
    };

    return {
      menuStore,
      sidebar,
      sidebarToggle,
      menuColor,
      userStore,
      handleLogout,
      mdiAccount,
      isMobileUi,
      onResize,
    };
  },
};
</script>

<style scoped lang="scss">
@keyframes backgroud-color-fadein {
  from {
    background-color: transparent;
  }
  to {
    background-color: white;
  }
}

.app-bar-animation {
  animation: backgroud-color-fadein 0.35s;
}

.gnb {
  overflow: unset !important;

  &__mobile {
    &__menu {
      @include lg-and-up {
        display: none !important;
      }
    }

    &__btn {
      &-black {
        filter: brightness(0) saturate(100%) invert(8%) sepia(11%) saturate(2583%) hue-rotate(185deg) brightness(95%) contrast(91%); //$black
      }

      @include lg-and-up {
        &-black {
          filter: brightness(0) saturate(100%) invert(8%) sepia(11%) saturate(2583%) hue-rotate(185deg) brightness(95%) contrast(91%); //$black
        }
      }
    }
  }

  &__menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

.gnb:deep(.v-toolbar__content) {
  padding: 0 !important;
  justify-content: center;
}

.gnb__item {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  line-height: 16px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  width: calc(100% - 10 * 4rem);
  height: 4rem;

  &__text {
    @include spreadTypoMap($caption-large);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    @include md-and-down {
      @include spreadTypoMap($caption-medium);
    }

    &-link {
      color: $gray-50;
    }

    &-clickable {
      @include spreadTypoMap($body-small);
      text-decoration: none;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.gnb__item-active {
  border-bottom: 2px solid $blue-50;
}

.text__active {
  color: $gray-80 !important;
}

.gnb__mobile-title {
  @include spreadTypoMap($title-xxsmall);
  font-weight: 500;
  line-height: 19px;

  color: $gray-80;
  word-break: keep-all;
}

.profile-btn {
  margin-right: 0.5rem;
  color: white;
  width: 32px !important;
  height: 32px !important;

  &__normal {
    background: $blue-05 !important;
  }

  &__white {
    background: transparent;
    color: white;
  }
}
</style>
