<template>
  <template v-if="isLoading">
    <slot name="loader" />
  </template>
  <template v-else>
    <slot name="contents" />
  </template>
</template>

<script>
import { computed, onBeforeMount } from 'vue';
import { useBranchStore, useLoadingStore } from '@/stores';
import { BRANCH_ACTION_NAME } from '@/stores/branch';

export default {
  name: 'BranchOfficeInfosFetcher',
  components: {},
  props: {},
  setup() {
    const branchStore = useBranchStore();
    const loadingStore = useLoadingStore();
    const isLoading = computed(() => loadingStore.getLoadings[BRANCH_ACTION_NAME.FETCH_BRANCH_INFOS]);

    const getBranchInfos = () => {
      branchStore.fetchBranchInfos();
    };

    onBeforeMount(() => {
      getBranchInfos();
    });

    return { isLoading };
  },
};
</script>

<style scoped lang="scss"></style>
