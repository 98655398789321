<template>
  <div class="form-player">
    <h2 class="text__title-medium form-player__title">{{ title }}</h2>
    <text-input
      v-if="isStudentForm"
      id="playerInfo.associationName"
      required
      name="associationName"
      :value="playerInfo.associationName"
      label="소속/학교명"
      placeholder="소속/학교명"
      :error-text="errors.associationName"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <selects-input
      v-if="!isStudentForm"
      :id="['playerInfo.regionName', 'playerInfo.associationName']"
      :name="['regionName', 'associationName']"
      required
      label="소속 지회, 지부 선택"
      :value="[playerInfo.regionName, playerInfo.associationName]"
      :error-text="errors.associationName"
      :selects="branchSelects"
      :default-option="[defaultMainBranch, defaultSubBranch]"
      @click="passInputValue"
      @blur="setInputError"
    />
    <text-input
      id="playerInfo.name"
      required
      name="name"
      :value="playerInfo.name"
      label="이름"
      placeholder="예) 홍길동"
      :error-text="errors.name"
      :disabled="isUpdateMode"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <birthday-input
      id="playerInfo.birthday"
      required
      label="생년월일"
      :value="playerInfo.birthday"
      :error-text="errors.birthday"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <text-input
      id="playerInfo.phone"
      required
      name="phone"
      :value="playerInfo.phone"
      label="연락처"
      data-type="number"
      type="tel"
      placeholder="예) 01012341234"
      :disabled="isUpdateMode"
      :error-text="errors.phone"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <file-input
      id="playerInfo.idPhoto"
      required
      name="idPhoto"
      accept="image/*"
      :preview="playerInfo.idPhoto"
      label="증명사진"
      placeholder="선택된 파일 없음"
      :data-category="FILE_CATEGORY.ID_PHOTO"
      :error-text="errors.idPhoto"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <file-input
      v-if="isStudentForm"
      id="playerInfo.enrollmentVerificationLetter"
      name="enrollmentVerificationLetter"
      accept="image/*"
      :preview="playerInfo.enrollmentVerificationLetter"
      label="재학증명서"
      placeholder="선택된 파일 없음"
      :data-category="FILE_CATEGORY.ENROLLMENT_VERIFICATION_LETTER"
      :error-text="errors.enrollmentVerificationLetter"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <text-input
      v-if="!isStudentForm"
      id="playerInfo.businessName"
      name="businessName"
      :value="playerInfo.businessName"
      label="업소명"
      placeholder="예) OO 헤어샵"
      :error-text="errors.businessName"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <file-input
      v-if="!isStudentForm && !isIndependentBusinessForm"
      id="playerInfo.certificate"
      required
      name="certificate"
      accept="image/*"
      :preview="playerInfo.certificate"
      label="자격증 또는 면허증 사본"
      placeholder="선택된 파일 없음"
      :data-category="FILE_CATEGORY.CERTIFICATE"
      :error-text="errors.certificate"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <file-input
      v-if="isIndependentBusinessForm"
      id="playerInfo.businessRegistration"
      required
      accept="image/*"
      name="businessRegistration"
      :preview="playerInfo.businessRegistration"
      label="사업자 등록증"
      placeholder="선택된 파일 없음"
      :data-category="FILE_CATEGORY.BUSINESS_REGISTRATION"
      :error-text="errors.businessRegistration"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <file-input
      v-if="isIndependentBusinessForm"
      id="playerInfo.smallBusinessConfirmation"
      required
      accept="image/*"
      name="smallBusinessConfirmation"
      :preview="playerInfo.smallBusinessConfirmation"
      label="미용사 면허증"
      placeholder="선택된 파일 없음"
      :data-category="FILE_CATEGORY.SMALL_BUSINESS_CONFIRMATION"
      :error-text="errors.smallBusinessConfirmation"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <daum-post-input
      id="playerInfo.address"
      required
      name="address"
      :value="playerInfo.address"
      label="주소"
      placeholder="주소 찾기"
      :error-text="errors.address"
      :on-complete-find-address="onCompleteFindAddress"
      button-text="찾기"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <text-input
      id="playerInfo.addressDetail"
      required
      name="addressDetail"
      :value="playerInfo.addressDetail"
      label="상세주소 입력"
      placeholder="상세 주소를 입력해주세요"
      :error-text="errors.addressDetail"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />

    <selects-input
      :id="['selectedClassification', 'selectedSector', 'selectedEvent']"
      label="종목선택"
      required
      :name="['selectedClassification', 'selectedSector', 'selectedEvent']"
      :value="[selectedClassification, selectedSector, selectedEvent]"
      :error-text="errors.contestItemIds"
      :selects="[classification, sector, event]"
      :default-option="['분류 선택', '부문 선택', '종목 선택']"
      @click="passInputValue"
    >
      <template #select-preview>
        <div class="d-flex justify-space-between align-items-center">
          <div class="contestItems-preview">
            <div v-if="playerInfo.contestItemIds.length === 0">아래에서 종목을 선택해주세요</div>
            <v-chip
              v-for="contestItemId in playerInfo.contestItemIds"
              v-else
              :key="contestItemId"
              class="mr-2 mb-1"
              :value="contestItemId"
              closable
              @click:close="() => onCloseSelectedContestItem(contestItemId)"
            >
              {{ allContestEvents.find((v) => v.id === contestItemId)?.name }}
            </v-chip>
          </div>
        </div>
        <small>종목 선택을 클릭해서 최대 5개 종목을 선택할 수 있습니다.</small>
      </template>
    </selects-input>

    <text-input
      v-if="!isLogin"
      id="playerInfo.password"
      required
      type="password"
      name="password"
      :minlength="4"
      :maxlength="12"
      :value="playerInfo.password"
      label="비밀번호"
      placeholder="대회 등록 전용 비밀번호"
      helper-text="최소 4자, 최대 12자"
      :error-text="errors.password"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <text-input
      id="playerInfo.note"
      required
      name="note"
      :value="playerInfo.note"
      label="비고"
      placeholder="입금 내역을 기재 바랍니다. 예) 6/15 홍길동 입금"
      :error-text="errors.note"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <p class="text__body-medium bank__description">
      <span v-text="bankAccountInformation"></span>
    </p>
  </div>
</template>

<script>
import { computed, onMounted, onUpdated, ref } from 'vue';
import { isEmpty } from 'lodash-es';

import TextInput from '@/components/form/TextInput';
import SelectsInput from '@/components/form/SelectsInput';
import FileInput from '@/components/form/FileInput';
import BirthdayInput from '@/components/form/BirthdayInput';
import DaumPostInput from '@/containers/Contest/DaumPostInput';

import { useContestStore } from '@/stores';
import { getContestItemByParentId } from '@/apis/contest';
import { branchMappedKo } from '@/constants/category';
import { getContestSeq } from '@/utils/contestSeqStoreUtil';

import {
  SET_INPUT,
  SET_INPUT_ERROR,
  RESET_INPUT_ERROR,
  FILE_CATEGORY,
  DELETE_CONTEST_ITEMS,
  CONTEST_REGISTER_TYPE_PASCAL,
  contestInfoMap,
} from '@/constants/contest';

const PLAYER_INFO = 'playerInfo';

/** onBlur 시 검사하는 validator */
const validator = {
  name: (value) => {
    if (value.trim().length < 2) {
      return { isValid: false, message: '이름은 2글자 이상 입력해주세요.' };
    }

    if (value.trim().length > 30) {
      return { isValid: false, message: '이름은 30글자 이하로 입력해주세요.' };
    }
    const nameReg = /^[ㄱ-ㅎ|가-힣|a-z|A-Z| |]+$/;
    if (!nameReg.test(value)) {
      return { isValid: false, message: '이름은 한글, 영어만 가능합니다.' };
    }

    return true;
  },
  phone: (value) => {
    const phoneNumberReg = /^(01[0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/;

    if (!phoneNumberReg.test(value.trim())) {
      return { isValid: false, message: '올바른 휴대전화 번호가 아닙니다.' };
    }
    return true;
  },
  password: (value) => {
    if (value.trim().length < 4) {
      return { isValid: false, message: '4글자 이상 입력해주세요.' };
    }

    return true;
  },
};

const defaultContestOption = { id: 'unSelect', label: '종목 선택' };

export default {
  name: 'PlayerInfoForm',
  components: {
    DaumPostInput,
    TextInput,
    SelectsInput,
    FileInput,
    BirthdayInput,
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false,
    },
    isStudentForm: {
      type: Boolean,
      required: true,
    },
    isIndependentBusinessForm: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    playerInfo: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    branchInfos: {
      type: Object,
      default: () => {},
    },
    regionSelects: {
      type: Array,
      default: () => [],
    },
    isUpdateMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const infoType = PLAYER_INFO;
    const contestStore = useContestStore();

    /** 지회, 지부 */
    const subBranches = ref([]);
    const defaultMainBranch = ref('소속 지회를 선택해주세요');
    const defaultSubBranch = ref('소속 지부를 선택해주세요');
    const allContestEvents = ref([]);

    /** 대회 */
    const classification = ref([]);
    const sector = ref([]);
    const event = ref([]);
    const selectedClassification = ref('');
    const selectedSector = ref('');
    const selectedEvent = ref('');

    const fetchAllContestItem = async () => {
      const contestRegisterType = props?.isStudentForm
        ? CONTEST_REGISTER_TYPE_PASCAL.STUDENT
        : props?.isIndependentBusinessForm
        ? CONTEST_REGISTER_TYPE_PASCAL.INDEPENDENT_BUSINESS
        : CONTEST_REGISTER_TYPE_PASCAL.GENERAL;

      const allContestItem = await contestStore.fetchAllContestItems(props.playerInfo.contestId, contestRegisterType);
      allContestEvents.value = contestStore.contestItems;

      // code 는 필요하면 넣기
      const classificationItems = allContestItem.reduce(
        (acc, { displayStatus, id, name }) => {
          if (displayStatus !== 'SHOW') return acc;
          return [...acc, { id, label: name }];
        },
        [defaultContestOption],
      );

      classification.value = classificationItems;
    };

    const fetchSectorByParentIds = async (parentId, optionName) => {
      const contestItems = await getContestItemByParentId(parentId);

      const convertedItems = contestItems?.reduce(
        (acc, { displayStatus, id, name }) => {
          if (displayStatus !== 'SHOW') return acc;
          return [...acc, { id, label: name }];
        },
        [defaultContestOption],
      );

      if (optionName === 'classification') {
        sector.value = convertedItems;
        return;
      }

      if (optionName === 'sector') {
        event.value = convertedItems;
      }
    };

    /** 대회 종목 아이템들 전체를 서버에서 받아온다. */
    onMounted(() => {
      fetchAllContestItem();
    });

    /** subBranch 를 regionName 에 맞추어 설정한다. */
    onUpdated(() => {
      if (isEmpty(props.branchInfos) || isEmpty(props.playerInfo.regionName) || !isEmpty(subBranches.value)) return;

      subBranches.value = props.branchInfos[props.playerInfo.regionName];
      defaultMainBranch.value = branchMappedKo[props.playerInfo.regionName].label;
      defaultSubBranch.value = props.playerInfo.associationName;
    });

    const passInputValue = (e) => {
      const { name, value, label, type, files, dataset } = e.target;

      if (/regionName|associationName/g.test(name)) {
        emit(RESET_INPUT_ERROR, { infoType, name: 'associationName', value: label || value });
      }

      if (name === 'regionName') {
        subBranches.value = props.branchInfos[value];
        defaultMainBranch.value = props.regionSelects[0].label;
        defaultSubBranch.value = subBranches.value[0].label;

        emit(SET_INPUT, { infoType, name: 'associationName', value: subBranches.value[0].label });

        if (!props?.isStudentForm) {
          emit(SET_INPUT, { infoType, name: 'branchOfficeId', value: subBranches.value[0].id });
        }
      } else if (name === 'associationName') {
        emit(SET_INPUT, { infoType, name: 'associationName', value: label || value });

        if (!props?.isStudentForm) {
          emit(SET_INPUT, { infoType, name: 'branchOfficeId', value: value });
        }
      } else if (/selectedClassification|selectedSector|selectedEvent/g.test(name)) {
        const changedOption = name.replace('selected', '').toLowerCase();

        /** 종목 선택 클릭 시 이하 모든 종목은 초기화 된다.  */
        if (value === 'unSelect') {
          if (changedOption === 'classification') {
            sector.value = [defaultContestOption];
            event.value = [defaultContestOption];
          } else if (changedOption === 'sector') {
            event.value = [defaultContestOption];
          }
        } else if (name === 'selectedEvent') {
          if (value === undefined) {
            return;
          }
          emit(RESET_INPUT_ERROR, { infoType, name: 'contestItemIds' });
          emit(SET_INPUT, { infoType, name: `contestItemIds`, value });
        } else {
          fetchSectorByParentIds(value, changedOption);
        }
      } else if (type === 'file') {
        const { playerFiles, attachmentList } = props.playerInfo;
        // 같은 종류의 file 을 2번 업로드 했을 때 중복 제거
        for (let i = 0; i < playerFiles.length; i++) {
          if (playerFiles[i]?.category === dataset.category) {
            playerFiles.splice(i, 1);
            attachmentList.splice(i, 1);
          }
        }
        // todo 파일 resize
        emit(SET_INPUT, { infoType, name: 'playerFiles', value: playerFiles.concat({ category: dataset.category }) });
        emit(SET_INPUT, { infoType, name: 'attachmentList', value: attachmentList.concat(files[0]) });
        emit(RESET_INPUT_ERROR, { infoType, name });
      } else {
        emit(SET_INPUT, { infoType, name, value });
      }
    };

    const setInputError = (e) => {
      const { name, value } = e.target;

      const { isValid = true, message } = validator[name]?.(value) || {};

      if (!isValid) {
        emit(SET_INPUT_ERROR, { infoType, name, message });
      }
    };

    const resetError = (e) => {
      const { name } = e.target;
      emit(RESET_INPUT_ERROR, { infoType, name });
    };

    const onCompleteFindAddress = (address, zip) => {
      emit(RESET_INPUT_ERROR, { infoType, name: 'address' });
      emit(SET_INPUT, { infoType, name: 'address', value: address });
      emit(SET_INPUT, { infoType, name: 'zip', value: zip });
    };

    const branchSelects = computed(() => [props.regionSelects, [...subBranches.value]]);

    const onCloseSelectedContestItem = (contestItemId) => {
      emit(DELETE_CONTEST_ITEMS, contestItemId);
    };

    const targetContestId = computed(() => contestStore.targetContestId || getContestSeq());
    const bankAccountInformation = computed(() => contestInfoMap[targetContestId.value]?.bankAccountInformation || 'Account information not available');

    return {
      branchSelects,
      defaultMainBranch,
      defaultSubBranch,
      allContestEvents,
      classification,
      sector,
      event,
      selectedClassification,
      selectedSector,
      selectedEvent,
      FILE_CATEGORY,
      passInputValue,
      setInputError,
      resetError,
      onCompleteFindAddress,
      onCloseSelectedContestItem,
      bankAccountInformation,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-player__title {
  margin: 4px 0 32px;

  @include md-and-up {
    margin: 48px 0;
  }
}

.form-player:deep {
  .form__input-root + .form__input-root {
    margin-top: 32px;

    @include md-and-up {
      margin-top: 48px;
    }
  }
}

.bank__description {
  color: $blue-50;
  margin-top: 8px;
}

.contestItems-preview {
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid $gray-20;
  border-radius: 8px;
  width: 100%;
}
</style>
