<template>
  <section class="root-wrapper">
    <product-mall-layout>
      <template #main-section>
        <h2 class="main__title">매칭 영업점 찾기</h2>
        <p class="main__desc">지회에 매칭되어 있는 영업점을 검색할 수 있습니다.</p>
        <div class="search-bar__wrapper">
          <div class="search-bar">
            <v-autocomplete
              v-model="searchText"
              :items="branchNames"
              label="지회명이나 지부명으로 검색해보세요"
              variant="plain"
              :menu-props="menuProps"
              hide-details
              hide-selected
              clearable
              full-width
            />
          </div>
        </div>
        <button v-if="!!targetBranchInfo" class="card__wrapper" @click="goToMyBranch(targetBranchInfo.myBranchUrl)">
          <div class="card__title">{{ targetBranchInfo.name }}</div>
          <dl class="card__row">
            <dt>매칭 영업점 이름</dt>
            <dd style="color: #008485; font-weight: 700">{{ targetBranchInfo.salesOffice }}</dd>
          </dl>
          <dl class="card__row">
            <dt>매칭 영업점 연락처</dt>
            <dd>{{ targetBranchInfo.salesTel }}</dd>
          </dl>
          <div class="card__footer">
            클릭해서 이동하기<icon-base icon-color="#6c727f"><right-arrow /></icon-base>
          </div>
        </button>
        <div v-if="!targetBranchInfo" class="not-found__wrapper">
          <p class="not-found__info">
            지회나 지부명을 통해<br />
            매칭가능한 영업점을 검색해보세요
          </p>
        </div>
      </template>
    </product-mall-layout>
  </section>
</template>

<script>
import { ref, watch } from 'vue';
import RightArrow from '@/icons/RightArrow';
import ProductMallLayout from '@/containers/ProductMall/ProductMallLayout';
import branchInfo from '@/constants/branch';
import { useUserStore } from '@/stores';
import { onMounted } from '@vue/runtime-core';

import { isMobileTablet } from '@/utils/device';
import { infoToast } from '@/utils/toast';

const menuProps = {
  maxHeight: 304,
};

export default {
  name: 'SearchBankBranch',
  components: {
    ProductMallLayout,
    RightArrow,
  },
  setup() {
    const dialog = ref(false);
    const searchText = ref('');
    const targetBranchInfo = ref(null);
    const branchNames = branchInfo.map(({ name }) => name);
    const userStore = useUserStore();

    const goToMyBranch = (url) => {
      if (isMobileTablet(window.navigator)) {
        window.open(url, '_blank');
      } else {
        infoToast('영업점 이동은 모바일 혹은 태블릿에서만 가능합니다.');
      }
    };

    const showResultCard = () => {
      targetBranchInfo.value = branchInfo.find(({ name }) => name === searchText.value);
    };

    watch(searchText, (cur, prev) => {
      if (!cur || cur === prev) return;

      showResultCard();
    });

    onMounted(() => {
      setTimeout(() => {
        const branchOfficeId = userStore?.getUserView?.branchOfficeId;
        if (branchOfficeId) {
          const foundBranch = branchInfo.find(({ id }) => id === branchOfficeId);
          if (foundBranch) {
            targetBranchInfo.value = foundBranch;
          }
        }
      }, 500);
    });

    return { dialog, searchText, branchNames, targetBranchInfo, menuProps, goToMyBranch, showResultCard };
  },
};
</script>

<style lang="scss" scoped>
.root-wrapper {
  background: white;
  padding: 24px 20px;

  @include md-and-up {
    padding: 64px 0;
  }
}

.main {
  &__title {
    @include spreadTypoMap($title-small);
    color: $gray-70;
    margin-bottom: 8px;

    @include md-and-up {
      @include spreadTypoMap($title-large);
      color: $gray-70;
      margin-bottom: 16px;
    }
  }

  &__desc {
    word-break: keep-all;
    @include spreadTypoMap($body-small);
    color: $gray-50;
    margin-bottom: 16px;

    @include md-and-up {
      @include spreadTypoMap($body-medium);
      color: $gray-50;
      margin-bottom: 16px;
    }
  }
}

.search-bar__wrapper {
  margin-bottom: 20px;

  @include md-and-up {
    margin-bottom: 32px;
  }
}

.search-bar {
  display: flex;
  align-items: center;
  border-bottom: 2px solid $gray-30;
}

.card {
  &__wrapper {
    width: 100%;
    background: white;
    border: 1px solid $gray-20;
    border-radius: 8px;
    padding: 20px 16px 10px;

    @include md-and-up {
      padding: 32px 10px 10px 32px;
      min-height: 200px;
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    @include spreadTypoMap($body-xsmall);
    color: $gray-50;

    svg {
      display: block;
      width: 14px;
      height: 14px;
    }

    @include md-and-up {
      @include spreadTypoMap($body-large);
      color: $gray-50;

      svg {
        display: block;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.not-found {
  &__wrapper {
    background: $gray-10;
    border: 1px solid $gray-20;
    border-radius: 8px;
    padding: 20px 16px;
    text-align: center;

    @include md-and-up {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 200px;
    }
  }

  &__info {
    @include spreadTypoMap($body-xxsmall);
    color: $gray-50;
    word-break: keep-all;
    margin-bottom: 0;

    @include md-and-up {
      @include spreadTypoMap($body-xLarge);
      color: $gray-50;
    }

    br {
      display: block;

      @include md-and-up {
        display: none;
      }
    }
  }
}

.card {
  &__title {
    @include spreadTypoMap($title-xsmall);
    color: $black;
    margin-bottom: 16px;
    text-align: left;

    @include md-and-up {
      @include spreadTypoMap($title-small);
      color: $black;
    }
  }

  &__row {
    margin-bottom: 8px;
    text-align: left;

    dt {
      @include spreadTypoMap($body-xsmall);
      color: $gray-60;
    }
    dd {
      @include spreadTypoMap($body-xsmall);
      color: $gray-60;
      margin-bottom: 0;
    }

    @include md-and-up {
      display: flex;

      dt {
        width: 160px;
        margin-right: 24px;
        @include spreadTypoMap($body-xLarge);
        color: $gray-60;
      }

      dd {
        @include spreadTypoMap($body-xLarge);
        color: $gray-60;
      }
    }
  }

  &__btn {
    @include spreadTypoMap($body-xsmall);
    color: $gray-60;

    @include md-and-up {
      @include spreadTypoMap($body-xLarge);
      color: $gray-60;
    }
  }
}
</style>
