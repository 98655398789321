<template>
  <div class="wrapper">
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-row>
      <v-col md="4" sm="12">
        <v-select
          v-model="selectedCategory"
          :items="[]"
          class="mb-3"
          variant="outlined"
          label="카테고리"
          item-value="key"
          item-title="name"
          hide-details="auto"
          density="comfortable"
          disabled
        ></v-select>
      </v-col>
    </v-row>
    <v-text-field v-model="title" class="mb-3" label="제목" placeholder="제목을 입력하세요" variant="outlined" hide-details="auto" disabled></v-text-field>
    <div class="editor-wrapper">
      <post-editor ref="postEditor" disabled></post-editor>
      <v-file-input v-model="files" disabled variant="outlined" class="mt-5" multiple small-chips truncate-length="15"></v-file-input>
    </div>
    <div class="secret-wrapper">
      <v-checkbox v-model="isSecretPost" label="비밀글" hide-details></v-checkbox>
      <v-text-field
        v-if="isSecretPost"
        v-model="password"
        disabled
        label="게시글 비밀번호"
        type="password"
        variant="outlined"
        density="comportable"
        maxlength="10"
        counter
        autofocus
      ></v-text-field>
    </div>
    <v-divider></v-divider>
    <v-row justify="end">
      <v-col cols="auto">
        <v-btn class="cancel-btn" variant="outlined" size="x-large" disabled>취소</v-btn>
        <v-btn class="post-btn" size="x-large" disabled>게시하기</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from 'vue';
import PostEditor from '@/components/PostEditor.vue';

export default {
  components: { PostEditor },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const selectedCategory = ref('');
    const isSecretPost = ref(false);

    const postEditor = ref(null);

    const title = ref('');
    const password = ref('');
    const files = ref([]);

    return { files, selectedCategory, isSecretPost, postEditor, title, password };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-bottom: 1.5rem;

  @include sm-and-down {
    padding: 1rem;
  }
}

.secret-wrapper {
  display: flex;

  :deep(.v-checkbox) {
    max-width: 7rem;
  }
}

.editor-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cancel-btn {
  border: 1px solid $gray-30;
  margin-right: 0.5rem;
}

.post-btn {
  background-color: $blue-50;
  color: $gray-10;
}
</style>
