<template>
  <v-row>
    <v-col style="margin-top: 10px !important; margin-bottom: 10px !important">
      <h1 class="title">{{ source?.title }}</h1>
    </v-col>
  </v-row>
  <v-row justify="space-between">
    <v-col class="meta-row__col">
      <span>
        <v-icon>{{ mdiAccount }}</v-icon> 대한미용사회
      </span>
      <div class="meta-row__col d-flex justify-end">
        <span>
          <v-icon class="mr-1">{{ mdiCalendarMonth }}</v-icon>
          <span class="mr-2">{{ source?.date }}</span>
        </span>
      </div>
    </v-col>
  </v-row>
  <template v-if="isLoading"> 미용회보를 불러오는중입니다.. </template>
  <template v-else>
    <span style="flex-direction: row; display: flex; justify-content: center; align-items: center; margin-bottom: 20px !important; margin-top: 20px">
      <div class="pdf-page-button">
        <v-icon :disabled="page <= 1" @click="page > 1 ? page-- : () => {}">{{ mdiChevronLeft }}</v-icon>
      </div>
      <div style="margin-left: 10px; margin-right: 10px">{{ page }} / {{ numberOfPages }}</div>
      <div class="pdf-page-button">
        <v-icon :disabled="page >= numberOfPages" @click="page++">{{ mdiChevronRight }}</v-icon>
      </div>
    </span>
  </template>
  <vue-pdf-embed
    :scale="10"
    :disable-annotation-layer="true"
    :page="page"
    width="300"
    :source="source?.pdfUrl"
    style="margin-bottom: 20px"
    @loaded="finishedLoaded"
  />
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed';
import { ref } from 'vue';
import { mdiChevronRight, mdiChevronLeft, mdiAccount, mdiCalendarMonth, mdiEyePlus } from '@mdi/js';

export default {
  name: 'PdfViewer',
  components: {
    VuePdfEmbed,
  },
  props: {
    source: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const page = ref(1);
    const isLoading = ref(true);
    const numberOfPages = ref(1);

    const finishedLoaded = (e) => {
      isLoading.value = false;
      numberOfPages.value = e?._pdfInfo?.numPages;
    };

    return { page, isLoading, numberOfPages, finishedLoaded, mdiChevronLeft, mdiChevronRight, mdiAccount, mdiCalendarMonth, mdiEyePlus };
  },
};
</script>

<style scoped>
:deep(canvas) {
  width: 100% !important;
  height: calc(100% * 1.414) !important;
}

.pdf-page-button {
  border-radius: 5px;
  border-width: 1px;
  border-color: #272f3e;
  border-style: solid;
  padding: 10px;
}
</style>
