import { defineStore } from 'pinia';
import { branchMappedKo } from '@/constants/category';
import { getBranchOfficeList } from '@/apis/branch';
import { useLoadingStore } from '@/stores/index';

const STORE_ID = 'branch';
const FETCH_BRANCH_INFOS = 'FETCH_BRANCH_INFOS';

export const BRANCH_ACTION_NAME = {
  [FETCH_BRANCH_INFOS]: `${STORE_ID}/fetchBranchInfos`,
};

export default defineStore({
  id: STORE_ID,
  state: () => ({
    branchInfos: {},
  }),
  getters: {
    branchInfoArray: (state) => {
      return Object.values(state.branchInfos).flat();
    },
    defaultBranchSelectorInfos: (state) => {
      return {
        main: Object.values(branchMappedKo),
        sub:
          state.branchInfos[Object.values(branchMappedKo)[0].id]?.map((v) => ({
            id: v.id,
            label: v.branchName,
          })) ?? [],
      };
    },
  },
  actions: {
    async fetchBranchInfos() {
      const loadingStore = useLoadingStore();
      loadingStore.setLoadingState(BRANCH_ACTION_NAME.FETCH_BRANCH_INFOS, true);

      try {
        const branchInfos = await getBranchOfficeList();

        this.branchInfos = branchInfos;
      } catch (e) {
        //
      } finally {
        loadingStore.setLoadingState(BRANCH_ACTION_NAME.FETCH_BRANCH_INFOS, false);
      }
    },
    getSubBranch(mainBranchId) {
      return this.branchInfos[mainBranchId]?.map((v) => ({ id: v.id, label: v.branchName })) ?? [];
    },
    getByBranchOfficeId(branchOfficeId) {
      const mainBranchId = this.branchInfoArray.find((v) => v.id === Number(branchOfficeId)).region;

      return {
        selectedMainBranch: mainBranchId,
        sub: this.branchInfos[mainBranchId]?.map((v) => ({ id: v.id, label: v.branchName })) ?? [],
      };
    },
  },
});
