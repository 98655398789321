<template>
  <page title="게시글 작성">
    <template #main-section>
      <post-edit-view-loading v-if="postDetailLoading" :breadcrumbs="breadcrumbs" />
      <component
        :is="renderer.component"
        v-else
        :breadcrumbs="breadcrumbs"
        :sub-category="(menuStore.getMenuMap[16] || []).filter((e) => e.writeAuthority)"
        :post-detail-data="postDetailData"
        :post-authority="postAuthority"
        :is-secure-post="isSecurePost"
      >
      </component>
      <secure-post-password-dialog
        :secure-post-dialog="securePostDialog"
        :get-secure-post="getSecurePost"
        :post-password="postPassword"
        :go-back="goBackToPost"
        go-back-text="게시글로 돌아가기"
        go-text="수정하러 가기"
        @update:postPassword="postPassword = $event"
      />
    </template>
  </page>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PostEditView from '@/containers/Post/PostEditView.vue';
import Page from '@/components/Page.vue';

import { useBranchStore, useMenuStore } from '@/stores';
import { getPostSpecificAPI } from '@/apis/__post__';
import PostEditViewLoading from '@/containers/Post/PostEditViewLoading';
import SecurePostPasswordDialog from '@/containers/Post/SecurePostPasswordDialog.vue';

import BranchOfficePostEdit from '@/containers/Post/BranchOffice/BranchOfficePostEdit.vue';
import ProblemHandlingPostEdit from '@/containers/Post/ProblemHandling/ReplyPostEdit.vue';

const DEFAULT_RENDER_INFO = {
  component: PostEditView,
};

const RENDER_INFO = {
  19: {
    component: BranchOfficePostEdit,
  },
  21: {
    component: ProblemHandlingPostEdit,
  },
};

/**
 * new post : /write?communityId=aaa
 * edit post : /write?postId=123
 */
export default {
  components: { PostEditViewLoading, PostEditView, Page, SecurePostPasswordDialog },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const branchStore = useBranchStore();

    const query = computed(() => route.query);

    const postId = query.value.postId;
    const communityId = query.value.communityId;
    const branchOfficeId = query.value.branchOfficeId ?? '';
    const renderer = ref(RENDER_INFO[communityId] ?? DEFAULT_RENDER_INFO);

    const branchOfficeName = ref('');

    const isSecurePost = ref(route.query.type === 'secure');

    const menuStore = useMenuStore();

    const breadcrumbs = [
      { text: '홈', href: '/', disabled: false },
      { text: '자료&게시판', href: '#', disabled: true },
      { text: '게시글 작성', href: '#', disabled: true },
    ];

    const securePostDialog = ref(isSecurePost.value);
    const postPassword = ref('');

    const postDetailLoading = ref(!!postId);
    const postDetailData = ref({
      boardId: communityId ?? '',
      body: '',
      postFileViewList: [],
      createdDate: '',
      id: '',
      thumbnail: '',
      title: '',
      type: '',
      userId: 0,
    });

    onMounted(() => {
      if (Number(communityId === 19)) {
        initBranchData();
      }
    });

    const postAuthority = computed(() => ({
      write: menuStore.getWriteAuthorityMap[`/community/${communityId}`]?.writeAuthority,
      fixed: menuStore.getWriteAuthorityMap[`/community/${communityId}`]?.writeFixedPostAuthority,
    }));

    const initBranchData = async () => {
      await branchStore.fetchBranchInfos();
      branchOfficeName.value = branchStore.branchInfoArray?.find((v) => v.id === Number(branchOfficeId))?.branchName || '';
    };

    const getSecurePost = async () => {
      try {
        const postData = await getPostSpecificAPI(postId, { password: postPassword.value });
        postDetailData.value = postData;
        postDetailLoading.value = false;
        securePostDialog.value = false;
        isSecurePost.value = true;
      } catch (e) {
        // console.log(e);
      }
    };

    const goBackToPost = async () => {
      router.push(`/post/${postId}?type=secure`);
    };

    const getPostSpecific = async () => {
      try {
        const postData = await getPostSpecificAPI(postId);

        renderer.value = RENDER_INFO[postData.boardId] ?? DEFAULT_RENDER_INFO;
        postDetailData.value = postData;
        postDetailLoading.value = false;
      } catch (e) {
        // 비밀글 게시글에 URL로 바로 접속한 경우
        if (e.code === -4004) {
          securePostDialog.value = true;
        }
      }
    };

    if (postId && !isSecurePost.value) {
      getPostSpecific();
    }

    return {
      postId: query.postId,
      securePostDialog,
      goBackToPost,
      getSecurePost,
      postPassword,
      breadcrumbs,
      menuStore,
      postDetailLoading,
      postDetailData,
      branchOfficeName,
      renderer,
      postAuthority,
      isSecurePost,
    };
  },
};
</script>

<style></style>
