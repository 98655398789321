import axios from '@/lib/axiosController';

/**
 * 대회 선수 등록
 */
export const uploadPlayer = (body) =>
  axios.post('/player/upload', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

/**
 * 대회 선수 정보 수정
 */
export const modifyPlayer = (playerId, body) =>
  axios.put(`/player/${playerId}`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

/**
 * 대회 선수 상세 정보 조회 API
 *
 * @param {number} contestId
 * @param {string} phoneNumber
 * @param {string} playerName
 * @param {string} password
 * @returns
 */
export const getPlayerDetail = (contestId, playerName, phoneNumber, password) =>
  axios.post(`/player/contest/${contestId}/find`, {
    phoneNumber,
    playerName,
    password,
  });
