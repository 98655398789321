export const MARKET_TYPE = {
  SELL: {
    ID: 'SELL',
    NAME: '판매',
    COLOR: 'primary',
  },
  BUY: {
    ID: 'BUY',
    NAME: '구매',
    COLOR: 'orange',
  },
  DONATE: {
    ID: 'DONATE',
    NAME: '나눔',
    COLOR: 'green',
  },
};
