<template>
  <page title="게시글 작성">
    <template #main-section>
      <post-edit-view :breadcrumbs="breadcrumbs" :sub-category="menuStore.getMenuMap[16]"></post-edit-view>
    </template>
  </page>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import PostEditView from '@/containers/Post/UsedMarket/PostEditView.vue';
import Page from '@/components/Page.vue';

import { useMenuStore } from '@/stores';

/**
 * new post : /write?mainCategory=aaa
 * edit post : /write?postId=123
 */
export default {
  components: { PostEditView, Page },
  setup() {
    const route = useRoute();

    const query = computed(() => route.query);

    const postId = query.value.postId;

    if (postId) {
      // TODO: postId 기반으로 데이터 받아오기
    }
    const menuStore = useMenuStore();

    const breadcrumbs = [
      { text: '홈', href: '/', disabled: false },
      { text: '자료&게시판', href: '#', disabled: true },
      { text: '중고매매', href: '#', disabled: true },
      { text: '게시글 작성', href: '#', disabled: true },
    ];

    return {
      postId: query.postId,
      breadcrumbs,
      menuStore,
    };
  },
};
</script>

<style></style>
