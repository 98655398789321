<template>
  <div class="mb-12 vertical-card" style="width: 100%">
    <img class="thumb" :src="src" width="300" height="200" />
    <v-chip-group v-if="tags.length >= 1" class="pa-0">
      <template v-for="(tag, idx) in tags" :key="idx">
        <v-chip class="chip">{{ tag.name }}</v-chip>
      </template>
    </v-chip-group>
    <h4 class="text__title-xsmall" style="color: #ffffff; margin: 16px 0 0">{{ title }}</h4>
    <p v-if="subText" class="text__body-small sub-text">{{ subText }}</p>
  </div>
</template>

<script>
export default {
  name: 'VerticalCard',
  components: {},
  props: {
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subText: {
      type: String,
      required: false,
      default: '',
    },
    to: {
      type: String,
      required: true,
      default: '/',
    },
    tags: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.thumb {
  object-fit: cover !important;
  object-position: center;
  margin-bottom: 17px;
  border-radius: 4px;
}

.vertical-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  box-shadow: none;
  background: #272f3e;
  padding: 16px;
}

.chip {
  background: #394150;
  color: #d3d6db;
  border-radius: 100px;
  padding: 8px 24px;
  margin-right: 8px;
}

.sub-text {
  color: #6c727f;
  margin: 16px 0 0;
}
</style>
