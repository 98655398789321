<template>
  <input-wrapper-grid :required="required" :label="label" :helper-text="helperText" :error-text="errorText" :label-for="yearAttr.id" :grid-items-length="3">
    <template #form-input>
      <div class="form__input-wrapper form__input-deep-wrapper" :class="{ 'form__input-wrapper-focus': isFocus.year, 'form__input-wrapper-error': errorText }">
        <input
          v-bind="attrs"
          :id="yearAttr.id"
          :name="yearAttr.name"
          :value="yearAttr.value"
          data-input-name="year"
          type="number"
          data-type="number"
          placeholder="년"
          min="1800"
          max="3000"
          inputmode="numeric"
          @focus="onFocus"
          @blur="onBlur"
          @input="onInput"
          @keydown="onKeydown"
        />
      </div>
      <div class="form__input-wrapper form__input-deep-wrapper" :class="{ 'form__input-wrapper-focus': isFocus.month, 'form__input-wrapper-error': errorText }">
        <input
          v-bind="attrs"
          :id="monthAttr.id"
          :name="monthAttr.name"
          :value="monthAttr.value"
          data-input-name="month"
          type="number"
          data-type="number"
          placeholder="월"
          min="1"
          max="12"
          inputmode="numeric"
          @focus="onFocus"
          @blur="onBlur"
          @input="onInput"
          @keydown="onKeydown"
        />
      </div>
      <div class="form__input-wrapper form__input-deep-wrapper" :class="{ 'form__input-wrapper-focus': isFocus.day, 'form__input-wrapper-error': errorText }">
        <input
          v-bind="attrs"
          :id="dayAttr.id"
          :name="dayAttr.name"
          :value="dayAttr.value"
          data-input-name="day"
          type="number"
          data-type="number"
          placeholder="일"
          min="1"
          max="31"
          inputmode="numeric"
          @focus="onFocus"
          @blur="onBlur"
          @input="onInput"
          @keydown="onKeydown"
        />
      </div>
    </template>
  </input-wrapper-grid>
</template>

<script>
import InputWrapperGrid from '@/components/form/InputWrapperGrid';
import { reactive, computed } from 'vue';

export default {
  name: 'BirthdayInput',
  components: { InputWrapperGrid },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    helperText: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
  },
  setup(props, { attrs }) {
    const isFocus = reactive({
      year: false,
      month: false,
      day: false,
    });

    const splitId = attrs.id.split('.');
    const name = splitId.length <= 1 ? splitId[0] : splitId[1];

    const yearAttr = computed(() => ({
      id: `${attrs.id}.year`,
      name: `${name}.year`,
      value: attrs.value.year,
    }));

    const monthAttr = computed(() => ({
      id: `${attrs.id}.month`,
      name: `${name}.month`,
      value: attrs.value.month,
    }));

    const dayAttr = computed(() => ({
      id: `${attrs.id}.day`,
      name: `${name}.day`,
      value: attrs.value.day,
    }));

    const onFocus = (e) => {
      const inputName = e.target.dataset.inputName;
      isFocus[inputName] = true;

      attrs?.onFocus?.(e);
    };

    const onBlur = (e) => {
      const inputName = e.target.dataset.inputName;
      isFocus[inputName] = false;

      attrs?.onBlur?.(e);
    };

    const onInput = (e) => {
      const {
        dataset: { type },
        name,
        value,
      } = e.target;

      if (type === 'number' && value && isNaN(value)) {
        attrs?.onInput?.({ target: { name, value: value.replace(/[^0-9]/g, '') } });
        return;
      }

      attrs?.onInput?.(e);
    };

    const onKeydown = (e) => {
      //
      attrs?.onKeydown?.(e);
    };

    return { attrs, yearAttr, monthAttr, dayAttr, isFocus, onFocus, onBlur, onInput, onKeydown };
  },
};
</script>

<style lang="scss" scoped>
.form__inputs-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}

.form__input-wrapper {
  padding: 12px 0 10px;
  border-bottom: 1px solid $gray-30;
  transition: border-bottom-color 0.3s ease-in-out;

  &-error {
    border-bottom-color: $koba-red;
  }

  &-focus {
    border-bottom-color: $gray-50;
  }
}

.form__input-deep-wrapper {
  input {
    width: 100%;
  }

  input:focus {
    outline: none;
  }

  input::placeholder {
    @include spreadTypoMap($body-medium);
    color: $gray-30;
  }
}
</style>
