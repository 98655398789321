<template>
  <div class="process">
    <div v-for="(item, idx) in items" :key="item.id" class="process__tab" :class="{ 'process__tab-active': item.id === activeId }">
      <span>{{ `${idx + 1}.${item.label}` }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activeId: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.process {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $gray-30;

  &__tab {
    @include spreadTypoMap($body-medium);
    padding: 1rem 0.75rem;
    text-align: center;
    width: 100%;

    @include sm-and-down {
      @include spreadTypoMap($body-small);
    }

    &-active {
      background: $blue-00;
      color: $blue-50;
    }
  }
}
</style>
