<template>
  <section class="root-wrapper">
    <product-mall-layout>
      <template #main-section>
        <h2 class="main__title">사)대한미용사회중앙회 X <hana-logo /></h2>
        <p class="main__desc">
          사)대한미용사회중앙회는 미용을 업으로 하는 미용인들이<br class="br_md-down" />
          미용업 발전 및 기술향상과 회원 상호간의<br class="br_md-up" />친목을 도모하여 공중위생과 국민보건의 향상에 기여함을 목적으로 결성된 단체입니다.
        </p>
        <div class="tabs__wrapper deep__wrapper">
          <tab2 v-model="selectedTab" :tabs="tabs" slider-color="white" />
        </div>
        <v-window v-model="selectedTab">
          <v-window-item v-for="(tabCards, index) in cards" :key="index">
            <div class="cards__wrapper">
              <div v-for="(tabCard, idx) in tabCards" :key="idx" class="cards__body">
                <div class="cards__label">{{ tabCard.label }}</div>
                <h3 class="cards__title-wrapper">
                  <strong v-for="(title, titleIdx) in tabCard.title.split('\n')" :key="titleIdx" class="cards__title" style="display: block">{{
                    title
                  }}</strong>
                </h3>
                <p v-if="tabCard.description" class="cards__desc-wrapper">
                  <template v-if="selectedTab === 2 && tabCard.label === '혜택3'">
                    <a href="tel:1566-7322" type="tel" class="cards__desc">상세 문의: (주) 씨앤씨밴 1566-7322</a>
                  </template>
                  <template v-else>
                    <span v-for="(desc, descIdx) in tabCard.description.split('\n')" :key="descIdx" class="cards__desc">
                      {{ desc }}
                    </span>
                  </template>
                </p>
              </div>
            </div>
          </v-window-item>
        </v-window>
      </template>
    </product-mall-layout>
  </section>
</template>

<script>
import { ref } from 'vue';
import ProductMallLayout from '@/containers/ProductMall/ProductMallLayout.vue';
import HanaLogo from '@/icons/HanaLogo.vue';
import Tab2 from '@/components/Tab2.vue';
import { tabs, cards } from '@/constants/ProductMall';

export default {
  name: 'ProductMallInfo',
  components: { ProductMallLayout, Tab2, HanaLogo },
  setup() {
    const selectedTab = ref(0);

    return { selectedTab, tabs, cards };
  },
};
</script>

<style lang="scss" scoped>
.root-wrapper {
  background: $hana-green-80;
  padding: 24px 20px;

  @include md-and-up {
    padding: 96px 0;
  }
}

.main {
  &__title {
    @include spreadTypoMap($title-small);
    color: white;
    margin-bottom: 8px;

    @include md-and-up {
      @include spreadTypoMap($title-large);
      color: white;
      margin-bottom: 30px;
    }

    svg {
      width: 40%;

      @include md-and-up {
        width: auto;
      }
    }
  }

  &__desc {
    word-break: keep-all;
    @include spreadTypoMap($body-small);
    color: $gray-30;
    margin-bottom: 16px;

    @include md-and-up {
      @include spreadTypoMap($body-medium);
      color: $gray-30;
      margin-bottom: 48px;
    }

    .br_md-down {
      display: block;

      @include md-and-up {
        display: none;
      }
    }

    .br_md-up {
      display: none;

      @include md-and-up {
        display: block;
      }
    }
  }
}

.tabs__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  @include md-and-up {
    margin-bottom: 48px;
  }
}

.deep__wrapper:deep {
  .custom-tab-group {
    margin-top: 8px;
    background: transparent !important;
    width: 100%;

    @include md-and-up {
      width: fit-content;
    }
  }

  .tab:first-of-type {
    margin-inline-start: 0 !important;
  }

  .custom-tab {
    @include spreadTypoMap($caption-medium);
    color: white;

    @include md-and-up {
      @include spreadTypoMap($caption-large);
      color: white;
    }
  }

  .custom-tab-group button[aria-selected='true'] {
    font-weight: 700;
    color: white;
  }
}

.cards {
  &__wrapper {
    @include md-and-up {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 16px;
    }
  }

  &__body {
    background: white;
    border: 1px solid $gray-20;
    backdrop-filter: blur(20px);
    border-radius: 8px;
    padding: 20px 16px;

    @include md-and-up {
      padding: 24px;
      min-height: 280px;
    }

    & + & {
      margin-top: 8px;

      @include md-and-up {
        margin-top: 0;
      }
    }
  }

  &__label {
    @include spreadTypoMap($body-xsmall);
    color: $hana-green-80;
    margin-bottom: 4px;

    @include md-and-up {
      @include spreadTypoMap($body-large);
      color: $hana-green-80;
      margin-bottom: 8px;
    }
  }

  &__title-wrapper {
    margin-bottom: 4px;

    @include md-and-up {
      margin-bottom: 24px;
    }
  }

  &__title {
    @include spreadTypoMap($title-xsmall);
    color: $gray-60;

    @include md-and-up {
      @include spreadTypoMap($title-medium);
      color: $gray-60;
    }
  }

  &__desc-wrapper {
    margin-bottom: 0;
  }

  &__desc {
    display: block;
    @include spreadTypoMap($body-xsmall);
    color: $gray-50;

    @include md-and-up {
      @include spreadTypoMap($body-medium);
      color: $gray-50;
    }
  }
}
</style>
