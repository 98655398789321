<template>
  <router-link :to="`/news-detail/${newsId}`" class="subTitleRow_wrapper">
    <div class="aspect-square pc:w-[160px] mobile:w-[120px]"><img class="w-full h-full object-cover aspect-[inherit]" :src="src" /></div>
    <div class="subTitleRow_info pc:w-[calc(100% - 160px - 16px)] mobile:w-[calc(100% - 120px - 16px)]">
      <div class="rank">{{ rank }}위</div>
      <h5 class="title text-truncate">
        {{ title }}
      </h5>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    newsId: {
      type: Number,
      required: true,
    },
    rank: {
      type: Number,
      default: 1,
    },
    src: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.subTitleRow_wrapper {
  @apply flex gap-[16px] no-underline w-full h-full p-0;
}

.subTitleRow_info {
  width: calc(100% - 160px);
}

.rank {
  @apply pc:text-caption-medium text-gray-50 mb-[16px];
}

.title {
  @apply pc:text-title-xxsmall text-black mobile:text-title-xsmall;
}
</style>
