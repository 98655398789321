<template>
  <article-wrapper>
    <article-title>Photo News</article-title>
    <slide-group-vue :slides="data" />
  </article-wrapper>
</template>

<script>
import SlideGroupVue from '@/components/SlideGroup.vue';
import { ArticleWrapper, ArticleTitle } from '@/containers/Main/components';
import { dayjs } from '@/utils/date';

export default {
  components: { ArticleTitle, ArticleWrapper, SlideGroupVue },
  props: {
    photoArticles: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const data =
      props.photoArticles.map((v) => ({
        id: v.articleId,
        src: v.thumbnail,
        title: v.title,
        subtitle: dayjs(v.createdDate).format('YYYY.MM.DD'),
        description: v.description,
      })) ?? [];

    return {
      data,
    };
  },
};
</script>

<style lang="scss" scoped></style>
