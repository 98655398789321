<template>
  <div ref="editor"></div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { debounce } from 'lodash-es';
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/i18n/ko-kr';

export default {
  props: {
    updateContent: {
      type: Function,
      default: () => {},
    },
    initialValue: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const editor = ref();

    onMounted(() => {
      const e = new Editor({
        el: editor.value,
        height: '600px',
        initialEditType: 'wysiwyg',
        initialValue: props.initialValue,
        events: {
          change: debounce(() => {
            props.updateContent(e.getHTML());
          }, 300),
        },
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'image', 'link'],
        ],
        language: 'ko-KR',
        placeholder: '내용을 입력하세요.',
        hideModeSwitch: true,
        autofocus: true,
      });
    });

    return { editor };
  },
};
</script>

<style scoped>
.toastui-editor {
  font-size: 16px;
}
</style>
