<template>
  <svg :height="height" viewBox="0 0 176 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_928_6507)">
      <path d="M81.662 11.8572H76.582V43.5142H81.662V23.2724H86.3716V20.4403H81.662V11.8572Z" fill="white" />
      <path
        d="M66.9792 11.0984H61.9056V14.0554H54.9883V16.8917H59.719C57.1621 18.7735 55.4539 22.1242 55.4539 25.9596C55.4539 31.8545 59.4756 36.6318 64.4435 36.6318C69.4049 36.6318 73.4372 31.8545 73.4372 25.9596C73.4372 22.1242 71.7269 18.7735 69.1679 16.8917H73.9135V14.0554H66.9792V11.0984ZM64.4816 33.7976C62.2993 33.804 60.517 30.3009 60.5107 25.9744C60.5043 21.65 62.2718 18.1469 64.4625 18.1385C66.6512 18.1363 68.4313 21.6394 68.4334 25.9596C68.4334 30.2797 66.6744 33.7976 64.4816 33.7976Z"
        fill="white"
      />
      <path
        d="M117.595 20.4393H112.892V11.8562H107.811V31.0015C106.975 31.8228 105.688 32.6906 103.934 32.661H95.8586C94.6585 32.661 93.6975 31.7 93.6975 30.521L93.706 13.2913H88.6641L88.6852 30.521C88.6852 33.277 90.9437 35.5164 93.7314 35.5164L102.473 35.5185C102.473 35.5185 105.559 35.6709 107.811 33.6135V43.5132H112.892V23.2672H117.595V20.4393Z"
        fill="white"
      />
      <path
        d="M140.587 20.293C140.587 15.2278 136.561 11.1214 131.599 11.1214C126.632 11.1214 122.608 15.2278 122.608 20.293C122.608 23.4637 124.187 26.2577 126.589 27.9045H117.27V30.8636H121.162L121.177 38.4752C121.177 41.2396 123.435 43.4769 126.225 43.4769L143.345 43.4854V40.6236H128.348C127.165 40.6236 126.196 39.6648 126.196 38.4752L126.204 30.8636H145.694V27.9045H136.605C139.004 26.2577 140.587 23.4637 140.587 20.293ZM131.739 13.9832C133.936 13.9726 135.725 16.7878 135.742 20.2845C135.767 23.7728 134.01 26.6176 131.822 26.6303C129.635 26.643 127.842 23.8215 127.825 20.3332C127.796 16.8386 129.559 13.9916 131.739 13.9832Z"
        fill="white"
      />
      <path
        d="M161.459 23.2901C161.459 20.6782 160.32 18.3541 158.559 16.8936H161.74V14.0572H156.726V11.0981H151.648V14.0572H146.598V16.8936H149.8C148.037 18.3541 146.9 20.6782 146.9 23.2901C146.9 27.7309 150.158 31.3208 154.175 31.3208C158.205 31.3208 161.459 27.7309 161.459 23.2901ZM154.203 28.4654C152.958 28.4781 151.938 26.1667 151.917 23.3113C151.896 20.4517 152.888 18.1339 154.137 18.1297C155.382 18.117 156.411 20.4284 156.432 23.2838C156.44 26.1392 155.443 28.4569 154.203 28.4654Z"
        fill="white"
      />
      <path
        d="M175.65 35.1052V11.8811H170.851V18.2036L168.534 18.193V11.8578H163.701V26.7168C160.196 27.972 157.684 31.3629 157.684 35.3656C157.684 40.4329 161.703 44.5308 166.677 44.5308C171.637 44.5308 175.663 40.4329 175.663 35.3656C175.663 35.2788 175.65 35.192 175.65 35.1052ZM170.851 21.0315V27.2502C170.132 26.8629 169.353 26.5687 168.534 26.3972V21.023L170.851 21.0315ZM166.766 41.5865C164.571 41.5928 162.793 38.7671 162.785 35.2809C162.772 31.7863 164.537 28.9478 166.72 28.9436C168.906 28.933 170.697 31.7588 170.705 35.2534C170.714 38.7459 168.949 41.5865 166.766 41.5865Z"
        fill="white"
      />
      <path
        d="M34.5524 28.4453C34.4275 27.713 34.2307 27.0039 33.9365 26.3477C33.0093 24.3453 31.7203 22.6668 29.7814 21.5365C26.7736 19.7797 23.1351 19.7924 20.1569 21.5979C18.8107 22.4107 17.7016 23.5833 16.9861 24.9909C16.7448 25.4693 16.548 25.9688 16.3871 26.479C15.6018 28.9724 15.6315 32.3273 17.0454 34.6027C17.5068 35.3478 18.1736 35.9807 18.9885 36.2982C19.8055 36.6115 20.7729 36.5797 21.5073 36.1013C22.4556 35.4917 22.6228 34.3212 22.4471 33.2841C22.2249 31.9611 22.0556 30.6551 22.5403 29.3576C22.699 28.9428 22.9192 28.5448 23.2367 28.2358C23.4314 28.0516 23.66 27.8971 23.9034 27.7828C24.4157 27.5309 25.002 27.4188 25.565 27.5119C26.5535 27.6728 27.3578 28.4348 27.7981 29.3343C28.2341 30.2297 28.3527 31.2457 28.4014 32.2427C28.6067 36.5882 27.5695 41.0903 26.4625 45.2623C26.3482 45.6941 26.2296 46.1217 26.109 46.5492C26.0116 46.8794 25.8465 47.2985 26.054 47.6224C26.145 47.7579 26.2889 47.8552 26.4413 47.9166C27.0869 48.177 27.6118 47.7833 28.0436 47.3472C30.5921 44.7839 32.2241 41.4777 33.3417 38.0783C33.7586 36.8104 34.1164 35.515 34.3577 34.2006C34.6858 32.3993 34.8741 30.3419 34.5524 28.4453Z"
        fill="white"
      />
      <path
        d="M52.7931 8.35965C52.7931 7.70771 52.2174 7.27803 51.5951 7.29708C50.9665 7.32248 50.3823 7.6421 49.8044 7.86646C38.5966 12.1252 25.958 12.9317 14.1469 11.232C12.1255 10.9399 10.0787 10.5335 8.04668 10.216C6.77879 10.017 5.51937 9.72914 4.23878 9.63389C3.14658 9.54922 1.78344 9.51112 0.900785 10.2689C-0.263386 11.2764 -0.180834 13.1835 0.479568 14.4451C1.27332 15.9564 2.66186 17.0888 4.10755 17.9524C5.5109 18.7885 7.18731 19.6817 8.8616 19.5759C13.3743 19.288 17.8765 18.852 22.3639 18.2699C30.0135 17.2751 38.0357 16.3374 45.2218 13.3698C47.5713 12.3983 49.8891 11.1896 51.902 9.62542C52.2682 9.34814 52.7931 8.88882 52.7931 8.35965Z"
        fill="white"
      />
      <path
        d="M20.6484 4.51936C20.6484 6.95353 22.6254 8.92627 25.0596 8.92627C27.4916 8.92627 29.4665 6.95353 29.4665 4.51936C29.4665 4.1574 29.3839 3.79334 29.2273 3.46525C28.8505 2.66303 28.4251 2.27145 28.4695 1.31471C28.5246 0.0171909 26.8651 0.000258446 25.9698 0.000258446C24.9961 0.000258446 23.9589 0.135726 23.0805 0.578111C22.3799 0.933712 21.7766 1.47558 21.3512 2.13598C20.9109 2.8112 20.6696 3.6113 20.6506 4.41776C20.6484 4.45374 20.6484 4.48549 20.6484 4.51936Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_928_6507">
        <rect width="175.662" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'HanaLogo',
  props: {
    height: {
      type: Number,
      default: 48,
    },
  },
};
</script>
