<template>
  <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 24px">
    <div style="display: flex"></div>
  </div>
  <table style="margin-top: 24px; width: 100%">
    <tr class="table-header-row">
      <th v-for="tableHeader in tableHeaders" :key="tableHeader.id" class="table-header" :data-th-id="tableHeader.id">{{ tableHeader.label }}</th>
    </tr>
    <tr v-if="posts.length === 0">
      <td colspan="5">
        <empty-data-card />
      </td>
    </tr>
    <tr v-for="post in posts" :key="post.id" class="table-row" :data-post-id="post.id" @click="goDetailReport(post.id)">
      <td>{{ post.id }}</td>
      <td class="post-title"><v-icon v-if="post.isSecure" :icon="mdiLock" size="x-small" start></v-icon>{{ post.title }}</td>
      <td>{{ post.author }}</td>
      <td>{{ toYYYYMMDD(post.createdDate) }}</td>
    </tr>
  </table>
  <div class="pagination-wrapper">
    <v-pagination v-model="currentPage" :length="pages || 1" />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useDisplay } from 'vuetify';
import { useRouter } from 'vue-router';
import { mdiLock } from '@mdi/js';
import EmptyDataCard from '@/components/EmptyDataCard.vue';
import { useUserStore } from '@/stores';
import { toYYYYMMDD } from '@/utils/date';

export default {
  name: 'RegularReportBoardPc',
  components: { EmptyDataCard },
  props: {
    selectedOptions: {
      type: Array,
      required: true,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    posts: {
      type: Array,
      default: () => [],
    },
    hideWriteButton: {
      type: Boolean,
      default: () => false,
    },
    pages: {
      type: Number,
      default: () => 0,
    },
    onPageChange: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const router = useRouter();
    const currentPage = ref(1);
    const userStore = useUserStore();

    const { name } = useDisplay();

    const btnSize = computed(() => (name.value === 'md' ? 'large' : 'x-large'));

    const goDetailReport = (id) => {
      router.push(`/regular-report/${id}`);
    };

    const initPage = () => {
      currentPage.value = 1;
    };

    watch(currentPage, (current, prev) => {
      props.onPageChange.call(this, current);
    });
    return { currentPage, btnSize, goDetailReport, mdiLock, initPage, userStore, toYYYYMMDD };
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/custom/customVPagination';

.input-wrapper {
  margin-left: 8px;
  min-width: 352px;
  background: white;
  border-bottom: 1px solid $gray-30;
  padding: 10px 0;
  display: flex;
}

.text-input {
  min-width: 300px;
  &:focus {
    outline: none;
  }
}

.view-more-btn {
  @include spreadTypoMap($button-medium);

  border: 1px solid $gray-30;
  border-radius: 4px;
  margin-right: 8px;
}

.add-btn {
  @include spreadTypoMap($button-medium);
  color: white;

  background: $blue-50;
  border-radius: 4px;

  @include md-and-down {
    padding: 0.5rem;
  }

  &__text {
    @include md-and-down {
      display: none;
    }
  }
}

// 테이블
.table-header-row {
  background: $blue-50;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: white;
}

.table-header {
  padding: 17px 18px;
  text-align: center;

  &[data-th-id='title'] {
    text-align: left;
  }
}

.table-row {
  background: white;
  border-bottom: 1px solid $gray-20;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  & > td {
    padding: 17px 18px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $gray-80;
    text-align: center;
  }

  .post-title {
    font-size: 16px;
    color: $black;
    text-align: left;
  }
}

.pagination-wrapper:deep {
  margin-top: 24px;
  margin-bottom: 48px;

  @include custom-v-pagination;
}
</style>
