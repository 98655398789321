<template>
  <div class="wrapper">
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-row>
      <v-col md="4" sm="12">
        <v-select
          v-model="selectedCategory"
          :items="subCategory"
          class="mb-3"
          variant="outlined"
          label="카테고리"
          item-value="key"
          item-title="name"
          hide-details="auto"
          density="comfortable"
          :disabled="postDetailData.id"
          return-object
        ></v-select>
      </v-col>
    </v-row>

    <div class="mb-6 text__body-small text-red">"{{ branchOfficeName }}" 게시판에 글이 작성됩니다.</div>

    <v-text-field v-model="title" class="mb-3" label="제목" placeholder="제목을 입력하세요" variant="outlined" hide-details="auto"></v-text-field>
    <div class="editor-wrapper">
      <post-editor ref="postEditor" :initial-value="initialValue" :update-content="updateContent"></post-editor>
      <v-file-input v-model="files" variant="outlined" class="mt-5" multiple chips @update:modelValue="changeFile" @click:clear="deleteAllFile">
        <template #selection>
          <template v-for="file in files" :key="file.id">
            <v-chip size="small" label color="primary" class="mr-2 mb-2">
              <span class="chip_name">{{ file.name || file.data.name }}</span>
              <button type="button" style="margin-left: 4px" @click="(e) => deleteFile(e, file.id)">x</button>
            </v-chip>
          </template>
        </template>
      </v-file-input>
    </div>
    <div class="secret-wrapper">
      <v-checkbox v-model="isSecretPost" label="비밀글" hide-details></v-checkbox>
      <v-checkbox v-if="isAbleFixedPost" v-model="isFixedPost" label="고정글" hide-details></v-checkbox>
      <v-text-field
        v-if="isSecretPost"
        v-model="password"
        label="게시글 비밀번호"
        type="password"
        variant="outlined"
        density="comportable"
        maxlength="10"
        counter
        autofocus
      ></v-text-field>
    </div>
    <v-divider></v-divider>
    <v-row justify="end">
      <v-col cols="auto">
        <v-btn class="cancel-btn" variant="outlined" size="x-large" @click="onClickCancel">취소</v-btn>
        <v-btn v-if="!postDetailData.id" class="post-btn" size="x-large" :loading="buttonLoading" :disabled="buttonLoading" @click="onClickPost"
          >게시하기</v-btn
        >
        <v-btn v-else class="post-btn" size="x-large" :loading="buttonLoading" :disabled="buttonLoading" @click="onClickEditPost">수정하기</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import PostEditor from '@/components/PostEditor.vue';
import { editPostAPI, uploadPostAPI } from '@/apis/__post__';
import { successToast, warningToast } from '@/utils/toast';
import { useBranchStore, useUserStore } from '@/stores';

import { getBranchOfficeBoardRWFRole } from '@/utils/community';

// import {getBranchOfficeName} from '@/'

export default {
  name: 'BranchOfficePostEdit',
  components: { PostEditor },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    subCategory: {
      type: Array,
      default: () => [],
    },
    postDetailData: {
      type: Object,
      default: () => {},
    },
    isSecurePost: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const branchStore = useBranchStore();
    const userStore = useUserStore();

    const branchOfficeId = props.postDetailData.branchOfficeId ?? route.query.branchOfficeId;
    const selectedCategory = ref(props.postDetailData.boardId);
    const isSecretPost = ref(!!props.isSecurePost);
    const isFixedPost = ref(!!props.postDetailData.isFixed);
    const isAbleFixedPost = ref(getBranchOfficeBoardRWFRole(userStore.getUserView?.boardAuthorities, branchOfficeId).fixed);
    const branchOfficeName = props.postDetailData.branchOfficeName
      ? props.postDetailData.branchOfficeName
      : computed(() => branchStore.branchInfoArray.find((v) => v.id === Number(branchOfficeId))?.branchName ?? '');

    watch(
      () => props.subCategory,
      (current) => {
        selectedCategory.value = current.find((v) => Number(v.key) === Number(props.postDetailData.boardId));
      },
    );

    const postEditor = ref(null);

    watch(
      () => userStore.getUserView,
      (current, prev) => {
        if (current?.boardAuthorities.length > 0) {
          isAbleFixedPost.value = getBranchOfficeBoardRWFRole(current?.boardAuthorities, branchOfficeId).fixed;
        }
      },
    );

    const title = ref(props.postDetailData.title);
    const password = ref('');
    const content = ref(props.postDetailData.body);

    const files = ref(props.postDetailData.postFileViewList.map((file) => ({ ...file, name: file.fileName })));
    const deleteFileList = ref([]);

    const buttonLoading = ref(false);

    const onClickCancel = () => {
      router.push(`/community/${selectedCategory.value.key ?? selectedCategory.value}`);
    };

    const updateContent = (e) => {
      content.value = e;
    };

    const formValidator = () => {
      if (!selectedCategory.value) {
        warningToast('카테고리를 선택해주세요');
        return false;
      }
      if (!title.value) {
        warningToast('제목은 필수값 입니다.');
        return false;
      }
      if (!content.value) {
        warningToast('내용을 입력해 주세요');
        return false;
      }
      if (isSecretPost.value && !password.value) {
        warningToast('비밀번호를 입력해주세요.');
        return false;
      }

      return true;
    };

    const uploadPost = async (type) => {
      buttonLoading.value = true;

      if (!formValidator()) {
        buttonLoading.value = false;
        return false;
      }

      const formData = new FormData();

      const boardId = selectedCategory.value.key ?? selectedCategory.value;
      const requestBody = JSON.stringify({
        boardId,
        title: title.value,
        body: content.value,
        password: password.value,
        deleteFileList: deleteFileList.value,
        isFixed: isFixedPost.value,
        branchOfficeId,
      });
      const blob = new Blob([requestBody], { type: 'application/json' });
      formData.set('request', blob);

      files.value.forEach((file) => {
        if (!file.url) {
          formData.append('attachmentList', file.data);
        }
      });

      if (type === 'edit') {
        await editPostAPI(formData, props.postDetailData.id);
      } else {
        await uploadPostAPI(formData);
      }

      const postEditType = props.postDetailData.id ? '수정' : '등록';
      successToast(`게시글 ${postEditType}이 완료되었습니다.`);
      buttonLoading.value = false;

      router.push(`/community/${boardId}?branchOfficeId=${branchOfficeId}`);
    };

    const onClickPost = () => {
      uploadPost('submit');
    };

    const onClickEditPost = () => {
      uploadPost('edit');
    };

    /** file control */
    const prefix = 'a';
    const uid = ref(0);
    const prevFiles = { list: files.value };

    const changeFile = (fileInputs) => {
      const fileInputsWithId = fileInputs.map((file) => {
        uid.value++;
        return { id: `${prefix}${uid.value}`, data: file };
      });

      files.value = [...prevFiles.list, ...fileInputsWithId];
      prevFiles.list = files.value;
    };

    const deleteFile = (e, fileId) => {
      e.stopPropagation();

      const targetIdx = prevFiles.list.findIndex(({ id }) => id === fileId);
      const fileUrl = prevFiles.list[targetIdx].url;

      if (fileUrl) {
        deleteFileList.value.push(fileId);
      }

      files.value.splice(targetIdx, 1);
      prevFiles.list = files.value;
    };

    const deleteAllFile = () => {
      const deleteFileIds = prevFiles.list.reduce((acc, cur) => (cur.id ? [...acc, cur.id] : acc), []);
      deleteFileList.value = deleteFileIds;

      files.value = [];
      prevFiles.list = files.value;
    };

    return {
      files,
      selectedCategory,
      isSecretPost,
      isFixedPost,
      isAbleFixedPost,
      onClickCancel,
      onClickPost,
      onClickEditPost,
      postEditor,
      updateContent,
      title,
      password,
      buttonLoading,
      initialValue: props.postDetailData.body,
      changeFile,
      deleteFile,
      deleteAllFile,
      branchOfficeName,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding-bottom: 3rem;

  @include sm-and-down {
    padding: 1rem;
  }
}

.secret-wrapper {
  display: flex;

  :deep(.v-checkbox) {
    max-width: 7rem;
  }
}

.editor-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cancel-btn {
  border: 1px solid $gray-30;
  margin-right: 0.5rem;
}

.post-btn {
  background-color: $blue-50;
  color: $gray-10;
}

.chip_name {
  padding: 4px 0 !important;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 96px;
}
</style>
