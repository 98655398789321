<template>
  <div class="relative">
    <div class="bg-[#181E2D] w-[200vw] h-full absolute left-[-50%]" />
    <article-wrapper>
      <div class="flex pc:flex-row lg:flex-row mobile:flex-col">
        <div class="z-[1] pc:max-w-[30rem] w-full">
          <article-title class="text-white pc:mb-8 mobile:mb-6"> Schdules </article-title>
          <div class="pc:text-body-xxLarge text-gray-50 mobile:text-body-xLarge">중앙회 일정을 확인해 보세요</div>
        </div>
        <div class="calendar__wrapper h-[36.125rem]">
          <calendar
            ref="calendarRef"
            trim-weeks
            is-expanded
            :from-page="fromPage"
            :attributes="attributes"
            class="calendar__deep w-full"
            @update:from-page="getScheduleList"
          >
            <template #day-content="{ day, attributes: innerAttributes }"
              ><div class="day-content__wrapper" @click="() => openDialog(innerAttributes, day)">
                <span class="day-content__day">{{ day.day }}</span>
                <p v-if="showTitle(innerAttributes, day)" class="day-content__title">{{ innerAttributes?.[0]?.customData.title }}</p>
              </div>
            </template>
          </calendar>
          <template v-if="showPopOver(dialogData.day)">
            <main-calendar-popover :day="dialogData.day" :attributes="dialogData.attributes" :close-dialog="closeDialog" />
          </template>
        </div>
      </div>
    </article-wrapper>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Calendar } from 'v-calendar';
import MainCalendarPopover from '@/containers/Main/MainCalendarPopover';
import { ArticleTitle, ArticleWrapper } from '@/containers/Main/components';

import { getScheduleListAPI } from '@/apis/schedule';
import { getKrDate, parseKoFullDate } from '@/utils/date';
import 'v-calendar/dist/style.css';
import { isEmpty } from 'lodash-es';

export default {
  name: 'MainCalendar',
  components: { ArticleWrapper, ArticleTitle, Calendar, MainCalendarPopover },
  props: {
    schedules: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const calendarProps = useCalendar();

    const showsPopOver = ref('');

    const dialogData = ref({ attributes: {}, day: {} });

    const showPopOver = (day) => {
      return showsPopOver.value === day.day;
    };

    const openDialog = (attributes, day) => {
      if (isEmpty(attributes)) return;

      dialogData.value.attributes = attributes;
      dialogData.value.day = day;

      showsPopOver.value = day.day;
    };

    const closeDialog = () => {
      showsPopOver.value = '';
      dialogData.value.attributes = {};
      dialogData.value.day = {};
    };

    return { ...calendarProps, showTitle, openDialog, showPopOver, closeDialog, showsPopOver, dialogData };
  },
};

const showTitle = (attributes, day) => {
  if (isEmpty(attributes?.[0]?.customData)) return false;

  const { title, startDate } = attributes[0].customData;
  const isStartDate = getKrDate(startDate).isSame(day.date, 'day');

  return !!title && isStartDate;
};

const getToday = () => {
  const today = getKrDate();
  const currentDate = {
    year: today.year(),
    month: today.month() + 1,
  };

  return { today, currentDate };
};

const getAttributeDate = (startDate, endDate) => {
  return { start: getKrDate(startDate).toDate(), end: getKrDate(endDate).toDate() };
};

const COLOR_PALETTE = ['blue', 'teal', 'indigo', 'green', 'gray'];

const useCalendar = () => {
  const { currentDate } = getToday();

  const calendarRef = ref(null);
  const attributes = ref([]);

  const getScheduleList = async ({ year, month }) => {
    const res = (await getScheduleListAPI({ year, month })) || [];

    const scheduleAttributes = res.map((item) => {
      const dates = getAttributeDate(item.startDate, item.endDate);
      const color = COLOR_PALETTE[item.id % 5];

      return {
        key: item.id,
        highlight: {
          start: { fillMode: 'solid', color },
          base: { fillMode: 'light', color },
          end: { fillMode: 'solid', color },
        },
        dates,
        customData: { title: item.title, description: item.description, startDate: dates.start },
        popover: true,
      };
    });

    attributes.value = scheduleAttributes;
  };

  return { calendarRef, fromPage: currentDate, attributes, getScheduleList, parseKoFullDate };
};
</script>

<style scoped lang="scss">
.calendar__wrapper {
  @apply pc:py-[72px] mobile:py-[48px];
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: auto;
}

.calendar__deep:deep {
  .vc-day-box-left-center {
    align-items: flex-start;
  }
  .vc-day-box-center-center {
    align-items: flex-start;
  }
  .vc-day-box-right-center {
    align-items: flex-start;
  }
}

.day-content {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: inherit;
    height: auto;
    align-items: center;
  }

  &__day {
    display: flex;
    justify-content: center;
    align-items: center;

    @include spreadTypoMap($body-xsmall);
    line-height: 28px;
  }

  &__title {
    @apply text-body-xxsmall line-clamp-1 m-0;
  }
}
</style>
