import axios from '@/lib/axiosController';

/**
 * 지회, 지부 전체 리스트를 가져오는 API
 * @returns
 */
export const getBranchOfficeList = async () => {
  const ret = await axios.get('/branch-office/list', { useCache: true });

  return ret;
};
