<template>
  <div class="box">
    <button class="btn" @click="() => {}">상세 정보</button>
    <button class="btn" @click="() => {}">수강생 리뷰</button>
    <button class="btn" @click="() => {}">강사 소개</button>
    <button class="btn" @click="() => {}">클래스 특징</button>
  </div>
</template>

<script></script>

<style lang="scss" scoped>
.box {
  background: rgba(39, 47, 62, 0.2);
  padding: 12px 40px;
  border-radius: 20px;

  & > :not(:last-child) {
    margin-right: 1rem;
  }
}

.btn {
  padding: 12px 24px;
  background-color: $blue-50;
  color: white;
  border: none;
  border-radius: 100px;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
}
</style>
