export const MAIN_BRANCH_TABLE_HEADERS = [
  { id: 'region', label: '소속' },
  { id: 'branchName', label: '지부명' },
  { id: 'branchManager', label: '지부장' },
  { id: 'officeDirector', label: '사무국장' },
];

export const SUB_BRANCH_TABLE_HEADERS = [
  { id: 'region', label: '지역' },
  { id: 'branchName', label: '지회명' },
  { id: 'branchManager', label: '지회장' },
  { id: 'officeDirector', label: '사무국장' },
];

export const MAIN_TABLE_ROWS = [
  {
    id: 1,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '성남시 수정구지부',
    branchManager: '박정금',
    officeDirector: '이민지',
  },
  {
    id: 2,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '성남시 중원구지부',
    branchManager: '김장순',
    officeDirector: '김영구',
  },
  {
    id: 3,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '성남시 분당구지부',
    branchManager: '강미자',
    officeDirector: '김규형',
  },
  {
    id: 4,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '부천시 원미구지부',
    branchManager: '김선호',
    officeDirector: '이동근',
  },
  {
    id: 5,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '부천시 소사구',
    branchManager: '임준경',
    officeDirector: '김숙혜',
  },
  {
    id: 6,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '부천시 오정구',
    branchManager: '김정옥',
    officeDirector: '정해정',
  },
  {
    id: 7,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '안양시 만안구',
    branchManager: '정숙희',
    officeDirector: '안도희',
  },
  {
    id: 8,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '안양시 동안구',
    branchManager: '박순임',
    officeDirector: '편미나',
  },
  {
    id: 9,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '안산시 단원구',
    branchManager: '오송림',
    officeDirector: '김서영',
  },
  {
    id: 10,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '안산시 상록구',
    branchManager: '황영은',
    officeDirector: '이용대',
  },
  {
    id: 11,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '광명시',
    branchManager: '김영애',
    officeDirector: '김경복',
  },
  {
    id: 12,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '광주시',
    branchManager: '최명주',
    officeDirector: '민지영',
  },
  {
    id: 13,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '군포시',
    branchManager: '최서인',
    officeDirector: '고정은',
  },
  {
    id: 14,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '오산시',
    branchManager: '박희주',
    officeDirector: '이순옥',
  },
  {
    id: 15,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '안성시',
    branchManager: '오미경',
    officeDirector: '이지혜',
  },
  {
    id: 16,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '이천시',
    branchManager: '이정희',
    officeDirector: '전세희',
  },
  {
    id: 17,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '시흥시',
    branchManager: '오수연',
    officeDirector: '김이경',
  },
  {
    id: 18,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '평택안중',
    branchManager: '민영순',
    officeDirector: '이현숙',
  },
  {
    id: 19,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '송탄',
    branchManager: '서강분',
    officeDirector: '김효신',
  },
  {
    id: 20,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '여주시',
    branchManager: '김용순',
    officeDirector: '우현주',
  },
  {
    id: 21,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '하남시',
    branchManager: '이호준',
    officeDirector: '손용환',
  },
  {
    id: 22,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '양평군',
    branchManager: '정애순',
    officeDirector: '이경미',
  },
  {
    id: 23,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '김포시',
    branchManager: '박윤소',
    officeDirector: '김상임',
  },
  {
    id: 24,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '의왕과천시',
    branchManager: '김현숙',
    officeDirector: '박주열',
  },
  {
    id: 25,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '화성시',
    branchManager: '배희자',
    officeDirector: '정정미',
  },
  {
    id: 26,
    region: 'GYEONGGI',
    regionLabel: '경기도',
    branchName: '화성시 동부',
    branchManager: '윤희',
    officeDirector: '박광근',
  },
  {
    id: 27,
    region: 'GYEONGGI_NORTH',
    regionLabel: '경기북부',
    branchName: '가평군',
    branchManager: '탁혜정',
    officeDirector: '-',
  },
  {
    id: 28,
    region: 'GYEONGGI_NORTH',
    regionLabel: '경기북부',
    branchName: '구리시',
    branchManager: '박정임',
    officeDirector: '이영득',
  },
  {
    id: 29,
    region: 'GYEONGGI_NORTH',
    regionLabel: '경기북부',
    branchName: '남양주시',
    branchManager: '이추우',
    officeDirector: '-',
  },
  {
    id: 30,
    region: 'GYEONGGI_NORTH',
    regionLabel: '경기북부',
    branchName: '동두천시',
    branchManager: '이선희',
    officeDirector: '조선영',
  },
  {
    id: 31,
    region: 'GYEONGGI_NORTH',
    regionLabel: '경기북부',
    branchName: '연천군',
    branchManager: '조연숙',
    officeDirector: '-',
  },
  {
    id: 32,
    region: 'GYEONGGI_NORTH',
    regionLabel: '경기북부',
    branchName: '의정부시',
    branchManager: '황례숙',
    officeDirector: '승미연',
  },
  {
    id: 33,
    region: 'GYEONGGI_NORTH',
    regionLabel: '경기북부',
    branchName: '양주시',
    branchManager: '홍미영',
    officeDirector: '주선영',
  },
  {
    id: 34,
    region: 'GYEONGGI_NORTH',
    regionLabel: '경기북부',
    branchName: '파주시',
    branchManager: '문경자',
    officeDirector: '노임앵',
  },
  {
    id: 35,
    region: 'GYEONGGI_NORTH',
    regionLabel: '경기북부',
    branchName: '포천시',
    branchManager: '조경희',
    officeDirector: '-',
  },
  {
    id: 36,
    region: 'GYEONGGI_NORTH',
    regionLabel: '경기북부',
    branchName: '남양주시풍양',
    branchManager: '허정원',
    officeDirector: '-',
  },
  {
    id: 37,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '춘천시',
    branchManager: '유나경',
    officeDirector: '이정희',
  },
  {
    id: 38,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '원주시',
    branchManager: '남양순',
    officeDirector: '최영자',
  },
  {
    id: 39,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '강릉시',
    branchManager: '전영옥',
    officeDirector: '장미라',
  },
  {
    id: 40,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '동해시',
    branchManager: '심미화',
    officeDirector: '김혜정',
  },
  {
    id: 41,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '태백시',
    branchManager: '이춘희',
    officeDirector: '권갑순',
  },
  {
    id: 42,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '속초시',
    branchManager: '이숙이',
    officeDirector: '차임순',
  },
  {
    id: 43,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '삼척시',
    branchManager: '박애자',
    officeDirector: '이영자',
  },
  {
    id: 44,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '홍천군',
    branchManager: '권금춘',
    officeDirector: '김현희',
  },
  {
    id: 45,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '횡성군',
    branchManager: '정덕희',
    officeDirector: '서지혜',
  },
  {
    id: 46,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '영월군',
    branchManager: '박명자',
    officeDirector: '김향숙',
  },
  {
    id: 47,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '정선군',
    branchManager: '임명임',
    officeDirector: '김숙이',
  },
  {
    id: 48,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '철원군',
    branchManager: '이숙희',
    officeDirector: '천지연',
  },
  {
    id: 49,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '화천군',
    branchManager: '함수미',
    officeDirector: '안지숙',
  },
  {
    id: 50,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '양구군',
    branchManager: '신필순',
    officeDirector: '심은선',
  },
  {
    id: 51,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '양양군',
    branchManager: '박영옥',
    officeDirector: '김정현',
  },
  {
    id: 52,
    region: 'GANGWON',
    regionLabel: '강원도',
    branchName: '평창군',
    branchManager: '이승희',
    officeDirector: '김명선',
  },
  {
    id: 53,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '계룡시',
    branchManager: '임화묵',
    officeDirector: '유현숙',
  },
  {
    id: 54,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '보령시',
    branchManager: '김경숙',
    officeDirector: '홍재분',
  },
  {
    id: 55,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '천안북부',
    branchManager: '장명옥',
    officeDirector: '최주연',
  },
  {
    id: 56,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '부여군',
    branchManager: '조종미',
    officeDirector: '추분영',
  },
  {
    id: 57,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '천안시',
    branchManager: '남유진',
    officeDirector: '박기옥',
  },
  {
    id: 58,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '당진시',
    branchManager: '손유희',
    officeDirector: '김은주',
  },
  {
    id: 59,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '태안군',
    branchManager: '윤행호',
    officeDirector: '김진화',
  },
  {
    id: 60,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '서천군',
    branchManager: '송점순',
    officeDirector: '이숙재',
  },
  {
    id: 61,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '서산시',
    branchManager: '백미희',
    officeDirector: '이정옥',
  },
  {
    id: 62,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '금산군',
    branchManager: '서정애',
    officeDirector: '김정희',
  },
  {
    id: 63,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '공주시',
    branchManager: '임규숙',
    officeDirector: '신금철',
  },
  {
    id: 64,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '아산시',
    branchManager: '조용히',
    officeDirector: '신혜옥',
  },
  {
    id: 65,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '논산시',
    branchManager: '차종기',
    officeDirector: '남화주',
  },
  {
    id: 66,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '홍성군',
    branchManager: '명춘식',
    officeDirector: '-',
  },
  {
    id: 67,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '예산군',
    branchManager: '이정순',
    officeDirector: '-',
  },
  {
    id: 68,
    region: 'CHUNGCHEONG_SOUTH',
    regionLabel: '충청남도',
    branchName: '청양군',
    branchManager: '강희경',
    officeDirector: '-',
  },
  {
    id: 69,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '청주시 상당구',
    branchManager: '박영해',
    officeDirector: '윤영미',
  },
  {
    id: 70,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '청주시 서원구',
    branchManager: '박정순',
    officeDirector: '윤은영',
  },
  {
    id: 71,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '청주시 흥덕구',
    branchManager: '유인화',
    officeDirector: '윤은영',
  },
  {
    id: 72,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '청주시 청원구',
    branchManager: '박현순',
    officeDirector: '강경희',
  },
  {
    id: 73,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '충주시',
    branchManager: '박현혜',
    officeDirector: '-',
  },
  {
    id: 74,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '제천시',
    branchManager: '김민현',
    officeDirector: '이순옥',
  },
  {
    id: 75,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '진천군',
    branchManager: '이옥희',
    officeDirector: '최은영',
  },
  {
    id: 76,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '증평군',
    branchManager: '김은미',
    officeDirector: '김나영',
  },
  {
    id: 77,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '보은군',
    branchManager: '한삼순',
    officeDirector: '박군희',
  },
  {
    id: 78,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '옥천군',
    branchManager: '염재은',
    officeDirector: '이은영',
  },
  {
    id: 79,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '영동군',
    branchManager: '오정숙',
    officeDirector: '김희정',
  },
  {
    id: 80,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '단양군',
    branchManager: '우재경',
    officeDirector: '허진',
  },
  {
    id: 81,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '음성군',
    branchManager: '안영애',
    officeDirector: '김태성',
  },
  {
    id: 82,
    region: 'CHUNGCHEONG_NORTH',
    regionLabel: '충청북도',
    branchName: '괴산군',
    branchManager: '장기자',
    officeDirector: '-',
  },
  {
    id: 83,
    region: 'JEOLLA_SOUTHWEST',
    regionLabel: '전남서부',
    branchName: '목포시',
    branchManager: '정숙희',
    officeDirector: '허기웅',
  },
  {
    id: 84,
    region: 'JEOLLA_SOUTHWEST',
    regionLabel: '전남서부',
    branchName: '강진군',
    branchManager: '마은정',
    officeDirector: '김세은',
  },
  {
    id: 85,
    region: 'JEOLLA_SOUTHWEST',
    regionLabel: '전남서부',
    branchName: '무안군',
    branchManager: '조현숙',
    officeDirector: '전선주',
  },
  {
    id: 86,
    region: 'JEOLLA_SOUTHWEST',
    regionLabel: '전남서부',
    branchName: '영광군',
    branchManager: '한미선',
    officeDirector: '김영숙',
  },
  {
    id: 87,
    region: 'JEOLLA_SOUTHWEST',
    regionLabel: '전남서부',
    branchName: '영암군',
    branchManager: '신미화',
    officeDirector: '박선화',
  },
  {
    id: 88,
    region: 'JEOLLA_SOUTHWEST',
    regionLabel: '전남서부',
    branchName: '장성군',
    branchManager: '문혜영',
    officeDirector: '유미진',
  },
  {
    id: 89,
    region: 'JEOLLA_SOUTHWEST',
    regionLabel: '전남서부',
    branchName: '진도군',
    branchManager: '박애리',
    officeDirector: '배미경',
  },
  {
    id: 90,
    region: 'JEOLLA_SOUTHWEST',
    regionLabel: '전남서부',
    branchName: '함평군',
    branchManager: '최영아',
    officeDirector: '강순금',
  },
  {
    id: 91,
    region: 'JEOLLA_SOUTHWEST',
    regionLabel: '전남서부',
    branchName: '해남군',
    branchManager: '김은정',
    officeDirector: '박병순',
  },
  {
    id: 92,
    region: 'JEOLLA_SOUTHWEST',
    regionLabel: '전남서부',
    branchName: '나주시',
    branchManager: '이정실',
    officeDirector: '',
  },
  {
    id: 93,
    region: 'JEOLLA_SOUTHWEST',
    regionLabel: '전남서부',
    branchName: '완도군',
    branchManager: '김금복',
    officeDirector: '',
  },
  {
    id: 94,
    region: 'JEOLLA_SOUTHEAST',
    regionLabel: '전남동부',
    branchName: '여수시',
    branchManager: '하옥현',
    officeDirector: '김시현',
  },
  {
    id: 95,
    region: 'JEOLLA_SOUTHEAST',
    regionLabel: '전남동부',
    branchName: '순천시',
    branchManager: '이영자',
    officeDirector: '최순미',
  },
  {
    id: 96,
    region: 'JEOLLA_SOUTHEAST',
    regionLabel: '전남동부',
    branchName: '광양시',
    branchManager: '이근자',
    officeDirector: '김영심',
  },
  {
    id: 97,
    region: 'JEOLLA_SOUTHEAST',
    regionLabel: '전남동부',
    branchName: '화순군',
    branchManager: '노대영',
    officeDirector: '',
  },
  {
    id: 98,
    region: 'JEOLLA_SOUTHEAST',
    regionLabel: '전남동부',
    branchName: '고흥군',
    branchManager: '박호양',
    officeDirector: '',
  },
  {
    id: 99,
    region: 'JEOLLA_SOUTHEAST',
    regionLabel: '전남동부',
    branchName: '장흥군',
    branchManager: '이정심',
    officeDirector: '',
  },
  {
    id: 100,
    region: 'JEOLLA_SOUTHEAST',
    regionLabel: '전남동부',
    branchName: '구례군',
    branchManager: '고신숙',
    officeDirector: '',
  },
  {
    id: 101,
    region: 'JEOLLA_SOUTHEAST',
    regionLabel: '전남동부',
    branchName: '곡성군',
    branchManager: '김경옥',
    officeDirector: '',
  },
  {
    id: 102,
    region: 'JEOLLA_SOUTHEAST',
    regionLabel: '전남동부',
    branchName: '담양군',
    branchManager: '국옥경',
    officeDirector: '',
  },
  {
    id: 103,
    region: 'JEOLLA_SOUTHEAST',
    regionLabel: '전남동부',
    branchName: '보성군',
    branchManager: '박정미',
    officeDirector: '',
  },
  {
    id: 104,
    region: 'JEOLLA_SOUTHEAST',
    regionLabel: '전남동부',
    branchName: '벌교',
    branchManager: '한경숙',
    officeDirector: '',
  },
  {
    id: 105,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '전주시 완산구',
    branchManager: '박성호',
    officeDirector: '박영희',
  },
  {
    id: 106,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '전주시 덕진구',
    branchManager: '조윤순',
    officeDirector: '장장운',
  },
  {
    id: 107,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '전주시 효자구',
    branchManager: '김연호',
    officeDirector: '이종준',
  },
  {
    id: 108,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '익산시',
    branchManager: '최춘자',
    officeDirector: '김기철',
  },
  {
    id: 109,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '정읍시',
    branchManager: '방서진',
    officeDirector: '김종운',
  },
  {
    id: 110,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '군산시',
    branchManager: '김정미',
    officeDirector: '조홍식',
  },
  {
    id: 111,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '남원시',
    branchManager: '김태',
    officeDirector: '모덕형',
  },
  {
    id: 112,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '김제시',
    branchManager: '오인자',
    officeDirector: '김은경',
  },
  {
    id: 113,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '고창군',
    branchManager: '정영자',
    officeDirector: '김계련',
  },
  {
    id: 114,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '부안군',
    branchManager: '조숙영',
    officeDirector: '이지아',
  },
  {
    id: 115,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '완주군',
    branchManager: '최기자',
    officeDirector: '이혜경',
  },
  {
    id: 116,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '무주군',
    branchManager: '김영아',
    officeDirector: '강명이',
  },
  {
    id: 117,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '임실군',
    branchManager: '이기미',
    officeDirector: '심명순',
  },
  {
    id: 118,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '진안군',
    branchManager: '김해자',
    officeDirector: '서영희',
  },
  {
    id: 119,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '장수군',
    branchManager: '최병우',
    officeDirector: '장지윤',
  },
  {
    id: 120,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '순창군',
    branchManager: '주혜순',
    officeDirector: '유현희',
  },
  {
    id: 121,
    region: 'JEOLLA_NORTH',
    regionLabel: '전라북도',
    branchName: '함열',
    branchManager: '김명화',
    officeDirector: '김해경',
  },
  {
    id: 122,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '거제시',
    branchManager: '전수빈',
    officeDirector: '권춘자',
  },
  {
    id: 123,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '밀양시',
    branchManager: '손영선',
    officeDirector: '김연희',
  },
  {
    id: 124,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '사천시',
    branchManager: '서경애',
    officeDirector: '정순덕',
  },
  {
    id: 125,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '산청군',
    branchManager: '하예담',
    officeDirector: '송호욱',
  },
  {
    id: 126,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '양산시',
    branchManager: '이경순(식대)',
    officeDirector: '김순영',
  },
  {
    id: 127,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '함양군',
    branchManager: '이순심',
    officeDirector: '김광일',
  },
  {
    id: 128,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '진주시',
    branchManager: '유애심',
    officeDirector: '박해경',
  },
  {
    id: 129,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '창녕군',
    branchManager: '김은진',
    officeDirector: '하재춘',
  },
  {
    id: 130,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '통영시',
    branchManager: '김광문',
    officeDirector: '황영미',
  },
  {
    id: 131,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '남해군',
    branchManager: '김막순',
    officeDirector: '',
  },
  {
    id: 132,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '하동군',
    branchManager: '김지영',
    officeDirector: '',
  },
  {
    id: 133,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '함안군',
    branchManager: '최미경',
    officeDirector: '',
  },
  {
    id: 134,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '의령군',
    branchManager: '강미영',
    officeDirector: '',
  },
  {
    id: 135,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '합천군',
    branchManager: '정현숙',
    officeDirector: '',
  },
  {
    id: 136,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '거창군',
    branchManager: '구희자',
    officeDirector: '',
  },
  {
    id: 137,
    region: 'GYEONGSANG_SOUTH',
    regionLabel: '경상남도',
    branchName: '고성군',
    branchManager: '강년숙',
    officeDirector: '',
  },
  {
    id: 138,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '경산시',
    branchManager: '최미자',
    officeDirector: '박동재',
  },
  {
    id: 139,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '김천시',
    branchManager: '이정분',
    officeDirector: '기영심',
  },
  {
    id: 140,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '경주시',
    branchManager: '손귀영',
    officeDirector: '김은정',
  },
  {
    id: 141,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '고령군',
    branchManager: '김미애',
    officeDirector: '이옥란',
  },
  {
    id: 142,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '구미시',
    branchManager: '김정미',
    officeDirector: '김은주',
  },
  {
    id: 143,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '군위군',
    branchManager: '장근희',
    officeDirector: '최미옥',
  },
  {
    id: 144,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '문경시',
    branchManager: '정태랑',
    officeDirector: '이창섭',
  },
  {
    id: 145,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '봉화군',
    branchManager: '김미자',
    officeDirector: '김영남',
  },
  {
    id: 146,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '상주시',
    branchManager: '이정민(직대)',
    officeDirector: '김상균',
  },
  {
    id: 147,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '선산군',
    branchManager: '이소현',
    officeDirector: '이미숙',
  },
  {
    id: 148,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '영덕군',
    branchManager: '김승희',
    officeDirector: '정주원',
  },
  {
    id: 149,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '영주시',
    branchManager: '조은희',
    officeDirector: '안소희',
  },
  {
    id: 150,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '예천군',
    branchManager: '권영옥',
    officeDirector: '황정원',
  },
  {
    id: 151,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '울진군',
    branchManager: '김미경',
    officeDirector: '김지원',
  },
  {
    id: 152,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '의성군',
    branchManager: '김숙자',
    officeDirector: '이윤정',
  },
  {
    id: 153,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '청도군',
    branchManager: '하복희',
    officeDirector: '문윤미',
  },
  {
    id: 154,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '칠곡군',
    branchManager: '이다예솜',
    officeDirector: '장오식',
  },
  {
    id: 155,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '포항시 남구',
    branchManager: '오성우',
    officeDirector: '박영옥',
  },
  {
    id: 156,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '포항시 북구',
    branchManager: '이애원',
    officeDirector: '장미리',
  },
  {
    id: 157,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '성주군',
    branchManager: '정미정',
    officeDirector: '',
  },
  {
    id: 158,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '안동시',
    branchManager: '윤복순',
    officeDirector: '',
  },
  {
    id: 159,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '영양군',
    branchManager: '김성자',
    officeDirector: '',
  },
  {
    id: 160,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '영천시',
    branchManager: '김안숙',
    officeDirector: '',
  },
  {
    id: 161,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '울릉군',
    branchManager: '최원경',
    officeDirector: '',
  },
  {
    id: 162,
    region: 'GYEONGSANG_NORTH',
    regionLabel: '경상북도',
    branchName: '청송군',
    branchManager: '권선아',
    officeDirector: '',
  },
  {
    id: 163,
    region: 'JEJU',
    regionLabel: '제주도',
    branchName: '제주시 중구',
    branchManager: '손인숙',
    officeDirector: '이한나',
  },
  {
    id: 164,
    region: 'JEJU',
    regionLabel: '제주도',
    branchName: '제주시 서구',
    branchManager: '최문희',
    officeDirector: '이한나',
  },
  {
    id: 165,
    region: 'JEJU',
    regionLabel: '제주도',
    branchName: '제주시 북구',
    branchManager: '박금련',
    officeDirector: '이한나',
  },
  {
    id: 166,
    region: 'JEJU',
    regionLabel: '제주도',
    branchName: '서귀포시 중구',
    branchManager: '송봉숙',
    officeDirector: '정현희',
  },
  {
    id: 167,
    region: 'JEJU',
    regionLabel: '제주도',
    branchName: '서귀포시 남구',
    branchManager: '임순화',
    officeDirector: '정현희',
  },
];

export const SUB_TABLE_ROWS = [
  {
    regionLabel: '특례시(사무실)',
    id: 263,
    branchName: '사)대한미용사회 고양특례시 덕양구지회',
    branchManager: '강영운',
    officeDirector: '최정임',
  },
  {
    regionLabel: '특례시(사무실)',
    id: 261,
    branchName: '사)대한미용사회 고양특례시 일산동구지회',
    branchManager: '방소림',
    officeDirector: '박취아',
  },
  {
    regionLabel: '특례시(사무실)',
    id: 262,
    branchName: '사)대한미용사회 고양특례시 일산서구지회',
    branchManager: '이나경',
    officeDirector: '이미정',
  },
  {
    regionLabel: '특례시(사무실)',
    id: 257,
    branchName: '사)대한미용사회 수원특례시 권선구지회',
    branchManager: '조원옥',
    officeDirector: '차소미',
  },
  {
    regionLabel: '특례시(사무실)',
    id: 254,
    branchName: '사)대한미용사회 수원특례시 영통구지회',
    branchManager: '최은경',
    officeDirector: '김금순',
  },
  {
    regionLabel: '특례시(사무실)',
    id: 256,
    branchName: '사)대한미용사회 수원특례시 장안구지회',
    branchManager: '강민재',
    officeDirector: '-',
  },
  {
    regionLabel: '특례시(사무실)',
    id: 255,
    branchName: '사)대한미용사회 수원특례시 팔달구지회',
    branchManager: '김갑녀',
    officeDirector: '박경애',
  },
  {
    regionLabel: '특례시(사무실)',
    id: 259,
    branchName: '사)대한미용사회 용인특례시 기흥구지회',
    branchManager: '최은숙',
    officeDirector: '한성욱',
  },
  {
    regionLabel: '특례시(사무실)',
    id: 258,
    branchName: '사)대한미용사회 용인특례시 수지구지회',
    branchManager: '조윤정',
    officeDirector: '한미연',
  },
  {
    regionLabel: '특례시(사무실)',
    id: 260,
    branchName: '사)대한미용사회 용인특례시 처인구지회',
    branchManager: '장옥자',
    officeDirector: '',
  },
  {
    regionLabel: '특례시(사무실)',
    id: 265,
    branchName: '사)대한미용사회 창원특례시 마산지회',
    branchManager: '심근하',
    officeDirector: '이맹희',
  },
  {
    regionLabel: '특례시(사무실)',
    id: 264,
    branchName: '사)대한미용사회 창원특례시 진해지회',
    branchManager: '장순자',
    officeDirector: '전말숙',
  },
  {
    regionLabel: '특례시(사무실)',
    id: 266,
    branchName: '사)대한미용사회 창원특례시 창원지회',
    branchManager: '박광윤',
    officeDirector: '남종숙',
  },
  {
    regionLabel: '인천(사무실)',
    id: 50,
    branchName: '사)대한미용사회 인천강화군지회',
    branchManager: '-',
    officeDirector: '-',
  },
  {
    regionLabel: '인천(사무실)',
    id: 51,
    branchName: '사)대한미용사회 인천계양구지회',
    branchManager: '유영례',
    officeDirector: '-',
  },
  {
    regionLabel: '인천(사무실)',
    id: 55,
    branchName: '사)대한미용사회 인천남동구지회',
    branchManager: '김미숙',
    officeDirector: '조두진',
  },
  {
    regionLabel: '인천(사무실)',
    id: 57,
    branchName: '사)대한미용사회 인천동구지회',
    branchManager: '김혜례',
    officeDirector: '-',
  },
  {
    regionLabel: '인천(사무실)',
    id: 56,
    branchName: '사)대한미용사회 인천미추홀구지회',
    branchManager: '신소연',
    officeDirector: '변성수',
  },
  {
    regionLabel: '인천(사무실)',
    id: 52,
    branchName: '사)대한미용사회 인천부평구지회',
    branchManager: '장춘화',
    officeDirector: '강선희',
  },
  {
    regionLabel: '인천(사무실)',
    id: 53,
    branchName: '사)대한미용사회 인천서구지회',
    branchManager: '전희정',
    officeDirector: '김우찬',
  },
  {
    regionLabel: '인천(사무실)',
    id: 54,
    branchName: '사)대한미용사회 인천연수구지회',
    branchManager: '장혜영',
    officeDirector: '전영희',
  },
  {
    regionLabel: '인천(사무실)',
    id: 58,
    branchName: '사)대한미용사회 인천중구지회',
    branchManager: '이종순',
    officeDirector: '-',
  },
  {
    regionLabel: '울산(사무실)',
    id: 114,
    branchName: '사)대한미용사회 울산울주군지회',
    branchManager: '이순녀',
    officeDirector: '-',
  },
  {
    regionLabel: '울산(사무실)',
    id: 115,
    branchName: '사)대한미용사회 울산북구지회',
    branchManager: '성혜숙',
    officeDirector: '-',
  },
  {
    regionLabel: '울산(사무실)',
    id: 117,
    branchName: '사)대한미용사회 울산남구지회',
    branchManager: '서민서',
    officeDirector: '강창희',
  },
  {
    regionLabel: '울산(사무실)',
    id: 116,
    branchName: '사)대한미용사회 울산중구지회',
    branchManager: '이영순',
    officeDirector: '-',
  },
  {
    regionLabel: '울산(사무실)',
    id: 118,
    branchName: '사)대한미용사회 울산동구지회',
    branchManager: '김경란',
    officeDirector: '-',
  },
  {
    regionLabel: '세종시(사무실)',
    id: 69,
    branchName: '사)대한미용사회 세종특별자치시지회',
    branchManager: '김순복',
    officeDirector: '-',
  },
  {
    regionLabel: '서울(사무실)',
    id: 13,
    branchName: '사)대한미용사회 구로구지회',
    branchManager: '김재숙',
    officeDirector: '김경숙',
  },
  {
    regionLabel: '서울(사무실)',
    id: 11,
    branchName: '사)대한미용사회 강동구지회',
    branchManager: '김수현',
    officeDirector: '-',
  },
  {
    regionLabel: '서울(사무실)',
    id: 14,
    branchName: '사)대한미용사회 강남구지회',
    branchManager: '이정민',
    officeDirector: '이영희',
  },
  {
    regionLabel: '서울(사무실)',
    id: 8,
    branchName: '사)대한미용사회 노원구지회',
    branchManager: '이종원',
    officeDirector: '이경숙',
  },
  {
    regionLabel: '서울(사무실)',
    id: 4,
    branchName: '사)대한미용사회 금천구지회',
    branchManager: '김정숙',
    officeDirector: '이미수',
  },
  {
    regionLabel: '서울(사무실)',
    id: 3,
    branchName: '사)대한미용사회 강북구지회',
    branchManager: '박정윤',
    officeDirector: '최미숙',
  },
  {
    regionLabel: '서울(사무실)',
    id: 16,
    branchName: '사)대한미용사회 관악구지회',
    branchManager: '김선녀',
    officeDirector: '이호영',
  },
  {
    regionLabel: '서울(사무실)',
    id: 2,
    branchName: '사)대한미용사회 광진구지회',
    branchManager: '송순복',
    officeDirector: '옥연주',
  },
  {
    regionLabel: '서울(사무실)',
    id: 18,
    branchName: '사)대한미용사회 용산구지회',
    branchManager: '전재만',
    officeDirector: '이창우',
  },
  {
    regionLabel: '서울(사무실)',
    id: 17,
    branchName: '사)대한미용사회 영등포구지회',
    branchManager: '김정분',
    officeDirector: '김영주',
  },
  {
    regionLabel: '서울(사무실)',
    id: 12,
    branchName: '사)대한미용사회 은평구지회',
    branchManager: '채순단',
    officeDirector: '김종우',
  },
  {
    regionLabel: '서울(사무실)',
    id: 9,
    branchName: '사)대한미용사회 중랑구지회',
    branchManager: '최은옥',
    officeDirector: '송기숙',
  },
  {
    regionLabel: '서울(사무실)',
    id: 22,
    branchName: '사)대한미용사회 동대문구지회',
    branchManager: '권소희',
    officeDirector: '김영수',
  },
  {
    regionLabel: '서울(사무실)',
    id: 5,
    branchName: '사)대한미용사회 송파구지회',
    branchManager: '전주현',
    officeDirector: '박근석',
  },
  {
    regionLabel: '서울(사무실)',
    id: 25,
    branchName: '사)대한미용사회 종로구지회',
    branchManager: '유정숙',
    officeDirector: '김재한',
  },
  {
    regionLabel: '서울(사무실)',
    id: 15,
    branchName: '사)대한미용사회 서울강서구지회',
    branchManager: '한임석',
    officeDirector: '박미라',
  },
  {
    regionLabel: '서울(사무실)',
    id: 24,
    branchName: '사)대한미용사회 서울중구지회',
    branchManager: '권오희',
    officeDirector: '이미옥',
  },
  {
    regionLabel: '서울(사무실)',
    id: 20,
    branchName: '사)대한미용사회 서대문구지회',
    branchManager: '장선숙',
    officeDirector: '남순원',
  },
  {
    regionLabel: '서울(사무실)',
    id: 10,
    branchName: '사)대한미용사회 동작구지회',
    branchManager: '유은파',
    officeDirector: '김효동',
  },
  {
    regionLabel: '서울(사무실)',
    id: 1,
    branchName: '사)대한미용사회 성북구지회',
    branchManager: '최병옥',
    officeDirector: '황선규',
  },
  {
    regionLabel: '서울(사무실)',
    id: 19,
    branchName: '사)대한미용사회 마포구지회',
    branchManager: '하지송',
    officeDirector: '김미경',
  },
  {
    regionLabel: '서울(사무실)',
    id: 23,
    branchName: '사)대한미용사회 성동구지회',
    branchManager: '주영이',
    officeDirector: '서이석',
  },
  {
    regionLabel: '서울(사무실)',
    id: 6,
    branchName: '사)대한미용사회 서초구지회',
    branchManager: '박민숙',
    officeDirector: '신민순',
  },
  {
    regionLabel: '서울(사무실)',
    id: 21,
    branchName: '사)대한미용사회 도봉구지회',
    branchManager: '강소연',
    officeDirector: '박붕엽',
  },
  {
    regionLabel: '서울(사무실)',
    id: 7,
    branchName: '사)대한미용사회 양천구지회',
    branchManager: '강신열',
    officeDirector: '정정욱',
  },
  {
    regionLabel: '부산(사무실)',
    id: 34,
    branchName: '사)대한미용사회 부산금정구지회',
    branchManager: '정두심',
    officeDirector: '-',
  },
  {
    regionLabel: '부산(사무실)',
    id: 33,
    branchName: '사)대한미용사회 부산강서구지회',
    branchManager: '고나연(직대)',
    officeDirector: '-',
  },
  {
    regionLabel: '부산(사무실)',
    id: 35,
    branchName: '사)대한미용사회 부산사하구지회',
    branchManager: '팽경자',
    officeDirector: '최요한',
  },
  {
    regionLabel: '부산(사무실)',
    id: 28,
    branchName: '사)대한미용사회 부산중구지회',
    branchManager: '박정해',
    officeDirector: '한시나',
  },
  {
    regionLabel: '부산(사무실)',
    id: 27,
    branchName: '사)대한미용사회 부산진구지회',
    branchManager: '원명자',
    officeDirector: '손효준',
  },
  {
    regionLabel: '부산(사무실)',
    id: 30,
    branchName: '사)대한미용사회 부산연제구지회',
    branchManager: '서성숙',
    officeDirector: '정문용',
  },
  {
    regionLabel: '부산(사무실)',
    id: 41,
    branchName: '사)대한미용사회 부산영도구지회',
    branchManager: '차명애',
    officeDirector: '왕혜경',
  },
  {
    regionLabel: '부산(사무실)',
    id: 38,
    branchName: '사)대한미용사회 부산북구지회',
    branchManager: '황병숙',
    officeDirector: '김강호',
  },
  {
    regionLabel: '부산(사무실)',
    id: 26,
    branchName: '사)대한미용사회 부산기장군지회',
    branchManager: '이미화',
    officeDirector: '전남현',
  },
  {
    regionLabel: '부산(사무실)',
    id: 32,
    branchName: '사)대한미용사회 부산남구지회',
    branchManager: '하태정',
    officeDirector: '이재광',
  },
  {
    regionLabel: '부산(사무실)',
    id: 39,
    branchName: '사)대한미용사회 부산동구지회',
    branchManager: '박미홍',
    officeDirector: '김수찬',
  },
  {
    regionLabel: '부산(사무실)',
    id: 36,
    branchName: '사)대한미용사회 부산해운대구지회',
    branchManager: '서희애',
    officeDirector: '김현철',
  },
  {
    regionLabel: '부산(사무실)',
    id: 31,
    branchName: '사)대한미용사회 부산수영구지회',
    branchManager: '양철기',
    officeDirector: '김명진',
  },
  {
    regionLabel: '부산(사무실)',
    id: 40,
    branchName: '사)대한미용사회 부산서구지회',
    branchManager: '김민주',
    officeDirector: '고민정',
  },
  {
    regionLabel: '부산(사무실)',
    id: 37,
    branchName: '사)대한미용사회 부산동래구지회',
    branchManager: '이현임',
    officeDirector: '강길영',
  },
  {
    regionLabel: '부산(사무실)',
    id: 29,
    branchName: '사)대한미용사회 부산사상구지회',
    branchManager: '홍도선',
    officeDirector: '이재호',
  },
  {
    regionLabel: '도지회(사무실)',
    id: 156,
    branchName: '사)대한미용사회 경기북부지회',
    branchManager: '권옥순',
    officeDirector: '주상란',
  },
  {
    regionLabel: '도지회(사무실)',
    id: 119,
    branchName: '사)대한미용사회 경기도지회',
    branchManager: '김장순(직대)',
    officeDirector: '-',
  },
  {
    regionLabel: '도지회(사무실)',
    id: 95,
    branchName: '사)대한미용사회 경상북도지회',
    branchManager: '심선자(직대)',
    officeDirector: '-',
  },
  {
    regionLabel: '도지회(사무실)',
    id: 96,
    branchName: '사)대한미용사회 경상남도지회',
    branchManager: '박소야',
    officeDirector: '윤은영',
  },
  {
    regionLabel: '도지회(사무실)',
    id: 157,
    branchName: '사)대한미용사회 강원도지회',
    branchManager: '김은주',
    officeDirector: '남기정',
  },
  {
    regionLabel: '도지회(사무실)',
    id: 230,
    branchName: '사)대한미용사회 전라북도지회',
    branchManager: '최인자',
    officeDirector: '이은상',
  },
  {
    regionLabel: '도지회(사무실)',
    id: 229,
    branchName: '사)대한미용사회 전남동부지회',
    branchManager: '하옥현',
    officeDirector: '조정훈',
  },
  {
    regionLabel: '도지회(사무실)',
    id: 248,
    branchName: '사)대한미용사회 제주특별자치도지회',
    branchManager: '양영단',
    officeDirector: '김명혜',
  },
  {
    regionLabel: '도지회(사무실)',
    id: 217,
    branchName: '사)대한미용사회 전남서부지회',
    branchManager: '정숙희',
    officeDirector: '허기웅',
  },
  {
    regionLabel: '도지회(사무실)',
    id: 191,
    branchName: '사)대한미용사회 충청북도지회',
    branchManager: '이옥규',
    officeDirector: '이경미',
  },
  {
    regionLabel: '도지회(사무실)',
    id: 191,
    branchName: '사)대한미용사회 충청남도지회',
    branchManager: '유금자',
    officeDirector: '박기옥',
  },
  {
    regionLabel: '대전(사무실)',
    id: 67,
    branchName: '사)대한미용사회 대전동구지회',
    branchManager: '고강철',
    officeDirector: '박옥경',
  },
  {
    regionLabel: '대전(사무실)',
    id: 66,
    branchName: '사)대한미용사회 대전서구지회',
    branchManager: '신관순',
    officeDirector: '신나영',
  },
  {
    regionLabel: '대전(사무실)',
    id: 64,
    branchName: '사)대한미용사회 대전대덕구지회',
    branchManager: '박상숙',
    officeDirector: '박오식',
  },
  {
    regionLabel: '대전(사무실)',
    id: 65,
    branchName: '사)대한미용사회 대전유성구지회',
    branchManager: '김수현',
    officeDirector: '민정민',
  },
  {
    regionLabel: '대전(사무실)',
    id: 68,
    branchName: '사)대한미용사회 대전중구지회',
    branchManager: '박종래',
    officeDirector: '황영길',
  },
  {
    regionLabel: '대구(사무실)',
    id: 47,
    branchName: '사)대한미용사회 대구북구지회',
    branchManager: '원동호',
    officeDirector: '임순남',
  },
  {
    regionLabel: '대구(사무실)',
    id: 44,
    branchName: '사)대한미용사회 대구달서구지회',
    branchManager: '박상기',
    officeDirector: '전영춘',
  },
  {
    regionLabel: '대구(사무실)',
    id: 48,
    branchName: '사)대한미용사회 대구남구지회',
    branchManager: '강경자',
    officeDirector: '-',
  },
  {
    regionLabel: '대구(사무실)',
    id: 43,
    branchName: '사)대한미용사회 대구동구지회',
    branchManager: '손금화',
    officeDirector: '박형진',
  },
  {
    regionLabel: '대구(사무실)',
    id: 46,
    branchName: '사)대한미용사회 대구서구지회',
    branchManager: '배덕순',
    officeDirector: '권오경',
  },
  {
    regionLabel: '대구(사무실)',
    id: 45,
    branchName: '사)대한미용사회 대구수성구지회',
    branchManager: '권춘자',
    officeDirector: '박영환',
  },
  {
    regionLabel: '대구(사무실)',
    id: 49,
    branchName: '사)대한미용사회 대구중구지회',
    branchManager: '장진희',
    officeDirector: '정태순',
  },
  {
    regionLabel: '대구(사무실)',
    id: 42,
    branchName: '사)대한미용사회 대구달성군지회',
    branchManager: '정용옥',
    officeDirector: '김장환',
  },
  {
    regionLabel: '광주(사무실)',
    id: 59,
    branchName: '사)대한미용사회 광주남구지회',
    branchManager: '김성자',
    officeDirector: '유경자',
  },
  {
    regionLabel: '광주(사무실)',
    id: 61,
    branchName: '사)대한미용사회 광주북구지회',
    branchManager: '김현자',
    officeDirector: '김은옥',
  },
  {
    regionLabel: '광주(사무실)',
    id: 63,
    branchName: '사)대한미용사회 광주동구지회',
    branchManager: '이예순',
    officeDirector: '박진영',
  },
  {
    regionLabel: '광주(사무실)',
    id: 62,
    branchName: '사)대한미용사회 광주서구지회',
    branchManager: '강순자',
    officeDirector: '국중규',
  },
  {
    regionLabel: '광주(사무실)',
    id: 60,
    branchName: '사)대한미용사회 광주광산구지회',
    branchManager: '이정례',
    officeDirector: '김덕희',
  },
];
