<template>
  <div class="wrapper">
    <v-breadcrumbs class="px-0" :items="breadcrumbs"></v-breadcrumbs>

    <used-market-summary
      :thumbnail="postDetailData.thumbnail"
      :price="postDetailData.price"
      :sell-type="postDetailData.type"
      :title="postDetailData.title"
      :user-name="postDetailData.userName"
      :views="postDetailData.viewsCount"
      :is-finish="postDetailData.finish"
      :created-date="postDetailData.createdDate"
    />

    <v-divider />

    <div class="viewer-wrapper">
      <h2 class="viewer-wrapper__title">상품정보</h2>
      <div class="viewer-wrapper__content">
        <div v-if="postDetailData.body === ''" class="text-center">내용이 없습니다</div>
        <post-viewer v-else :content="postDetailData.body"></post-viewer>
      </div>
    </div>

    <template v-if="userStore.getUserView.id === postDetailData.userId">
      <v-divider />

      <div class="post-action__group">
        <v-btn class="post-action__btn" flat @click="editPost">수정</v-btn>
        <v-btn class="post-action__btn" flat :loading="deletePostBtnLoading" @click="deletePost">삭제</v-btn>
      </div>
    </template>

    <v-divider thickness="2"></v-divider>

    <!-- 댓글 작성-->

    <div class="text__body-large mb-4">{{ commentsLength }}개의 댓글</div>
    <v-textarea
      v-model="writedComment"
      :disabled="!userStore.getUserView?.id"
      :label="!!userStore.getUserView?.id ? '댓글을 적어주세요' : '댓글을 작성하려면 로그인이 필요합니다.'"
      placeholder="권리침해, 욕설 및 특정 대상을 비하하는 내용을 게시할 경우 이용약관 및 관련법률에 의해 제제될 수 있습니다. 공공기기에서는 사용 후 로그아웃 해주세요."
      maxlength="400"
      full-width
      counter
    ></v-textarea>

    <div v-if="!!userStore.getUserView?.id" class="post-btn__wrapper">
      <v-btn v-if="commentAction === CREATE" class="post-btn" flat @click="createCommentHandler">등록</v-btn>
      <v-btn v-if="commentAction === EDIT" class="post-btn" flat @click="updateComment">수정</v-btn>
      <v-btn v-if="commentAction === EDIT" class="post-btn reply-cancel-btn" flat @click="cancelEditComment">수정 취소</v-btn>
    </div>
  </div>

  <!-- 댓글 리스트 -->
  <div class="p-2 my-12">
    <div v-for="commentInfo in comments || []" :key="commentInfo.commentId">
      <v-divider />
      <v-row>
        <v-col class="comment__row">
          <div class="comment__header">
            <span class="comment__writer">{{ commentInfo.userName }}</span>
            <span v-if="!!commentInfo?.createdDate" class="text__body-small"> {{ parseAgoDate(commentInfo.createdDate) }}</span>
          </div>

          <!--      comments action-->
          <div v-if="userStore.getUserView.id === commentInfo.userId" class="comment__action">
            <button @click="clickEditBtn(commentInfo.commentId, commentInfo.content)">수정</button>
            <button @click="deleteComment(commentInfo.commentId)">삭제</button>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="p-4">
          <div class="text__body-small">{{ commentInfo.content }}</div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axiosController from '@/lib/axiosController';
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import PostViewer from '@/components/PostViewer.vue';
import UsedMarketSummary from '@/components/UsedMarketSummary.vue';
import { mdiAccount, mdiCalendarMonth, mdiEyePlus, mdiCloudDownload } from '@mdi/js';
import { useUserStore } from '@/stores';
import { parseAgoDate } from '@/utils/date';
import {
  getUsedMarketPostAPI,
  createUsedMarketCommentAPI,
  getUsedMarketCommentAPI,
  deleteUsedMarketCommentAPI,
  modifyUsedMarketCommentAPI,
  deleteUsedMarketPostAPI,
} from '@/apis/usedMarket';
import { successToast } from '@/utils/toast';

const downloadItem = (file) => {
  axiosController
    .get(file.url, { responseType: 'blob' })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = file.url.match(/(\w+)(\.\w+)+(?!.*(\w+)(\.\w+)+)/)[0];
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(console.error);
};

const [CREATE, EDIT] = ['CREATE', 'EDIT'];

export default {
  components: { PostViewer, UsedMarketSummary },
  props: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const userStore = useUserStore();

    const writeCommentLoading = ref(false);
    const writedComment = ref('');
    const commentAction = ref(CREATE);
    const targetCommentId = ref(null);

    const comments = ref([]);
    const commentsLength = computed(() => (comments.value || []).length);
    const postDetailData = ref({
      body: '',
      commentViewList: [],
      createdDate: '',
      finish: false,
      postId: 0,
      price: 0,
      thumbnail: '',
      title: '',
      type: '',
      userId: 0,
      userName: '',
      viewCount: 0,
    });

    const query = computed(() => route.query);

    const postId = route.params.id;

    const apiCall = async () => {
      const postData = await getUsedMarketPostAPI(postId);

      comments.value = postData.commentViewList;
      postDetailData.value = postData;
    };

    if (postId) {
      apiCall();
      document.title = '게시물 상세';
    }

    // const currentMainCategory = mainCategory[query.value.mainCategory] ?? mainCategory.community;

    const breadcrumbs = [
      { text: '홈', href: '/', disabled: false },
      { text: '자료&커뮤니티', href: '/community/20', disabled: false },
      { text: '중고매매', href: '/#', disabled: true },
    ];

    const onClickCancel = () => {
      router.back();
    };

    const onClickPost = () => {
      router.back();
    };

    const createCommentHandler = async () => {
      writeCommentLoading.value = true;
      await createUsedMarketCommentAPI(postId, writedComment.value);
      const newComments = await getUsedMarketCommentAPI(postId);
      comments.value = newComments;
      writedComment.value = '';
      successToast('댓글을 추가했습니다.');
    };

    const resetComment = () => {
      writedComment.value = '';
      commentAction.value = CREATE;
      targetCommentId.value = null;
    };

    const clickEditBtn = (commentId, targetComment) => {
      targetCommentId.value = commentId;
      writedComment.value = targetComment;
      commentAction.value = EDIT;
    };

    const cancelEditComment = () => {
      resetComment();
    };

    const updateComment = async () => {
      await modifyUsedMarketCommentAPI(targetCommentId.value, writedComment.value);
      successToast('댓글이 수정되었습니다.');

      resetComment();
      comments.value = await getUsedMarketCommentAPI(postId);
    };

    const deleteComment = async (commentId) => {
      await deleteUsedMarketCommentAPI(commentId);
      successToast('댓글이 삭제되었습니다.');

      comments.value = await getUsedMarketCommentAPI(postId);
    };

    /** 포스트 수정 & 삭제 */
    const editPost = () => {
      router.push(`/used-market/write?postId=${postId}`);
    };

    const deletePostBtnLoading = ref(false);
    const deletePost = async () => {
      deletePostBtnLoading.value = true;
      try {
        await deleteUsedMarketPostAPI(postId);
        successToast('게시글을 삭제했습니다.');

        router.back();
      } catch (e) {
        //
      } finally {
        deletePostBtnLoading.value = false;
      }
    };

    return {
      postId: query.postId,
      // subCategory: currentMainCategory.subCategory,
      breadcrumbs,
      comments,
      commentsLength,
      commentAction,
      postDetailData,
      writedComment,
      clickEditBtn,
      onClickCancel,
      onClickPost,
      mdiAccount,
      mdiCalendarMonth,
      mdiEyePlus,
      downloadItem,
      mdiCloudDownload,
      userStore,
      parseAgoDate,
      createCommentHandler,
      updateComment,
      cancelEditComment,
      deleteComment,
      CREATE,
      EDIT,
      editPost,
      deletePost,
      deletePostBtnLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-bottom: 1.5rem;

  @include sm-and-down {
    padding: 1rem;
  }
}

.viewer-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;

  &__title {
    @include spreadTypoMap($title-medium);
  }

  &__content {
    padding: 1rem;
  }
}

.cancel-btn {
  border: 1px solid $gray-30;
  margin-right: 0.5rem;
}

.post-btn {
  @include spreadTypoMap($button-small);

  background-color: $blue-50;
  color: white;

  &__wrapper {
    display: flex;
    justify-content: end;
  }
}

.post-action {
  &__group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__btn {
    background: $gray-10;
    border-radius: 4px;
    padding: 4px 8px;
    border: 1px solid $gray-20;

    @include spreadTypoMap($button-xsmall);
    color: $gray-50;

    & + & {
      margin-left: 0.5rem;
    }
  }
}

.comment {
  &__writer {
    @include spreadTypoMap($body-medium);
    color: $blue-50;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div > span:not(:last-child) {
      margin-right: 0.5rem;
    }

    @include md-and-up {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    @include md-and-up {
      display: block;
    }
  }

  &__action {
    align-self: flex-end;

    button {
      background: $gray-10;
      border-radius: 4px;
      padding: 4px 8px;
      border: 1px solid $gray-20;

      @include spreadTypoMap($button-xxsmall);
      color: $gray-50;
    }

    button + button {
      margin-left: 0.5rem;
    }
  }
}

.reply-cancel-btn {
  margin-left: 0.5rem;
  background: white;
  border: 1px solid $blue-50;
  color: $blue-50;
}
</style>
