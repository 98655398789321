import REGEX from '@/constants/commons/regex';

const inputRules = {
  required: (value) => !!value || '필수로 입력해주세요.',
  maxLength: (maxLength) => (value) => (value && value.length <= maxLength) || `${maxLength}글자 이하로 입력해주세요.`,
  minLength: (minLength) => (value) => (value && value.length >= minLength) || `${minLength}글자 이상 입력해주세요.`,
  onlyNumber: (value) => (value && REGEX.number.test(value)) || '숫자만 입력해주세요.',
  checkPhone: (value) => (value && REGEX.phone.test(value)) || '올바른 전화번호를 입력해주세요.',
};

export default inputRules;
