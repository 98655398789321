<template>
  <page title="뷰티">
    <template #main-section>
      <v-row v-resize="onResize" class="m-0"> </v-row>
      <tab2 v-model="selectedTab" :tabs="tabs" is-full-width :tab-action="tabAction" />
      <div class="wrapper hidden-sm-and-down">
        <!--        <button class="view-type-btn">-->
        <!--          리스트 형태<icon-base style="margin-left: 4px"><chevron-down-arrow /></icon-base>-->
        <!--        </button>-->
        <!--        <div style="display: flex">-->
        <!--          <select-box :options="selectOptions" />-->
        <!--          <div class="input-wrapper">-->
        <!--            <input type="search" placeholder="텍스트를 입력하세요" class="text-input" />-->
        <!--            <button class="search-btn">검색</button>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>

      <!--      뉴스 뷰-->
      <template v-if="numberOfElements > 0">
        <news-list :news-list="isMobileUi ? newsListMobile.data : newsListPC.data" />
        <div v-if="page < totalPages" class="more-btn-wrapper text-center mb-12">
          <button type="button" class="view-more-btn" :disabled="totalPages === page" @click="displayMore">더보기</button>
        </div>
      </template>

      <!--      empty 뷰-->
      <template v-else>
        <div
          style="
            background: #e5e7eb;
            text-align: center;
            vertical-align: middle;
            height: 400px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          :style="`margin: ${isMobileUi ? '20px' : '24px 0'}`"
        >
          <div :class="{ 'text__body-xxLarge': !isMobileUi, 'text__body-medium': isMobileUi }" style="color: #6c727f">데이터가 없습니다.</div>
        </div>
      </template>
      <div class="pagination-wrapper text-center">
        <v-pagination v-model="page" :length="totalPages || 1" />
      </div>
    </template>
  </page>
</template>

<script>
import { reactive, ref, watch, computed, onMounted } from 'vue';
import Page from '@/components/Page';
import Tab2 from '@/components/Tab2';
// import SelectBox from '@/components/SelectBox';
import NewsList from '@/containers/News/NewsList';
// import ChevronDownArrow from '@/icons/ChevronDownArrow';
import { useRoute } from 'vue-router';
import { useMenuStore } from '@/stores';
import { getArticles } from '@/apis/article';

export default {
  name: 'Beauty',
  components: {
    NewsList,
    Page,
    Tab2,
    // ChevronDownArrow,
    // SelectBox,
  },
  setup() {
    const windowSize = reactive({ x: 0, y: 0 });
    const isMobileUi = computed(() => windowSize.x < 960);

    const menuStore = useMenuStore();
    const tabs = computed(() => {
      const pageId = menuStore.getMenu?.find(({ name }) => name === '뷰티')?.id ?? 1;
      return menuStore.getMenuMap[pageId];
    });

    const page = ref(1);
    const route = useRoute();
    const id = Number(route.params.beautyId); // read parameter id (it is reactive)

    const selectOptions = [
      { id: 'all', label: '전체' },
      { id: 'title', label: '제목' },
      { id: 'author', label: '작성자' },
    ];
    const selectedTab = ref(id);

    const newsListPC = reactive({ data: [] });
    const newsListMobile = reactive({ data: [] });
    const totalPages = ref(0);
    const numberOfElements = ref(0);

    const onResize = () => {
      windowSize.x = window.innerWidth;
      windowSize.y = window.innerHeight;
    };

    const setArticles = (res, clearMobileData = false) => {
      totalPages.value = res.totalPages;
      numberOfElements.value = res.numberOfElements;
      newsListPC.data = res.content;
      newsListMobile.data = clearMobileData ? res.content : newsListMobile.data.concat(res.content);
    };

    onMounted(() => {
      onResize();

      (async () => {
        const res = await getArticles({ page: page.value - 1, newsId: selectedTab.value });
        setArticles(res);
      })();
    });

    watch([page, selectedTab], ([nextPage, nextSelectedTab], [, prevSelectedTab]) => {
      (async () => {
        const res = await getArticles({ page: nextPage - 1, newsId: nextSelectedTab });
        const clearMobileData = prevSelectedTab !== nextSelectedTab;
        setArticles(res, clearMobileData);
      })();
    });

    /** 탭 이동시 페이지 리셋 */
    const resetPage = () => {
      page.value = 1;
    };

    const displayMore = () => {
      page.value = page.value + 1;
    };

    return {
      onResize,
      isMobileUi,
      windowSize,
      tabs,
      newsListPC,
      newsListMobile,
      totalPages,
      numberOfElements,
      page,
      selectOptions,
      selectedTab,
      tabAction: resetPage,
      displayMore,
    };
  },
};
</script>

<style scoped lang="scss">
@import 'src/styles/custom/customVPagination';

.wrapper {
  @include md-and-up {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
  }
}

.input-wrapper {
  margin-left: 8px;
  background: white;
  display: flex;
}

.text-input {
  min-width: 249px;
  margin-right: 8px;
  border-bottom: 1px solid $gray-30;
  height: 48px;

  &:focus {
    outline: none;
  }
}

.search-btn {
  background: $blue-50;
  border-radius: 4px;
  padding: 16px 18px;
  height: 48px;

  @include spreadTypoMap($button-medium);
  color: white;
}

.view-type-btn {
  @include spreadTypoMap($button-medium);

  background: white;
  padding: 16px 12px 16px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray-30;
  border-radius: 4px;
  margin-right: 8px;
  height: 48px;
}

.more-btn-wrapper {
  display: block;
  margin-bottom: 47.94px;

  @include md-and-up {
    display: none;
  }
}

.pagination-wrapper:deep {
  display: none;

  @include md-and-up {
    display: block;
    margin-bottom: 47.94px;

    @include custom-v-pagination;
  }
}

.view-more-btn {
  border: 1px solid $gray-20;
  padding: 10px 41.5px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;

  @include md-and-up {
    display: none;
  }
}
</style>
