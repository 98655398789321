<template>
  <div class="bg">
    <div class="mr-16">
      <div class="category">클래스 특징</div>
      <div class="wrapper__title">이론부터<br />커트 테크닉까지,</div>
    </div>

    <div class="bg-wrapper">
      <div class="wrapper">
        <div class="subtitle mb-2">여성 커트에 필요한 모든 내용을 한 번에!</div>
        <div class="content">
          다음 두 스타일, 무엇이 다른지 아시겠나요? 두상의 굴곡과 각도, 형태와 섹션에 따라 디테일이 다른 커트가 완성됩니다. 여성 커트와 대비되는 남성 커트만의
          특징에 대한 이해를 바탕으로 남성 헤어 커트의 4가지 핵심 원리와 3 포지션에 대한 개념을 배워보세요. 재방문율을 높이는 커트 실력을 갖출 수 있게 될
          거예요.
        </div>
      </div>

      <img class="img-wrapper" src="https://storage.googleapis.com/static.fastcampus.co.kr/prod/uploads/202106/141523-380/choijaeyoung-sellingpoint.png" />

      <div class="wrapper">
        <div class="subtitle mb-2">ABC만 알면 될까? 실전에서 많이 쓰이는 D(디스커넥션)</div>
        <div class="content">
          커트 교본 사순 ABC를 기반으로, 실제 살롱워크에 바로 적용 가능한 D(디스커넥션) 테크닉까지 한 번에 알려드립니다. 동양인에게 딱 맞는 디스커넥션 커트
          테크닉을 이용한 실전 살롱 디자인 6가지를 모델 실습을 보고 따라 하는 것을 넘어, 고객을 상대로 직접 응용하는 방법까지 디테일하게 다뤄 봅니다.
        </div>
      </div>
      <img class="img-wrapper" src="https://storage.googleapis.com/static.fastcampus.co.kr/prod/uploads/202106/124518-380/sellingpoint3.png" />
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: white;
  border-radius: 20px;
  padding: 80px;
  display: flex;
  margin-bottom: 2.5rem;
}

.bg-wrapper {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 5rem;
  }
}

.subtitle {
  font-weight: 600;
  font-size: 28px;
  line-height: 150%;
}

.content {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 5rem;
  color: #4d5562;
}

.category {
  color: $koba-red;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}

.img-wrapper {
  width: 720px;
  height: 480px;
}
.wrapper {
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    white-space: nowrap;
  }

  &__desc {
    @include spreadTypoMap($body-large);
    color: $gray-80;
  }
}
</style>
