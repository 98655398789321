export const tabs = [
  { id: 0, name: '사업자 주거래 우대통장' },
  { id: 1, name: '미용사 전용 제휴카드' },
  { id: 2, name: '가맹점 결제 계좌변경' },
];

export const cards = {
  0: [
    {
      label: '가입 대상',
      title: '개인 사업자라면 누구나',
      description: '(1인 1계좌 개설 가능)',
    },
    {
      label: '우대조건',
      title: '하나카드 또는\n' + 'BC카드 가맹점 매출 대금 이체',
      description: '',
    },
    {
      label: '우대사항',
      title: '수수료 우대 서비스',
      description:
        'ATM 마감 후 현금인출거래 / ATM 타행이체 거래\n' +
        '인터넷, ARS, 모바일 뱅킹\n' +
        '타행체 거래 & 타행 자동이체\n' +
        '창구 타행이체\n' +
        '타행 ATM 현금인출 거래(제휴 ATM기 제외)',
    },
  ],
  1: [
    {
      label: '혜택',
      title: '1인당 최대 8만머니 적립',
      description:
        '하나카드 최초신규시 5만머니 및 연회비 적립\n(발급 후 30만원 이상 사용자에 한함)\n하나은행 결제계좌 연결신규 2만머니 적립\n(기존 하나카드 회원도 지원대상)',
    },
    {
      label: '카드 상품 1',
      title: '사업자 전용 1Q 비즈니스 카드',
      description: '월 최대 4만5천머니 적립 + 스타벅스/커피빈 4천원 할인',
    },
    {
      label: '카드 상품 2',
      title: '비사업자 전용 1Q 쇼핑 카드',
      description: '월 최대 5만5천머니 적립 + 스타벅스/커피빈 4천원 할인',
    },
  ],
  2: [
    {
      label: '혜택1',
      title: '가맹점 결제계좌 변경비용 전액 무료',
      description: '',
    },
    {
      label: '혜택2',
      title: '카드단말기 월 이용수수료 \n' + '전액면제',
      description: '기존약정 종료된 카드단말기에 한함',
    },
    {
      label: '혜택3',
      title: '최신 카드단말기 무료 변경',
      description: '상세 문의: (주) 씨앤씨밴 1566-7322',
    },
  ],
};
