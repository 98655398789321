<template>
  <div class="d-flex flex-column justify-content-center align-items-center" :class="className">
    <div v-if="title" class="title">{{ title }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include spreadTypoMap($title-large);
  color: $black;
  text-align: center;
  margin: 0;

  @include sm-and-down {
    @include spreadTypoMap($title-xsmall);
  }
}
</style>
