<template>
  <v-dialog :model-value="securePostDialog" persistent>
    <v-card width="400">
      <v-container>
        <v-card-title>비밀글 암호 입력</v-card-title>
        <v-card-text>
          <basic-input
            id="post-password"
            placeholder="비밀글 암호를 입력하세요"
            type="password"
            :text-value="postPassword"
            persistent-hint
            hide-details
            @update:textValue="(value) => $emit('update:postPassword', value)"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="goBack">{{ goBackText }}</v-btn>
          <v-btn @click="getSecurePost">보러가기</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import BasicInput from '@/components/BasicInput.vue';

export default {
  components: { BasicInput },
  props: {
    securePostDialog: {
      type: Boolean,
      default: false,
    },
    getSecurePost: {
      type: Function,
      default: () => {},
    },
    goBack: {
      type: Function,
      default: () => {},
    },
    goBackText: {
      type: String,
      default: '뒤로가기',
    },
    postPassword: {
      type: String,
      default: '',
    },
  },
};
</script>

<style></style>
