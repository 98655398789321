<template>
  <div v-if="showOptions" class="select-box__back" @click="toggleOptions" />
  <div class="select-box__wrapper">
    <button type="button" class="select-box__selected" :class="{ 'select-box__full-width': isFullWidth }" :style="`color: ${textColor}`" @click="toggleOptions">
      {{ selected || defaultOption }}
      <icon-base icon-name="chevron-down-arrow" :icon-color="iconColor" :width="iconWidth" :height="iconHeight" class="select-box__icon">
        <chevron-down-arrow />
      </icon-base>
    </button>
    <ul v-if="showOptions" class="select-box__options custom-select-box__options" @click="onClickOption">
      <li v-for="({ id, label }, idx) in options" :key="idx" :data-option-value="id" :data-option-label="label">{{ label }}</li>
    </ul>
  </div>
</template>

<script>
import ChevronDownArrow from '@/icons/ChevronDownArrow';
import { computed, ref, watch } from 'vue';
import { isEmpty } from 'lodash-es';

const useSelectBox = (props, attrs) => {
  const defaultOption = computed(() => props.defaultOption || props.options?.[0]?.label);

  const showOptions = ref(false);
  const selected = ref('');

  /** 첫 데이터 세팅 땐 defaultOption, 이후엔 옵션이 변경되면 제일 첫번째의 라벨로 초기화 */
  watch(
    () => props.options,
    (cur, prev) => {
      if (isEmpty(prev) && !isEmpty(cur)) {
        selected.value = defaultOption.value;
        return;
      }
      selected.value = cur[0].label;
    },
  );

  const toggleOptions = () => {
    showOptions.value = !showOptions.value;
  };
  const onClickOption = (e) => {
    const {
      dataset: { optionLabel, optionValue },
    } = e.target;

    selected.value = optionLabel;

    toggleOptions();
    attrs?.onClick?.({ target: { name: attrs.name, value: optionValue, label: optionLabel } });
  };

  return { showOptions, selected, defaultOption, toggleOptions, onClickOption };
};

export default {
  name: 'SelectBox',
  components: { ChevronDownArrow },
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    defaultOption: {
      type: String,
      default: '',
    },
    iconWidth: {
      type: Number,
      default: 24,
    },
    iconHeight: {
      type: Number,
      default: 24,
    },
    textColor: {
      type: String,
      default: '#0070bc',
    },
    iconColor: {
      type: String,
      default: '#0070bc',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { attrs }) {
    const { showOptions, toggleOptions, onClickOption, selected } = useSelectBox(props, attrs);

    return { selected, showOptions, toggleOptions, onClickOption };
  },
};
</script>

<style lang="scss" scoped>
.select-box__back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

.select-box__wrapper {
  position: relative;
}

.select-box__full-width {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-box__selected {
  @include spreadTypoMap($body-medium);
  color: $blue-50;
  padding: 12px 0;
}

.select-box__icon {
  margin-left: 4px;
}

.select-box__options {
  position: absolute;
  top: 0;
  left: 0;

  list-style: none;

  background: white;
  border: 1px solid $gray-30;
  border-radius: 4px;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 5px 5px 8px -3px rgba(0, 0, 0, 0.3);
  z-index: 1;

  @include spreadTypoMap($body-small);

  & > li + li {
    border-top: 1px solid $gray-30;
  }

  & > li {
    padding: 4px;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }
}
</style>
