<template>
  <div class="wrapper" :style="`background: ${background}`">
    <v-row v-resize="onResize" class="m-0" style="display: none" />
    <v-col md="2" lg="2" class="aside" tag="aside"><slot name="aside-left" /></v-col>
    <v-col sm="12" md="8" lg="8" class="p-0" tag="section">
      <slot name="main-section" :isMobileUi="isMobileUi" />
    </v-col>
    <v-col md="2" lg="2" class="aside" tag="aside"><slot name="aside-right" /></v-col>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from 'vue';

export default {
  name: 'SectionLayout',
  props: {
    background: {
      type: String,
      default: 'white',
    },
  },
  setup() {
    const windowSize = reactive({ x: 0, y: 0 });
    const isMobileUi = computed(() => windowSize.x < 960);

    const onResize = () => {
      windowSize.x = window.innerWidth;
      windowSize.y = window.innerHeight;
    };

    onMounted(() => {
      onResize();
    });

    return { isMobileUi, onResize };
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  display: flex;
}

.aside {
  display: none;
  padding: 0;

  @include md-and-up {
    display: block;
  }
}

.center-title {
  text-align: center;
}
</style>
