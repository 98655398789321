import {
  EmptyTable,
  GrievanceTable,
  HairArtTable,
  HairSketchTable,
  HairSkillTable,
  MakeupTable,
  MarketingTable,
  TraditionalTable,
  WigTable,
} from '@/components/committee';

// 테이블 헤더들
const marketingTableHeaders = [
  { id: 'position', label: '직위' },
  { id: 'name', label: '성명' },
  { id: 'address', label: '주소' },
];

const hairSkillTableHeaders = [
  { id: 'position', label: '직위' },
  { id: 'name', label: '성명' },
  { id: 'number', label: '기수' },
  { id: 'note', label: '비고' },
];

const hairSketchTableHeaders = [
  { id: 'position', label: '직위' },
  { id: 'name', label: '성명' },
  { id: 'number', label: '기수' },
  { id: 'note', label: '비고' },
];

const globalBeautyTableHeaders = [
  { id: 'position', label: '직위' },
  { id: 'name', label: '성명' },
  { id: 'number', label: '기수' },
  { id: 'note', label: '비고' },
];

const traditionalTableHeaders = [
  { id: 'position', label: '직위' },
  { id: 'name', label: '성명' },
  { id: 'number', label: '기수' },
  { id: 'note', label: '비고' },
];

const grievanceTableHeaders = [
  { id: 'position', label: '직위' },
  { id: 'name', label: '성명' },
];

const scalpTableHeaders = [
  { id: 'position', label: '직위' },
  { id: 'name', label: '성명' },
];

const makeupTableHeaders = [
  { id: 'position', label: '직위' },
  { id: 'name', label: '성명' },
  { id: 'note', label: '비고' },
];

const hairArtTableHeaders = [
  { id: 'position', label: '직위' },
  { id: 'name', label: '성명' },
  { id: 'note', label: '비고' },
];

const wigTableHeaders = [
  { id: 'position', label: '직위' },
  { id: 'name', label: '성명' },
];

// 위원회 콘텐츠
const marketingCommittee = [
  { id: '0', position: '위원장', name: '이광종', address: '서울 금천구', highlight: true },
  { id: '1', position: '자문위원', name: '황애자', address: '서울 중랑구', highlight: true },
  { id: '2', position: '부위원장(4명)', name: '남양순', address: '강원 원주시', highlight: false, rowSpan: 4 },
  { id: '3', position: '', name: '정연희', address: '경기도 수원시 권선구', highlight: false },
  { id: '4', position: '', name: '채순단', address: '서울 은평구', highlight: false },
  { id: '5', position: '', name: '노대영', address: '전남 화순군', highlight: false },
  { id: '6', position: '위원', name: '권옥순', address: '경기도 가평군', highlight: false, rowSpan: 36 },
  { id: '7', position: '', name: '이순녀', address: '경남 진주시', highlight: false },
  { id: '8', position: '', name: '이문희', address: '경기 고양시 덕양구', highlight: false },
  { id: '9', position: '', name: '이정실', address: '전남 나주시', highlight: false },
  { id: '10', position: '', name: '김미숙', address: '인천 남동구', highlight: false },
  { id: '11', position: '', name: '임준경', address: '경기 부천시', highlight: false },
  { id: '12', position: '', name: '박경옥', address: '대구시 수성구', highlight: false },
  { id: '13', position: '', name: '이정옥', address: '충남 보령시', highlight: false },
  { id: '14', position: '', name: '배희자', address: '경기 화성시', highlight: false },
  { id: '15', position: '', name: '김희숙', address: '전남 고흥군', highlight: false },
  { id: '16', position: '', name: '이애숙', address: '전남 보성군', highlight: false },
  { id: '17', position: '', name: '박정해', address: '부산 중구', highlight: false },
  { id: '18', position: '', name: '안귀례', address: '서울시 은평구', highlight: false },
  { id: '19', position: '', name: '손월순', address: '충남 당진시', highlight: false },
  { id: '20', position: '', name: '심근하', address: '경남 창원시 마산합포구', highlight: false },
  { id: '21', position: '', name: '강복순', address: '충남 당진시', highlight: false },
  { id: '22', position: '', name: '이호준', address: '경기 하남시', highlight: false },
  { id: '23', position: '', name: '김경숙', address: '충남 보령시', highlight: false },
  { id: '24', position: '', name: '김미숙', address: '경남 김해시', highlight: false },
  { id: '25', position: '', name: '박선자', address: '경남 창원시 진해구', highlight: false },
  { id: '26', position: '', name: '이정훈', address: '경남 함양군', highlight: false },
  { id: '27', position: '', name: '윤 희', address: '경기 화성시', highlight: false },
  { id: '28', position: '', name: '이나경', address: '경기 고양시 일산서구', highlight: false },
  { id: '29', position: '', name: '최연숙', address: '경남 창원시 의창구', highlight: false },
  { id: '30', position: '', name: '정은주', address: '경기도 파주시', highlight: false },
  { id: '31', position: '', name: '최은경', address: '서울시 광진구', highlight: false },
  { id: '32', position: '', name: '김영배', address: '경기 남양주시', highlight: false },
  { id: '33', position: '', name: '왕상석', address: '전북 전주시 완산구', highlight: false },
  { id: '34', position: '', name: '강소연', address: '서울시 도봉구', highlight: false },
  { id: '35', position: '', name: '김 겸', address: '전남 여수시', highlight: false },
  { id: '36', position: '', name: '한중석', address: '충북 충주시', highlight: false },
  { id: '37', position: '', name: '박정윤', address: '서울 강북구', highlight: false },
  { id: '38', position: '', name: '유영례', address: '인천 계양구', highlight: false },
  { id: '39', position: '', name: '성이진', address: '', highlight: false },
  { id: '40', position: '', name: '오영진', address: '', highlight: false },
];

const hairSkillCommittee = [
  { id: '0', position: '위원장', name: '송순복', number: '4기', note: '', highlight: true },
  { id: '1', position: '부위원장(6명)', name: '박일순', number: '3기', note: '', highlight: false, rowSpan: 6 },
  { id: '2', position: '', name: '김경희', number: '4기', note: '', highlight: false },
  { id: '3', position: '', name: '김지윤', number: '6기', note: '', highlight: false },
  { id: '4', position: '', name: '장선숙', number: '6기', note: '', highlight: false },
  { id: '5', position: '', name: '이해분', number: '7기', note: '', highlight: false },
  { id: '6', position: '', name: '박주화', number: '12기', note: '', highlight: false },
  { id: '7', position: '총무', name: '이영숙', number: '9기', note: '', highlight: true },
  { id: '8', position: '운영위원(53명)', name: '안옥희', number: '4기', note: '', highlight: false, rowSpan: 53 },
  { id: '9', position: '', name: '하지송', number: '6기', note: '', highlight: false },
  { id: '10', position: '', name: '김모진', number: '8기', note: '', highlight: false },
  { id: '11', position: '', name: '박순례', number: '8기', note: '', highlight: false },
  { id: '12', position: '', name: '왕순자', number: '8기', note: '', highlight: false },
  { id: '13', position: '', name: '김장순', number: '9기', note: '', highlight: false },
  { id: '14', position: '', name: '박순임', number: '9기', note: '', highlight: false },
  { id: '15', position: '', name: '양경순', number: '9기', note: '', highlight: false },
  { id: '16', position: '', name: '진현진', number: '9기', note: '', highlight: false },
  { id: '17', position: '', name: '김재숙', number: '10기', note: '', highlight: false },
  { id: '18', position: '', name: '배강미', number: '10기', note: '', highlight: false },
  { id: '19', position: '', name: '오송림', number: '10기', note: '', highlight: false },
  { id: '20', position: '', name: '이예순', number: '10기', note: '', highlight: false },
  { id: '21', position: '', name: '황정순', number: '10기', note: '', highlight: false },
  { id: '22', position: '', name: '고민화', number: '11기', note: '', highlight: false },
  { id: '23', position: '', name: '김춘호', number: '11기', note: '', highlight: false },
  { id: '24', position: '', name: '안옥희', number: '11기', note: '', highlight: false },
  { id: '25', position: '', name: '양은이', number: '11기', note: '', highlight: false },
  { id: '26', position: '', name: '이민휴', number: '11기', note: '', highlight: false },
  { id: '27', position: '', name: '정귀임', number: '11기', note: '', highlight: false },
  { id: '28', position: '', name: '고강철', number: '12기', note: '', highlight: false },
  { id: '29', position: '', name: '김수현', number: '13기', note: '', highlight: false },
  { id: '30', position: '', name: '임은경', number: '13기', note: '', highlight: false },
  { id: '31', position: '', name: '정윤남', number: '13기', note: '', highlight: false },
  { id: '32', position: '', name: '최금주', number: '13기', note: '', highlight: false },
  { id: '33', position: '', name: '최유희', number: '13기', note: '', highlight: false },
  { id: '34', position: '', name: '홍혜숙', number: '13기', note: '', highlight: false },
  { id: '35', position: '', name: '박현숙', number: '14기', note: '', highlight: false },
  { id: '36', position: '', name: '방소림', number: '14기', note: '', highlight: false },
  { id: '37', position: '', name: '오성우', number: '14기', note: '', highlight: false },
  { id: '38', position: '', name: '조원옥', number: '14기', note: '', highlight: false },
  { id: '39', position: '', name: '김영란', number: '15기', note: '', highlight: false },
  { id: '40', position: '', name: '서영옥', number: '15기', note: '', highlight: false },
  { id: '41', position: '', name: '소정예', number: '15기', note: '', highlight: false },
  { id: '42', position: '', name: '송정현', number: '15기', note: '', highlight: false },
  { id: '43', position: '', name: '오술생', number: '15기', note: '', highlight: false },
  { id: '44', position: '', name: '이점숙', number: '15기', note: '', highlight: false },
  { id: '45', position: '', name: '정애순', number: '15기', note: '', highlight: false },
  { id: '46', position: '', name: '황영은', number: '15기', note: '', highlight: false },
  { id: '47', position: '', name: '김안숙', number: '16기', note: '', highlight: false },
  { id: '48', position: '', name: '김양아', number: '16기', note: '', highlight: false },
  { id: '49', position: '', name: '박성호', number: '17기', note: '', highlight: false },
  { id: '50', position: '', name: '박정금', number: '17기', note: '', highlight: false },
  { id: '51', position: '', name: '손귀영', number: '17기', note: '', highlight: false },
  { id: '52', position: '', name: '안미경', number: '17기', note: '', highlight: false },
  { id: '53', position: '', name: '이영희', number: '17기', note: '', highlight: false },
  { id: '54', position: '', name: '최은경', number: '17기', note: '', highlight: false },
  { id: '55', position: '', name: '한영순', number: '17기', note: '', highlight: false },
  { id: '56', position: '', name: '황현숙', number: '17기', note: '', highlight: false },
  { id: '57', position: '', name: '권정란', number: '18기', note: '', highlight: false },
  { id: '58', position: '', name: '신화남', number: '18기', note: '', highlight: false },
  { id: '59', position: '', name: '장금자', number: '18기', note: '', highlight: false },
  { id: '60', position: '', name: '황춘희', number: '18기', note: '', highlight: false },
  { id: '61', position: '감사(2명)', name: '원동호', number: '11기', note: '', highlight: false, rowSpan: 2 },
  { id: '62', position: '', name: '박송재', number: '11기', note: '', highlight: false },
];

const hairSketchCommittee = [
  { id: '0', position: '위원장', name: '김선녀', number: '1기', note: '', highlight: true },
  { id: '1', position: '부위원장', name: '전정자', number: '1기', note: '', highlight: false },
  { id: '2', position: '총무', name: '김미경', number: '2기', note: '', highlight: true },
  { id: '3', position: '학술위원', name: '백단심', number: '2기', note: '', highlight: false, rowSpan: 6 },
  { id: '4', position: '', name: '유건숙', number: '2기', note: '', highlight: false },
  { id: '5', position: '', name: '유소연', number: '2기', note: '', highlight: false },
  { id: '6', position: '', name: '최민자', number: '2기', note: '', highlight: false },
  { id: '7', position: '', name: '마은정', number: '3기', note: '', highlight: false },
  { id: '8', position: '', name: '임대혁', number: '6기', note: '', highlight: false },
  { id: '9', position: '위원', name: '권영해', number: '2기', note: '', highlight: false, rowSpan: 9 },
  { id: '10', position: '', name: '진화영', number: '4기', note: '', highlight: false },
  { id: '11', position: '', name: '방경례', number: '5기', note: '', highlight: false },
  { id: '12', position: '', name: '오수연', number: '5기', note: '', highlight: false },
  { id: '13', position: '', name: '이은옥', number: '5기', note: '', highlight: false },
  { id: '14', position: '', name: '현서경', number: '5기', note: '', highlight: false },
  { id: '15', position: '', name: '강수희', number: '6기', note: '', highlight: false },
  { id: '16', position: '', name: '배영언', number: '6기', note: '', highlight: false },
  { id: '17', position: '', name: '정미영', number: '6기', note: '', highlight: false },
];

const globalBeautyCommittee = [
  { id: '0', position: '위원장', name: '권기형', number: '9기', note: '', highlight: true },
  { id: '1', position: '부위원장(5명)', name: '양애순', number: '6기', note: '', highlight: false, rowSpan: 5 },
  { id: '2', position: '', name: '최복자', number: '9기', note: '', highlight: false },
  { id: '3', position: '', name: '이미숙', number: '10기', note: '', highlight: false },
  { id: '4', position: '', name: '이승현 (승미)', number: '10기', note: '', highlight: false },
  { id: '5', position: '', name: '임은경', number: '13기', note: '', highlight: false },
  { id: '6', position: '총무', name: '김승연', number: '11기', note: '', highlight: true },
  { id: '7', position: '위원', name: '오미경', number: '11기', note: '', highlight: false, rowSpan: 19 },
  { id: '8', position: '', name: '김소흔', number: '12기', note: '', highlight: false },
  { id: '9', position: '', name: '장미숙', number: '12기', note: '', highlight: false },
  { id: '10', position: '', name: '노재웅', number: '13기', note: '', highlight: false },
  { id: '11', position: '', name: '이민애', number: '13기', note: '', highlight: false },
  { id: '12', position: '', name: '최영규', number: '13기', note: '', highlight: false },
  { id: '13', position: '', name: '구자경', number: '15기', note: '', highlight: false },
  { id: '14', position: '', name: '윤미영', number: '15기', note: '', highlight: false },
  { id: '15', position: '', name: '제갈미', number: '15기', note: '', highlight: false },
  { id: '16', position: '', name: '가상란', number: '17기', note: '', highlight: false },
  { id: '17', position: '', name: '김은지', number: '17기', note: '', highlight: false },
  { id: '18', position: '', name: '오승현', number: '17기', note: '', highlight: false },
  { id: '19', position: '', name: '손진아', number: '', note: '', highlight: false },
  { id: '20', position: '', name: '임옥진', number: '', note: '', highlight: false },
  { id: '21', position: '', name: '김신아', number: '', note: '', highlight: false },
  { id: '22', position: '', name: '박하늘', number: '', note: '', highlight: false },
  { id: '23', position: '', name: '정명호', number: '', note: '', highlight: false },
  { id: '24', position: '', name: '박형대', number: '', note: '', highlight: false },
  { id: '25', position: '', name: '황수민', number: '', note: '', highlight: false },
];

const traditionalCommittee = [
  { id: '0', position: '위원장', name: '주외숙', number: '5기', note: '', highlight: true },
  { id: '1', position: '부위원장(4명)', name: '정기분', number: '2기', note: '', highlight: false, rowSpan: 4 },
  { id: '2', position: '', name: '최미숙', number: '2기', note: '', highlight: false },
  { id: '3', position: '', name: '김영숙', number: '3기', note: '', highlight: false },
  { id: '4', position: '', name: '최복자', number: '4기', note: '', highlight: false },
  { id: '5', position: '총무', name: '이효지', number: '2기', note: '', highlight: true },
  { id: '6', position: '위원(14명)', name: '박은경', number: '4기', note: '', highlight: false, rowSpan: 14 },
  { id: '7', position: '', name: '석인자', number: '4기', note: '', highlight: false },
  { id: '8', position: '', name: '신송자', number: '4기', note: '', highlight: false },
  { id: '9', position: '', name: '오술생', number: '4기', note: '', highlight: false },
  { id: '10', position: '', name: '오창현', number: '4기', note: '', highlight: false },
  { id: '11', position: '', name: '장은순', number: '4기', note: '', highlight: false },
  { id: '12', position: '', name: '조현지', number: '4기', note: '', highlight: false },
  { id: '13', position: '', name: '박현순', number: '6기', note: '', highlight: false },
  { id: '14', position: '', name: '정선자', number: '6기', note: '', highlight: false },
  { id: '15', position: '', name: '장광분', number: '7기', note: '', highlight: false },
  { id: '16', position: '', name: '정찬이', number: '7기', note: '', highlight: false },
  { id: '17', position: '', name: '조은숙', number: '7기', note: '', highlight: false },
  { id: '18', position: '', name: '최은실', number: '7기', note: '', highlight: false },
];

const grievanceCommittee = [
  { id: '0', position: '위원장', name: '이계진', highlight: true },
  { id: '1', position: '부위원장', name: '김현자', highlight: false, rowSpan: 5 },
  { id: '2', position: '', name: '하찬선', highlight: false },
  { id: '3', position: '', name: '김희자', highlight: false },
  { id: '4', position: '', name: '손금녕', highlight: false },
  { id: '5', position: '', name: '홍혜숙', highlight: false },
  { id: '6', position: '위원', name: '이혜숙', highlight: false, rowSpan: 14 },
  { id: '7', position: '', name: '이숙임', highlight: false },
  { id: '8', position: '', name: '이윤자', highlight: false },
  { id: '9', position: '', name: '성혜숙', highlight: false },
  { id: '10', position: '', name: '남양순', highlight: false },
  { id: '11', position: '', name: '김영배', highlight: false },
  { id: '12', position: '', name: '김영애', highlight: false },
  { id: '13', position: '', name: '권옥순', highlight: false },
  { id: '14', position: '', name: '김갑녀', highlight: false },
  { id: '15', position: '', name: '박성호', highlight: false },
  { id: '16', position: '', name: '박명희', highlight: false },
  { id: '17', position: '', name: '문애선', highlight: false },
  { id: '18', position: '', name: '황현숙', highlight: false },
  { id: '19', position: '', name: '황정순', highlight: false },
  { id: '20', position: '간사', name: '박영희', highlight: false },
];

const scalpCommittee = [
  { id: '0', position: '위원장', name: '박경애', highlight: true },
  { id: '1', position: '부위원장(5명)', name: '배강미', highlight: false, rowSpan: 5 },
  { id: '2', position: '', name: '양재혁', highlight: false },
  { id: '3', position: '', name: '고민석', highlight: false },
  { id: '4', position: '', name: '김수현', highlight: false },
  { id: '5', position: '', name: '박기원', highlight: false },
  { id: '6', position: '총무', name: '공명숙', highlight: true },
  { id: '7', position: '위원(31명)', name: '정순옥', highlight: false, rowSpan: 31 },
  { id: '8', position: '', name: '심임숙', highlight: false },
  { id: '9', position: '', name: '박태화', highlight: false },
  { id: '10', position: '', name: '문외숙', highlight: false },
  { id: '11', position: '', name: '최선희', highlight: false },
  { id: '12', position: '', name: '유은파', highlight: false },
  { id: '13', position: '', name: '강희선', highlight: false },
  { id: '14', position: '', name: '이애원', highlight: false },
  { id: '15', position: '', name: '김명애', highlight: false },
  { id: '16', position: '', name: '박선이', highlight: false },
  { id: '17', position: '', name: '권오혁', highlight: false },
  { id: '18', position: '', name: '김민', highlight: false },
  { id: '19', position: '', name: '박현숙', highlight: false },
  { id: '20', position: '', name: '최은정', highlight: false },
  { id: '21', position: '', name: '이현성', highlight: false },
  { id: '22', position: '', name: '이명화', highlight: false },
  { id: '23', position: '', name: '고강철', highlight: false },
  { id: '24', position: '', name: '김옥매', highlight: false },
  { id: '25', position: '', name: '이승희', highlight: false },
  { id: '26', position: '', name: '강미영', highlight: false },
  { id: '27', position: '', name: '김윤경', highlight: false },
  { id: '28', position: '', name: '구자경', highlight: false },
  { id: '29', position: '', name: '이남지', highlight: false },
  { id: '30', position: '', name: '최은실', highlight: false },
  { id: '31', position: '', name: '임옥경', highlight: false },
  { id: '32', position: '', name: '강주희', highlight: false },
  { id: '33', position: '', name: '최인실', highlight: false },
  { id: '34', position: '', name: '유소연', highlight: false },
  { id: '35', position: '', name: '서경애', highlight: false },
  { id: '36', position: '', name: '오해석', highlight: false },
  { id: '37', position: '', name: '이문희', highlight: false },
];

const makeupCommittee = [
  { id: '0', position: '위원장', name: '박소야', note: '', highlight: true },
  { id: '1', position: '부위원장(5명)', name: '김장순', note: '', highlight: false, rowSpan: 5 },
  { id: '2', position: '', name: '김갑녀', note: '', highlight: false },
  { id: '3', position: '', name: '조원옥', note: '', highlight: false },
  { id: '4', position: '', name: '박성미', note: '', highlight: false },
  { id: '5', position: '', name: '윤미정', note: '', highlight: false },
  { id: '6', position: '총무', name: '장순희', note: '', highlight: true },
  { id: '7', position: '위원', name: '안옥희', note: '', highlight: false, rowSpan: 44 },
  { id: '8', position: '', name: '박옥남', note: '', highlight: false },
  { id: '9', position: '', name: '정태랑', note: '', highlight: false },
  { id: '10', position: '', name: '박옥희', note: '', highlight: false },
  { id: '11', position: '', name: '왕순자', note: '', highlight: false },
  { id: '12', position: '', name: '최복자', note: '', highlight: false },
  { id: '13', position: '', name: '석명화', note: '', highlight: false },
  { id: '14', position: '', name: '권옥순', note: '', highlight: false },
  { id: '15', position: '', name: '정경희', note: '', highlight: false },
  { id: '16', position: '', name: '이화자', note: '', highlight: false },
  { id: '17', position: '', name: '송석초', note: '', highlight: false },
  { id: '18', position: '', name: '이순옥', note: '', highlight: false },
  { id: '19', position: '', name: '이점숙 (경남)', note: '', highlight: false },
  { id: '20', position: '', name: '이종란', note: '', highlight: false },
  { id: '21', position: '', name: '정윤남', note: '', highlight: false },
  { id: '22', position: '', name: '김성자', note: '', highlight: false },
  { id: '23', position: '', name: '조수현', note: '', highlight: false },
  { id: '24', position: '', name: '박정해', note: '', highlight: false },
  { id: '25', position: '', name: '황병숙', note: '', highlight: false },
  { id: '26', position: '', name: '유은파', note: '', highlight: false },
  { id: '27', position: '', name: '심근하', note: '', highlight: false },
  { id: '28', position: '', name: '황정순', note: '', highlight: false },
  { id: '29', position: '', name: '오창현', note: '', highlight: false },
  { id: '30', position: '', name: '황영은', note: '', highlight: false },
  { id: '31', position: '', name: '이기빈', note: '', highlight: false },
  { id: '32', position: '', name: '김안숙', note: '', highlight: false },
  { id: '33', position: '', name: '방소림', note: '', highlight: false },
  { id: '34', position: '', name: '윤희', note: '', highlight: false },
  { id: '35', position: '', name: '김옥매', note: '', highlight: false },
  { id: '36', position: '', name: '최연숙', note: '', highlight: false },
  { id: '37', position: '', name: '박명숙', note: '', highlight: false },
  { id: '38', position: '', name: '최은경', note: '', highlight: false },
  { id: '39', position: '', name: '최서영', note: '', highlight: false },
  { id: '40', position: '', name: '강미영', note: '', highlight: false },
  { id: '41', position: '', name: '이기순', note: '', highlight: false },
  { id: '42', position: '', name: '성주현', note: '', highlight: false },
  { id: '43', position: '', name: '오정숙', note: '', highlight: false },
  { id: '44', position: '', name: '양희숙', note: '', highlight: false },
  { id: '45', position: '', name: '구은미', note: '', highlight: false },
  { id: '46', position: '', name: '이승희', note: '', highlight: false },
  { id: '47', position: '', name: '최원희', note: '', highlight: false },
  { id: '48', position: '', name: '이미영', note: '', highlight: false },
  { id: '49', position: '', name: '정희선', note: '', highlight: false },
  { id: '50', position: '', name: '이슬기', note: '', highlight: false },
];

const hairArtCommittee = [
  { id: '0', position: '위원장', name: '김옥순', note: '', highlight: true },
  { id: '1', position: '부위원장(6명)', name: '문성자', note: '', highlight: false, rowSpan: 6 },
  { id: '2', position: '', name: '김현자', note: '', highlight: false },
  { id: '3', position: '', name: '김미섭', note: '', highlight: false },
  { id: '4', position: '', name: '송명애', note: '', highlight: false },
  { id: '5', position: '', name: '김옥매', note: '', highlight: false },
  { id: '6', position: '', name: '김미소', note: '', highlight: false },
  { id: '7', position: '총무', name: '김은진', note: '', highlight: true },
  { id: '8', position: '위원(36명)', name: '이종숙', note: '', highlight: false, rowSpan: 36 },
  { id: '9', position: '', name: '양철기', note: '', highlight: false },
  { id: '10', position: '', name: '박명희', note: '', highlight: false },
  { id: '11', position: '', name: '김영숙', note: '', highlight: false },
  { id: '12', position: '', name: '장지의', note: '', highlight: false },
  { id: '13', position: '', name: '정향옥', note: '', highlight: false },
  { id: '14', position: '', name: '도애석', note: '', highlight: false },
  { id: '15', position: '', name: '오영주', note: '', highlight: false },
  { id: '16', position: '', name: '고재경', note: '', highlight: false },
  { id: '17', position: '', name: '소정예', note: '', highlight: false },
  { id: '18', position: '', name: '심남연', note: '', highlight: false },
  { id: '19', position: '', name: '박상숙', note: '', highlight: false },
  { id: '20', position: '', name: '최미자', note: '', highlight: false },
  { id: '21', position: '', name: '황희정', note: '', highlight: false },
  { id: '22', position: '', name: '김민정', note: '', highlight: false },
  { id: '23', position: '', name: '정미경', note: '', highlight: false },
  { id: '24', position: '', name: '이란희', note: '', highlight: false },
  { id: '25', position: '', name: '최금주', note: '', highlight: false },
  { id: '26', position: '', name: '심경남', note: '', highlight: false },
  { id: '27', position: '', name: '최연숙', note: '', highlight: false },
  { id: '28', position: '', name: '고경서', note: '', highlight: false },
  { id: '29', position: '', name: '임은앵', note: '', highlight: false },
  { id: '30', position: '', name: '조은숙', note: '', highlight: false },
  { id: '31', position: '', name: '손영숙', note: '', highlight: false },
  { id: '32', position: '', name: '이은미', note: '', highlight: false },
  { id: '33', position: '', name: '엄소정', note: '', highlight: false },
  { id: '34', position: '', name: '윤지희', note: '', highlight: false },
  { id: '35', position: '', name: '오승현', note: '', highlight: false },
  { id: '36', position: '', name: '서정희', note: '', highlight: false },
  { id: '37', position: '', name: '이지영', note: '', highlight: false },
  { id: '38', position: '', name: '신미화', note: '', highlight: false },
  { id: '39', position: '', name: '류영주', note: '', highlight: false },
  { id: '40', position: '', name: '강명이', note: '', highlight: false },
  { id: '41', position: '', name: '김현옥', note: '', highlight: false },
  { id: '42', position: '', name: '이상미', note: '', highlight: false },
  { id: '43', position: '', name: '이수민', note: '', highlight: false },
];

const wigCommittee = [
  { id: '0', position: '위원장', name: '송현주', highlight: true },
  { id: '1', position: '부위원장(2명)', name: '김 호', highlight: false, rowSpan: 2 },
  { id: '2', position: '', name: '김명순', highlight: false },
  { id: '3', position: '총무', name: '김승연', highlight: true },
  { id: '4', position: '운영위원(47명)', name: '신인숙', highlight: false, rowSpan: 47 },
  { id: '5', position: '', name: '김미경', highlight: false },
  { id: '6', position: '', name: '이연미', highlight: false },
  { id: '7', position: '', name: '정세주', highlight: false },
  { id: '8', position: '', name: '이영선', highlight: false },
  { id: '9', position: '', name: '한진희', highlight: false },
  { id: '10', position: '', name: '이진희', highlight: false },
  { id: '11', position: '', name: '신명숙', highlight: false },
  { id: '12', position: '', name: '권현경', highlight: false },
  { id: '13', position: '', name: '김수미', highlight: false },
  { id: '14', position: '', name: '김호숙', highlight: false },
  { id: '15', position: '', name: '이경희', highlight: false },
  { id: '16', position: '', name: '김영미', highlight: false },
  { id: '17', position: '', name: '이종원', highlight: false },
  { id: '18', position: '', name: '이미향', highlight: false },
  { id: '19', position: '', name: '강문정', highlight: false },
  { id: '20', position: '', name: '김민수', highlight: false },
  { id: '21', position: '', name: '이둘화', highlight: false },
  { id: '22', position: '', name: '구복연', highlight: false },
  { id: '23', position: '', name: '김주혜', highlight: false },
  { id: '24', position: '', name: '이진영', highlight: false },
  { id: '25', position: '', name: '박미옥', highlight: false },
  { id: '26', position: '', name: '오혜란', highlight: false },
  { id: '27', position: '', name: '장금순', highlight: false },
  { id: '28', position: '', name: '정형호', highlight: false },
  { id: '29', position: '', name: '정경자', highlight: false },
  { id: '30', position: '', name: '이혜경', highlight: false },
  { id: '31', position: '', name: '진명희', highlight: false },
  { id: '32', position: '', name: '하민하', highlight: false },
  { id: '33', position: '', name: '이정수', highlight: false },
  { id: '34', position: '', name: '오송림', highlight: false },
  { id: '35', position: '', name: '김순경', highlight: false },
  { id: '36', position: '', name: '황계분', highlight: false },
  { id: '37', position: '', name: '윤상희', highlight: false },
  { id: '38', position: '', name: '홍순옥', highlight: false },
  { id: '39', position: '', name: '박금란', highlight: false },
  { id: '40', position: '', name: '임정숙', highlight: false },
  { id: '41', position: '', name: '한미례', highlight: false },
  { id: '42', position: '', name: '강인영', highlight: false },
  { id: '43', position: '', name: '현소영', highlight: false },
  { id: '44', position: '', name: '김춘희', highlight: false },
  { id: '45', position: '', name: '정희정', highlight: false },
  { id: '46', position: '', name: '우경숙', highlight: false },
  { id: '47', position: '', name: '이영임', highlight: false },
  { id: '48', position: '', name: '정성녀', highlight: false },
  { id: '49', position: '', name: '김보민', highlight: false },
  { id: '50', position: '', name: '김보라', highlight: false },
  { id: '51', position: '특별회원(45명)', name: '신지현', highlight: false, rowSpan: 45 },
  { id: '52', position: '', name: '박성용', highlight: false },
  { id: '53', position: '', name: '권언주', highlight: false },
  { id: '54', position: '', name: '이지은', highlight: false },
  { id: '55', position: '', name: '김도연', highlight: false },
  { id: '56', position: '', name: '안연희', highlight: false },
  { id: '57', position: '', name: '이경은', highlight: false },
  { id: '58', position: '', name: '김 희', highlight: false },
  { id: '59', position: '', name: '길민정', highlight: false },
  { id: '60', position: '', name: '권진형', highlight: false },
  { id: '61', position: '', name: '최정원', highlight: false },
  { id: '62', position: '', name: '오문주', highlight: false },
  { id: '63', position: '', name: '최지은', highlight: false },
  { id: '64', position: '', name: '김윤지', highlight: false },
  { id: '65', position: '', name: '이진강', highlight: false },
  { id: '66', position: '', name: '김남일', highlight: false },
  { id: '67', position: '', name: '최수인', highlight: false },
  { id: '68', position: '', name: '노명희', highlight: false },
  { id: '69', position: '', name: '오수진', highlight: false },
  { id: '70', position: '', name: '신은영', highlight: false },
  { id: '71', position: '', name: '박인숙', highlight: false },
  { id: '72', position: '', name: '이채원', highlight: false },
  { id: '73', position: '', name: '권연화', highlight: false },
  { id: '74', position: '', name: '김수화', highlight: false },
  { id: '75', position: '', name: '박미경', highlight: false },
  { id: '76', position: '', name: '김미경', highlight: false },
  { id: '77', position: '', name: '정지현', highlight: false },
  { id: '78', position: '', name: '윤미경', highlight: false },
  { id: '79', position: '', name: '박진숙', highlight: false },
  { id: '81', position: '', name: '송은희', highlight: false },
  { id: '82', position: '', name: '김혜연', highlight: false },
  { id: '83', position: '', name: '이연화', highlight: false },
  { id: '84', position: '', name: '유경숙', highlight: false },
  { id: '85', position: '', name: '석경희', highlight: false },
  { id: '86', position: '', name: '김선하', highlight: false },
  { id: '87', position: '', name: '신부남', highlight: false },
  { id: '88', position: '', name: '안연주', highlight: false },
  { id: '89', position: '', name: '이상희', highlight: false },
  { id: '90', position: '', name: '김소현', highlight: false },
  { id: '91', position: '', name: '김시원', highlight: false },
  { id: '92', position: '', name: '오봉임', highlight: false },
  { id: '93', position: '', name: '박경희', highlight: false },
  { id: '94', position: '', name: '우종숙', highlight: false },
  { id: '95', position: '', name: '남연숙', highlight: false },
];

export const tabs = [
  { id: '0', name: '홍보위원회', tableHeaders: marketingTableHeaders, committee: marketingCommittee, table: MarketingTable },
  { id: '1', name: '미용기술위원회', tableHeaders: hairSkillTableHeaders, committee: hairSkillCommittee, table: HairSkillTable },
  { id: '2', name: '헤어스케치위원회', tableHeaders: hairSketchTableHeaders, committee: hairSketchCommittee, table: HairSketchTable },
  { id: '3', name: '국제미용위원회', tableHeaders: globalBeautyTableHeaders, committee: globalBeautyCommittee, table: EmptyTable },
  { id: '4', name: '고전머리위원회', tableHeaders: traditionalTableHeaders, committee: traditionalCommittee, table: TraditionalTable },
  { id: '5', name: '고충처리위원회', tableHeaders: grievanceTableHeaders, committee: grievanceCommittee, table: GrievanceTable },
  { id: '6', name: '두피모발위원회', tableHeaders: scalpTableHeaders, committee: scalpCommittee, table: EmptyTable },
  { id: '7', name: '반영구메이크업위원회', tableHeaders: makeupTableHeaders, committee: makeupCommittee, table: MakeupTable },
  { id: '8', name: '헤어아트위원회', tableHeaders: hairArtTableHeaders, committee: hairArtCommittee, table: HairArtTable },
  { id: '9', name: '증모가발특별위원회', tableHeaders: wigTableHeaders, committee: wigCommittee, table: WigTable },
];

export const selectOptions = [
  { id: '1', label: '1기' },
  { id: '2', label: '2기' },
  { id: '3', label: '3기' },
  { id: '4', label: '4기' },
  { id: '5', label: '5기' },
  { id: '6', label: '6기' },
  { id: '7', label: '7기' },
];

export const committee = [
  { id: '0', position: '위원장', name: '홍길동', number: '1기', note: '', highlight: true },
  { id: '1', position: '위원장', name: '홍길동', number: '1기', note: '', highlight: true },
  { id: '2', position: '부위원장<br />(3명)', name: '홍길동', number: '1기', note: '', highlight: false, rowSpan: 3 },
  { id: '3', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '4', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '5', position: '총무', name: '홍길동', number: '2기', note: '', highlight: true },
  { id: '6', position: '운영위원<br />(30명)', name: '홍길동', number: '2기', note: '', highlight: false, rowSpan: 30 },
  { id: '7', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '8', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '9', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '10', position: '', name: '홍길동', number: '3기', note: '', highlight: false },
  { id: '11', position: '', name: '홍길동', number: '3기', note: '', highlight: false },
  { id: '12', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '13', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '14', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '15', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '16', position: '', name: '홍길동', number: '3기', note: '', highlight: false },
  { id: '17', position: '', name: '홍길동', number: '3기', note: '', highlight: false },
  { id: '18', position: '', name: '홍길동', number: '3기', note: '', highlight: false },
  { id: '19', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '20', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '21', position: '', name: '홍길동', number: '3기', note: '', highlight: false },
  { id: '22', position: '', name: '홍길동', number: '3기', note: '', highlight: false },
  { id: '23', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '24', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '25', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '26', position: '', name: '홍길동', number: '3기', note: '', highlight: false },
  { id: '27', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '28', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '29', position: '', name: '홍길동', number: '3기', note: '', highlight: false },
  { id: '30', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '31', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '32', position: '', name: '홍길동', number: '2기', note: '', highlight: false },
  { id: '33', position: '', name: '홍길동', number: '3기', note: '', highlight: false },
  { id: '34', position: '', name: '홍길동', number: '3기', note: '', highlight: false },
  { id: '35', position: '', name: '홍길동', number: '3기', note: '', highlight: false },
];

export const COMMITTEE_TYPES = {
  marketing: 'marketing',
  hairSkill: 'hairSkill',
  hairSketch: 'hairSketch',
  globalBeauty: 'globalBeauty',
  traditional: 'traditional',
  grievance: 'grievance',
  scalp: 'scalp',
  makeup: 'makeup',
  hairArt: 'hairArt',
  wig: 'wig',
};

export const COMMITTEE_NAME = {
  [COMMITTEE_TYPES.marketing]: '홍보위원회',
  [COMMITTEE_TYPES.hairSkill]: '미용기술위원회',
  [COMMITTEE_TYPES.hairSketch]: '헤어스케치위원회',
  [COMMITTEE_TYPES.globalBeauty]: '국제미용위원회',
  [COMMITTEE_TYPES.traditional]: '고전머리위원회',
  [COMMITTEE_TYPES.grievance]: '고충처리위원회',
  [COMMITTEE_TYPES.scalp]: '두피모발위원회',
  [COMMITTEE_TYPES.makeup]: '반영구메이크업위원회',
  [COMMITTEE_TYPES.hairArt]: '헤어아트위원회',
  [COMMITTEE_TYPES.wig]: '증모가발특별위원회',
};

export const COMMITTEE_COMPONENT = {
  [COMMITTEE_TYPES.marketing]: MarketingTable,
  [COMMITTEE_TYPES.hairSkill]: HairSkillTable,
  [COMMITTEE_TYPES.hairSketch]: HairSketchTable,
  [COMMITTEE_TYPES.globalBeauty]: EmptyTable,
  [COMMITTEE_TYPES.traditional]: TraditionalTable,
  [COMMITTEE_TYPES.grievance]: GrievanceTable,
  [COMMITTEE_TYPES.scalp]: EmptyTable,
  [COMMITTEE_TYPES.makeup]: MakeupTable,
  [COMMITTEE_TYPES.hairArt]: HairArtTable,
  [COMMITTEE_TYPES.wig]: WigTable,
};
