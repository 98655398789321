<template>
  <input-wrapper
    :required="required"
    :label="label"
    :helper-text="helperText"
    :error-text="errorText"
    :is-focus="isFocus"
    has-button
    :button-order="buttonOrder"
  >
    <template #form-input>
      <input readonly type="text" v-bind="attrs" style="width: 100%" @focus="onFocus" @blur="onBlur" @click="openFindAddress" />
    </template>
    <template #form-button>
      <k-button
        type="button"
        :text="buttonText"
        variant="primary"
        size="xSmall"
        style="border-radius: 3px; background-color: #e5e7eb"
        @click="openFindAddress"
      />
    </template>
  </input-wrapper>
  <v-dialog v-model="isOpenDialog">
    <daum-postcode :on-complete="afterFindAddress" />
  </v-dialog>
</template>

<script>
import InputWrapper from '@/components/form/InputWrapper';
import DaumPostcode from '@/components/DaumPostcode.vue';
import { ref } from 'vue';

export default {
  name: 'DaumPostInput',
  components: {
    InputWrapper,
    DaumPostcode,
  },
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    helperText: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
    buttonOrder: {
      type: Number,
      default: 1,
    },
    buttonText: {
      type: String,
      default: '',
    },
    onCompleteFindAddress: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, { attrs }) {
    const isFocus = ref(false);
    const isOpenDialog = ref(false);

    const onFocus = (e) => {
      isFocus.value = true;

      attrs?.onFocus?.(e);
    };

    const onBlur = (e) => {
      isFocus.value = false;

      attrs?.onBlur?.(e);
    };

    const openFindAddress = () => {
      isOpenDialog.value = true;
    };

    const afterFindAddress = (address, zip) => {
      isOpenDialog.value = false;
      props.onCompleteFindAddress(address, zip);
    };

    return { attrs, isFocus, onFocus, onBlur, openFindAddress, afterFindAddress, isOpenDialog };
  },
};
</script>

<style lang="scss" scoped></style>
