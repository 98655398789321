import { ETC_DATA_IDS } from '@/constants/organization/organizationDataName';

export const ORGANIZATION_LABELS = {
  // [ETC_DATA_IDS.president]: '회장',
  // [ETC_DATA_IDS.vicePresident]: '부회장, 이사',
  // [ETC_DATA_IDS.grandAssemblyman]: '대의원',
  // [ETC_DATA_IDS.audit]: '감사',
  // [ETC_DATA_IDS.boardOfDirectors]: '이사회',
  // [ETC_DATA_IDS.secretaryGeneral]: '사무총장',

  [ETC_DATA_IDS.administrationMain]: '총무국',
  [ETC_DATA_IDS.administrationDepartment]: '총무부',
  [ETC_DATA_IDS.administrationAccounting]: '경리부',

  [ETC_DATA_IDS.publicityMain]: '홍보국',
  [ETC_DATA_IDS.publicityAdvertising]: '광고부',
  [ETC_DATA_IDS.publicityEdit]: '편집부',

  [ETC_DATA_IDS.educationMain]: '교육원',
  [ETC_DATA_IDS.educationDepartment]: '교육부',

  [ETC_DATA_IDS.committeeMain]: '위원회',
  [ETC_DATA_IDS.committeeManagement]: '위원회 관리부',
};
