<template>
  <center-contents-card :title="title" :class-name="className">
    <p v-for="(paragraph, index) in paragraphs" :key="index" class="paragraph">
      {{ paragraph }}
    </p>
  </center-contents-card>
</template>

<script>
import CenterContentsCard from '@/components/CenterContentsCard';

export default {
  components: { CenterContentsCard },
  props: {
    className: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    paragraphs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.paragraph {
  @include spreadTypoMap($body-large);
  color: $gray-50;
  text-align: center;
  word-break: keep-all;

  @include sm-and-down {
    @include spreadTypoMap($body-xsmall);
  }
}
</style>
