<template>
  <div class="introduce">
    <title-large-paragraph :title="perpose.title" :paragraphs="perpose.paragraphs" class-name="title-paragraph__wrapper"></title-large-paragraph>

    <div class="ethics__wrapper">
      <center-contents-card class-name="ethics__wrapper__box" :title="ethics.title">
        <v-row class="ethics__wrapper__row text-center" justify="center">
          <v-col v-for="(paragraph, index) in ethics.paragraphs" :key="index" class="mr-6">
            <div class="ethics__subtitle mb-6">
              {{ `0${index + 1}` }}
            </div>
            <p class="ethics__pragraph">{{ paragraph }}</p>
            <v-responsive width="100%"></v-responsive>
          </v-col>
        </v-row>
      </center-contents-card>
    </div>

    <title-large-paragraph class-name="title-paragraph__wrapper" :title="activity.title" :paragraphs="activity.paragraphs"></title-large-paragraph>
  </div>
</template>

<script>
import TitleLargeParagraph from '@/components/TitleLargeParagraph';
import CenterContentsCard from '@/components/CenterContentsCard';

export default {
  components: { TitleLargeParagraph, CenterContentsCard },
  setup() {
    const perpose = {
      title: '창립 취지',
      paragraphs: [
        '사)대한미용사회중앙회는 미용을 업으로 하는 미용인들이 미용업 발전 및 기술향상과 회원 상호간의 친목을 도모하여 공중위생과 국민보건의 향상에 기여함을 목적으로 결성된 단체입니다. 회원이 공동의 목적을 실현하기 위해 활발한 대외적인 활동을 통해 회원들의 권익을 보호하고 미용인의 위상제고 힘쓰고 있습니다. 아울러 새로운 뷰티트렌드 등 각종 정보의 교류로 미용인들의 기술향상은 물론 우리나라 미용 산업 발전에 중추적인 역할을 담당하고 있습니다.',
      ],
    };

    const activity = {
      title: '협회 활동',
      paragraphs: [
        '1945년 임의단체로 출발하여 1957년 대한미용협회를 발족시키고 1966년 사단법인으로 등록 1989년 3월 14일부로 공중위생법 제32조에 의거 사단법인 대한미용사회로 전환, 법정단체로서 현재에 이르고 있습니다. 사)대한미용사회중앙회는 회원 수 74,000여명으로 전국 85개 지회와 182개 지부로 체계적으로 구성된 단체입니다.',
        '아울러 사)대한미용사회중앙회 뿐 만 아니라 산하 각 지회 및 지부별로 사회보호시설 및 불우한 이웃돕기 자원봉사도 활발히 전개하고 있습니다. 오랜 역사를 자랑하는 미용인들의 꾸준한 봉사활동은 중앙정부는 물론 지방정부와 유기적인 협력을 이끌어내어 우리 사회의 어려운 이웃들에게 큰 힘이 되고 있습니다.',
        '나아가 사)대한미용사회중앙회는 회원들에게 국제적인 수준의 미용감각을 제공하기 위해 세계 이·미용협회, OMC(Organization Mondial Coiffure)에 가입하여 회원국 간의 정보교류를 통한 한국미용의 세계화에 기여하고 있습니다',
        '특히 사)대한미용사회중앙회는 지난 1998년과 2016년도 전세계 미용인들의 올림픽인 헤어월드 대회를 개최한 바 있는데, 아시아에서는 최초로 헤어월드 2회 개최국이 되었습니다. 특히 2014년 독일대회 2016년 한국대회에서 종합우승으로 미용기술 최정상 선진국으로 도약, 크게 국위를 선했습니다.',
        '변화된 한국미용의 위상에 걸맞게 사)대한미용사회중앙회는 지구촌 뷰티트렌드를 선도하며, 회원들에게 더욱 가까이 다가가고, 한 차원 더 높은 행정과 봉사로 회원들에게 버팀목이 되기 위해 최선을 다 할 것입니다.',
      ],
    };

    const ethics = {
      title: '미용사 윤리 강령',
      paragraphs: [
        '미용사는 국민의 한사람으로서 준법정신에 투철하며 국가발전과 국민보건 향상을 위하여 헌신하여야 한다.',
        '미용사는 미용문화 창달을 위하여 그 사명감에 충실하고 공중위생에 대한 실천자가 되어야 한다.',
        '미용사는 미용의 전문가로서 항상 새로운 지식을 연마하여 참신한 미용기술개발에 기여하여야 한다.',
        '미용사는 미용의 공익성을 지켜야 하며 미용의 정상적인 발전을 위하여 상호 협조하고 질서 확립에 최선을 다하여야 한다.',
      ],
    };

    return { perpose, activity, ethics };
  },
};
</script>

<style lang="scss" scoped>
.introduce {
  :not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.title-paragraph {
  &__wrapper {
    padding: 3rem 0;
    margin: auto;
    width: 80%;
    gap: 1.5rem;

    @include md-and-down {
      padding: 2rem 1.25rem;
      gap: 1rem;
      width: unset;
    }
  }
}

.ethics {
  &__wrapper {
    background-color: $blue-50;

    &__box {
      @include md-and-up {
        padding: 4.5rem 3rem;
        gap: 3.5rem;
      }

      & ::v-deep(.title) {
        color: $gray-20;
      }
    }

    &__row {
      @include sm-and-down {
        padding: 2rem 0;
        flex-direction: column;
      }
    }

    @include sm-and-down {
      height: auto;
      gap: unset;
      padding: 2rem 1.25rem;
    }
  }

  &__subtitle {
    @include spreadTypoMap($title-small);
    color: $gray-20;
  }

  &__pragraph {
    @include spreadTypoMap($body-medium);
    color: $gray-30;
  }
}
</style>
