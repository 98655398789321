<template>
  <v-dialog v-model="isDialogOpen" :fullscreen="isMobileUi">
    <v-card>
      <form class="card__form" :class="{ card__form_mobile: isMobileUi }" @submit="updateHanaAccount">
        <div>
          <div class="justify-content-between align-center" style="display: flex">
            <v-card-title>하나은행 계좌번호 인증하기</v-card-title>
            <button class="btn__close" type="button" @click="onClose"><v-icon :icon="mdiClose" /></button>
          </div>
          <v-divider class="ma-0"></v-divider>
        </div>
        <v-card-item>
          <v-text-field
            v-model.trim="form.name"
            name="name"
            label="예금주명"
            placeholder="예금주명을 입력해주세요"
            outlined
            :error="!!errors.name"
            :error-messages="errors.name"
            @blur="checkIsValid"
            @keydown="resetErrors"
          />
          <v-text-field
            v-model.trim="form.accountNumber"
            name="accountNumber"
            label="계좌번호"
            placeholder="계좌변호를 입력해주세요."
            outlined
            :error="!!errors.accountNumber"
            :error-messages="errors.accountNumber"
            @input="convertDataForApi"
            @blur="checkIsValid"
            @keydown="resetErrors"
          />
        </v-card-item>
        <div>
          <v-btn
            class="btn__verify"
            color="primary"
            :loading="isLoading"
            type="submit"
            :disabled="disabled"
            style="width: 100%; border-radius: 0; height: 48px"
            >{{ buttonText }}</v-btn
          >
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, ref } from 'vue';
import { successToast } from '@/utils/toast';
import { updateHanaAccountAPI } from '@/apis/loan';
import REGEX from '@/constants/commons/regex';
import { mdiClose } from '@mdi/js';

const validation = {
  name: (value) => ({ isValid: !!value, ...(!value && { message: '이름을 입력해주세요.' }) }),
  accountNumber: (value) => ({ isValid: !!value, ...(!value && { message: '계좌번호를 입력해주세요.' }) }),
};

const useForm = (props) => {
  const isLoading = ref(false);

  const form = ref({ name: '', accountNumber: '' });
  const errors = ref({ name: '', accountNumber: '' });

  const resetForm = () => {
    form.value.name = '';
    form.value.accountNumber = '';
  };

  const resetErrors = () => {
    errors.value.name = '';
    errors.value.accountNumber = '';
  };

  const checkIsValid = (e) => {
    const { name } = e.target;
    const { isValid, message } = validation[name](form.value[name]);

    if (isValid) return;

    errors.value[name] = message;
  };

  const convertDataForApi = (e) => {
    const { value } = e.target;
    if (!value) return;

    form.value.accountNumber = value.replaceAll(REGEX.notANumber, '');
  };

  const onClose = () => {
    resetErrors();
    resetForm();
    props.closeModal();
  };

  const updateHanaAccount = async (e) => {
    e.preventDefault();
    isLoading.value = true;

    try {
      await updateHanaAccountAPI(form.value);
      onClose();
      successToast('상담 신청이 완료되었습니다.');
    } catch (e) {
      //
    } finally {
      isLoading.value = false;
    }
  };

  return { isLoading, form, errors, convertDataForApi, resetErrors, checkIsValid, updateHanaAccount, onClose };
};

export default {
  name: 'LoanBenefitsFormModal',
  components: {},
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    isMobileUi: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const isDialogOpen = computed(() => props.dialog);
    const { isLoading, form, errors, convertDataForApi, resetErrors, checkIsValid, updateHanaAccount, onClose } = useForm(props);

    const disabled = computed(() => isLoading.value || Object.values(errors.value).some((error) => error) || Object.values(form.value).some((value) => !value));
    const buttonText = computed(() => (disabled.value ? '모두 입력해주세요' : '인증하기'));

    return {
      isDialogOpen,
      buttonText,
      disabled,
      mdiClose,
      isLoading,
      form,
      errors,
      convertDataForApi,
      resetErrors,
      checkIsValid,
      updateHanaAccount,
      onClose,
    };
  },
};
</script>

<style scoped lang="scss">
.card__form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card__form_mobile {
  padding-bottom: calc(60px + env(safe-area-inset-bottom));
}

.btn__close {
  all: unset;
  padding: 1rem 0.5rem;
  cursor: pointer;
}

.btn__verify {
  background: $blue-50 !important;

  &:disabled {
    background: gray !important;
  }
}
</style>
