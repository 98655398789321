import { defineStore } from 'pinia';

export default defineStore({
  id: 'ui',
  state: () => ({
    appBar: {
      title: '',
    },
  }),
  getters: {
    getAppBar: (state) => state.appBar,
  },
  actions: {
    updateAppBarUi({ name, value }) {
      this.appBar[name] = value;
    },
  },
});
