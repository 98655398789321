import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Toast, { POSITION } from 'vue-toastification';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import IconBase from '@/components/IconBase';
import Button from '@/components/Button';
import { setupWorker } from 'msw';
import { SetupCalendar } from 'v-calendar';

import 'vue-toastification/dist/index.css';

import '@/styles/tailwind.css';

if (process.env.VUE_APP_MOCK && process.env.NODE_ENV === 'development') {
  (async () => {
    const { default: handlers } = await import('@/mocks/handlers');
    const worker = setupWorker(...handlers);
    worker.start();
  })();
}

const appInstance = createApp(App);
const pinia = createPinia();

appInstance.use(router);
appInstance.use(vuetify);
appInstance.use(pinia);
appInstance.use(Toast, {
  position: POSITION.TOP_RIGHT,
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
  maxToasts: 10,
  newestOnTop: true,
});
// Setup plugin for defaults or `$screens` (optional)
appInstance.use(SetupCalendar, {});

// 컴포넌트 전역등록
appInstance.component('IconBase', IconBase);
appInstance.component('KButton', Button);

appInstance.mount('#app');
