<template>
  <v-row class="category-card__wrapper" justify="center" tag="section">
    <template v-for="category in CATEGORIES" :key="category.title">
      <category-card :title="category.title" :description="category.desc" :to="category.to" :on-click="category.onClick"></category-card>
    </template>
  </v-row>
</template>

<script>
import CategoryCard from '@/components/CategoryCard';

export default {
  components: { CategoryCard },
  setup() {
    const CATEGORIES = [
      { title: 'News', desc: '뉴스 모아보기', to: '/news/1' },
      { title: 'Beauty', desc: '뷰티 정보', to: '/beauty/7' },
      { title: 'Culture', desc: '칼럼 & 컬쳐', to: '/culture/11' },
      { title: 'Finance', desc: '하나은행과 함께', to: '/product-mall' },
    ];
    return { CATEGORIES };
  },
};
</script>

<style scoped lang="scss">
.category-card__wrapper {
  @apply m-0 w-full grid pc:grid-cols-4 mobile:grid-cols-1 pc:gap-x-[40px] mobile:gap-y-[40px] pc:py-[72px] mobile:py-[48px] pc:px-0 mobile:px-[20px] lg:px-[20px];
}
</style>
