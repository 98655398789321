<template>
  <v-app>
    <GNB v-if="!isHideGNB" :is-gnb-transparent="isGNBTransparent" :page-title="pageTitle"></GNB>
    <v-main>
      <slot :page-title="pageTitle"></slot>
    </v-main>
    <FNB></FNB>
  </v-app>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import GNB from '@/components/GNB.vue';
import FNB from '@/components/FNB.vue';
import { useUiStore } from '@/stores';

export default {
  components: {
    GNB,
    FNB,
  },
  setup() {
    const isHideGNB = computed(() => useRoute().meta.hideGNB);
    const isGNBTransparent = computed(() => useRoute().meta.GNBTransparent);
    const pageTitle = computed(() => useUiStore().getAppBar.title || useRoute().meta.title);

    return {
      isHideGNB,
      isGNBTransparent,
      pageTitle,
    };
  },
};
</script>

<style></style>
