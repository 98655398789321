import axios from '@/lib/axiosController';

/**
 * @param  {string} {loginId}
 */
export const checkLoginId = async ({ loginId }) => {
  try {
    await axios.post('/user/check/loginId', { loginId });
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * 회원가입 Request API
 */
export const signup = async (req) => {
  try {
    const data = await axios.post(`/user/signup`, req);
    return data;
  } catch (e) {
    return false;
  }
};

/**
 * 핸드폰 인증번호 요청 API
 * @param  {string} {name
 * @param  {phone} phone}
 */
export const authSend = async ({ name, phone }) => {
  const data = await axios.post('/user/auth/send', {
    name,
    phone,
  });

  return data;
};

/**
 * 핸드폰 인증번호 인증 API
 * @param  {string} {name
 * @param  {string} phone
 * @param  {string} authCode}
 */
export const authVerify = async ({ name, phone, authCode }) => {
  try {
    await axios.post('/user/auth/verify', {
      name,
      phone,
      authCode,
    });

    return { result: true };
  } catch (e) {
    return {
      result: false,
      message: e.message,
    };
  }
};

/**
 * 핸드폰 인증번호 인증 API
 * @param  {string} {name
 * @param  {string} phone
 * @param  {string} authCode}
 */
export const verifyAccountNumberApi = async ({ name, accountNumber }) => {
  try {
    await axios.post('/user/check/account-holder', {
      name,
      accountNumber,
    });

    return { result: true };
  } catch (e) {
    return {
      result: false,
      message: e.message,
    };
  }
};
