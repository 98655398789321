<template>
  <contest-main-info :is-contests-loading="isContestsLoading" :contest-list="contestList" />
  <page doc-title="2024 서울시 미용예술 경연대회">
    <template #main-section="{ isMobileUi }">
      <div :style="`margin-top: ${isMobileUi ? '16px' : '48px'}`">
        <tab2 v-model="selectedTab" :tabs="tabs" is-full-width :router-click="routerClick" />
      </div>
      <component :is="componentInfo[selectedTab]" :is-mobile-ui="isMobileUi" />
      <!--      <v-dialog v-model="dialog">-->
      <!--        <router-link to="/product-mall">-->
      <!--          <v-img src="/assets/contest_regist_complete_dialog.jpg" class="regist-complete-dialog"></v-img>-->
      <!--        </router-link>-->
      <!--      </v-dialog>-->
    </template>
  </page>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import { useRoute, RouterLink, useRouter } from 'vue-router';

import Page from '@/components/Page';
import Tab2 from '@/components/Tab2';

import ContestMainInfo from '@/containers/Contest/ContestMainInfo';
import ContestInfoDetail from '@/containers/Contest/ContestInfoDetail';
import ContestRegisterInfo from '@/containers/Contest/ContestRegisterInfo';
import ContestPlayerUpdate from '@/containers/Contest/ContestPlayerUpdate.vue';
import ExhibitionItems from '@/containers/Contest/ExhibitionItems.vue';
import BoothInformation from '@/containers/Contest/BoothInformation.vue';
import BoothLayout from '@/containers/Contest/BoothLayout.vue';
import HotelGuide from '@/containers/Contest/HotelGuide.vue';

import { useContestStore, useLoadingStore, useScrollPositionStore } from '@/stores';
import { CONTEST_ACTION_NAME } from '@/stores/contest';
import { getContestSeq, setContestSeq } from '@/utils/contestSeqStoreUtil';

const COMPLETE = 'complete';

export default {
  name: 'Contest',
  components: {
    ContestRegisterInfo,
    ContestPlayerUpdate,
    ContestInfoDetail,
    Page,
    ContestMainInfo,
    Tab2,
    ExhibitionItems,
    BoothInformation,
    BoothLayout,
    RouterLink,
  },
  setup() {
    const route = useRoute();
    const id = Number(route.params.contestTabId);
    const selectedTab = ref(id);
    const dialog = ref(false);

    const loadingStore = useLoadingStore();
    const isContestsLoading = ref();

    const contestStore = useContestStore();
    const contestList = ref();
    const router = useRouter();

    const scrollPositionStore = useScrollPositionStore();
    const contestId = route.query.contestId;
    if (contestId) {
      setContestSeq(contestId);
    } else {
      const recentVisitContestSeq = getContestSeq();
      if (recentVisitContestSeq) {
        router.push(`/contest/${id}?contestId=` + recentVisitContestSeq);
      }
    }

    onMounted(() => {
      const routeQuery = route.query;

      if (routeQuery.regist === COMPLETE) {
        dialog.value = true;
      }
      contestStore.setDefaultContestInfos();
    });

    const getContestAllActionName = CONTEST_ACTION_NAME.SET_DEFAULT_CONTEST_INFOS;

    watch(
      () => loadingStore.getLoadings[getContestAllActionName],
      (curLoading, prevLoading) => {
        isContestsLoading.value = curLoading;

        if (prevLoading && !curLoading) {
          if (contestId) {
            contestList.value = contestStore.getContests.filter((contest) => contest.id === Number(contestId));
          } else {
            contestList.value = contestStore.getContests;
          }
        }
      },
    );

    const tabs = [
      {
        description: '경기 안내',
        displayStatus: 'SHOW',
        id: 1,
        key: 1,
        name: '경기 안내',
        path: `/contest/1?contestId=${getContestSeq() ? getContestSeq() : ''}`,
      },
      {
        description: '선수 접수',
        displayStatus: 'SHOW',
        id: 2,
        key: 2,
        name: '선수 접수',
        path: `/contest/2?contestId=${getContestSeq() ? getContestSeq() : ''}`,
      },
      {
        description: '접수 수정',
        displayStatus: 'SHOW',
        id: 3,
        key: 3,
        name: '접수 수정',
        path: `/contest/3?contestId=${getContestSeq() ? getContestSeq() : ''}`,
      },
      // { description: '전시 품목', displayStatus: 'SHOW', id: 4, key: 4, name: '전시 품목', path: '/contest/4' },
      // { description: '부스 안내', displayStatus: 'SHOW', id: 5, key: 5, name: '부스 안내', path: '/contest/5' },
      // { description: '부스 배치도', displayStatus: 'SHOW', id: 6, key: 6, name: '부스 배치도', path: '/contest/6' },
      // { description: '숙박 안내', displayStatus: 'SHOW', id: 7, key: 7, name: '숙박 안내', path: '/contest/7' },
    ];

    const componentInfo = {
      1: ContestInfoDetail,
      2: ContestRegisterInfo,
      3: ContestPlayerUpdate,
      4: ExhibitionItems,
      5: BoothInformation,
      6: BoothLayout,
      7: HotelGuide,
    };

    const routerClick = () => {
      scrollPositionStore.updateScrollPosition({ top: window.scrollY, left: 0 });
    };

    return { dialog, selectedTab, tabs, isContestsLoading, contestList, componentInfo, routerClick };
  },
};
</script>

<style scoped lang="scss">
.regist-complete-dialog {
  width: calc(100vw / 1.5);
  max-width: 700px;
}
</style>
