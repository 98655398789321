<template>
  <div class="text-center">
    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'CircularLoader',
};
</script>

<style></style>
