<template>
  <div class="wrapper">
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

    <basic-input
      class="mb-6"
      label="제목"
      placeholder="제목을 입력하세요"
      variant="outlined"
      hide-details="auto"
      :text-value="title"
      required
      @update:textValue="title = $event"
    ></basic-input>

    <div class="wrapper__inputWrapper">
      <basic-input
        class="w-50"
        label="가격"
        placeholder="판매/구입 가격을 입력하세요"
        variant="outlined"
        type="number"
        hide-details="auto"
        :text-value="price"
        required
        @update:textValue="price = $event"
      >
        <template #details>
          <div class="wrapper__priceHint">{{ priceHint }} 원</div>
        </template>
      </basic-input>
      <div class="w-50">
        <basic-button-group label="판매 유형" :items="displaySellTypes" :active-value="sellType" @update:activeValue="sellType = $event"></basic-button-group>
      </div>
    </div>

    <div class="editor-wrapper">
      <post-editor ref="postEditor" :update-content="updateContent"></post-editor>
      <v-file-input
        v-model="files"
        accept="image/*"
        label="물품 이미지 업로드"
        variant="outlined"
        hint="이미지 업로드는 한 장만 가능합니다"
        class="mt-5"
        small-chips
        persistent-hint
      ></v-file-input>
    </div>
    <v-divider></v-divider>
    <v-row justify="end">
      <v-col cols="auto">
        <v-btn class="cancel-btn" variant="outlined" size="x-large" @click="onClickCancel">취소</v-btn>
        <v-btn class="post-btn" size="x-large" :loading="loading" @click="onClickPost">게시하기</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import PostEditor from '@/components/PostEditor.vue';
import BasicInput from '@/components/BasicInput.vue';
import BasicButtonGroup from '@/components/BasicButtonGroup.vue';
import { uploadUsedMarketPostAPI } from '@/apis/usedMarket';
import { warningToast } from '@/utils/toast';
import { MARKET_TYPE } from '@/constants/usedMarket';

export default {
  components: { PostEditor, BasicInput, BasicButtonGroup },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    subCategory: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const router = useRouter();

    const loading = ref(false);
    const postEditor = ref(null);
    const title = ref('');
    const price = ref('');
    const content = ref('');
    const files = ref([]);
    const sellType = ref(MARKET_TYPE.SELL.ID);
    const displaySellTypes = Object.values(MARKET_TYPE).map((v) => ({
      id: v.ID,
      label: v.NAME,
    }));
    const priceHint = computed(() => Intl.NumberFormat('ko-KR').format(price.value));

    const onClickCancel = () => {
      router.back();
    };

    const updateContent = (e) => {
      content.value = e;
    };

    const uploadPost = async () => {
      loading.value = true;
      const formData = new FormData();

      if (!title.value) {
        warningToast('제목은 필수값 입니다.');
        return;
      }
      if (!content.value) {
        warningToast('내용을 입력해 주세요');
        return;
      }
      const requestBody = JSON.stringify({
        title: title.value,
        body: content.value,
        price: price.value,
        type: sellType.value,
      });
      const blob = new Blob([requestBody], { type: 'application/json' });
      formData.set('request', blob);
      formData.append('thumbnailImage', files.value[0]);

      await uploadUsedMarketPostAPI(formData);

      loading.value = false;
    };

    const onClickPost = () => {
      uploadPost();
    };

    return { files, loading, priceHint, displaySellTypes, sellType, onClickCancel, onClickPost, postEditor, updateContent, title, price };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-bottom: 1.5rem;

  @include sm-and-down {
    padding: 1rem;
  }

  &__inputWrapper {
    display: flex;

    & > :not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__input {
    max-width: 40rem;
  }

  &__priceHint {
    @include spreadTypoMap($body-medium);
  }
}

.editor-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cancel-btn {
  @include spreadTypoMap($button-small);

  border: 1px solid $gray-30;
  margin-right: 0.5rem;
}

.post-btn {
  @include spreadTypoMap($button-small);

  background-color: $blue-50;
  color: white;
}
</style>
