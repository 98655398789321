<template>
  <div class="card">데이터가 없습니다.</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card {
  padding: 3rem;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: $gray-10;
  color: $gray-50;
  border: none;
  border-radius: 4px;
}
</style>
