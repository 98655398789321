<template>
  <div class="main-wrapper">
    <page>
      <template #main-section>
        <div class="content-wrapper">
          <EducationDetailTitle />
          <EducationDetailProcess />
          <EducationDetailClassDesc />
          <EducationDetailReview />
          <EducationDetailDesigner />
          <EducationDetailMain />
        </div>
      </template>
    </page>
  </div>
</template>

<script>
import EducationDetailTitle from '@/education-mocking/detail/EducationDetailTitle.vue';
import EducationDetailProcess from '@/education-mocking/detail/EducationDetailProcess.vue';
import EducationDetailClassDesc from '@/education-mocking/detail/EducationDetailClassDesc.vue';
import EducationDetailMain from '@/education-mocking/detail/EducationDetailMain.vue';
import EducationDetailReview from '@/education-mocking/detail/EducationDetailReview.vue';
import EducationDetailDesigner from '@/education-mocking/detail/EducationDetailDesigner.vue';

import { mdiCalendarCheck, mdiPlay } from '@mdi/js';
import Page from '@/components/Page';

export default {
  components: {
    EducationDetailProcess,
    EducationDetailTitle,
    EducationDetailClassDesc,
    EducationDetailMain,
    EducationDetailDesigner,
    EducationDetailReview,
    Page,
  },
  setup() {
    return { mdiCalendarCheck, mdiPlay };
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  & > :not(:last-child) {
    margin-bottom: 2.5rem;
  }
}
.title {
  &__profile {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    width: 540px;
    border-radius: 70px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: auto;
    margin-bottom: 1rem;
  }

  &__edu_name {
    @include spreadTypoMap($title-large);
    text-align: center;
    margin-bottom: 0.75rem;
  }

  &__designer_name {
    @include spreadTypoMap($title-small);
    font-weight: 500;
    text-align: center;
  }
}

.main-wrapper {
  background-color: $black;
  padding-top: 2.5rem;
  & > :not(:last-child) {
    margin-bottom: 2rem;
  }
}
</style>
