<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24" :aria-labelledby="iconName">
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <g :fill="iconColor" :stroke="iconStroke">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconBase',
  props: {
    iconName: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: '#181E2D',
    },
    iconStroke: {
      type: String,
      default: 'none',
    },
  },
};
</script>
