<template>
  <div v-click-outside="closeHandler" class="alarm">
    <div class="alarm__icon">
      <v-icon :class="{ alarm__exist: pushHistoryCount > 0 }" :icon="mdiBell" size="large" :color="fill" @click="toggleHandler"> </v-icon>
    </div>
    <v-card class="alarmCard" :class="{ alarmCard__open: isOpen, alarmCard__close: !isOpen }">
      <v-card-title class="alarmCard__title">
        <v-icon class="alarm__icon mr-2" :icon="mdiBellCheckOutline"></v-icon>
        푸시 알림
      </v-card-title>
      <v-card-subtitle class="text-right">읽지 않은 알림 개수: {{ pushHistoryCount }}</v-card-subtitle>

      <v-card-text class="alarmCardBody">
        <template v-if="isLoading">
          <circular-loader />
        </template>
        <template v-else-if="pushHistories.length > 0">
          <div v-for="history in pushHistories" :key="history.appPushId" class="alarmCardBody__box">
            <div class="alarm__icon mr-4">
              <v-icon :class="{ 'alarmCardBody__not-read': !history.isRead }" :icon="mdiMessageTextOutline" size="large"> </v-icon>
            </div>
            <div class="alarmCardBody__content">
              <div class="alarmCardBody__title">{{ history.title }}</div>
              <div class="alarmCardBody__desc">{{ history.body }}</div>
              <div class="alarmCardBody__date">{{ parseKoFullDate(history.createdDate) }}</div>
            </div>
          </div>
        </template>
        <div v-else class="alarmCardBody__box">푸시 알림이 없습니다.</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { mdiBell, mdiBellCheckOutline, mdiMessageTextOutline } from '@mdi/js';

import { getPushHistoryCount, getPushHistory } from '@/apis/push';

import { parseKoFullDate } from '@/utils/date';
import CircularLoader from '@/components/CircularLoader.vue';

export default {
  components: { CircularLoader },
  props: {
    fill: {
      type: String,
      default: '#272F3E',
    },
  },
  setup() {
    const isOpen = ref(false);
    const isLoading = ref(false);
    const pushHistoryCount = ref(0);
    const pushHistories = ref([]);

    const initApi = async () => {
      const data = await getPushHistoryCount();
      pushHistoryCount.value = data.count;
    };

    onMounted(() => {
      initApi();
    });

    const toggleHandler = async () => {
      isOpen.value = !isOpen.value;

      if (isOpen.value) {
        isLoading.value = true;
        const data = await getPushHistory();
        pushHistories.value = data;
        isLoading.value = false;
      }
    };

    const closeHandler = () => {
      isOpen.value = false;
    };

    return {
      mdiBell,
      mdiBellCheckOutline,
      isOpen,
      closeHandler,
      toggleHandler,
      pushHistoryCount,
      pushHistories,
      parseKoFullDate,
      isLoading,
      mdiMessageTextOutline,
    };
  },
};
</script>

<style scoped lang="scss">
.alarm {
  &__icon {
    position: relative;

    &__count {
      position: ab;
    }
  }

  &__btn {
    padding: 0;
    margin-right: 4px;
    cursor: pointer;
  }

  &__exist {
    &::before {
      position: absolute;
      top: 3px;
      right: 3px;
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $koba-red;
    }
  }
}

.alarmCard {
  position: absolute;
  top: 70px;
  right: 0;
  margin: 18px;
  width: 80%;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color: rgb(238, 240, 247);

  transition: all ease-in-out 0.5s;

  &__open {
    max-height: 80vh;
  }

  &__close {
    visibility: hidden;
    max-height: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    padding-top: 1rem;
  }
}

.alarmCardBody {
  overflow-y: auto;
  height: 100%;

  & > * {
    margin-bottom: 1rem;
  }

  &__box {
    display: flex;
    border-radius: 12px;
    border: 1px solid rgb(108, 108, 108);
    padding: 1rem;
    word-break: break-all;
  }

  &__not-read {
    &::before {
      position: absolute;
      top: 0px;
      right: 0px;
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $koba-red;
    }
  }

  &__title {
    @include spreadTypoMap($title-xsmall);
    color: $gray-70;
    margin-bottom: 0.5rem;
  }

  &__desc {
    @include spreadTypoMap($body-small);
    color: $gray-50;
    margin-bottom: 0.25rem;
  }

  &__date {
    @include spreadTypoMap($body-xsmall);
    color: $gray-40;
  }
}
</style>
