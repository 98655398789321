<template>
  <v-app>
    <GNB-layout>
      <Suspense>
        <router-view />

        <template #fallback> Loading... </template>
      </Suspense>
    </GNB-layout>
  </v-app>
</template>

<script>
import GNBLayout from '@/layout/GNBLayout.vue';

import '@/styles/global.css';
import { getToken } from '@/utils/tokenUtil';
import { useMenuStore, useUserStore } from '@/stores';
import { setAuthHeader } from '@/lib/axiosController';
import { useRoute } from 'vue-router';
import { onMounted, computed } from 'vue';
import { updateMenuStatistic } from '@/apis/statistics';

export default {
  name: 'App',
  components: {
    GNBLayout,
  },
  setup() {
    const route = useRoute();
    const path = computed(() => route.path);
    const menuStore = useMenuStore();
    onMounted(() => {
      setTimeout(() => {
        const currentMenu = menuStore.getPathMap[path.value];
        if (currentMenu) {
          updateMenuStatistic(currentMenu.id);
        }
      }, 1000);
    });
  },
  async beforeCreate() {
    const token = getToken();
    const userStore = useUserStore();
    const menuStore = useMenuStore();

    if (token) {
      setAuthHeader(token);
      await userStore.refreshLogin(token);
    }
    await menuStore.updateMenuList();
  },
};
</script>

<style lang="scss">
.v-application {
  [class*='text-'] {
    font-family: $font-family !important;
  }
  font-family: $font-family !important;
}
</style>
