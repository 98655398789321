<template>
  <div class="bg-gray-10 rounded-md flex flex-col items-center pb-[4.5rem]">
    <a class="no-underline" href="/education/home">
      <ArticleTitle>Education Platform</ArticleTitle>
    </a>
    <div class="pc:text-body-xxLarge mobile:text-body-xxsmall text-gray-50 mb-8 text-center">
      주) 대한미용사회 교육 플랫폼이 오픈 되었습니다.<br />
      다양한 미용기술을 무료 온라인 강의를 통해 쉽게 접해보세요.
    </div>
    <video id="video-player" class="w-full h-full max-w-[45rem] mb-4" :poster="thumnail" controls></video>
    <div class="pc:text-title-small mobile:text-title-xsmall text-black mb-4">{{ title }}</div>
    <div class="pc:text-body-medium mobile:text-body-xxsmall text-gray-50">{{ createdDate }}</div>
  </div>
</template>

<script>
import { ArticleTitle } from '@/containers/Main/components';
import { dayjs } from '@/utils/date';
import Hls from 'hls.js';
import { onMounted } from 'vue';

export default {
  components: {
    ArticleTitle,
  },
  props: {
    lectureView: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    onMounted(() => {
      const videoSource = props.lectureView.videoUrl;
      const videoTag = document.getElementById('video-player');

      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(videoSource);
        hls.attachMedia(videoTag);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          // videoTag.play();
        });
      } else if (videoTag.canPlayType('application/vnd.apple.mpegurl')) {
        videoTag.src = videoSource;
        videoTag.addEventListener('loadedmetadata', () => {
          // videoTag.play();
        });
      }
    });

    return {
      title: props.lectureView.title ?? '',
      description: props.lectureView.description ?? '',
      createdDate: dayjs(props.lectureView.createdDate).format('YYYY.MM.DD (ddd) hh:mm') ?? '',
      thumnail: props.lectureView.thumbnailUrl ?? require('@/assets/main_education_video_thumnail.png'),
    };
  },
};
</script>

<style lang="scss" scoped>
video {
  object-fit: fill;
}
</style>
