<template>
  <v-carousel :hide-delimiters="true" :show-arrows="false" style="height: auto">
    <v-carousel-item v-for="(item, i) in items" :key="i">
      <div class="banner_wrapper">
        <div class="banner_content">
          <div class="text__title-medium mb-4" style="color: #e5e7eb">대한미용사회 교육 플랫폼 오픈</div>
          <p class="text__body-medium" style="color: #e5e7eb">
            안녕하십니까? 대한미용사회 미용업 온라인 기술교육 사이트에 오신 것을 환영합니다.<br />대한미용사회는 미용을 업으로 하는 미용인들이 미용업 발전 및
            기술향상과 회원 상호간의 친목을 도모하여<br />공중위생과 국민보건의 향상에 기여함을 목적으로 결성된 단체입니다.<br />회원이 공동의 목적을 실현하기
            위해 활발한 대외적인 활동을 통해 회원들의 권익을 보호하고 미용인의 위상제고 힘쓰고 있습니다.<br />아울러 새로운 뷰티트렌드 등 각종 정보의 교류로
            미용인들의 기술향상은 물론 우리나라 미용 산업 발전에 중추적인 역할을 담당하고 있습니다.
          </p>
        </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'EducationFeature',
  components: {},
  props: {},
  setup() {
    const items = [{ src: require('@/assets/president.png'), title: 'ddd' }];
    return { items };
  },
};
</script>

<style scoped lang="scss">
.banner_wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #853ac0;
  width: 100%;
  height: 320px;

  @include sm-and-up {
    flex-wrap: nowrap;
  }

  @include sm-and-down {
    height: 365px;
  }
}

.banner_content {
  width: 100%;
  padding: 20px 320px;

  @include md-and-down {
    padding: 20px;
  }

  @include sm-and-down {
    padding: 20px;
  }
}

.text__title-medium {
  @include sm-and-down {
    font-size: 20px;
  }
}

.text__body-medium {
  @include sm-and-down {
    font-size: 14px;
  }
}
</style>
