<template>
  <div class="table-wrapper">
    <div class="commitee-table-community-selector">
      <v-btn flat class="button-medium-line" type="button" @click="moveToCommiteeCommunityPage(100009)">지회지부 게시판으로 이동하기</v-btn>
    </div>
    <table-with-photo number="1">
      <tr>
        <td>1</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2805_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>강미영</td>
        <td>강미영헤어샵</td>
      </tr>
      <tr>
        <td>2</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2806_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>구은미</td>
        <td>민스헤어뷰티샵</td>
      </tr>
      <tr>
        <td>3</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2807_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>권옥순</td>
        <td>머리공방</td>
      </tr>
      <tr>
        <td>4</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2808_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김갑녀</td>
        <td>월드헤어로드</td>
      </tr>
      <tr>
        <td>5</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2809_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김성자</td>
        <td>김주희미용실</td>
      </tr>
      <tr>
        <td>6</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2810_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김안숙</td>
        <td>송죽미용실</td>
      </tr>
      <tr>
        <td>7</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2811_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김옥매</td>
        <td>옥돌매헤어샵</td>
      </tr>
      <tr>
        <td>8</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2812_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김장순</td>
        <td>헤어샤믈렛</td>
      </tr>
      <tr>
        <td>9</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2813_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박명숙</td>
        <td>본연애헤어목동점</td>
      </tr>
      <tr>
        <td>10</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2814_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박성미</td>
        <td>이지애미용실</td>
      </tr>
      <tr>
        <td>11</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2815_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박소야</td>
        <td>박소야헤어</td>
      </tr>
      <tr>
        <td>12</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2816_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박옥남</td>
        <td>리앤박헤어메이크업</td>
      </tr>
      <tr>
        <td>13</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2817_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박옥희</td>
        <td>여왕프로헤어</td>
      </tr>
      <tr>
        <td>14</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2818_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>박정해</td>
        <td>에스티헤어컴</td>
      </tr>
      <tr>
        <td>15</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2819_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>방소림</td>
        <td>라브릿지헤어</td>
      </tr>
      <tr>
        <td>16</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2820_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>서유경</td>
        <td>뷰티스토리</td>
      </tr>
      <tr>
        <td>17</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2821_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>성주현</td>
        <td>헤어썸데이</td>
      </tr>
      <tr>
        <td>18</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2822_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>심근하</td>
        <td>Y2헤어코디</td>
      </tr>
      <tr>
        <td>19</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2823_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>안옥희</td>
        <td>안옥희건행헤어</td>
      </tr>
      <tr>
        <td>20</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2824_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>양희숙</td>
        <td>볼륨헤어살롱</td>
      </tr>
      <tr>
        <td>21</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2825_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>오정숙</td>
        <td>미모미용실</td>
      </tr>
      <tr>
        <td>22</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2826_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>왕순자</td>
        <td>은실미용실</td>
      </tr>
      <tr>
        <td>23</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2827_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>유은파</td>
        <td>미용고수클럽</td>
      </tr>
      <tr>
        <td>24</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2829_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>윤미정</td>
        <td>제시뷰티아카데미</td>
      </tr>
      <tr>
        <td>25</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2828_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>윤희</td>
        <td>스콜헤어</td>
      </tr>
      <tr>
        <td>26</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2830_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이기빈</td>
        <td>기린헤어</td>
      </tr>
      <tr>
        <td>27</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2831_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이기순</td>
        <td>미스타미용실</td>
      </tr>
      <tr>
        <td>28</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2832_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이순옥</td>
        <td>댕기미용실</td>
      </tr>
      <tr>
        <td>29</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2833_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이슬기</td>
        <td>이태리헤어</td>
      </tr>
      <tr>
        <td>30</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2834_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이승희</td>
        <td>제시아카데미 강릉점</td>
      </tr>
      <tr>
        <td>31</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2835_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이점숙</td>
        <td>새로미헤어샵</td>
      </tr>
      <tr>
        <td>32</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2836_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이종란</td>
        <td>CW헤어모드</td>
      </tr>
      <tr>
        <td>33</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2837_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이화자</td>
        <td>리가토탈헤어클럽</td>
      </tr>
      <tr>
        <td>34</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2838_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>장순희</td>
        <td>제이헤어플러스</td>
      </tr>
      <tr>
        <td>35</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2839_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>정경희</td>
        <td>송죽미용실</td>
      </tr>
      <tr>
        <td>36</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2840_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>정애순</td>
        <td>신성헤어</td>
      </tr>
      <tr>
        <td>37</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2841_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>정윤남</td>
        <td>정윤남미장</td>
      </tr>
      <tr>
        <td>38</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2843_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>정희선</td>
        <td>-</td>
      </tr>
      <tr>
        <td>39</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2848_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>최원희</td>
        <td>에셀슈와리뷰티살롱</td>
      </tr>
      <tr>
        <td>40</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2849_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>최은경</td>
        <td>하이클라스헤어샵</td>
      </tr>
      <tr>
        <td>41</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2850_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>황병숙</td>
        <td>황정영미용실</td>
      </tr>
      <tr>
        <td>42</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2851_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>황영은</td>
        <td>헤어스미용실</td>
      </tr>
      <tr>
        <td>43</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_20&amp;savefilename=bbs_20_2852_1.jpg&amp;filename=중앙회CI.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>황정순</td>
        <td>헤어아트유니크</td>
      </tr></table-with-photo
    >
  </div>
</template>

<script>
import TableWithPhoto from '@/components/committee/TableWithPhoto';
import { moveToCommiteeCommunityPage } from '@/components/committee/commonUtil';

export default {
  components: { TableWithPhoto },
  setup() {
    return { moveToCommiteeCommunityPage };
  },
};
</script>

<style scoped lang="scss">
.commitee-table-community-selector:deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -10px;

  .branch-selects {
    .branch-selector {
      max-width: unset;
      width: auto;
    }
  }
}
</style>
