const validate = (rules, form) => {
  let message;
  let isValid = true;

  /** 일반적인 입력 룰 */
  outer: for (const ruleName in rules) {
    for (const rule of rules[ruleName]) {
      const res = rule(form[ruleName]);

      if (typeof res === 'string') {
        message = `${formName[ruleName] || ''}${res}`;
        isValid = false;
        break outer;
      }
    }
  }

  return { isValid, message };
};

const formName = {
  name: '이름을 ',
  loginId: '아이디를 ',
  phone: '전화번호를 ',
  authcode: '인증코드를 ',
  password: '비밀번호를 ',
};

export default validate;
