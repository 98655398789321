<template>
  <article-wrapper>
    <article-title>Beauty Article</article-title>
    <beauty-article-vertical-group :articles="postInfos" />
  </article-wrapper>
</template>

<script>
import { ref } from 'vue';

import { ArticleWrapper, ArticleTitle, BeautyArticleVerticalGroup } from '@/containers/Main/components';
import { getImportantArticleAPI } from '@/apis/__main__';
import { ARTICLE_TYPE } from '@/constants/article';
import { UNEXPECTED_ERROR } from '@/constants/commons/errors';
import { errorToast } from '@/utils/toast';

export default {
  components: { ArticleWrapper, ArticleTitle, BeautyArticleVerticalGroup },
  props: {
    importantArticles: {
      type: Array,
      default: () => [
        {
          bodySummary: '',
          id: 5659,
          newsType: 'NEWS',
          thumbnail: 'https://storage.googleapis.com/koba-production-bucket/hls/raw/newsletter_8.png',
          title: '',
        },
        {
          bodySummary: '',
          id: 5659,
          newsType: 'NEWS',
          thumbnail: 'https://storage.googleapis.com/koba-production-bucket/hls/raw/newsletter_9.png',
          title: '',
        },
        {
          bodySummary: '',
          id: 5659,
          newsType: 'NEWS',
          thumbnail: 'https://storage.googleapis.com/koba-production-bucket/hls/raw/newsletter_10.png',
          title: '',
        },
      ],
    },
  },
  setup(props) {
    const selectedTab = ref(null);
    const postInfos = ref(props.importantArticles);

    const onClickTab = async (id) => {
      try {
        const news = await getImportantArticleAPI(id);
        postInfos.value = news;
      } catch (e) {
        errorToast(e.message || UNEXPECTED_ERROR);
      }
    };
    const mockTabsData = [
      { id: 1, name: ARTICLE_TYPE.CENTRAL.NAME },
      { id: 2, name: ARTICLE_TYPE.COMMITTEE.NAME },
      { id: 3, name: ARTICLE_TYPE.BRANCH.NAME },
      { id: 4, name: ARTICLE_TYPE.COMPANY.NAME },
      { id: 5, name: ARTICLE_TYPE.ISSUE.NAME },
      { id: 6, name: ARTICLE_TYPE.EVENT.NAME },
    ];

    return { mockTabsData, selectedTab, onClickTab, postInfos };
  },
};
</script>
