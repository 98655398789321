import { computed, ref } from 'vue';

/**
 * @param {object} params
 * @param {number} params.initialTime
 * @param {(count: number) => void=} params.callback
 *
 * @description
 * - initialTime: 초 단위
 * - format: MM:SS 와 같은 형식
 */
const useTimer = ({ initialTime, callback }) => {
  const count = ref(initialTime);
  const isActive = ref(false);
  const isFinish = ref(false);

  const time = computed(() => {
    if (count.value <= 0) {
      return setTime(0, 0);
    }

    const minute = Math.trunc(count.value / 60);
    const second = count.value % 60;

    return setTime(minute, second);
  });

  let interVerId;

  /** 타이머를 시작하는 함수 */
  const start = () => {
    if (isNaN(initialTime)) {
      throw new Error('숫자가 아닌 값을 지정할 수는 없습니다.');
    }

    isActive.value = true;

    interVerId = setInterval(() => {
      if (count <= 0) {
        stop();
      }

      if (isActive) {
        isFinish.value = false;
        count.value -= 1;
        callback?.(count.value);
      }
    }, 1000);
  };

  /** 타이머를 일시정지하는 함수 */
  const pause = () => {
    isActive.value = !isActive.value;
  };

  /** 타이머를 완전히 종료하는 함수 */
  const stop = () => {
    clearInterval(interVerId);
    interVerId = null;
    count.value = initialTime;
    isFinish.value = true;
    isActive.value = false;
  };

  return { isFinish, count, time, isActive, timerAction: { start, pause, stop } };
};

const setTime = (minute, second) => ({ minute, second });

export default useTimer;
