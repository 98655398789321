<template>
  <article-wrapper>
    <article-title>Latest News</article-title>
    <article-tabs
      :selected-tab="selectedTab"
      :is-customized-tab-action="true"
      :tabs="mockTabsData"
      :on-click="onClickTab"
      :is-full-width="true"
      class="mobile:pr-[20px]"
    />
    <article-vertical-group :articles="postInfos" />
  </article-wrapper>
</template>

<script>
import { ArticleWrapper, ArticleTitle, ArticleTabs, ArticleVerticalGroup } from '@/containers/Main/components';

import { ref } from 'vue';
import { getLatestArticleAPI } from '@/apis/__main__';
import { ARTICLE_TYPE } from '@/constants/article';

export default {
  components: { ArticleWrapper, ArticleTitle, ArticleTabs, ArticleVerticalGroup },
  props: {
    initArticles: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const selectedTab = ref(null);
    const postInfos = ref(props.initArticles ?? []);

    const onClickTab = async (id) => {
      const news = await getLatestArticleAPI(id, 5);

      postInfos.value = news;
    };

    const mockTabsData = [
      { id: ARTICLE_TYPE.NEWS.ID, name: ARTICLE_TYPE.NEWS.NAME },
      { id: ARTICLE_TYPE.BEAUTY.ID, name: ARTICLE_TYPE.BEAUTY.NAME },
      { id: ARTICLE_TYPE.COLUMN.ID, name: ARTICLE_TYPE.COLUMN.NAME },
    ];

    return { mockTabsData, selectedTab, onClickTab, postInfos };
  },
};
</script>
