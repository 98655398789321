<template>
  <section-layout background="#181e2d">
    <template #main-section="{ isMobileUi }">
      <div :style="isMobileUi ? 'padding: 52px 20px 48px' : 'padding: 32px 0 96px'">
        <div>
          <div>
            <h3 class="text__title-small mb-4" style="color: white">강사 소개</h3>
          </div>
          <div class="vertical-card-group">
            <template v-for="item in instructors" :key="item.id">
              <vertical-card to="/" :src="item.thumbnailUrl" :title="item.name" :sub-text="item.description" :tags="item.lectureTags" />
            </template>
          </div>
        </div>
        <div>
          <div>
            <h3 class="text__title-small mb-4" style="color: white">2023 추천 교육</h3>
          </div>
          <div class="horizontal-card-group">
            <template v-for="item in lectures" :key="item.lectureId">
              <horizontal-card
                to="/"
                :src="item.thumbnailUrl"
                :title="item.title"
                :sub-text="item.description"
                :tags="item.lectureTags"
                @click="moveToPage(item)"
              />
            </template>
          </div>
        </div>
      </div>
    </template>
  </section-layout>
</template>

<script>
import SectionLayout from '@/education-mocking/educationHome/SectionLayout';
import VerticalCard from '@/education-mocking/educationHome/VerticalCard';
import HorizontalCard from '@/education-mocking/educationHome/HorizontalCard';
import { useRouter } from 'vue-router';
// import { isMobileTablet } from '@/utils/device';

export default {
  name: 'EducationListGroups',
  components: { SectionLayout, VerticalCard, HorizontalCard },
  props: {
    lectures: {
      type: Array,
      default: () => [],
    },
    instructors: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const router = useRouter();
    const moveToPage = (data) => {
      router.push(`/education/video-player-mobile/${data?.lectureId}`);
      // if (isMobileTablet(window.navigator)) {
      //   router.push(`/education/video-player-mobile/${data?.lectureId}`);
      // } else {
      //   router.push(`/education/video-player/${data?.lectureId}`);
      // }
    };
    return { moveToPage };
  },
};
</script>

<style scoped lang="scss">
.vertical-card-group {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  gap: 24px;
  padding: 5px 10px;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.horizontal-card-group {
  border-radius: 8px;
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  @include sm-and-down {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
