<template>
  <tabs v-model="selected" is-customized-tab-action :tabs="tabs" :tab-action="onClick" class="tab-deep" slider-color="#181e2d" :is-full-width="isFullWidth" />
</template>

<script>
import { ref } from 'vue';
import Tabs from '@/components/Tab2.vue';

export default {
  components: { Tabs },
  props: {
    selectedTab: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    isCustomizedTabAction: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const selected = ref(props.selectedTab);
    return { selected };
  },
};
</script>

<style scoped lang="scss">
.tab-deep:deep {
  .custom-tab-group {
    background: transparent !important;
    width: 100%;
  }

  .tab:first-of-type {
    margin-inline-start: 0 !important;
  }

  .custom-tab {
    @apply pc:text-caption-large mobile:text-caption-medium text-gray-50;
  }

  button[aria-selected='true'] {
    @apply pc:text-button-xLarge mobile:text-button-medium text-black;
  }
}
</style>
