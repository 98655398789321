<template>
  <div>
    <div class="d-flex flex-column">
      <branch-office-infos-fetcher class="mb-4">
        <template #contents>
          <branch-office-selects :default-branch-office-id="defaultBranchOfficeId" @update:branchOfficeId="selectedBranchOfficeId = $event" />
        </template>
      </branch-office-infos-fetcher>
      <div class="filter-search-wrapper">
        <select-box :options="selectedOptions" :default-option="selectedOptions[0]?.label" @click="onChangeSearchCategory" />
        <form @submit="onSearchPost">
          <div class="input-wrapper">
            <input v-model="searchInputModel" type="search" placeholder="검색어를 입력하세요" class="text-input" />
            <v-btn style="margin-left: 4px" size="small" type="submit" :loading="isLoading" flat>
              <icon-base icon-name="search" icon-color="none" icon-stroke="#0070bc" width="16" height="16"><search /></icon-base>
            </v-btn>
          </div>
        </form>
      </div>
    </div>

    <!--  게시글-->
    <ul style="padding-left: 0">
      <empty-data-card v-if="posts.length === 0" />
      <li
        v-for="post in posts"
        :key="post.id"
        class="list-card"
        :class="{ 'fixed-post': !!post.fixed }"
        :data-post-id="post.id"
        @click="(e) => goDetailPost(e, post)"
      >
        <p class="list-author">{{ post.userName }}</p>
        <p class="list-title">
          <span v-if="!!post.fixed" class="text__body-xsmall text-white mr-2 fixed-badge">고정글</span>
          <v-icon v-if="post.hasPassword" :icon="mdiLock" size="x-small" start></v-icon>{{ post.title }}
        </p>
        <span class="list-footer">{{ toYYYYMMDD(post.createdDate) }}</span>
        <span class="list-footer">조회수 {{ post.views }}</span>
      </li>
    </ul>
    <div v-if="!isFinished" class="more-btn-wrapper">
      <button class="more-btn" @click="onPressNext">더보기</button>
    </div>
    <!--  플로팅 버튼-->
    <button v-if="hideWriteable" class="add-btn" @click="goToWritePage">
      <icon-base icon-color="white" icon-name="plus" style="margin-right: 8px"><plus /></icon-base>
      글 작성하기
    </button>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import Plus from '@/icons/Plus';
import Search from '@/icons/Search';
import SelectBox from '@/components/SelectBox';
import { computed, ref, watch } from 'vue';
import { toYYYYMMDD } from '@/utils/date';
import { mdiLock } from '@mdi/js';
import { BranchOfficeInfosFetcher, BranchOfficeSelects } from '@/components/branchOffice';

import { getBranchOfficeBoardRWFRole } from '@/utils/community';
import EmptyDataCard from '@/components/EmptyDataCard.vue';
import { useMenuStore, useUserStore } from '@/stores';

export default {
  name: 'BranchOfficeBoardMobile',
  components: { Plus, EmptyDataCard, Search, SelectBox, BranchOfficeInfosFetcher, BranchOfficeSelects },
  props: {
    selectedOptions: {
      type: Array,
      required: true,
    },
    posts: {
      type: Array,
      default: () => [],
    },
    hideWriteButton: {
      type: Boolean,
      default: () => false,
    },
    searchPost: {
      type: Function,
      default: () => {},
    },
    isFinished: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const currentPage = ref(1);
    const userStore = useUserStore();
    const menuStore = useMenuStore();
    const communityId = computed(() => route.params.communityId); // read parameter id (it is reactive)
    const searchInputModel = ref('');
    const isLoading = ref(false);
    const selectedBranchOfficeId = ref('');
    const hideWriteable = ref(false);

    const defaultBranchOfficeId = computed(() => route.query.branchOfficeId);

    let selectedCategory = props.selectedOptions?.[0].id;
    let searchInputValue = '';

    watch(selectedBranchOfficeId, () => {
      searchInputModel.value = '';
      hideWriteable.value = getWriteRole();
      selectedCategory = 'all';

      currentPage.value = 1;
      fetchPost(1, true);
    });

    watch(
      () => userStore.getUserView,
      (current) => {
        if (current?.boardAuthorities.length > 0) {
          hideWriteable.value = getWriteRole();
        }
      },
    );

    function getWriteRole() {
      const communityMenuMap = menuStore.getWriteAuthorityMap;
      const communityMenu = communityMenuMap[`/community/${communityId.value}`];
      const writeAuthority = communityMenu?.writeAuthority;
      if (!communityMenuMap || !communityMenu) {
        return true;
      }

      return !!userStore.getUserView?.id && writeAuthority && rwfRole().write;
    }

    function rwfRole() {
      return getBranchOfficeBoardRWFRole(userStore.getUserView?.boardAuthorities, selectedBranchOfficeId.value);
    }

    const onChangeSearchCategory = ({ target = {} }) => {
      if (target?.value) {
        selectedCategory = target.value;
      }
    };

    const goDetailPost = (e, post) => {
      const { postId } = e.currentTarget.dataset;
      if (post.hasPassword) {
        router.push(`/post/${postId}?type=secure`);
        return;
      }

      router.push(`/post/${postId}`);
    };

    const goToWritePage = () => {
      router.push(`/write?communityId=${communityId.value}&branchOfficeId=${selectedBranchOfficeId.value}`);
    };

    const onPressNext = () => {
      currentPage.value += 1;
      fetchPost(currentPage.value);
    };

    const fetchPost = async (page = 1, isMobileInit = false) => {
      isLoading.value = true;
      searchInputValue = searchInputModel.value;
      try {
        if (selectedCategory === 'author') {
          await props.searchPost({ page, keyword: '', userName: searchInputValue, branchOfficeId: selectedBranchOfficeId.value, isMobileInit });
        } else if (selectedCategory === 'all') {
          await props.searchPost({ page, keyword: searchInputValue, userName: searchInputValue, branchOfficeId: selectedBranchOfficeId.value, isMobileInit });
        } else {
          await props.searchPost({ page, keyword: searchInputValue, userName: '', branchOfficeId: selectedBranchOfficeId.value, isMobileInit });
        }
      } finally {
        isLoading.value = false;
      }
    };

    const onSearchPost = (e) => {
      e.preventDefault();
      if (!isLoading.value) {
        fetchPost(1, true);
      }
    };

    return {
      goDetailPost,
      goToWritePage,
      onPressNext,
      toYYYYMMDD,
      mdiLock,
      hideWriteable,
      onChangeSearchCategory,
      onSearchPost,
      isLoading,
      searchInputModel,
      defaultBranchOfficeId,
      selectedBranchOfficeId,
    };
  },
};
</script>

<style lang="scss" scoped>
.text-input {
  min-width: 300px;
  &:focus {
    outline: none;
  }
}
.input-wrapper {
  margin-left: 8px;
  min-width: 352px;
  background: white;
  border-bottom: 1px solid $gray-30;
  padding: 10px 0;
  display: flex;
}

// 선택 + 검색
.filter-search-wrapper:deep {
  display: flex;
  flex-direction: column;

  margin-bottom: 1rem;
  margin-left: 4px;

  .select-box__selected {
    @include spreadTypoMap($body-xsmall);
    color: $blue-50;
    padding: 7px 0;
  }

  .select-box__options {
    @include spreadTypoMap($body-xsmall);
  }

  .select-box__icon {
    margin-left: 2px;
  }
}

.dropdown-wrapper {
  width: 45px;
}

.search-type {
  @include spreadTypoMap($body-xsmall);
  color: $blue-50;
}

.search-btn {
  @include spreadTypoMap($button-xsmall);

  justify-content: center;
  align-items: center;
  border: 1px solid $gray-30;
  border-radius: 3px;
  padding: 9px 9px 9px 12px;
}

// 게시글 목록
.list-card {
  list-style: none;
  padding: 1rem;
  background: white;
  border-bottom: 1px solid $gray-20;

  &:hover {
    cursor: pointer;
  }
}

.list-author {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: -0.07em;
  color: $black;
  margin-bottom: 8px;
}

.list-title {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: -0.07em;
  color: $black;
  margin-bottom: 8px;
}

.list-footer {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.07em;
  color: $gray-50;

  & + & {
    margin-left: 4px;
  }
}

.more-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.more-btn {
  border: 1px solid $gray-20;
  padding: 10px 41.5px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
}

// 플로팅 버튼
.add-btn {
  @include spreadTypoMap($button-large);
  color: white;

  position: fixed;
  left: 0;
  bottom: calc(60px + env(safe-area-inset-bottom));
  z-index: $community-write-button-z-index;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  background: $blue-50;
}

.fixed-post {
  background: #f9f9f8;
}

.fixed-badge {
  padding: 4px 6px;
  border-radius: 8px;
  background: $koba-red;
  font-weight: 600;
}
</style>
