import axios from '@/lib/axiosController';

export const searchPostListAPI = async (params) => {
  return await axios.get('/post/search', { params });
};

export const getPostSpecificAPI = async (postId, body = {}) => {
  return await axios.post(`/post/${postId}`, body);
};

export const uploadPostAPI = async (formData, body) => {
  return await axios.post(`/post/upload`, formData || {}, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const editPostAPI = async (formData, postId) => {
  return await axios.put(`/post/${postId}`, formData || {}, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deletePostAPI = (postId) => axios.delete(`/post/delete/${postId}`);

export const uploadReplayPostAPI = async (formData, body) => {
  return await axios.post(`/post/reply/upload`, formData || {}, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
};
