<template>
  <section class="section-wrapper">
    <h3 class="text__title-large text-center section-title" :style="`display: ${isMobileUi ? 'none' : 'block'}`">전시 품목</h3>

    <div class="grid-box">
      <title-desc-card v-for="item in EXHIBITION_ITEMS" :key="item.title" :title="item.title" :desc="item.desc" />
    </div>
  </section>
</template>

<script>
import TitleDescCard from '@/components/TitleDescCard.vue';

import { EXHIBITION_ITEMS } from '@/constants/contest';

export default {
  components: { TitleDescCard },
  props: {
    isMobileUi: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { EXHIBITION_ITEMS, TitleDescCard };
  },
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  margin-top: 24px;
  padding: 0 20px;

  @include md-and-up {
    margin-top: 48px;
    padding: 0 20px;
  }
}

.grid-box {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  margin: 3rem 0;

  @include md-and-down {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
