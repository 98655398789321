<template>
  <form :style="`padding: 0 ${isMobileUi ? 20 : 0}px 48px;`" class="form-body" @submit="resetPassword">
    <v-text-field v-model.trim="form.name" name="name" label="이름" placeholder="2글자 이상 입력해주세요." :rules="rules.name" variant="underlined" clearable />
    <v-text-field v-model.trim="form.loginId" name="loginId" label="아이디" :rules="rules.loginId" variant="underlined" clearable />
    <v-text-field
      v-model.trim="form.phone"
      name="phone"
      label="전화번호"
      type="tel"
      placeholder="숫자만 입력해주세요."
      :rules="rules.phone"
      :disabled="disabledPhoneField"
      variant="underlined"
      class="text-field-deep"
      clearable
      ><template #append
        ><v-btn
          type="button"
          class="button-xSmall-line append-button"
          :loading="request.isLoading"
          :disabled="request.isLoading && isRequestBtnActive"
          @click="requestAuthCode"
        >
          <span>인증번호 받기</span>
        </v-btn></template
      ></v-text-field
    >
    <v-text-field
      v-model.trim="form.authcode"
      name="authcode"
      type="number"
      label="인증코드"
      placeholder="인증번호를 입력해주세요"
      hint="핸드폰 번호를 다시 입력하려면 인증하기 버튼을 눌러주세요."
      :rules="rules.authcode"
      variant="underlined"
      class="text-field-deep"
      clearable
    >
      <template v-if="request.success && (verify.success || !isFinish)" #prepend-inner>
        <div class="text__caption-small" style="padding-right: 0.5rem; padding-top: 5px" :style="`color: ${verify.success ? 'green' : 'tomato'}`">
          {{ verify.success ? '인증완료' : mmSS }}
        </div>
      </template>

      <template #append>
        <v-btn type="button" class="button-xSmall-line append-button" :loading="verify.isLoading" :disabled="verify.isLoading" @click="verifyAuthCode"
          ><span>{{ verify.success || verify.error ? '재인증하기' : '인증하기' }}</span></v-btn
        >
      </template>
    </v-text-field>

    <v-text-field v-model.trim="form.password" type="password" name="password" label="새로운 비밀번호" :rules="rules.password" variant="underlined" clearable />
    <v-text-field
      v-model.trim="form.passwordRepeat"
      type="password"
      name="passwordRepeat"
      label="새로운 비밀번호 다시 입력"
      :rules="rules.passwordRepeat"
      variant="underlined"
      clearable
    />

    <button
      :class="{ 'button-large-primary': !isMobileUi, 'button-medium-primary': isMobileUi }"
      type="submit"
      :loading="isSubmitButtonLoading"
      style="width: 100%"
    >
      <span>비밀번호 재설정하기</span>
    </button>
  </form>
  <basic-modal :is-open="isDialogOpen" :body="dialogBody" :close-button="dialogCloseButton" />
</template>

<script>
import { computed, ref, watch } from 'vue';

import BasicModal from '@/components/modal/BasicModal';
import { ruleTemplate, usePhone, validate } from '@/containers/Find/service';
import useTimer from '@/containers/Find/service/useTimer';
import { toMMSS } from '@/utils/date';
import { postForgetPasswordAPI, postForgetPasswordVerificationAPI, postPasswordResetAPI } from '@/apis/forget';
import { errorToast, infoToast, successToast } from '@/utils/toast';
import { pick } from 'lodash-es';
import { useRouter } from 'vue-router';

export default {
  name: 'FindPassword',
  components: { BasicModal },
  props: {
    isMobileUi: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    /** formData */
    const form = ref({
      name: '',
      loginId: '',
      phone: '',
      authcode: '',
      password: '',
      passwordRepeat: '',
    });
    const isVerified = ref(false);

    /** btn */
    const isRequestBtnActive = ref(true);
    const isSubmitButtonLoading = ref(false);

    /** dialog */
    const isDialogOpen = ref(false);
    const dialogBody = ref('');
    const dialogCloseButton = ref({
      isLoading: false,
      text: '닫기',
      disabled: false,
      onClick: () => {
        isDialogOpen.value = false;
        dialogBody.value = '';
      },
    });

    const openDialog = (message) => {
      isDialogOpen.value = true;
      dialogBody.value = message;
    };

    const disabledPhoneField = ref(false);
    const { request, verify, phoneVerifyRequest, phoneVerify, resetPhoneVerifyResult } = usePhone();
    const { isFinish, time, timerAction } = useTimer({ initialTime: 180 });

    const mmSS = computed(() => toMMSS(time.value.minute, time.value.second));

    const requestAuthCode = async () => {
      const newRules = pick(rules, 'name', 'loginId', 'phone');
      const { isValid, message } = validate(newRules, { ...form.value, isVerified: isVerified.value });

      if (!isValid) {
        openDialog(message);
        return;
      }

      disabledPhoneField.value = true;

      await phoneVerifyRequest(() => postForgetPasswordAPI({ name: form.value.name, phone: form.value.phone, loginId: form.value.loginId }));
      timerAction.start();
      isRequestBtnActive.value = false;
    };

    const activateRequestAuthCode = () => {
      isRequestBtnActive.value = true;
      disabledPhoneField.value = false;
      form.value.authcode = '';
      timerAction.stop();
    };

    watch(
      () => request.value.error,
      (cur, prev) => {
        if (!prev && cur) {
          activateRequestAuthCode();
          resetPhoneVerifyResult();
        }
      },
    );

    const verifyAuthCode = async () => {
      /** 재인증하기 클릭 후 인증번호 받기를 누르지 않고 인증하기를 클릭하는 경우 */
      if (isFinish.value && !verify.success && !verify.value.error) {
        infoToast('인증번호 요청 후 인증 번호 받기 버튼을 눌러주세요.');
      }

      /** 재인증하기 -> 인증번호 찾기 버튼 활성화 */
      if (verify.value.success || verify.value.error || isFinish.value) {
        activateRequestAuthCode();
        resetPhoneVerifyResult();
        return;
      }

      const newRules = pick(rules, 'name', 'loginId', 'phone', 'authcode');
      const { isValid, message } = validate(newRules, { ...form.value, isVerified: isVerified.value });

      if (!isValid) {
        openDialog(message);
        return;
      }

      await phoneVerify(() =>
        postForgetPasswordVerificationAPI({ name: form.value.name, phone: form.value.phone, loginId: form.value.loginId, authcode: form.value.authcode }),
      );
      isVerified.value = verify.value.success;
      timerAction.stop();
    };

    const resetPassword = async (e) => {
      e.preventDefault();

      const newRules = pick(rules, 'loginId', 'password', 'isVerified');
      const { isValid, message } = validate(newRules, { ...form.value, isVerified: isVerified.value });

      if (form.value.password !== form.value.passwordRepeat) {
        openDialog('새로운 비밀번호가 서로 일치하지 않습니다.');
        return;
      }

      if (!isValid) {
        openDialog(message);
        return;
      }

      isSubmitButtonLoading.value = true;

      try {
        await postPasswordResetAPI({ loginId: form.value.loginId, password: form.value.password });
        successToast('비밀번호 재설정이 완료되었습니다.');
        router.push('/signin');
      } catch (e) {
        errorToast('비밀번호 재설정에 실패했습니다.');
      } finally {
        isSubmitButtonLoading.value = false;
      }
    };
    return {
      form,
      rules,
      request,
      mmSS,
      verify,
      isFinish,
      disabledPhoneField,
      isRequestBtnActive,
      isDialogOpen,
      dialogBody,
      dialogCloseButton,
      isSubmitButtonLoading,
      requestAuthCode,
      verifyAuthCode,
      resetPassword,
    };
  },
};

const rules = {
  name: ruleTemplate.name,
  loginId: ruleTemplate.loginId,
  phone: ruleTemplate.phone,
  authcode: ruleTemplate.authCode,
  password: ruleTemplate.password,
  passwordRepeat: ruleTemplate.password,
  isVerified: ruleTemplate.isVerified,
};
</script>

<style scoped lang="scss">
.form-body {
  margin-top: 32px;
}

.text-field-deep:deep {
  .v-input__append {
    padding-top: 0;
    margin-inline-start: 8px;
  }
}

.append-button {
  box-shadow: none;
  width: 102px;
  height: 100%;
  align-self: flex-end;

  &:hover {
    box-shadow: none;
  }
}
</style>
