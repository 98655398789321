/**
 * styleSheet 로드
 * @param {object} params
 * @param {string} params.id
 * @param {string} params.href
 *
 * */
export const getStyleSheet = ({ id, href }) => {
  const style = document.createElement('link');
  style.type = 'text/css';
  style.rel = 'stylesheet';
  style.href = href;
  style.id = id;
  document.head.appendChild(style);
};

/**
 * styleSheet 제거
 * @param {string} id - elementId
 */
export const removeStyleSheet = (id) => {
  if (!document) {
    throw Error('document 가 존재하지 않습니다.');
  }

  const element = document.head.querySelector(`#${id}`);

  if (element) {
    document.head.removeChild(element);
  }
};
