<template>
  <div class="bg">
    <div class="d-flex flex-column">
      <div class="category">수강생 리뷰</div>
      <div class="wrapper__title">현직 미용인들이 체감한<br />최재영의 교육 효과</div>
    </div>

    <div class="cardWrapper">
      <img width="475px" src="https://storage.googleapis.com/static.fastcampus.co.kr/prod/uploads/202106/132248-380/kimsanghee-review1.png" />
      <img width="475px" src="https://storage.googleapis.com/static.fastcampus.co.kr/prod/uploads/202106/132253-380/kimsanghee-review2.png" />
      <img width="475px" src="https://storage.googleapis.com/static.fastcampus.co.kr/prod/uploads/202106/132333-380/kimsanghee-review5.png" />
      <img width="475px" src="https://storage.googleapis.com/static.fastcampus.co.kr/prod/uploads/202106/132336-380/kimsanghee-review6.png" />
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: $gray-80;
  padding: 5rem;
  border-radius: 20px;
}

.category {
  @include spreadTypoMap($title-small);
  color: $koba-red;
  margin-bottom: 1rem;
}
.wrapper {
  display: flex;

  &__title {
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    color: white;

    & > div {
      margin-bottom: 0.5rem;
    }
  }

  &__desc {
    @include spreadTypoMap($body-large);
    color: $gray-80;
  }
}

.cardWrapper {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  & > :nth-child(1),
  & > :nth-child(3) {
    margin-left: auto;
  }
}
</style>
