<template>
  <div class="table-wrapper">
    <div class="commitee-table-community-selector">
      <v-btn flat class="button-medium-line" type="button" @click="moveToCommiteeCommunityPage(100004)">위원회 게시판으로 이동하기</v-btn>
    </div>
    <table-with-photo number="1">
      <tr>
        <td>1</td>
        <td>1기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2281.jpg&amp;filename=김선녀.jpg" alt="" style="width: 80px" />
        </td>
        <td>김선녀</td>
        <td>김진선헤어</td>
      </tr>
      <tr>
        <td>2</td>
        <td>1기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2282.jpg&amp;filename=박재영.jpg" alt="" style="width: 80px" />
        </td>
        <td>박재영</td>
        <td>우주헤어</td>
      </tr>
      <tr>
        <td>3</td>
        <td>1기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2283.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>전정자</td>
        <td>전정자헤어클리닉</td>
      </tr>
    </table-with-photo>

    <table-with-photo number="2">
      <tr>
        <td>1</td>
        <td>2기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2284.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>권영해</td>
        <td>권윤아헤어샾</td>
      </tr>
      <tr>
        <td>2</td>
        <td>2기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2285.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김미경</td>
        <td>한스헤어</td>
      </tr>
      <tr>
        <td>3</td>
        <td>2기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2286.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김선희</td>
        <td>샤넬헤어샵</td>
      </tr>
      <tr>
        <td>4</td>
        <td>2기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2287.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>백단심</td>
        <td>단심머리방</td>
      </tr>
      <tr>
        <td>5</td>
        <td>2기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2288.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>유건숙</td>
        <td>Bella hair</td>
      </tr>
      <tr>
        <td>6</td>
        <td>2기</td>
        <td>
          <img src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2289.png&amp;filename=17유소연.png" alt="" style="width: 80px" />
        </td>
        <td>유소연</td>
        <td>보까미용그룹</td>
      </tr>
      <tr>
        <td>7</td>
        <td>2기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2290.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이순자</td>
        <td>이가자헤어버스</td>
      </tr>
      <tr>
        <td>8</td>
        <td>2기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2291.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>최민자</td>
        <td>민 헤어스케치</td>
      </tr>
    </table-with-photo>

    <table-with-photo number="3"
      ><tr>
        <td>1</td>
        <td>3기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2292.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>마은정</td>
        <td>마헤어갤러리</td>
      </tr></table-with-photo
    >
    <table-with-photo number="4">
      <tr>
        <td>1</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2293.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김경자</td>
        <td>이슈헤어</td>
      </tr>
      <tr>
        <td>2</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2294.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김금순</td>
        <td>김금순Hair studio</td>
      </tr>
      <tr>
        <td>3</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2295.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김연민</td>
        <td>김연민헤어</td>
      </tr>
      <tr>
        <td>4</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2296.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김태현</td>
        <td>김윤희헤어샵</td>
      </tr>
      <tr>
        <td>5</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2297.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김해정</td>
        <td>리안헤어</td>
      </tr>
      <tr>
        <td>6</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2298.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이정숙</td>
        <td>이정숙헤어짱</td>
      </tr>
      <tr>
        <td>7</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2299.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>진화영</td>
        <td>진화영헤어샵</td>
      </tr>
      <tr>
        <td>8</td>
        <td>4기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2300.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>현명주</td>
        <td></td></tr
    ></table-with-photo>

    <table-with-photo number="5">
      <tr>
        <td>1</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2301.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>김미영</td>
        <td></td>
      </tr>
      <tr>
        <td>2</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2302.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>방경례</td>
        <td>희망미용실</td>
      </tr>
      <tr>
        <td>3</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2303.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>오수연</td>
        <td>오수연헤어아트</td>
      </tr>
      <tr>
        <td>4</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2304.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>이은옥</td>
        <td>세현뷰티샾</td>
      </tr>
      <tr>
        <td>5</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2305.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>재혜주</td>
        <td>폭스헤어살롱</td>
      </tr>
      <tr>
        <td>6</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2306.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>한혜경</td>
        <td>가인</td>
      </tr>
      <tr>
        <td>7</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2307.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>현서경</td>
        <td>고려정공</td>
      </tr>
      <tr>
        <td>8</td>
        <td>5기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2308.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>황형희</td>
        <td>공감헤어</td>
      </tr>
    </table-with-photo>

    <table-with-photo number="6">
      <tr>
        <td>1</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2309.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>강수희</td>
        <td>강수희스타일헤어샵</td>
      </tr>
      <tr>
        <td>2</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2310.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>배영언</td>
        <td>미라클헤어</td>
      </tr>
      <tr>
        <td>3</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2311.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>임대혁</td>
        <td>미모안헤어</td>
      </tr>
      <tr>
        <td>4</td>
        <td>6기</td>
        <td>
          <img
            src="http://www.ko-ba.org/bbs/download.php?table=bbs_12&amp;savefilename=bbs_12_2312.jpg&amp;filename=중앙회마크.jpg"
            alt=""
            style="width: 80px"
          />
        </td>
        <td>정미영</td>
        <td>미영헤어스케치</td>
      </tr>
    </table-with-photo>
  </div>
</template>

<script>
import TableWithPhoto from '@/components/committee/TableWithPhoto';
import { moveToCommiteeCommunityPage } from '@/components/committee/commonUtil';

export default {
  components: { TableWithPhoto },
  setup() {
    return { moveToCommiteeCommunityPage };
  },
};
</script>

<style scoped lang="scss">
.commitee-table-community-selector:deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -10px;

  .branch-selects {
    .branch-selector {
      max-width: unset;
      width: auto;
    }
  }
}
</style>
