<template>
  <section class="section-wrapper">
    <h3 class="text__title-large section-title" :style="`display: ${isMobileUi ? 'none' : 'block'}`">숙박 안내</h3>

    <v-card variant="tonal">
      <v-card-text>
        <div class="sub-title">롯데시티호텔</div>
        <div class="desc">
          <p>
            상기금액은 대한미용사회 프로모션가격입니다.<br />
            <strong>단, 하기 숙박신청서를 신청서 내의 이메일로 제출한 예약에 한함</strong><br />
            - 스탠다드(더블), 헐리우드(더블): 132,000원 (부가세포함/조식미포함)
          </p>
          <a :href="file" download="롯데시티호텔 숙박신청서"> <span>숙박신청서 다운받기</span> </a><br />
          <a href="https://www.lottehotel.com/daejeon-city/ko.html" target="_blank">홈페이지</a>
        </div>
      </v-card-text>
    </v-card>

    <v-card variant="tonal">
      <v-card-text>
        <div class="sub-title">ICC호텔</div>
        <div class="desc">
          - 스탠다드 (트윈) : 125,000원 (부가세포함)<br />
          - 문의 전화 : 042-866-5001<br />
          <a href="http://hotel.hotelicc.com" target="_blank">홈페이지</a>
        </div>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  setup() {
    const file = require('@/assets/2022_contest_lotte_city_hotel.pdf');

    return {
      file,
    };
  },
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  margin-top: 24px;
  padding: 0 20px;
  text-align: center;

  @include md-and-up {
    margin-top: 48px;
    padding: 0 20px;
  }

  & > * {
    margin-bottom: 3rem;
  }
}

.section-button {
  text-decoration: none;

  @include sm-and-down {
    span {
      @include spreadTypoMap($button-small);
    }
  }

  svg {
    margin-left: 4px;
  }

  @include md-and-up {
    svg {
      margin-left: 8px;
    }
  }
}

.sub-title {
  @include spreadTypoMap($title-small);
  color: $gray-80;
  margin-bottom: 1rem;
}

.desc {
  @include spreadTypoMap($body-large);
  color: $gray-80;
}
</style>
