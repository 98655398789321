<template>
  <div class="table-wrapper">
    <div class="commitee-table-community-selector">
      <v-btn flat class="button-medium-line" type="button" @click="moveToCommiteeCommunityPage(268)">위원회 게시판으로 이동하기</v-btn>
    </div>
    <table class="table">
      <tbody>
        <tr>
          <td class="table-tit" style="width: 50%; text-align: center">직위</td>
          <td class="table-tit" style="width: 50%; text-align: center">성명</td>
        </tr>
        <tr>
          <td class="table-1st" style="text-align: center">위 원 장</td>
          <td class="table-2ed" style="text-align: center">송 현주</td>
        </tr>
        <tr>
          <td class="table-1st" colspan="1" rowspan="4" style="text-align: center">
            <p>부위원장</p>

            <p>(4명)</p>
          </td>
          <td class="table-2ed" style="text-align: center">김&nbsp; 호</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 서원</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">오 송림</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 둘화</td>
        </tr>
        <tr>
          <td class="table-1st" colspan="1" rowspan="2" style="text-align: center">
            <p>감&nbsp; &nbsp; &nbsp; 사</p>

            <p>(2명)</p>
          </td>
          <td class="table-2ed" style="text-align: center">배 석근</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 종원</td>
        </tr>
        <tr>
          <td class="table-1st" colspan="1" style="text-align: center">총&nbsp; &nbsp; &nbsp; 무</td>
          <td class="table-2ed" style="text-align: center">김 승연</td>
        </tr>
        <tr>
          <td class="table-1st" rowspan="46" style="text-align: center">
            <p>운영위원</p>

            <p>(43명)</p>
          </td>
          <td class="table-2ed" style="text-align: center">신 인숙</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 미경</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 연미</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">정 세주</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 영선</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">한 진희</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 진희</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">신 명숙</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">권 현경</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 수미</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 호숙</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 경희</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 영미</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 미향</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">강 문정</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 민수</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">구 복연</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 주혜</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 진영</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">박 미옥</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">오 혜란</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">장 금순</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">정 형호</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">정 경자</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 혜경</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">진 명희</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">하 민하</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 정수</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 순경</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">황 계분</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">윤 상희</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">홍 순옥</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">박 금란</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">임 정숙</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">한 미례</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">강 인영</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">현 소영</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 춘희</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">정 희정</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">우 경숙</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 영임</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">정 성녀</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 보민</td>
        </tr>
      </tbody>
    </table>

    <table class="table mt-4">
      <tbody>
        <tr>
          <td class="table-tit" style="width: 50%; text-align: center">직위</td>
          <td class="table-tit" style="width: 50%; text-align: center">성명</td>
        </tr>
        <tr>
          <td class="table-1st" rowspan="51" style="text-align: center">
            특별회원<br />
            (47명)
          </td>
          <td class="table-2ed" style="text-align: center">김 보라</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">신 지현</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">박 성용</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">권 언주</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 지은</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 도연</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">안 연희</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 경은</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 희</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">길 민정</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">권 진형</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">최 정원</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">오 문주</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">최 지은</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 윤지</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 진강</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 남일</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">최 수인</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">노 명희</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">오 수진</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">신 은영</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">박 인숙</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 채원</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">권 연화</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 수화</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">박 미경</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 미경</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">정 지현</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">윤 미경</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">박 진숙</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">송 은희</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 혜연</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 연화</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">유 경숙</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">석 경희</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 선하</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">신 부남</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">안 연주</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 상희</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 소현</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">김 시원</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">오 봉임</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">박 경희</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">우 종숙</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">남 연숙</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">이 성주</td>
        </tr>
        <tr>
          <td class="table-2ed" style="text-align: center">최 광옥</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { moveToCommiteeCommunityPage } from '@/components/committee/commonUtil';

export default {
  setup() {
    return { moveToCommiteeCommunityPage };
  },
};
</script>

<style scoped lang="scss">
.commitee-table-community-selector:deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 15px;

  .branch-selects {
    .branch-selector {
      max-width: unset;
      width: auto;
    }
  }
}
</style>
