<template>
  <div @click="toLink">
    <v-hover v-slot="{ isHovering, props }">
      <v-card class="mx-auto card-cover" :class="className">
        <v-img :src="src" class="thumbnail" cover v-bind="props" style="aspect-ratio: 4/3">
          <v-fade-transition>
            <div v-if="isHovering" class="image-cover-card__overlay flex flex-column justify-content-end w-100 h-100 p-[16px]">
              <v-card-title class="text-title-xsmall text-white mb-[16px] p-0">{{ title }}</v-card-title>
              <div class="text-title-body-medium text-gray-30 mb-[16px]">{{ subtitle }}</div>
              <div class="text-title-body-medium text-gray-30 mb-0 line-clamp-2">{{ text }}</div>
            </div>
          </v-fade-transition>
        </v-img>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
  props: {
    className: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    newsId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const router = useRouter();
    const toLink = () => {
      router.push(`/news-detail/${props.newsId}`);
    };

    return { toLink };
  },
};
</script>

<style lang="scss" scoped>
.card-cover {
  @apply shadow-none;
  border-radius: 0;
}

.thumbnail {
  @apply pc:w-[418px] mobile:w-[280px];
}

.image-cover-card {
  &__overlay {
    background-color: rgba(2, 11, 28, 0.5);
    cursor: pointer;
  }
}
</style>
