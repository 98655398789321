import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { getImportantArticleAPI, getLatestArticleAPI } from '@/apis/__main__';

export default defineStore('main', () => {
  const importantArticles = ref({});
  // const popularArticles = ref({});
  const latestArticles = ref({});

  async function fetchImportantArticles(param) {
    importantArticles.value = await getImportantArticleAPI(param);
  }

  async function fetchLatestArticles(param) {
    latestArticles.value = await getLatestArticleAPI(param);
  }

  const getImportantArticles = computed(() => importantArticles.value);
  const getLatestArticles = computed(() => latestArticles.value);

  return {
    fetchImportantArticles,
    fetchLatestArticles,
    getImportantArticles,
    getLatestArticles,
  };
});
