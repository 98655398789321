<template>
  <input-wrapper-grid :required="required" :label="label" :helper-text="helperText" :error-text="errorText" :grid-items-length="selects.length">
    <template #form-preview>
      <slot name="select-preview"></slot>
    </template>
    <template #form-input>
      <template v-for="(select, idx) in selects" :key="idx">
        <div class="select-deep-wrapper">
          <select-box
            :id="attrs.id[idx]"
            :name="attrs.name[idx]"
            :value="attrs.value[idx]"
            :options="select"
            :default-option="defaultOption[idx]"
            is-full-width
            icon-color="#181e2d"
            text-color="#6c727f"
            @click="attrs.onClick"
          />
          <hr style="margin: 0" />
        </div>
      </template>
    </template>
    <template #form-button>
      <slot name="select-button"></slot>
    </template>
  </input-wrapper-grid>
</template>

<script>
import InputWrapperGrid from '@/components/form/InputWrapperGrid';
import SelectBox from '@/components/SelectBox';

export default {
  name: 'SelectInput',
  components: {
    InputWrapperGrid,
    SelectBox,
  },
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    helperText: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
    selects: {
      type: Array,
      default: () => [],
    },
    defaultOption: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { attrs }) {
    return { attrs };
  },
};
</script>

<style scoped lang="scss">
.select-deep-wrapper:deep {
  .custom-select-box__options {
    max-height: 300px;
    overflow-y: scroll;
  }

  li {
    padding: 12px 4px;
  }
}
</style>
