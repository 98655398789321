<template>
  <page title="미용회보">
    <template #aside-left></template>
    <template #main-section>
      <div class="board-pc">
        <regular-report-board-pc :selected-options="selectOptions" :table-headers="tableHeaders" :posts="regularReportList" hide-write-button />
      </div>
      <div class="board-mobile">
        <regular-report-board-mobile :selected-options="selectOptions" :posts="regularReportList" hide-write-button />
      </div>
    </template>
    <template #aside-right></template>
  </page>
</template>

<script>
import Page from '@/components/Page';

import { communitySubCategory } from '@/constants/category';
import { regularReportDataList } from '@/constants/regularReport';
import RegularReportBoardPc from '@/containers/RegularReport/RegularReportBoardPc';
import RegularReportBoardMobile from '@/containers/RegularReport/RegularReportBoardMobile';

export default {
  name: 'RegularReport',
  components: {
    Page,
    RegularReportBoardPc,
    RegularReportBoardMobile,
  },
  setup() {
    const selectOptions = [
      { id: 'all', label: '전체' },
      { id: 'title', label: '제목' },
      { id: 'author', label: '작성자' },
    ];

    const tableHeaders = [
      { id: 'number', label: '번호' },
      { id: 'title', label: '제목' },
      { id: 'nickname', label: '닉네임' },
      { id: 'date', label: '작성일' },
    ];

    const regularReportList = Object.values(regularReportDataList).reverse();

    // todo 미용회보 api로 분리할 것.
    return { tabs: communitySubCategory, selectOptions, regularReportList, tableHeaders };
  },
};
</script>

<style scoped lang="scss">
// board pc & mobile
.board-pc {
  display: none;

  @include md-and-up {
    display: block;
  }
}

.board-mobile {
  display: block;
  margin-top: 32px;
  padding: 0 20px 48px;

  @include md-and-up {
    display: none;
  }
}
</style>
