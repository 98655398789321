<template>
  <v-dialog v-model="dialog" close-on-back @click:outside="closeButton.onClick">
    <v-card width="260" style="border-radius: 6px">
      <template v-if="body">
        <v-card-item class="text__body-medium mb-1 text-center" style="min-height: 100px">
          <img v-if="imageSrc" :src="imageSrc" alt="Modal Image" class="image" style="max-width: 100%; height: auto" />
          <div v-for="(line, idx) in body.split('\n')" :key="idx">{{ line }}</div></v-card-item
        >
      </template>

      <v-card-actions class="button-group" :style="`grid-template-columns: repeat(${additionalButton.show ? 2 : 1}, 1fr);`">
        <v-btn
          :class="{ outlined: additionalButton, contained: !additionalButton }"
          variant="flat"
          :loading="closeButton.isLoading"
          type="button"
          :disabled="closeButton.disabled"
          @click="closeButton.onClick"
          >{{ closeButton.text || '닫기' }}</v-btn
        >
        <v-btn
          v-if="additionalButton.show"
          class="button-medium-primary"
          :loading="additionalButton.isLoading"
          type="button"
          :disabled="additionalButton.disabled"
          @click="additionalButton.onClick"
          >{{ additionalButton.text || '확인' }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'BasicModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    closeButton: {
      type: Object,
      default: () => ({ isLoading: false, text: '닫기', disabled: false }),
    },
    additionalButton: {
      type: Object,
      default: () => ({ isLoading: false, text: '확인', disabled: false, show: false }),
    },
    imageSrc: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const dialog = computed(() => props.isOpen);

    return { dialog };
  },
};
</script>

<style scoped lang="scss">
.button-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.button-single {
  width: 100%;
}

.contained:deep {
  @include button('medium', 'primary');

  span {
    @include spreadTypoMap($button-medium);
    color: white !important;
  }
}

.outlined {
  @include button('medium', 'line');

  span {
    @include spreadTypoMap($button-medium);
  }
}
</style>
