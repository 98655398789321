import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import 'dayjs/locale/ko';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.locale('ko');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

dayjs.tz.setDefault('Asia/Seoul');

const makeValidDate = (year, month, day) => dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD');

/**
 * minute second를 받아 MM:SS 형태로 변환해주는 함수
 *
 * @param {number} minute
 * @param {number} second
 */
const toMMSS = (minute, second) => {
  let newMinute = `${minute}`;
  let newSecond = `${second}`;

  if (minute < 10) {
    newMinute = `0${newMinute}`;
  }

  if (second < 10) {
    newSecond = `0${newSecond}`;
  }

  return `${newMinute}:${newSecond}`;
};

/**
 * string 시간 데이터를 한국 포맷으로 변환하는 함수
 * @param {string} date
 */
const parseAgoDate = (date) => {
  const koDate = dayjs.tz(date).fromNow();

  return koDate;
};

/**
 * string 시간 데이터를 한국 포맷으로 변환하는 함수
 * @param {string} date
 */
const parseKoFullDate = (date) => {
  const koDate = dayjs.tz(date).format('YYYY년 M월 D일 H시M분');

  return koDate;
};

/**
 * string 시간 데이터를 한국 포맷으로 날짜만 남기도록 변환하는 함수
 * @param {string} date
 */
const toYYYYMMDD = (date) => {
  const koDate = dayjs.tz(date).format('YYYY.MM.DD.');

  return koDate;
};

const getKrDate = (date) => dayjs.tz(date);

export { makeValidDate, parseAgoDate, toYYYYMMDD, parseKoFullDate, toMMSS, getKrDate, dayjs };
