<template>
  <router-link :to="`/regular-report`" class="post-card__wrapper">
    <div class="aspect-[6/8]"><img :src="imgSrc" alt="img" class="w-full h-full object-cover aspect-[inherit]" /></div>
    <h5 class="post-card__title">{{ title }}</h5>
    <p class="post-card__desc">{{ desc }}</p>
  </router-link>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      default: '',
    },
    newsId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.post-card {
  &__wrapper {
    @apply flex flex-col gap-[16px] w-auto h-auto no-underline mr-[16px] pc:w-[418px] mobile:w-[280px];
  }

  &__title {
    @apply pc:text-title-small mobile:text-title-xsmall text-black m-0 line-clamp-1;
  }

  &__desc {
    @apply pc:text-body-medium mobile:text-body-medium text-gray-50 m-0 line-clamp-2;
  }
}
</style>
