import axios from '@/lib/axiosController';

const BASE = '/profile';

/**
 * 비밀번호 변경 API
 * @param {string} password
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putPasswordAPI = (password) => axios.put(`${BASE}/password`, { password });

/** 마이페이지 상세 데이터 조회 API */
export const getProfileDetailAPI = () => axios.get(`${BASE}/detail`);

/** 회원탈퇴하기 */
export const withdrawUserAPI = () => axios.delete(`${BASE}/withdraw`);
