<template>
  <h2 class="title"><slot /></h2>
</template>

<script>
export default {
  components: {},
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.title {
  @apply pc:pt-[72px] pc:pb-[32px] pc:text-[64px] mobile:text-[48px] mobile:pt-0 mobile:pt-[48px] mobile:pb-[24px] mb-0 text-black tracking-tight;
  font-family: 'Abril Fatface' !important;
  font-weight: 400;
  line-height: 100%;
}
</style>
