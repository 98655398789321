<template>
  <page>
    <template #main-section>
      <used-market-post-detail-view></used-market-post-detail-view>
    </template>
  </page>
</template>

<script>
import UsedMarketPostDetailView from '@/containers/Post/UsedMarket/PostDetailView.vue';

import Page from '@/components/Page.vue';

/**
 * new post : /write?mainCategory=aaa
 * edit post : /write?postId=123
 */
export default {
  components: { UsedMarketPostDetailView, Page },
};
</script>

<style></style>
