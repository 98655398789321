<template>
  <v-img :src="require('@/assets/grievanceInfoImage.jpeg')"></v-img>
  <div class="commitee-table-community-selector">
    <v-btn flat class="button-medium-line" type="button" @click="moveToCommiteeCommunityPage(100007)">위원회 게시판으로 이동하기</v-btn>
  </div>
  <div class="table-wrapper">
    <table class="table">
      <tbody>
        <tr>
          <td class="table-tit" style="width: 50%">직위</td>
          <td class="table-tit" style="width: 25%">성명</td>
        </tr>
        <tr>
          <td class="table-1st" rowspan="5">부위원장</td>
          <td class="table-2ed">김현자</td>
        </tr>
        <tr>
          <td class="table-2ed">하찬선</td>
        </tr>
        <tr>
          <td class="table-2ed">김희자</td>
        </tr>
        <tr>
          <td class="table-2ed">손금녕</td>
        </tr>
        <tr>
          <td class="table-2ed">홍혜숙</td>
        </tr>
        <tr>
          <td class="table-1st" rowspan="17">위원</td>
          <td class="table-2ed">이혜숙</td>
        </tr>
        <tr>
          <td class="table-2ed">이숙임</td>
        </tr>
        <tr>
          <td class="table-2ed">이윤자</td>
        </tr>
        <tr>
          <td class="table-2ed">성혜숙</td>
        </tr>
        <tr>
          <td class="table-2ed">남양순</td>
        </tr>
        <tr>
          <td class="table-2ed">김영배</td>
        </tr>
        <tr>
          <td class="table-2ed">김영애</td>
        </tr>
        <tr>
          <td class="table-2ed">권옥순</td>
        </tr>
        <tr>
          <td class="table-2ed">김갑녀</td>
        </tr>
        <tr>
          <td class="table-2ed">박성호</td>
        </tr>
        <tr>
          <td class="table-2ed">박명희</td>
        </tr>
        <tr>
          <td class="table-2ed">문애선</td>
        </tr>
        <tr>
          <td class="table-2ed">황현숙</td>
        </tr>
        <tr>
          <td class="table-2ed">황정순</td>
        </tr>
        <tr>
          <td class="table-2ed">김영수</td>
        </tr>
        <tr>
          <td class="table-2ed">한정숙</td>
        </tr>
        <tr>
          <td class="table-2ed">김선녀</td>
        </tr>

        <tr>
          <td class="table-1st">간사</td>
          <td class="table-2ed">이승우</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { moveToCommiteeCommunityPage } from '@/components/committee/commonUtil';

export default {
  setup() {
    return { moveToCommiteeCommunityPage };
  },
};
</script>

<style scoped lang="scss">
.commitee-table-community-selector:deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;

  .branch-selects {
    .branch-selector {
      max-width: unset;
      width: auto;
    }
  }
}
</style>
