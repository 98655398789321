import { defineStore } from 'pinia';

export default defineStore('loading', {
  state: () => ({
    loadings: {},
  }),
  getters: {
    getLoadings: (state) => state.loadings,
  },
  actions: {
    setLoadingState(actionName, loadingState) {
      this.loadings[actionName] = loadingState;
    },
  },
});
