<template>
  <div class="horizontal-card" style="width: 100%">
    <img class="thumb" :src="src" />
    <div class="content-group">
      <v-chip-group class="mb-2">
        <template v-for="(tag, idx) in tags" :key="idx">
          <v-chip class="chip" :class="{ 'chip-contained': tag.name === '인기', 'chip-outlined': tag.name !== '인기' }">{{ tag.name }}</v-chip>
        </template>
      </v-chip-group>
      <h4 class="text__title-xsmall mb-2" style="color: #ffffff">{{ title }}</h4>
      <p class="text__body-small mb-2 sub-text">{{ subText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorizontalCard',
  components: {},
  props: {
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subText: {
      type: String,
      required: false,
      default: '',
    },
    to: {
      type: String,
      required: true,
      default: '/',
    },
    tags: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.thumb {
  object-fit: cover;
  width: 120px !important;
  height: 120px;
  border-radius: 4px;
  background: $gray-10;
}

.horizontal-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background: #272f3e;
  border-radius: 8px;
  padding: 16px;
}

.content-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 140px);
}

.chip {
  background: #394150 !important;
  border-radius: 100px;
  padding: 8px 24px;
  margin-right: 8px;
  font-weight: 600 !important;
  color: white;
}

.chip-outlined {
  background: white;
  border: none;
}

.chip-contained {
  background: #29a5ff !important;
  border: none;
  color: white;
}

.sub-text {
  color: #d3d6db;
}
</style>
