<template>
  <section class="section-wrapper">
    <h3 class="text__title-large section-title" :style="`display: ${isMobileUi ? 'none' : 'block'}`">부스 안내</h3>

    <div>
      <div class="sub-title">참가 신청</div>
      <div class="desc">
        신청기간 : 2022년 11월 25일까지<br />
        신청방법 : 참가신청서 및 계약서 작성, 사업자등록증 사본과 함께 송부<br />
        계 약 금 : 신청서 접수와 함께 참가비 전액 입금 (부가세 포함)<br />
        참가비 납부방법 : 하나은행 375-910019-28304 / 예금주 : 사)대한미용사회<br />
      </div>
    </div>

    <div>
      <div class="sub-title">참가 비용</div>
      <div class="desc">
        기본부스(3m x 3m)<br />
        조립부스 부스당 160만원(장치비 포함 부가세 별도) 1부스 Size<br />
        독립부스 부스당 130만원(전시면적만 제공, 부가세 별도) 4부스 이상 신청시 가능<br />
        배너광고 300만원(4.5m x 5.5m, 부가세 별도)<br />
      </div>
    </div>

    <div>
      <div class="sub-title">부대서비스</div>
      <div class="desc">
        시스템 칸막이, 간판 상호, 안내데스크(1), 접의자(1), 바닥파이텍스<br />
        전기 - 스포트라이프 3개, 콘센트 1개(220V 단상 1Kw)<br />
        <small>※전기/전화/인터넷/급배수/압축공기등은 희망업체에 한해 유료신청</small><br />
      </div>
    </div>

    <div>
      <div class="sub-title">참가 특전</div>
      <div class="desc">
        디렉토리에 업체정보 게재<br />
        전시회 홈페이지와 참가업체 홈페이지 링크<br />
        전시회 참가 안내 및 대대적인 홍보 (디렉토리에 필요한 업체정보 자료는 계약과 동시에 주최측에 데이터로 송부)<br />
      </div>
    </div>

    <div style="display: flex; justify-content: center; align-items: center">
      <a
        href="https://storage.googleapis.com/koba-dev-static/2022_contest/2022_IKBF_%EB%B6%80%EC%8A%A4_%EC%B0%B8%EA%B0%80_%EA%B3%84%EC%95%BD%EC%84%9C.hwp"
        download="부스 참가 계약서"
        class="button-large-line section-button"
      >
        <span>
          부스 참가 계약서 다운로드 <icon-base><chevron-right-arrow /></icon-base>
        </span>
      </a>
    </div>

    <div class="desc">문의 | 사)대한미용사회중앙회 TEL : 02-585-3351~3</div>
  </section>
</template>

<script>
import ChevronRightArrow from '@/icons/ChevronRightArrow';

export default {
  components: { ChevronRightArrow },
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  margin-top: 24px;
  padding: 0 20px;
  text-align: center;

  @include md-and-up {
    margin-top: 48px;
    padding: 0 20px;
  }

  & > * {
    margin-bottom: 3rem;
  }
}

.section-button {
  text-decoration: none;

  @include sm-and-down {
    span {
      @include spreadTypoMap($button-small);
    }
  }

  svg {
    margin-left: 4px;
  }

  @include md-and-up {
    svg {
      margin-left: 8px;
    }
  }
}

.sub-title {
  @include spreadTypoMap($title-small);
  color: $gray-80;
  margin-bottom: 1rem;
}

.desc {
  @include spreadTypoMap($body-large);
  color: $gray-80;
}
</style>
