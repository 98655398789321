<template>
  <input-wrapper :required="required" :label="label" :helper-text="helperText" :error-text="errorText" :is-focus="isFocus">
    <template #form-input>
      <input type="text" v-bind="attrs" style="width: 100%" @focus="onFocus" @blur="onBlur" @keydown="onKeyDown" @input="onInput" />
    </template>
  </input-wrapper>
</template>

<script>
import InputWrapper from '@/components/form/InputWrapper';
import { ref } from 'vue';

export default {
  name: 'TextInput',
  components: {
    InputWrapper,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    helperText: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
  },
  setup(props, { attrs }) {
    const isFocus = ref(false);

    const onFocus = (e) => {
      isFocus.value = true;

      attrs.onFocus?.(e);
    };

    const onBlur = (e) => {
      isFocus.value = false;

      attrs.onBlur?.(e);
    };

    const onInput = (e) => {
      const {
        dataset: { type },
        name,
        value,
      } = e.target;

      if (type === 'number' && value && isNaN(value)) {
        attrs?.onInput?.({ target: { name, value: value.replace(/[^0-9]/g, '') } });
        return;
      }

      attrs?.onInput?.(e);
    };

    const onKeyDown = (e) => {
      const {
        dataset: { type },
        name,
        value,
      } = e.target;

      if (type === 'number' && value && isNaN(value)) {
        attrs?.onInput?.({ target: { name, value: value.replace(/[^0-9]/g, '') } });
      }
    };

    return { attrs, isFocus, onFocus, onBlur, onKeyDown, onInput };
  },
};
</script>

<style lang="scss" scoped></style>
