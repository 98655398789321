<template>
  <div class="text__title-xsmall mb-2 mt-2">{{ number }}기</div>
  <table class="table">
    <tbody>
      <tr>
        <td class="table-tit">번호</td>
        <td class="table-tit">기수</td>
        <td class="table-tit">사진</td>
        <td class="table-tit">이름</td>
        <td class="table-tit">업소명</td>
      </tr>
      <slot />
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    number: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss"></style>
