<template>
  <div class="form-recommender">
    <h2 class="text__title-medium form-recommender__title">추천인 정보</h2>
    <text-input
      v-if="isStudentForm"
      id="recommender.associationName"
      required
      name="associationName"
      :value="recommender.associationName"
      label="소속 (학교)"
      placeholder="소속 (학교)"
      :error-text="errors.associationName"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <selects-input
      v-if="!isStudentForm"
      :id="['recommender.regionName', 'recommender.associationName']"
      :name="['regionName', 'associationName']"
      required
      label="소속 (지회/지부)"
      :value="[recommender.regionName, recommender.associationName]"
      :error-text="errors.associationName"
      :selects="branchSelects"
      :default-option="[defaultMainBranch, defaultSubBranch]"
      @click="passInputValue"
      @blur="setInputError"
    />
    <text-input
      v-if="!isStudentForm"
      id="recommender.ordinalNumber"
      name="ordinalNumber"
      :value="recommender.ordinalNumber"
      type="number"
      data-type="number"
      label="강사 기수"
      placeholder="숫자만 입력"
      :error-text="errors.ordinalNumber"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <text-input
      id="recommender.name"
      required
      name="name"
      :value="recommender.name"
      :label="attrInfo.name.label"
      :placeholder="attrInfo.name.placeholder"
      :error-text="errors.name"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
    <text-input
      id="recommender.phone"
      required
      name="phone"
      :value="recommender.phone"
      label="연락처"
      type="tel"
      data-type="number"
      placeholder="예) 01012341234"
      :error-text="errors.phone"
      @input="passInputValue"
      @blur="setInputError"
      @keydown="resetError"
    />
  </div>
</template>

<script>
import { computed, onUpdated, ref } from 'vue';
import TextInput from '@/components/form/TextInput';
import SelectsInput from '@/components/form/SelectsInput';
import { STUDENT_ATTR, GENERAL_ATTR, SET_INPUT, SET_INPUT_ERROR, RESET_INPUT_ERROR } from '@/constants/contest';
import { isEmpty } from 'lodash-es';
import { branchMappedKo } from '@/constants/category';

const RECOMMENDER_INFO = 'recommender';

/**
 *  onBlur 시 검사하는 validator
 *  - 추천인 정보는 옵셔널하기 때문에 값이 존재할 때만 검사한다.
 *  */
const validator = {
  name: (value) => {
    if (!value.trim()) return true;

    if (value.trim().length < 2) {
      return { isValid: false, message: '이름은 2글자 이상 입력해주세요.' };
    }

    if (value.trim().length > 10) {
      return { isValid: false, message: '이름은 30글자 이하로 입력해주세요.' };
    }

    const nameReg = /^[ㄱ-ㅎ|가-힣|a-z|A-Z| |]+$/;
    if (!nameReg.test(value)) {
      return { isValid: false, message: '이름은 한글, 영어만 가능합니다.' };
    }

    return true;
  },
  ordinalNumber: () => true,
  phone: (value) => {
    const phoneNumberReg = /^(01[0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/;

    if (value && !phoneNumberReg.test(value.trim())) {
      return { isValid: false, message: '올바른 휴대전화 번호가 아닙니다.' };
    }
    return true;
  },
};

export default {
  name: 'RecommenderForm',
  components: {
    TextInput,
    SelectsInput,
  },
  props: {
    isStudentForm: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    recommender: {
      type: Object,
      default: () => {},
    },
    branchInfos: {
      type: Object,
      default: () => {},
    },
    regionSelects: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const infoType = RECOMMENDER_INFO;
    const attrInfo = computed(() => (props.isStudentForm ? STUDENT_ATTR : GENERAL_ATTR));

    const subBranches = ref([]);
    const defaultMainBranch = ref('소속 지회를 선택해주세요');
    const defaultSubBranch = ref('소속 지부를 선택해주세요');

    /** subBranch 를 regionName 에 맞추어 설정한다. */
    onUpdated(() => {
      if (isEmpty(props.branchInfos) || isEmpty(props.recommender.regionName) || !isEmpty(subBranches.value)) return;

      subBranches.value = props.branchInfos[props.recommender.regionName];
      defaultMainBranch.value = branchMappedKo[props.recommender.regionName].label;
      defaultSubBranch.value = props.recommender.associationName;
    });

    const passInputValue = (e) => {
      const { name, value, label } = e.target;

      if (/regionName|associationName/g.test(name)) {
        emit(RESET_INPUT_ERROR, { infoType, name: 'associationName', value: label || value });
      }

      if (name === 'regionName') {
        subBranches.value = props.branchInfos[value];
        defaultMainBranch.value = props.regionSelects[0].label;
        defaultSubBranch.value = subBranches.value[0].label;

        emit(SET_INPUT, { infoType, name: 'associationName', value: subBranches.value[0].label });

        if (!props.isStudentForm) {
          emit(SET_INPUT, { infoType, name: 'branchOfficeId', value: subBranches.value[0].id });
        }
      } else if (name === 'associationName') {
        emit(SET_INPUT, { infoType, name: 'associationName', value: label || value });

        if (!props.isStudentForm) {
          emit(SET_INPUT, { infoType, name: 'branchOfficeId', value: value });
        }

        return;
      }

      emit(SET_INPUT, { infoType, name, value });
    };

    const setInputError = (e) => {
      const { name, value } = e.target;

      const { isValid = true, message } = validator[name]?.(value) || {};

      if (!isValid) {
        emit(SET_INPUT_ERROR, { infoType, name, message });
      }
    };

    const resetError = (e) => {
      const { name } = e.target;
      emit(RESET_INPUT_ERROR, { infoType, name });
    };

    const branchSelects = computed(() => [props.regionSelects, [...subBranches.value]]);

    return { attrInfo, branchSelects, defaultMainBranch, defaultSubBranch, passInputValue, setInputError, resetError };
  },
};
</script>

<style lang="scss" scoped>
.form-recommender:deep {
  .form__input-root + .form__input-root {
    margin-top: 32px;

    @include md-and-up {
      margin-top: 48px;
    }
  }
}

.form-recommender__title {
  margin-bottom: 32px;

  @include md-and-up {
    margin-bottom: 48px;
  }
}
</style>
