<template>
  <div style="width: 100%; display: flex; flex-direction: row; align-items: center">
    <page title="기술교육 시청">
      <template #main-section>
        <div style="display: flex; flex-direction: column">
          <div style="border-width: 0 0 1px 0; border-style: solid; border-color: lightgrey">
            <v-list-item style="width: 300px; max-width: 300px">
              <v-list-item-content>
                <v-list-item-title style="font-weight: 500; text-decoration: underline" class="text-h6">{{ lectureData?.title }} </v-list-item-title>
                <v-list-item-subtitle style="margin-top: 5px; margin-bottom: 5px">
                  총 {{ lectureData?.lectureItems?.length || 0 }}개의 교시로 구성되어있습니다.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div style="margin-top: 0; justify-content: center">
            <div style="margin-left: 15px">
              <div style="display: flex; flex-direction: row; align-items: center; margin-top: 10px; margin-bottom: 10px">
                <div style="margin-right: 8px; font-size: 14px">
                  <v-icon color="#0070bc" style="margin-right: 5px; margin-bottom: 2px" size="small" :icon="mdiNumeric2BoxOutline" />강의 난이도
                  <strong>{{ lectureData?.difficulty }}</strong>
                </div>
                |
                <div style="margin-right: 8px; margin-left: 8px; font-size: 14px">
                  담당 <strong>{{ lectureData?.instructorName }}</strong>
                </div>
              </div>
            </div>

            <video id="video-player-mobile" controls></video>
            <v-list-item-content>
              <v-list-item-title style="font-weight: 500; margin-left: 15px; margin-top: 10px" class="text-h6"> 강의 목차 </v-list-item-title>
            </v-list-item-content>
            <v-list dense nav style="border-width: 0 0 1px 0; border-style: solid; border-color: lightgrey">
              <v-list-item v-for="(item, index) in lectureData?.lectureItems || []" :key="item.title" link>
                <div style="display: flex; flex-direction: row; align-items: center" @click="changeLectureIndex(index)">
                  <v-icon :icon="mdiCheck" size="x-small" start></v-icon>
                  <div>
                    <v-list-item-icon>
                      <v-list-item-title style="text-decoration: underline; font-size: 15px; font-weight: 500">{{ item.name }}</v-list-item-title>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title style="margin-top: 2px; font-size: 12px; color: grey">video | {{ item.videoDuration }}분</v-list-item-title>
                    </v-list-item-content>
                  </div>
                </div>
              </v-list-item>
            </v-list>

            <div style="margin-top: 20px; height: 400px !important">
              <tab2
                v-model="selectedTab"
                is-customized-tab-action
                :tabs="[
                  { id: 0, name: '요약' },
                  { id: 1, name: '강사 소개' },
                ]"
                slider-color="#0070bc"
                :tab-action="onChangeTab"
              />
              <div v-if="selectedTab === 0" style="padding: 20px; font-size: 14px">
                {{ lectureData?.lectureItems[currentSelectedLectureIndex]?.description }}
              </div>
              <div v-if="selectedTab === 1" style="padding: 20px; font-size: 14px" v-html="lectureData?.instructorDescriptionDetail"></div>
            </div>
          </div>
        </div>
      </template>
    </page>
    <v-dialog v-model="isOpenDialog">
      <div style="width: 100%; height: 100%; justify-content: center; align-items: center; display: flex; flex-direction: column">
        <img src="https://storage.googleapis.com/koba-dev-static/Certificate.png" width="1000" height="707" />
        <div
          style="
            background-color: whitesmoke;
            padding: 10px;
            margin-top: 20px;
            width: 180px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
          "
        >
          <v-icon style="margin-right: 2px; margin-bottom: 2px" size="small" :icon="mdiPrinter" /><strong> 수료증 출력하기</strong>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';

import Page from '@/components/Page';
import Tab2 from '@/components/Tab2.vue';
import {
  mdiTrayArrowDown,
  mdiCheck,
  mdiNoteEditOutline,
  mdiShareVariant,
  mdiAlarm,
  mdiNumeric2BoxOutline,
  mdiStarOutline,
  mdiPrinter,
  mdiMessageReplyOutline,
} from '@mdi/js';
import { useRoute } from 'vue-router';
import { getSpecificLectureData } from '@/apis/__education__';
import Hls from 'hls.js';
export default {
  name: 'VideoPlayerMobile',
  components: { Page, Tab2 },
  props: {
    selectedOptions: {
      type: Array,
      required: true,
    },
    posts: {
      type: Array,
      default: () => [],
    },
    hideWriteButton: {
      type: Boolean,
      default: () => false,
    },
    onPageChange: {
      type: Function,
      default: () => {},
    },
    isFinished: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const isOpenDialog = ref(false);
    const onClickPrint = () => {
      isOpenDialog.value = true;
    };

    const selectedTab = ref(0);
    const lectureId = ref();
    const route = useRoute();
    const lectureData = ref();
    const currentSelectedLectureIndex = ref(0);

    onMounted(async () => {
      lectureId.value = route.params.lectureId;
      lectureData.value = await getSpecificLectureData(lectureId.value);
      loadLectureVideoPlayer();
    });

    const changeLectureIndex = (index) => {
      currentSelectedLectureIndex.value = index;
      loadLectureVideoPlayer();
    };

    const loadLectureVideoPlayer = () => {
      const videoSource = lectureData.value?.lectureItems[currentSelectedLectureIndex.value].videoUrl;
      const videoTag = document.getElementById('video-player-mobile');

      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(videoSource);
        hls.attachMedia(videoTag);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          // videoTag.play();
        });
      } else if (videoTag.canPlayType('application/vnd.apple.mpegurl')) {
        videoTag.src = videoSource;
        videoTag.addEventListener('loadedmetadata', () => {
          // videoTag.play();
        });
      }
    };

    const onChangeTab = (index) => {
      selectedTab.value = index;
    };

    return {
      mdiTrayArrowDown,
      mdiCheck,
      mdiNoteEditOutline,
      mdiShareVariant,
      mdiAlarm,
      mdiNumeric2BoxOutline,
      mdiStarOutline,
      mdiPrinter,
      mdiMessageReplyOutline,
      onClickPrint,
      isOpenDialog,
      selectedTab,
      lectureId,
      lectureData,
      currentSelectedLectureIndex,
      changeLectureIndex,
      onChangeTab,
    };
  },
};
</script>

<style lang="scss" scoped>
#video-player-mobile {
  width: 100% !important;
  height: auto !important;
  object-fit: cover;
}
</style>
