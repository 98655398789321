<template>
  <section class="container">
    <div class="box">
      <button
        class="btn"
        @click="
          () => {
            router.push('/seoul2024');
          }
        "
      >
        제 8회 서울시 미용예술 대회 바로가기
      </button>
      <button
        style="margin-left: 10px"
        class="btn"
        @click="
          () => {
            router.push('/ikbf2024');
          }
        "
      >
        2024 국제한국 미용페스티벌 바로가기
      </button>
    </div>
    <v-card elevation="0" class="mx-auto" :rounded="0"
      ><v-carousel :continuous="true" :cycle="true" :show-arrows="false" hide-delimiter-background hide-delimiters style="height: auto">
        <v-carousel-item v-for="post in postInfos" :key="post.id">
          <div class="position-relative thumbnail-wrapper">
            <div class="image-overlay"></div>
            <img class="w-full h-full object-cover" :src="post.thumbnail" />
            <div class="post-info-box">
              <p class="post-info-title">{{ post.title }}</p>
              <p class="post-info-summary">{{ post.bodySummary }}</p>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel></v-card
    >
  </section>
</template>

<script>
import { mdiMinus } from '@mdi/js';
import { useRouter } from 'vue-router';

export default {
  name: 'MainFeature',
  components: {},
  props: {
    mainArticles: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const postInfos = props.mainArticles;
    const router = useRouter();

    return { postInfos, mdiMinus, router };
  },
};
</script>

<style scoped lang="scss">
.container {
  @apply p-0 max-w-none pc:mb-[48px] mobile:mb-[40px];
}

.image-overlay {
  @apply absolute top-0 left-0 w-full h-full bg-black opacity-50 z-10;
}

.thumbnail-wrapper {
  @apply pc:aspect-video mobile:aspect-square overflow-hidden;
}

.post-info {
  &-box {
    @apply absolute bottom-0 left-0 text-white z-20 pc:p-[40px] mobile:p-[24px];
  }

  &-title {
    @apply pc:text-title-xxLarge pc:mb-[24px] mobile:text-title-medium mobile:mb-[12px] line-clamp-2;
  }

  &-summary {
    @apply pc:text-body-medium mobile:text-body-xsmall mb-0 max-w-[632px] line-clamp-2;
  }
}

.btn {
  padding: 12px 24px;
  background-color: $blue-50;
  color: white;
  border: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  width: 47%;
}

.box {
  display: flex;
  justify-content: center;
  width: 100%;
  align-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
