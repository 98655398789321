<template>
  <div class="table-wrapper">
    <table class="table">
      <tbody>
        <tr>
          <td v-for="tableHeader in tableHeaders" :key="tableHeader.id" class="table-tit">{{ tableHeader.label }}</td>
        </tr>
        <template v-if="tableRows.length > 0">
          <tr v-for="row in tableRows" :key="row.id">
            <td>{{ row.regionLabel }}</td>
            <td>{{ row.branchName }}</td>
            <td>{{ row.branchManager }}</td>
            <td>{{ row.officeDirector }}</td>
          </tr>
        </template>
        <template v-else>
          <tr>
            데이터가 존재하지 않습니다.
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    /** 표 제목 {id: string; label: string;} */
    tableHeaders: {
      type: Array,
      required: true,
    },
    tableRows: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
