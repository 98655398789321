<template>
  <div class="form__input-root">
    <label class="text__caption-small form__input-label" :for="labelFor || attrs.id" :class="{ 'form__input-label-required': required }">{{ label }}</label>
    <slot name="form-preview"></slot>
    <div class="form__inputs-wrapper" :style="`grid-template-columns: repeat(${gridItemsLength}, 1fr);`">
      <slot name="form-input" />
      <slot name="form-button" />
    </div>
    <div v-if="errorText || helperText" class="text__caption-small form__input-description" :class="{ 'form__input-description-error': errorText }">
      {{ errorText || helperText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputWrapperGrid',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    helperText: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
    labelFor: {
      type: String,
      default: '',
    },
    gridItemsLength: {
      type: Number,
      required: true,
    },
  },
  setup(props, { attrs }) {
    return { attrs };
  },
};
</script>

<style lang="scss" scoped>
.form__input-label {
  color: $gray-60;

  &-required::after {
    content: '*';
    color: $koba-red;
  }
}

.form__inputs-wrapper {
  display: grid;
  grid-gap: 16px;
}

.form__input-description {
  margin-top: 8px;

  &-error {
    color: $koba-red;
  }
}
</style>
