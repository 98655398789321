import axios from '@/lib/axiosController';

/**
 * 교육 페이지 카테고리 list 조회 API
 * @returns
 */
export const getEducationCategoryList = async () => {
  return await axios.get('/education/main/category-list');
};

/**
 * 교육 페이지 강사 list 조회 API
 * @returns
 */
export const getInstructorList = async () => {
  return await axios.get('/education/main/instructor-list');
};

/**
 * 교육 페이지 추천교육 list 조회 API
 * @returns
 */
export const getRecommendLectureList = async (lectureCategoryId) => {
  return await axios.get('/education/main/lecture-list', {
    params: {
      lectureCategoryId,
    },
  });
};

/**
 * 교육 상세페이지 조회 API
 * @returns
 */
export const getSpecificLectureData = async (lectureId) => {
  return await axios.get(`/education/player/lecture/${lectureId}`);
};
