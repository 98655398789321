export const getBranchOfficeBoardRWFRole = (branches, targetId) => {
  let role = {
    read: false,
    write: false,
    fixed: false,
  };
  const numTargetId = Number(targetId);

  if (Array.isArray(branches) && branches.length > 0 && !Number.isNaN(numTargetId)) {
    const branch = branches.find((v) => v.branchOfficeId === numTargetId);

    if (branch) {
      role = {
        read: branch.read,
        write: branch.write,
        fixed: branch.writeFixedPost,
      };
    }
  }

  return role;
};

export const getBranchOfficeName = (branches, targetId) => {
  return branches.find((v) => v.branchOfficeId === targetId)?.branchOfficeName ?? '';
};
