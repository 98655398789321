<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.04 7.54039C10.2938 7.28655 10.7054 7.28655 10.9592 7.54039L14.2521 10.8333C14.8965 11.4776 14.8965 12.5224 14.2521 13.1667L10.9592 16.4596C10.7054 16.7135 10.2938 16.7135 10.04 16.4596C9.78615 16.2058 9.78615 15.7942 10.04 15.5404L13.3329 12.2475C13.4696 12.1108 13.4696 11.8892 13.3329 11.7525L10.04 8.45963C9.78615 8.20578 9.78615 7.79423 10.04 7.54039Z"
  />
</template>

<script>
export default {};
</script>
