import { ref } from 'vue';
import { successToast } from '@/utils/toast';

const usePhone = () => {
  const request = ref({
    isLoading: false,
    success: false,
    error: false,
  });

  /** 인증 번호 요청 */
  const phoneVerifyRequest = async (requestAPI) => {
    request.value.isLoading = true;
    try {
      await requestAPI();
      successToast('인증번호가 발송되었습니다.');
      request.value.success = true;
    } catch (e) {
      request.value.success = false;
      request.value.error = true;
    } finally {
      request.value.isLoading = false;
    }
  };

  const verify = ref({
    isLoading: false,
    success: false,
    error: false,
  });

  /**
   *  인증번호 확인
   * - result 가 있으면 result 를 return 한다.
   * */
  const phoneVerify = async (requestAPI) => {
    if (verify.value.success) {
      request.value.success = false;
      verify.value.success = false;
      verify.value.error = false;
      return;
    }

    verify.value.isLoading = true;

    try {
      const res = await requestAPI();
      verify.value.success = true;

      return res;
    } catch (e) {
      verify.value.success = false;
      verify.value.error = true;
    } finally {
      verify.value.isLoading = false;
    }
  };

  const resetRequestValue = () => {
    request.value = { isLoading: false, success: false, error: false };
  };
  const resetVerifyValue = () => {
    verify.value = { isLoading: false, success: false, error: false };
  };

  const resetPhoneVerifyResult = () => {
    resetRequestValue();
    resetVerifyValue();
  };

  return { request, verify, phoneVerifyRequest, phoneVerify, resetPhoneVerifyResult, resetVerifyValue };
};

export default usePhone;
