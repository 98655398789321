import axios from '@/lib/axiosController';

export const getUsedMarketPostAPI = async (postId) => await axios.get(`/usedmarket/${postId}`);

export const getUsedMarketPostCommentAPI = async (postId) => await axios.get(`/usedmarket/${postId}/comment`);

export const getAllUsedMarketPostAPI = async ({ size, page, keyword = '' }) =>
  await axios.get(`/usedmarket/list`, {
    params: {
      size,
      page,
      keyword,
    },
  });

export const uploadUsedMarketPostAPI = async (formData) => {
  return await axios.post(`/usedmarket/upload`, formData || {}, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * 게시글 삭제 API
 * @param {string} postId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteUsedMarketPostAPI = (postId) => axios.delete(`/usedmarket/${postId}`);

/**
 * TODO: isPrivate backend랑 맞춰서 fadeout
 * 게시글 댓글 추가 API
 * @param {string} postId
 * @param {string} content
 * @param {boolean} isPrivate
 */
export const createUsedMarketCommentAPI = async (postId, content, isPrivate = false) => {
  return await axios.post('/usedmarket/create-comment', {
    postId,
    content,
    isPrivate,
  });
};

/**
 * 게시글 댓글 조회 API
 * @param {string} postId
 * @returns
 */
export const getUsedMarketCommentAPI = async (postId) => await axios.get(`/usedmarket/${postId}/comment`);

/**
 * 게시글 댓글 삭제 API
 * @param {string} commentId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteUsedMarketCommentAPI = (commentId) => axios.delete('/usedmarket/delete-comment', { data: { commentId } });

export const modifyUsedMarketCommentAPI = (commentId, content, isPrivate = false) => axios.put('/usedmarket/modify-comment', { commentId, content, isPrivate });
