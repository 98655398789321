export const ARTICLE_TYPE = {
  NEWS: {
    ID: 'NEWS',
    NAME: '뉴스',
  },
  BEAUTY: {
    ID: 'BEAUTY',
    NAME: '뷰티',
  },
  COLUMN: {
    ID: 'COLUMN',
    NAME: '칼럼&문화',
  },
  CENTRAL: {
    ID: 'CENTRAL',
    NAME: '중앙회',
  },
  COMMITTEE: {
    ID: 'COMMITTEE',
    NAME: '위원회',
  },
  BRANCH: {
    ID: 'BRANCH',
    NAME: '지회,지부',
  },
  COMPANY: {
    ID: 'COMPANY',
    NAME: '업체',
  },
  ISSUE: {
    ID: 'ISSUE',
    NAME: '이슈',
  },
  EVENT: {
    ID: 'EVENT',
    NAME: '이벤트',
  },
};
