<template>
  <div class="name" :class="{ 'disabled-title': disabled }">{{ label }}<span v-if="required" class="required">*</span></div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.name {
  @include spreadTypoMap($caption-xsmall);
  color: $gray-60;
  letter-spacing: 0.005em;
}

.required {
  color: $koba-red;
}

.disabled-title {
  color: $gray-30 !important;
}
</style>
