import axios from '@/lib/axiosController';

export const getReplyList = async (postId) => {
  return await axios.get(`/reply/post/${postId}`);
};

/** 댓글 생성 */
export const createReply = ({ postId, content }) => axios.post('/reply/create', { postId, content });

/** 댓글 수정 */
export const editReply = ({ replyId, content }) => axios.put(`/reply/${replyId}`, { content });

/** 댓글 삭제 */
export const deleteReply = (replyId) => axios.delete(`reply/delete/${replyId}`);
