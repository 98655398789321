<template>
  <section class="root-wrapper">
    <product-mall-layout>
      <template #main-section="{ isMobileUi }">
        <h2 class="main__title">대출 혜택</h2>
        <p class="main__desc">대한미용사회 회원들은 하나은행 대출을 <br />더욱 합리적인 혜택으로 이용할 수 있습니다.</p>
        <div class="cards__wrapper">
          <div class="card__wrapper">
            <div class="card__label">미용사 회원이라면 누구나 가능한 마이너스통장</div>
            <h3 class="card__title">미용사 전용 300만원 비상금대출</h3>
            <i class="card__icon card__icon-check" />
          </div>
          <div class="card__wrapper">
            <div class="card__label">무보증! 무담보! 최대 3천만원 한도</div>
            <h3 class="card__title">새희망홀씨Ⅱ대출</h3>
            <i class="card__icon card__icon-puzzle" />
          </div>
          <div class="card__wrapper">
            <div class="card__label">비은행권(저축은행 등)에서 7%이상 고금리대출 사용중이라면</div>
            <h3 class="card__title">소상공인 대환대출</h3>
            <i class="card__icon card__icon-credential" />
          </div>
        </div>
        <div class="btn__wrapper">
          <button type="button" @click="applyLoan">금융주치의 상담신청</button>
        </div>
        <loan-benefits-form-modal :is-mobile-ui="isMobileUi" :dialog="dialog" :close-modal="closeModal" />
      </template>
    </product-mall-layout>
  </section>
</template>

<script>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import ProductMallLayout from '@/containers/ProductMall/ProductMallLayout';
import LoanBenefitsFormModal from '@/containers/ProductMall/LoanBenefitsFormModal';

import { useUserStore } from '@/stores';

import { checkHanaAccountAPI, updateLoanRequestAPI } from '@/apis/loan';
import { infoToast, successToast } from '@/utils/toast';

export default {
  name: 'SearchBankBranch',
  components: {
    ProductMallLayout,
    LoanBenefitsFormModal,
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const isLogin = computed(() => userStore.checkLoginStatus());

    const isLoading = ref(false);
    const dialog = ref(false);

    /** 금융주치의 상담 신청 기능 */
    const applyLoan = async () => {
      isLoading.value = true;

      if (!isLogin.value) {
        isLoading.value = false;
        router.push('/signin');
        return;
      }

      const { authenticatedAccount, requestedLoan } = (await checkHanaAccountAPI()) || {};

      if (authenticatedAccount && requestedLoan) {
        infoToast('이미 상담 신청이 완료되었습니다.');
        return;
      }

      if (authenticatedAccount) {
        await updateLoanRequestAPI();
        successToast('상담 신청이 완료되었습니다.');
        isLoading.value = false;
        return;
      }

      dialog.value = true;
    };

    const closeModal = () => {
      dialog.value = false;
    };

    return { isLogin, dialog, applyLoan, closeModal };
  },
};
</script>

<style lang="scss" scoped>
.root-wrapper {
  background: $hana-green-40;
  padding: 24px 20px;

  @include md-and-up {
    padding: 72px 0;
  }
}

.main {
  &__title {
    text-align: center;
    @include spreadTypoMap($title-small);
    color: $gray-70;
    margin-bottom: 8px;

    @include md-and-up {
      @include spreadTypoMap($title-large);
      color: $gray-70;
      margin-bottom: 16px;
    }
  }

  &__desc {
    text-align: center;
    @include spreadTypoMap($body-small);
    color: $gray-50;
    margin-bottom: 16px;

    @include md-and-up {
      text-align: center;
      @include spreadTypoMap($body-medium);
      color: $gray-50;
      margin-bottom: 32px;
    }

    br {
      display: block;

      @include md-and-up {
        display: none;
      }
    }
  }
}

.cards__wrapper {
  margin-bottom: 16px;

  @include md-and-up {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    margin-bottom: 32px;
  }
}

.card {
  &__wrapper {
    background: white;
    border-radius: 8px;
    padding: 20px 16px;

    @include md-and-up {
      padding: 24px;
      min-height: 176px;
    }

    & + & {
      margin-top: 8px;

      @include md-and-up {
        margin-top: 0;
      }
    }
  }

  &__label {
    @include spreadTypoMap($body-xsmall);
    color: $gray-50;
    margin-bottom: 4px;

    @include md-and-up {
      @include spreadTypoMap($body-medium);
      color: $gray-50;
      margin-bottom: 8px;
    }
  }

  &__title {
    @include spreadTypoMap($title-xsmall);
    color: $gray-60;
    margin-bottom: 0;

    @include md-and-up {
      @include spreadTypoMap($title-small);
      color: $gray-60;
      margin-bottom: 32px;
    }
  }

  &__icon {
    display: none;

    @include md-and-up {
      display: block;
      width: 40px;
      height: 40px;
    }
  }

  &__icon-check {
    background: url('@/icons/loanCheck.svg');
  }

  &__icon-puzzle {
    background: url('@/icons/loanPuzzle.svg');
  }

  &__icon-credential {
    background: url('@/icons/loanCredential.svg');
  }
}

.btn__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background: $hana-green-80;
    border-radius: 4px;
    padding: 10px 12px;
    @include spreadTypoMap($button-small);
    color: white;

    @include md-and-up {
      padding: 19px 24px;
      @include spreadTypoMap($button-large);
      color: white;
    }
  }
}
</style>
